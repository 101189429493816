import { EditAsideFreeTextAuthors } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import SimpleInput from '../asideSimpleInput/SimpleInput';
import AsideValidation from '../asideValidationErrors/AsideValidation';

const AsideFreeTextAuthor: React.FC<{
  config: EditAsideFreeTextAuthors;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const editHref = useEditHref();

  const readOnly = useReadOnly();

  const currentValue = useInitialValue(config.property)?.[0];

  const change = (value) => {
    dispatch(
      patchNodeAction(getResourceKey(editHref), {
        [config.property]: [value],
      })
    );
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <SimpleInput
        defaultValue={currentValue}
        label={config.label}
        readOnly={readOnly}
        onChange={change}
      />
    </AsideValidation>
  );
};

export default AsideFreeTextAuthor;

AsideFreeTextAuthor.displayName = 'AsideFreeTextAuthor';

AsideFreeTextAuthor.propTypes = {
  config: any,
};
