import { Content, ContentType } from '@generalTypes/apiTypes';

export const getGoalIdentifier = (pathToRoot: readonly Content[]) => {
  return pathToRoot.reduce((acc, item) => {
    const identifier = item.identifiers?.join('');
    if (identifier === undefined || identifier === '') {
      return acc;
    }

    if (
      item.type === ContentType.CURRICULUM_ODET_DEVELOPMENT_GOAL ||
      item.type === ContentType.CURRICULUM_ODET_ENDTERM
    ) {
      return `.${identifier}${acc}`;
    }

    if (item.type === ContentType.LLINKID_GOAL && acc !== '') {
      return `${identifier}.${acc}`; // only add the . for subgoals. in that case, acc should already have a value.
    }
    if (
      item.type === ContentType.CURRICULUM_ODET_LEERGEBIED ||
      item.type === ContentType.CURRICULUM_ODET_KEY_COMPETENCE
    ) {
      // if there was no subsection that added a number before the dot, we remove the dot from the accumulator
      return /^\./.test(acc) ? `${identifier} ${acc.replace(/\./g, '')}` : `${identifier} ${acc}`;
    }
    if (item.type === ContentType.LLINKID_CURRICULUM) {
      return `${identifier} ${acc}`;
    }
    return `${identifier}${acc}`;
  }, '');
};
