import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

require('./rowLegal.scss');

class rowLegal {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onChanges() {
    this.$scope.$broadcast('$$rebind::change');
  }

  saveInline(e, editorData) {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(editorData.key, {
        [editorData.field]: editorData.value,
      })
    );
  }
}

export const rowLegalComponent = {
  template: require('./rowLegal.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
  },
  controller: rowLegal,
};
