export const trustedFilter = [
  '$sce',
  ($sce) => {
    return (url) => {
      if (url !== false) {
        return $sce.trustAsResourceUrl(url);
      }
      return null;
    };
  },
];
