import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class asideDevelopmentPhase {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.anValues = [];
    this.anValues.push({
      href: '/content/f0b48d8f-5f1d-4030-b5ca-2acd96643664',
      name: 'Ontdekkers',
    });
    this.anValues.push({
      href: '/content/b965b75d-d9d9-4792-ae74-3d0aa767d566',
      name: 'Stille praters',
    });
    this.anValues.push({ href: '/content/416ac5de-c973-429d-9400-433fca41c274', name: 'Starters' });
    this.anValues.push({ href: '/content/d2dee605-227c-4c77-9100-62ed9feadf3b', name: 'Bouwers' });
    this.anValues.push({
      href: '/content/c2eeb16d-e68e-41ca-a41d-769506ab0c47',
      name: 'Taaldenkers',
    });
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const relation = state.document.apiWithPendingChanges.contentRelations.from[
        `/content/${this.sDocument.key}`
      ]
        ? state.document.apiWithPendingChanges.contentRelations.from[
            `/content/${this.sDocument.key}`
          ].find((rel) => rel.relationtype === 'REQUIRES')
        : null;

      let selectedDevPhase;
      if (relation) {
        selectedDevPhase = this.anValues.find((v) => v.href === relation.to.href);
      }

      return {
        developmentPhase: selectedDevPhase,
        relation,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  updateDevelopmentPhase() {
    if (!this.developmentPhase) {
      if (this.relation) {
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(this.relation.key));
      }
    } else if (this.relation) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.patchRelationAction(this.relation.key, {
          to: { href: this.developmentPhase.href },
        })
      );
    } else {
      const newRelation = {
        relationtype: 'REQUIRES',
        strength: 'MEDIUM',
        from: {
          href: `/content/${this.sDocument.key}`,
        },
        to: {
          href: this.developmentPhase.href,
        },
      };

      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation));
    }
  }
}

export default {
  template: require('./asideDevelopmentPhase.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideDevelopmentPhase,
};
