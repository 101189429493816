import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { removeRelationAction } from '@store/actions/documentActions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useDeleteRelationHook = () => {
  const dispatch = useDispatch();
  const confirmationService = getAngularService('ConfirmationService');

  const onDelete = useCallback(
    async (item) => {
      const confirmed = await confirmationService.confirmDeletion();
      if (confirmed) {
        dispatch(removeRelationAction(item.key));
      }
    },
    [confirmationService, dispatch]
  );

  return onDelete;
};
