import { extractContentAndRelations } from '@newStore/documentApi/documentApiHelpers';
import { addExtraInternalData } from '@newStore/documentApi/documentApiState';
import {
  addResourceAncestorsToLoad,
  addResourcesToLoad,
} from '@newStore/externalData/externalDataState';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getAllHrefs } from '@newStore/documentApi/documentApiDataAccess';
import { selectExternalContent } from '@newStore/externalData/externalDataSelectors';
import { addRelationAction } from '@store/actions/documentActions';
import { ContentWith$$Relations } from '@generalTypes/apiTypes';
import { waitFor } from '@store/helpers/sagaUtils';
import { selectApiWithPendingChanges } from '@newStore/documentApi/documentApiSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectPracticalExampleHrefsForZillIllustration,
  selectAllZillIllustrationGoalHrefsToLoad,
  selectZillIllustrationsHrefsForZillPracticalExample,
  selectZillIllustrationRelationsForPracticalExample,
} from './zillSelectors';
import { SelectedZillGoal, ZillGoalSubDetail } from './zillTypes';

function* loadZillGoalsForZillIllustrationSaga() {
  const goalHrefsToLoad: ReturnType<typeof selectAllZillIllustrationGoalHrefsToLoad> = yield select(
    selectAllZillIllustrationGoalHrefsToLoad
  );
  if (goalHrefsToLoad) {
    yield put(addResourceAncestorsToLoad({ resources: goalHrefsToLoad }));
  }
}

function* loadIllustrationsWithGoalsReferencingPracticalExampleSaga() {
  yield call(
    waitFor,
    (state: RootState) => Object.keys(selectApiWithPendingChanges(state).content).length > 0
  );
  const illustrationHrefsToLoad: ReturnType<
    typeof selectZillIllustrationRelationsForPracticalExample
  > = yield select(selectZillIllustrationsHrefsForZillPracticalExample);
  if (illustrationHrefsToLoad.length > 0) {
    const apiIllustrations: ContentWith$$Relations[] = yield call(
      getAllHrefs,
      illustrationHrefsToLoad
    );
    const { content: illustrations, relations } = extractContentAndRelations(apiIllustrations);
    const goalRelations = relations.filter(
      (rel) => rel.relationtype === 'REFERENCES' && rel.strength === 'LOW'
    );

    yield put(addExtraInternalData({ content: illustrations, relations: goalRelations }));

    yield loadZillGoalsForZillIllustrationSaga();
  }
}

function* loadPracticalExampleForZillIllustrationSaga() {
  const practicalExampleHrefs: string[] = yield select(
    selectPracticalExampleHrefsForZillIllustration
  );
  const externalContent: ReturnType<typeof selectExternalContent> = yield select(
    selectExternalContent
  );
  const practicalExampleHrefsToLoad = practicalExampleHrefs.filter(
    (href) => !externalContent[href]
  );

  if (practicalExampleHrefsToLoad.length > 0) {
    yield put(addResourcesToLoad({ resources: practicalExampleHrefsToLoad }));
  }
}

function* addSubDetailsToGoalOfExistingIllustrationSaga({
  payload,
}: PayloadAction<{ subDetail: ZillGoalSubDetail; existingGoal: SelectedZillGoal }[]>) {
  for (const { subDetail, existingGoal } of payload) {
    yield put(
      addRelationAction({
        relationtype: 'REFERENCES',
        from: {
          href: existingGoal.illustration.href,
        },
        to: {
          href: subDetail.href,
        },
        strength: 'LOW',
      })
    );
  }
}

export const loadIllustrationsReferencingPracticalExamples = createAction(
  'zill/loadIllustrationsReferencingPracticalExamples'
);
export const loadZillGoalsForZillIllustrations = createAction(
  'zill/loadZillGoalsForZillIllustration'
);
export const loadPracticalExampleForZillIllustrations = createAction(
  'zill/loadPracticalExampleForZillIllustrations'
);

export const addSubDetailToGoalOfExistingIllustration = createAction<
  {
    subDetail: ZillGoalSubDetail;
    existingGoal: SelectedZillGoal;
  }[]
>('zill/addSubDetailToGoalOfExistingIllustration');

export function* zillSaga() {
  yield takeEvery(
    loadIllustrationsReferencingPracticalExamples.match,
    loadIllustrationsWithGoalsReferencingPracticalExampleSaga
  );
  yield takeEvery(loadZillGoalsForZillIllustrations.match, loadZillGoalsForZillIllustrationSaga);
  yield takeEvery(
    loadPracticalExampleForZillIllustrations.match,
    loadPracticalExampleForZillIllustrationSaga
  );
  yield takeEvery(
    addSubDetailToGoalOfExistingIllustration.match,
    addSubDetailsToGoalOfExistingIllustrationSaga
  );
}
