/* eslint-disable @typescript-eslint/no-explicit-any */
import { api, cachedApi, contentApi, proposalApi } from '@store/api/apiConfig';
import {
  ContentHref,
  ContentKey,
  ContentWith$$Relations,
  Proposal,
  WebPage,
} from '@generalTypes/apiTypes';
import { NewsletterSettings } from '@generalTypes/newsletterTypes';
import { BatchResult } from '@generalTypes/sriTypes';

export const getAllByRoot = async (hrefs: string[]): Promise<ContentWith$$Relations[]> => {
  return contentApi.getAll('/content', { root: hrefs.join(','), limit: 6000 });
};

export const getDocumentByRoot = async (key: string): Promise<ContentWith$$Relations[]> => {
  return contentApi.getAll('/content', { root: key, limit: 6000 });
};

export const getCachedDocumentByRoot = async (key: ContentKey) => {
  const { hash } = await contentApi.get(`/content/${key}/hash`);
  try {
    return cachedApi.getAll(
      '/content',
      { root: key, limit: 6000 },
      { headers: { 'vsko-resource-hash': hash } }
    );
  } catch (error) {
    console.warn(
      `Failed to get document ${key} from cached api, we will try to fetch it from regular api`
    );
    return getDocumentByRoot(key);
  }
};

export const getProposalsByRoot = (key: string): Promise<Proposal[]> => {
  return proposalApi.getAll('/proposals', {
    statusIn: 'IN_PROGRESS,SUBMITTED_FOR_REVIEW',
    externalReferencesContains: `/content/${key}`,
  });
};

export const getNewsletterSettings = async (
  contentHref: string
): Promise<NewsletterSettings | null> => {
  // There can only be one newsletter settings resource for every content item
  const newsletterSettingsList = await api.getAll('/newsletter/settings', {
    'newsletter.href': contentHref,
  });
  if (newsletterSettingsList.length > 0) {
    return newsletterSettingsList[0];
  }
  return null;
};

export const getAllWebConfigurationsForRoot = (href: string): Promise<WebPage[]> => {
  return contentApi.getAll('/web/pages', {
    externalReferencesContains: href,
  });
};

export const getWebPagesForContent = async (hrefs: ContentHref[]): Promise<WebPage[]> => {
  const batch = hrefs.map((href) => {
    return {
      href: `/web/pages?externalReferencesContains=${href}&limit=500`,
      verb: 'GET',
    };
  });

  const resp: BatchResult = await contentApi.put('/web/pages/batch', batch);
  return resp.flatMap((r) => r.body?.results).map((z) => z.$$expanded);
};

export const getAllHrefs = async (hrefs) => {
  return contentApi.getAll('/content', { hrefs, limit: 5000 });
};
