import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { isEmpty } from '../../../reduxLoop/helpers/documentHelpers';

class rowNewsItem {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  hasEditSection(name, value) {
    const component = this.sItem.$$editSections.filter((o) => o.component === name);
    if (component.length > 0) {
      if (component[0].showPlaceholder) {
        return false;
      }
      if (component[0].view && component[0].view.hideIfEmpty && isEmpty(value)) {
        return false;
      }
      return true;
    }
    return false;
  }

  saveInline(scope, event, editorData) {
    const patch = {
      [editorData.field]: editorData.value,
    };
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(editorData.key, patch));
  }
}

export const rowNewsItemComponent = {
  template: require('./rowNewsItem.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<',
  },
  controller: rowNewsItem,
};
