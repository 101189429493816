import './asideThemes.scss';
import { escapeRegExp } from 'lodash';
import { thirdGradeOldStructure } from '@kathondvla/curricula-selector';
import he from 'he';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import * as NOTIFICATION_ACTIONS from '../../../reduxLoop/actions/notificationActions';
import {
  dienstverleningKovKey,
  sectionCurrVakkenEnLeerplannen,
} from '../../../reduxLoop/constants/constants';
import { hrefsUnderNode } from '../../../reduxLoop/helpers/documentHelpers';

class asideThemes {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.template = '/asideThemeTemplate.html';
  }

  $onInit() {
    // Set default options if they're not specified
    this.sConfig.options = this.sConfig.options || {
      referenceFrameKey: dienstverleningKovKey,
      types: ['THEME'],
    };

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const referenceFrame =
        state.document.viewModel.aside.referenceFrames[this.sConfig.options.referenceFrameKey] ||
        [];

      const referenceFrameOptions = referenceFrame.filter((item) => {
        if (!this.sConfig.options.types.includes(item.type)) {
          return false;
        }
        return this.sConfig.options.filter ? this.sConfig.options.filter(item) : true;
      });

      let highlightHrefs = [];
      if (this.sConfig.options.highlightOldCurrItems) {
        const highlightSection = referenceFrame.find(
          (x) => x.key === sectionCurrVakkenEnLeerplannen
        );
        const oldCurrHrefs = hrefsUnderNode(highlightSection);
        const thirdGradeOldStructureHrefs = [
          ...thirdGradeOldStructure.studyFieldHrefs,
          ...thirdGradeOldStructure.subjectHrefs,
        ];
        highlightHrefs = oldCurrHrefs.filter((href) => !thirdGradeOldStructureHrefs.includes(href));

        referenceFrameOptions.forEach((option) => {
          if (option.title) {
            option.title = he.decode(option.title);
          }
          option.display = he.decode(option.display);
          option.highlight = highlightHrefs.includes(option.$$meta.permalink);
        });
      }

      const nodeThemes = state.document.viewModel.aside.editDocument.themes;
      const selectedThemes = referenceFrameOptions.filter(
        (o) => nodeThemes && nodeThemes.value.includes(o.$$meta.permalink)
      );
      const otherThemes = nodeThemes
        ? nodeThemes.value.filter(
            (themeHref) => !selectedThemes.some((t) => t.$$meta.permalink === themeHref)
          )
        : [];

      return {
        themes: nodeThemes,
        referenceFrameOptions,
        selectedThemes,
        otherThemes,
        themesLoading:
          !state.document.viewModel.aside.referenceFrames[this.sConfig.options.referenceFrameKey],
        isReadOnly: state.document.viewModel.aside.isReadOnly,
        editKey: state.document.viewModel.aside.editDocument.key,
        documentType: state.document.viewModel.aside.editDocument.type,
      };
    })(this);

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.getReferenceFrameAction({ key: this.sConfig.options.referenceFrameKey })
    );

    if (this.documentType === 'TEASER') {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.loadNamedSetsAction('doelgroepen'));
    }
  }

  $onDestroy() {
    this.unsubscribe();
  }

  showWarning() {
    return this.documentType === 'SHARED_MINI_DATABASE_ITEM' && this.selectedThemes.length === 0;
  }

  change(e, items) {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchThemes(
        this.editKey,
        items.map((i) => i.$$meta.permalink).concat(this.otherThemes)
      )
    );
  }

  pasteEventFunction = ($event) => {
    const event = $event.originalEvent;
    const regExp = /(\/\w+)+\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
    const paste = (event.clipboardData || window.clipboardData).getData('text');
    const permalinks = paste.match(regExp);
    if (!permalinks) {
      return;
    }

    event.preventDefault(); // only prevent default if we have a permalink matched;
    const foundTags = [];
    const notFoundTags = [];

    permalinks.forEach((permalink) => {
      const foundTag = this.referenceFrameOptions.find((e) => e.$$meta.permalink === permalink);

      if (!foundTag) {
        notFoundTags.push(permalink);
        console.warn("Couldn't find tag for", permalink);
        return;
      }
      foundTags.push(permalink);
    });

    if (notFoundTags.length > 0) {
      const lines = paste.replace('\r', '').split('\n');
      const notFoundLines = notFoundTags
        .map((href) => lines.find((str) => str.includes(href)))
        .join('<br/>');
      this.$ngRedux.dispatch(
        NOTIFICATION_ACTIONS.addNotificationAction({
          type: 'WARNING',
          removeAfter: 0,
          message: 'edit.pastedThemeNotFound',
          params: {
            listOfLabels: notFoundLines,
          },
        })
      );
    }

    if (foundTags.length > 0) {
      const newTags = [
        ...new Set([
          ...this.otherThemes,
          ...this.selectedThemes.map((e) => e.$$meta.permalink),
          ...foundTags,
        ]),
      ];
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchThemes(this.editKey, newTags));
    }
    event.target.blur(); // doesn't close the dropdown in the resource picker.
  };

  filterData(data, str) {
    if (str.length === 0) {
      return data;
    }
    return data.filter((elm) => {
      const regExp = new RegExp(escapeRegExp(str), 'gi');
      return regExp.test(elm.title) || regExp.test(elm.name);
    });
  }
}

export default {
  template: require('./asideThemes.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideThemes,
};
