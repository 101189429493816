import { isExternalLinkHttp } from '../../../../reduxLoop/helpers/documentHelpers';

class linkModal {
  constructor($notification, $translate, $ngRedux, $scope) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
  }

  hasEmptyUrl() {
    return this.link.title && !this.link.url;
  }

  isExternalLinkInvalid() {
    return this.link.title && this.link.url && !isExternalLinkHttp(this.link.url);
  }

  async submit() {
    if (!this.hasEmptyUrl() && !this.isExternalLinkInvalid()) {
      this.modalInstance.close(this.link);
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  $onInit() {
    // console.log('Init data:', this.resolve.modalData);
    this.link = {
      title: this.resolve.modalData.title,
      url: this.resolve.modalData.url,
    };
    this.modalTitle = this.resolve.modalData.modalTitle;
  }
}

export default {
  template: require('./linkModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: linkModal,
};
