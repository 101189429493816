import './inlineMarkExplanation.scss';
import { getResourceKey } from '../../../reduxLoop/helpers/documentHelpers';

class inlineMarkExplanation {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (this.markExplanation || !state.document.viewModel.markExplanationReferences) {
        return {};
      }
      const markExplanation = state.document.viewModel.markExplanationReferences.find(
        (md) => md.key === getResourceKey(this.sHref)
      );
      return { markExplanation };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  getPopoverHtml() {
    if (!this.markExplanation) {
      return '';
    }
    const description = this.markExplanation.description
      ? `<br><span class="tooltip-popover-description">${this.markExplanation.description}</span>`
      : '';
    return this.markExplanation.title + description;
  }
}

export const inlineMarkExplanationComponent = {
  transclude: true,
  template: require('./inlineMarkExplanation.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHref: '@',
  },
  controller: inlineMarkExplanation,
};
