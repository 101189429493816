import * as constants from '@store/constants/constants';

export const themeNotEmpty = (node, state) => {
  const themeReferenceFrame = state.referenceFrame[constants.dienstverleningKovKey];

  return themeReferenceFrame &&
    themeReferenceFrame.some((i) => node.themes?.includes(i.$$meta.permalink))
    ? true
    : { type: 'WARNING', message: 'validation.themeNotEmpty' };
};
