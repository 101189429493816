import { any } from 'prop-types';
import React from 'react';
import { getResourceKey } from '@store/helpers/documentHelpers';
import AsideEditor from '@UI/editor/AsideEditor';
import { EditAsideDescription } from '@nodeTypeConfig/configTypes';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';

const AsideDescription: React.FC<{
  config: EditAsideDescription;
}> = ({ config }) => {
  const editHref = useEditHref();

  const initialValue = useInitialValue(config.property);

  const readOnly = useReadOnly();

  const label = config.fullLabel ?? config.label;

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        {label && <label className="control-label control-label-inline">{label}</label>}
        <AsideEditor
          placeholder={''}
          skey={getResourceKey(editHref)}
          initial={initialValue}
          field={config.property}
          readOnly={readOnly}
          plainText={false}
          inline={false}
          showCharacterCount={true}
        ></AsideEditor>
      </AsideValidation>
    </div>
  );
};

AsideDescription.displayName = 'AsideDescription';

export default AsideDescription;

AsideDescription.propTypes = {
  config: any,
};
