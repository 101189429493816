import InlineEditor from '@UI/editor/InlineEditor';
import { ZillLeerlijnStepNode } from '@newStore/documentUI/documentUITypes';
import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import './ZillLeerlijn.scss';
import { getPlaceHolderLabel } from '../../contentRowHelpers';

const ZillLeerlijnStepRow: React.FC<{ contentNode: ZillLeerlijnStepNode }> = ({ contentNode }) => {
  const nodeTypeConfig = useSelector((state: RootState) =>
    selectAppliedNodeConfig(state, contentNode.href)
  );

  return (
    <div className="zill-row-container">
      <div>
        {contentNode.ageRangeLabel && (
          <span className="timeline-age-box">{contentNode.ageRangeLabel}</span>
        )}
      </div>
      <div>
        <InlineEditor
          initial={contentNode.description}
          field="description"
          placeholder={getPlaceHolderLabel(nodeTypeConfig, 'description')}
          skey={contentNode.key}
          href={contentNode.href}
        ></InlineEditor>
      </div>
    </div>
  );
};

export default ZillLeerlijnStepRow;
