class RenderStartEndAge {
  constructor() {
    'ngInject';

    this.ageRangeManager = require('@kathondvla/age-range-manager');
  }

  showRange() {
    const isCluster = this.sLeerlijn.type === 'CURRICULUM_ZILL_LEERLIJN_CLUSTER';
    const isEmpty =
      (this.dateOption.startageYear === 0 || this.dateOption.startageYear === undefined) &&
      (this.dateOption.startageMonth === 0 || this.dateOption.startageMonth === undefined) &&
      (this.dateOption.endageYear === 0 || this.dateOption.endageYear === undefined) &&
      (this.dateOption.endageMonth === 0 || this.dateOption.endageMonth === undefined);
    return isCluster && !isEmpty;
  }

  showDevelopmentPhase() {
    return this.developmentPhase;
  }

  $onChanges() {
    if (this.sLeerlijn) {
      this.ageRangeText = this.ageRangeManager.ageRangeToString(
        this.sLeerlijn.startage,
        this.sLeerlijn.endage
      );
      this.isEmptyRange = this.ageRangeText === '';
    }
  }
}

export default {
  template: require('./renderStartEndAge.html'),
  controllerAs: 'ctrl',
  bindings: {
    sLeerlijn: '<',
  },
  controller: RenderStartEndAge,
};
