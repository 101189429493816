import { oauthClient, oldSecurityApi, personsApi, securityApi } from '@store/api/apiConfig';
import { PersonHref } from '@generalTypes/apiTypes';
import { settings } from '../../config/settings';

export const getMe = async () => {
  try {
    const resp = await oauthClient.get('/me', undefined, { credentials: 'include' });
    return resp;
  } catch (error) {
    if (error.status === 403) {
      window.location.href = `${settings.oauth.authenticate}?scope=${
        settings.oauth.scope
      }&response_type=none&client_id=${settings.oauth.clientID}&redirect_uri=${
        settings.oauth.redirectUri
      }&state=${encodeURIComponent(window.location.origin)}`;
    } else {
      console.error('[user commands] Error fetching /me', error);
      throw error;
    }
  }
};

export const getSecurityData = async (userKey: string) => {
  const ret: { [key: string]: Array<string> } = {};
  const tasks = settings.securityModes.map(async (mode) => {
    const securityClient = mode.useOldSecurity ? oldSecurityApi : securityApi;
    const securityGroups: string[] = await securityClient.get('/security/query/resources/raw', {
      person: `/persons/${userKey}`,
      component: mode.componentHref,
      ability: mode.ability,
    });
    ret[mode.type] = securityGroups;
  });
  await Promise.all(tasks);

  return ret;
};

export const getUserRoles = (userKey: string) => {
  return securityApi.get('/security/query/abilities', {
    person: `/persons/${userKey}`,
    component: settings.redactieSecurityComponent,
    resource: 'NONE',
  });
};

export const getPerson = (href: PersonHref) => {
  return personsApi.get(href);
};
