import { isAttachmentAttachmentMetaInfo } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector, parentChildRelationFilter } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { Attachment, AttachmentsGroupNode } from '../documentUITypes';
import { selectNodeType } from '../nodeTypeConfigSelectors';
import { selectProposedContentHrefsToDelete } from '../transformProposal/proposalSelectors';
import {
  getAttachmentCreateDate,
  getAttachmentFileSize,
  getFileTag,
  getFileType,
  getIconSrc,
} from './attachmentHelpers';

const selectAttachmentGroupAttachments = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectApiWithPendingChanges(state).content,
    selectProposedContentHrefsToDelete,
    (state, href: string) => href,
  ],
  (relationsMap, content, proposedHrefsToDelete, href): Array<Attachment> =>
    relationsMap.to[href]
      .filter(parentChildRelationFilter)
      .sort((c1, c2) => Number(c1.readorder) - Number(c2.readorder))
      .flatMap((childRel) => {
        const contentAttachment = content[childRel.from.href];
        if (!contentAttachment) {
          // if global document is included there is a moment it still needs to be loaded by externalData slice
          return [];
        }
        // ATTACHMENT of ATTACHMENTS_GROUP normally has one and only one attachment elem in attachments array which is of type ATTACHMENT
        const attachment = contentAttachment.attachments.find(isAttachmentAttachmentMetaInfo);
        if (!attachment) {
          console.warn(
            'corrupt content of type ATTACHMENT with no element in attachments array of type ATTACHMENT',
            contentAttachment
          );
          return [];
        }
        return {
          attachmentKey: contentAttachment.key,
          isDeletedByProposal: proposedHrefsToDelete.includes(contentAttachment.$$meta.permalink),
          title: contentAttachment.title,
          description: contentAttachment.description,
          fileTypeIconSrc: getIconSrc(attachment),
          createDate: getAttachmentCreateDate(attachment),
          fileType: getFileType(attachment),
          fileTag: getFileTag(contentAttachment),
          fileSize: getAttachmentFileSize(attachment),
        };
      })
);

export const selectAttachmentsGroupNode = createTypedSelector(
  [
    (state, href) => selectContentItem(state, href),
    selectAttachmentGroupAttachments,
    (state, href) => selectNodeType(state, href),
  ],
  (content, attachments, type): AttachmentsGroupNode => {
    if (!(type === NodeType.ATTACHMENTS_GROUP || type === NodeType.SHARED_ATTACHMENTS_GROUP)) {
      throw new Error('only call selectAttachmentsGroupNode for the correct types');
    }
    return {
      href: content.$$meta.permalink,
      key: content.key,
      type,
      customRender: true,
      attachments,
    };
  }
);
