import { GlobalDocumentNode } from '@newStore/documentUI/documentUITypes';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
// eslint-disable-next-line
import downloadIcon from '!!raw-loader!@img/download.svg';
import { useDispatch } from 'react-redux';
import { updateAsideViewModelAction } from '@store/actions/documentActions';
import { getBase64 } from '@store/helpers/documentHelpers';
import uuidv4 from 'uuid/v4';
import { useState } from 'react';
import { replaceContentAttachmentOfGlobalDocument } from '@newStore/pendingActions/pendingActionsSagas';
import { AttachmentToUploadMetaInfo } from '@generalTypes/apiTypes';

const GlobalDocumentRow: React.FC<{ contentNode: GlobalDocumentNode }> = ({ contentNode }) => {
  const dispatch = useDispatch();
  const [embedUrl, setEmbedUrl] = useState(contentNode.embedUrl);

  const replaceAttachment = async () => {
    const opts = {
      component: 'browseFileModal',
      resolve: {
        modalHeaderTitle: () => `Nieuwe versie voor ${contentNode.title}`,
        browseLabel: () => 'Kies een nieuwe versie',
      },
    };
    const modalWindowService = getAngularService('ModalWindowService');
    const response = await modalWindowService.open(opts);
    if (response) {
      const base64 = await getBase64(response);
      const newAttachment: AttachmentToUploadMetaInfo = {
        key: uuidv4(),
        type: 'CONTENT',
        name: response.name,
        size: response.size,
        $$base64: base64,
        isNew: true,
        created: new Date(),
        contentType: response.type,
      };
      dispatch(replaceContentAttachmentOfGlobalDocument({ newAttachment }));
      setEmbedUrl(base64);
    }
  };

  return (
    <div className="row-unstructured-document">
      <div className="button-row">
        <a
          className="download-link"
          href={contentNode.downloadUrl}
          target="_blank"
          download
          rel="noreferrer"
        >
          <span>{contentNode.attachmentName}</span>
          <span className="download-icon" dangerouslySetInnerHTML={{ __html: downloadIcon }}></span>
        </a>
        <button className="btn btn-purple btn-lg" onClick={replaceAttachment}>
          Vervangen
        </button>
      </div>

      {contentNode.previewPages.length === 0 && contentNode.isPdf && (
        <embed className="embeded-pdf" src={embedUrl} width="100%" height="1200px" />
      )}
      <div>
        {contentNode.previewPages.map((page) => (
          <img
            key={page.key}
            className="preview-img"
            src={page.src}
            onClick={() => {
              getAngularService('$state').go('edit.aside', { editKey: contentNode.key });
              dispatch(updateAsideViewModelAction(contentNode.key));
            }}
          />
        ))}
      </div>
      {contentNode.previewPages.length === 0 && !contentNode.isPdf && (
        <div className="no-preview-msg">
          Er is nog geen preview beschikbaar voor dit document. <br />
          De preview wordt gegenereerd binnen het kwartier na het aanmaken of vervangen van een
          gedeeld document
        </div>
      )}
    </div>
  );
};

export default GlobalDocumentRow;
