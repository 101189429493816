import { BuildingBlockNodeConfig, NodeType, RequiredType } from '@nodeTypeConfig/configTypes';

const CURRICULUM_THEME: BuildingBlockNodeConfig = {
  information: {
    single: 'Leerplanthema',
    plural: "Leerplanthema's",
  },

  buildingBlocks: [{ type: NodeType.CURRICULUM_THEME }, { type: NodeType.THEME }],
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      label: 'Titel',
      required: RequiredType.ERROR,
    },
  ],
  onNewNodeDropped: {
    focusOnField: 'title',
  },
  confirmDelete: true,
  isCollapsible: false,
};

export default CURRICULUM_THEME;
