import { AttachmentAttachmentMetaInfo, AttachmentMetaInfo, Content } from '@generalTypes/apiTypes';
import { formatDate } from '@newStore/genericHelpers';

export const attachmentTypeConversionMap = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    name: 'Ms Word-document',
    icon: require('../../../../img/icons/doc.svg'),
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': {
    name: 'Ms Word-document',
    icon: require('../../../../img/icons/doc.svg'),
  },
  'application/msword': {
    name: 'Ms Word-document',
    icon: require('../../../../img/icons/doc.svg'),
  },
  'application/pdf': {
    name: 'Adobe Acrobat Document',
    icon: require('../../../../img/icons/pdf.svg'),
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    name: 'Ms Excel-document',
    icon: require('../../../../img/icons/xls.svg'),
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': {
    name: 'Ms Excel-document',
    icon: require('../../../../img/icons/xls.svg'),
  },
  'application/vnd.ms-excel': {
    name: 'Ms Excel-document',
    icon: require('../../../../img/icons/xls.svg'),
  },
  'video/mp4': {
    name: 'Video MP4',
    icon: require('../../../../img/icons/mp4.svg'),
  },
  'audio/mp3': {
    name: 'Audio MP3',
    icon: require('../../../../img/icons/mp3.svg'),
  },
  'audio/mpeg': {
    name: 'Audio MP3',
    icon: require('../../../../img/icons/mp3.svg'),
  },
  'image/jpeg': {
    name: 'Image JPEG',
    icon: require('../../../../img/icons/jpg.svg'),
  },
  'image/jpg': {
    name: 'Image JPG',
    icon: require('../../../../img/icons/jpg.svg'),
  },
  'image/png': {
    name: 'Image PNG',
    icon: require('../../../../img/icons/jpg.svg'),
  },
  'image/tiff': {
    name: 'Image TIFF',
    icon: require('../../../../img/icons/jpg.svg'),
  },
  'application/vnd.ms-powerpoint': {
    name: 'Ms PowerPoint-document',
    icon: require('../../../../img/icons/ppt.svg'),
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    name: 'Ms PowerPoint-document',
    icon: require('../../../../img/icons/ppt.svg'),
  },
  'application/vnd.openxmlformats-officedocument.presentationml.template': {
    name: 'Ms PowerPoint-document',
    icon: require('../../../../img/icons/ppt.svg'),
  },
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': {
    name: 'Ms PowerPoint-document',
    icon: require('../../../../img/icons/ppt.svg'),
  },
};

export const attachmentDocumentTypes = [
  {
    display: 'advies',
    value: 'ADVISE',
  },
  {
    display: 'afbeelding',
    value: 'IMAGE',
  },
  {
    display: 'arrest',
    value: 'ARREST',
  },
  {
    display: 'artikel',
    value: 'ARTICLE',
  },
  {
    display: 'beslissingen',
    value: 'DECISIONS',
  },
  {
    display: 'brief',
    value: 'LETTER',
  },
  {
    display: 'CAO',
    value: 'CAO',
  },
  {
    display: 'convenant',
    value: 'CONVENANT',
  },
  {
    display: 'formulier',
    value: 'FORM',
  },
  {
    display: 'handleiding',
    value: 'MANUAL',
  },
  {
    display: 'kalender',
    value: 'CALENDAR',
  },
  {
    display: 'leerplan',
    value: 'CURRICULUM',
  },
  {
    display: 'lezing',
    value: 'LECTURE',
  },
  {
    display: 'lijst',
    value: 'LIST',
  },
  {
    display: 'mededeling',
    value: 'ANNOUNCEMENT',
  },
  {
    display: 'model',
    value: 'MODEL',
  },
  {
    display: 'nieuwsbrief',
    value: 'NEWSLETTER',
  },
  {
    display: 'nota',
    value: 'BILL',
  },
  {
    display: 'ontwerp wetgeving',
    value: 'DRAFT_LEGISLATION',
  },
  {
    display: 'parlementaire vraag',
    value: 'PARLIAMENTARY_QUESTION',
  },
  {
    display: 'persmedeling',
    value: 'PRESS_RELEASE',
  },
  {
    display: 'presentatie',
    value: 'PRESENTATION',
  },
  {
    display: 'publicatie',
    value: 'PUBLICATION',
  },
  {
    display: 'reglement',
    value: 'RULES',
  },
  {
    display: 'uitnodiging',
    value: 'INVITATION',
  },
  {
    display: 'vacature',
    value: 'VACANCY',
  },
  {
    display: 'verslag',
    value: 'REPORT',
  },
  {
    display: 'visietekst',
    value: 'VISIONTEXT',
  },
  {
    display: 'wetgeving',
    value: 'LEGISLATION',
  },
];

export const getIconSrc = (attachment: AttachmentMetaInfo) => {
  if (attachment) {
    const { contentType } = attachment;
    const icon = attachmentTypeConversionMap[contentType]
      ? attachmentTypeConversionMap[contentType].icon
      : null;

    if (icon !== '') {
      return icon;
    }
    return 'img/icons/file.svg';
  }

  return '';
};

export const getAttachmentCreateDate = (attachment: AttachmentMetaInfo) => {
  if (attachment) {
    let creationDate;

    if (attachment.$$meta && attachment.$$meta.created) {
      creationDate = new Date(attachment.$$meta.created);
    }

    if (creationDate === undefined) {
      return '-';
    }

    return formatDate(creationDate);
  }

  return '';
};

export const getFileType = (attachment: AttachmentMetaInfo) => {
  if (attachment) {
    const { contentType } = attachment;
    const label = attachmentTypeConversionMap[contentType]
      ? attachmentTypeConversionMap[contentType].name
      : null;

    if (label !== '') {
      return label;
    }

    if (contentType.length > 25) {
      return `${contentType.substring(0, 23)}...`;
    }

    return contentType;
  }

  return '';
};

export const getFileTag = (contentAttachment: Content) => {
  if (contentAttachment.tags && contentAttachment.tags.length > 0) {
    let value = '';
    contentAttachment.tags.forEach((tag) => {
      const attachType = attachmentDocumentTypes.find((t) => t.value === tag);
      if (attachType) {
        value = attachType.display;
      }
    });
    return value;
  }
  return '-';
};

export const getAttachmentFileSize = (attachment: AttachmentAttachmentMetaInfo) => {
  if (!attachment) {
    return '-';
  }

  const sizeInBytes = attachment.size;

  if (sizeInBytes === undefined) {
    return '-';
  }

  if (sizeInBytes >= 1048576) {
    return `${(sizeInBytes / 1024 / 1024).toFixed(0)} MB`;
  }

  return `${(sizeInBytes / 1024).toFixed(0)} KB`;
};
