import { BuildingBlock, NodeType } from '@nodeTypeConfig/configTypes';

export const llinkidDefaultBuildingBlocks: Array<BuildingBlock> = [
  { type: NodeType.SECTION },
  { type: NodeType.PARAGRAPH },
  { type: NodeType.IMAGE },
  // { type: ContentType.IMAGE_GROUP },
  { type: NodeType.REFERENCE_GROUP },
  // { type: NodeType.EXAMPLE },
  // { type: ContentType.ATTACHMENTS_GROUP },
  // { type: ContentType.MUSIC },
  { type: NodeType.VIDEO },
  // { type: ContentType.VIDEO_GROUP },
  // { type: ContentType.QUOTE },
];
