import { useDispatch } from 'react-redux';
import { unCollapseNode } from '@newStore/documentUI/documentUIState';
import { ContentHref } from '@generalTypes/apiTypes';
import { getContentNodeId } from '@newStore/documentApi/documentApiHelpers';

export const useScrollToNode = (onlyScrollIfNotInViewport = false) => {
  const dispatch = useDispatch();

  const scrollToNode = (href: ContentHref, parentHref: ContentHref | undefined) => {
    console.log('scrolling to ', href, parentHref);
    dispatch(unCollapseNode({ nodeHref: href }));

    /**
     * Scroll to the element after a short delay to ensure that the element is rendered
     * when images get loaded, the scroll position might be off, so we jump to the element again after each image load, for 0.5s
     */
    setTimeout(() => {
      const element = document.getElementById(getContentNodeId({ childHref: href, parentHref }));
      const scrollingParent = document.getElementById('content');

      if (element && scrollingParent) {
        const onLoad = () => {
          // Check if the element is within 80px of its scrolling parent
          const distanceFromTop =
            element.getBoundingClientRect().top - scrollingParent.getBoundingClientRect().top;
          const isAtTheTop = distanceFromTop <= 80 && distanceFromTop >= -80;
          if (!isAtTheTop) {
            // console.log('scrolling to', href);
            element.scrollIntoView({ behavior: 'instant', block: 'start' });
            // Adjust scroll position to leave some margin at the top
            scrollingParent.scrollBy(0, -15);
          } else {
            // console.log('already in view', href);
          }
        };

        const rect = element.getBoundingClientRect();
        const isInViewPort = rect.top >= 0 && rect.top <= window.innerHeight;

        if (!isInViewPort || !onlyScrollIfNotInViewport) {
          onLoad();

          const { images } = document;

          for (let i = 0; i < images.length; i += 1) {
            images[i].addEventListener('load', onLoad);
          }

          // Stop listening after 0.5 seconds, since this blocks the user from scrolling away whilst images are loaded.
          setTimeout(() => {
            for (let i = 0; i < images.length; i += 1) {
              images[i].removeEventListener('load', onLoad);
            }
          }, 500);
        }
      }
    }, 200);
  };

  return scrollToNode;
};
