import { RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { publishComponent, titleComponent } from '@nodeTypeConfig/helpers/genericComponents';

const MARK_EXPLANATION: RootNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'Markeerverklaring',
    plural: 'Markeerverklaringen',
    icon: require('../../../../img/icons/mark-explanation.svg'),
  },

  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [],
  tocTypes: [],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
      required: RequiredType.ERROR,
    },
    publishComponent,
  ],
  isCreatable: true,
  isSearchable: true,
  allowSuggestions: false,
};

export default MARK_EXPLANATION;
