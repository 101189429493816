module.exports = [
  '$q',
  'settings',
  function ($q, settings) {
    const service = {};
    let mySelector;
    let messageEvent;
    let onEventFn;

    service.openZillSelector = async function (
      zillHref,
      alreadySelectedValues,
      returnButtonName = 'praktijkvoorbeeld'
    ) {
      const defer = $q.defer();
      if (mySelector) {
        // if a new selector is opened, but there was already another selector opened we will close the latter and remove the event listener
        mySelector.close();
        window.removeEventListener(messageEvent, onEventFn);
      }

      let state = {
        activity: {
          class: false,
          pupilGroup: {},
          selections: [],
        },
        curriculum: {
          version: zillHref,
        },
        put: false,
        returnButton: returnButtonName,
      };

      if (alreadySelectedValues) {
        state.activity.selections = alreadySelectedValues;
      }

      const goToSelectorNewWindow = function () {
        mySelector = window.open(`${settings.apisAndUrls.zillSelector}/#/?waitForState`);
        let checkChildTimer = null;
        function checkChild() {
          if (mySelector && mySelector.closed) {
            // handle when the user manually closed the zill selector
            window.removeEventListener(messageEvent, onEventFn);
            defer.reject('ZILL_SELECTOR_ABORTED');
            clearInterval(checkChildTimer);
          }
        }
        checkChildTimer = setInterval(checkChild, 500);
      };

      const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
      const eventer = window[eventMethod];

      onEventFn = (event) => {
        // make your application secure by checking on the origin of the message
        if (event.origin !== settings.apisAndUrls.zillSelector) {
          return;
        }
        console.log('Zill Selector Event:', event);

        if (event.data.event === 'loaded') {
          // when zill selector is loaded it will send this event upon redactie will send the current state.
          mySelector.postMessage({ status: 0, state }, '*');
        } else {
          // handle when
          if (mySelector) {
            mySelector.close();
            window.removeEventListener(messageEvent, onEventFn);
          }
          if (event.data.status === 1) {
            // handle confirmation button of zill selector clicked.
            state = event.data.state;
            defer.resolve(event.data.state.activity.selections);
          } else {
            defer.reject('UNKNOWN_STATUS_FROM_ZILL_EVENT_LISTENER');
          }
        }
      };

      eventer(messageEvent, onEventFn); // = window.addEventListener(name, eventListenerFn)
      goToSelectorNewWindow();

      return defer.promise;
    };

    return service;
  },
];
