// eslint-disable-next-line import/no-webpack-loader-syntax
import LinkIcon from '!!raw-loader!../../../../img/icons/customLink.svg';
import tinymce, { Editor } from 'tinymce/tinymce';
import { getStore } from '@store/store';
import { selectContentItem, selectDocumentRoot } from '@newStore/documentApi/documentApiSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectContentNodeChildren } from '@newStore/documentUI/documentUISelectors';
import { Content } from '@generalTypes/apiTypes';
import { openModal, setupButton } from './editorHelpers';

const renderFunction = (modalResult, selectedText) => {
  return `<a href="${modalResult.href}" rel="${modalResult.rel}" ${
    modalResult.root ? `root="${modalResult.root}"` : ''
  }>${selectedText}</a>`;
};

const getChildrenSections = (state, nodeHref, $$parent) => {
  const fullNode = {
    ...selectContentItem(state, nodeHref),
    $$parent,
  };
  if (!$$parent || ['SECTION', 'WEBPAGE2'].includes(fullNode.type)) {
    return selectContentNodeChildren(state, nodeHref).reduce(
      (acc, curr) => [...acc, ...getChildrenSections(state, curr, fullNode)],
      [fullNode]
    );
  }
  return [];
};

const onAction = async (editor: Editor) => {
  const state = getStore().getState() as unknown as RootState;
  const documentRoot = selectDocumentRoot(state);
  const children = getChildrenSections(state, documentRoot?.$$meta.permalink, null);
  const linkData: {
    currentDocument: Content | null;
    sections: object;
    href?: string | null;
    root?: string | null;
  } = {
    currentDocument: documentRoot,
    sections: children,
  };

  const selectedNode = editor.selection.getNode();
  if (selectedNode.tagName === 'A') {
    // opening existing link
    linkData.href = selectedNode.getAttribute('href');
    linkData.root = selectedNode.getAttribute('root');
  }

  const modalOptions = {
    component: 'proWebsiteLinkButtonModalForm',
    dataForModal: linkData,
  };
  openModal(editor, modalOptions, renderFunction);
  setTimeout(() => {
    // Workaround to avoid the built in link window showing when the user presses ctrl+k
    if (tinymce?.activeEditor) {
      tinymce.activeEditor.windowManager.close();
    }
  }, 10);
};

const isEnabledButton = (editor: Editor) =>
  editor.selection.getNode().getAttribute('rel') !== 'term' &&
  (!editor.selection.isCollapsed() || editor.selection.getNode().tagName === 'A');

const exists = tinymce.PluginManager.get('customLink');
if (!exists) {
  tinymce.PluginManager.add('customLink', (editor) => {
    setupButton(editor, 'customLink', 'Link', LinkIcon as string, onAction, isEnabledButton);
    editor.addShortcut('ctrl+k', 'Open link modal', () => {
      onAction(editor);
    });
  });
}
