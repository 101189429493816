import { RootState } from '@generalTypes/rootStateTypes';
import { EditAsideValidityPeriod } from '@nodeTypeConfig/configTypes';
import { selectCurrentEditingNode } from '@store/selectors/asideSelectors';
import { any } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import SimpleInput from './SimpleInput';

const AsideValidityPeriod: React.FC<{
  config: EditAsideValidityPeriod;
}> = ({ config }) => {
  const defaultValue = useSelector((state: RootState) => {
    const item = selectCurrentEditingNode(state);
    // @ts-expect-error doesn't know about validityPeriod
    const validityPeriod = item.$$validityPeriod;
    if (!validityPeriod) {
      return 'Nog niet van toepassing';
    }

    const msg = `Vanaf schooljaar ${validityPeriod.start} ${
      validityPeriod.end ? `tot en met schooljaar ${validityPeriod.end}` : ''
    }`;
    return msg;
  });

  const readOnly = useReadOnly(config.readonly);

  return (
    <AsideValidation property={config.property} component={config.component}>
      <SimpleInput
        defaultValue={defaultValue}
        label={config.label}
        readOnly={readOnly}
        onChange={(_value) => {}}
      />
    </AsideValidation>
  );
};

AsideValidityPeriod.displayName = 'AsideValidityPeriod';

export default AsideValidityPeriod;

AsideValidityPeriod.propTypes = {
  config: any,
};
