import './ContentChangedIndicator.scss';
import { RootState } from '@generalTypes/rootStateTypes';
import { useSelector } from 'react-redux';
import { selectModifiedSinceLastReadInfo } from '@newStore/documentUI/documentUISelectors';

const ContentChangedIndicator: React.FC<{ href: string; height90?: boolean }> = ({
  href,
  height90,
}) => {
  const modifiedSinceLastReadText = useSelector((state: RootState) =>
    selectModifiedSinceLastReadInfo(state, href)
  );

  if (!modifiedSinceLastReadText) {
    return null;
  }

  return (
    <div
      className="redactie-content-changed-indicator"
      style={{ height: height90 ? '90%' : undefined }}
    >
      <div className="content-changed-container" style={{ width: '4px' }}>
        <div className="line" title={modifiedSinceLastReadText}></div>
      </div>
    </div>
  );
};

export default ContentChangedIndicator;
