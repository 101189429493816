import React from 'react';
import { string } from 'prop-types';

const he = require('he');

const EncodedText = ({ className = '', children, text }) => {
  if (!children && !text) {
    return null;
  }
  const decodedText = he.decode(children ?? text);

  return <span className={className}>{decodedText}</span>;
};

EncodedText.propTypes = {
  text: string,
};

export default EncodedText;
