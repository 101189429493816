import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SriContentList } from '@generalTypes/apiTypes';
import { areAllResultsSelected, createApiPayloads } from './newDocumentListHelpers';
import { DocumentListState, SearchParams } from './newDocumentListTypes';

/**
 * This is the initial state of this reducer.
 */
const initialState: DocumentListState = {
  loading: false,
  loadingMore: false,
  results: [],
  selected: {}, // all the selected results
  searchParams: {},
  publishModalOpen: false, // state of the publish modal
  createContentModalOpen: false, // state of the create content modal
  confirmationModalOpen: false,
  createType: '',
  apiPending: [], // array of batch opereation pending to be sent to the api
  proposalsToReviewCount: {},
  creatingCopy: false,
  creatingNewVersion: false,
  deleting: false,
  saveAttempted: false,
};

const newDocumentListState = createSlice({
  name: 'newDocumentListState',
  initialState,
  reducers: {
    setSearchResults: (state, action: PayloadAction<{ pageResults: SriContentList }>) => {
      const { pageResults } = action.payload;
      state.loading = false;
      state.loadingMore = false;
      state.results = [...state.results, ...pageResults];
      state.nextLink = pageResults.next;
    },
    setProposalsToReview: (
      state,
      action: PayloadAction<{ proposalsToReview: { contentHref: string; count: number }[] }>
    ) => {
      const { proposalsToReview } = action.payload;
      const proposalsToReviewCount = proposalsToReview.reduce((acc, curr) => {
        acc[curr.contentHref] = curr.count;
        return acc;
      }, {});
      state.proposalsToReviewCount = proposalsToReviewCount;
    },
    loadMore: (state, action: PayloadAction<{ href: string }>) => {
      state.loadingMore = true;
    },
    sortByColumn: (state, action: PayloadAction<{ column: string }>) => {
      const { column } = action.payload;
      const { columnSort } = state.searchParams;
      const isAscending = columnSort?.name === column ? !columnSort.isAscending : true;
      state.searchParams.columnSort = { name: column, isAscending };
      state.loading = true;
      state.results = [];
    },
    toggleSelected: (state, action: PayloadAction<{ href: string }>) => {
      const { href } = action.payload;
      if (state.selected[href]) {
        delete state.selected[href];
      } else {
        state.selected[href] = true;
      }
    },
    toggleSelectAll: (state) => {
      if (areAllResultsSelected(state.results, state.selected)) {
        state.selected = {};
      } else {
        state.results.forEach((result) => {
          state.selected[result.$$meta.permalink] = true;
        });
      }
    },
    setFilterProperty: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = { ...state.searchParams, ...action.payload };
      state.loading = true;
      state.results = [];
    },
    search: (state, action) => {
      state.loading = true;
      state.searchParams = action.payload;
      state.results = [];
      state.selected = {};
    },
    refreshList: (state) => {
      state.loading = true;
      state.results = [];
      state.selected = {};
    },
    openPublishModal: (state) => {
      state.publishModalOpen = true;
    },

    closePublishModal: (state) => {
      state.publishModalOpen = false;
    },

    openCreateContentModal: (state, action: PayloadAction<{ type: string }>) => {
      const { type } = action.payload;
      state.createContentModalOpen = true;
      state.createType = type;
    },

    closeCreateContentModal: (state) => {
      state.createContentModalOpen = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createCopy: (state, action) => {
      state.creatingCopy = true;
    },

    copyOfContentFinished: (state) => {
      state.confirmationModalOpen = false;
      state.creatingCopy = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createNewVersion: (state, action) => {
      state.creatingNewVersion = true;
    },

    newVersionOfContentFinished: (state) => {
      state.confirmationModalOpen = false;
      state.creatingNewVersion = false;
    },

    validateAndDeleteSelected: (state) => {
      state.deleting = true;
    },

    removeSelectedFromResults: (state) => {
      state.results = state.results.filter(
        (result) => state.selected[result.$$meta.permalink] !== true
      );
    },

    deleteSelectedFinished: (state) => {
      state.deleting = false;
      state.selected = {};
    },

    updateNews: () => {},

    validateCreateContent: () => {},

    createContent: (state, action) => {
      state.apiPending = createApiPayloads(action.payload);
      state.saveAttempted = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createContentFinished: (state, action) => {
      state.createContentModalOpen = false;
      state.apiPending = [];
      state.saveAttempted = false;
    },

    publishContent: () => {},

    showValidationErrors: () => {},
  },
});

export const {
  setSearchResults,
  setProposalsToReview,
  loadMore,
  sortByColumn,
  toggleSelected,
  toggleSelectAll,
  setFilterProperty,
  search,
  openPublishModal,
  closePublishModal,
  openCreateContentModal,
  closeCreateContentModal,
  createCopy,
  copyOfContentFinished,
  refreshList,
  createNewVersion,
  newVersionOfContentFinished,
  validateAndDeleteSelected,
  removeSelectedFromResults,
  deleteSelectedFinished,
  updateNews,
  validateCreateContent,
  createContent,
  createContentFinished,
  publishContent,
  showValidationErrors,
} = newDocumentListState.actions;
export default newDocumentListState;
