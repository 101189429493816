import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const LLINKID_PEDAGOGICAL_TIP: BuildingBlockNodeConfig = {
  information: {
    single: 'Wenk',
    plural: 'Wenken',
  },

  buildingBlocks: [],
  edit: [
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
      required: RequiredType.WARNING,
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'previousVersionItems',
      reactComponent: 'AsidePreviousVersionItems',
      label: 'Vervangt (uit vorige versie)',
      options: {
        relationTypes: ['REPLACES'],
        modal: 'llinkidPreviousVersionModal',
        modalTitle: 'Vervangt (uit vorige versie)',
        listColumn: 'description',
        placeholder: 'Kies een te vervangen wenk',
        relation: 'Wenk',
        errorMessage: 'Je moet een geldige wenk selecteren',
        type: ContentType.LLINKID_PEDAGOGICAL_TIP,
      },
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'description',
  },
};

export default LLINKID_PEDAGOGICAL_TIP;
