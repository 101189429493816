import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectApiWithPendingChanges } from '@newStore/documentApi/documentApiSelectors';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { getNodeTypeConfig } from '@nodeTypeConfig/index';
import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import { selectCurrentDocumentHref } from '../documentUISelectors';
import { BuildingBlockTile } from '../documentUITypes';
import { selectBuildingBlocksForNode } from '../nodeTypeConfigSelectors';

/**
 * Returns a list of all NodeTypes / BuildingBlocks that are to be used in the document.
 * the input selector is terrible, because i wanted to reuse the selector above instead of redoing the whole thing again.
 * the output is memoized, i expect the overhead to be very minimal.
 */
const selectBuildingBlockTypesForDocument = createSelector(
  [(state) => state],
  (state: RootState) => {
    const rootHref = selectCurrentDocumentHref(state);
    if (!rootHref) {
      return [];
    }

    const { content } = selectApiWithPendingChanges(state);
    const blocksSet = new Set<NodeType>();
    Object.keys(content).forEach((href) => {
      const blocks = selectBuildingBlocksForNode(state, href as ContentHref);

      blocks.forEach((block) => {
        blocksSet.add(block.type);
      });
    });

    return [...blocksSet];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectBuildingBlocksForDocument = createSelector(
  [selectBuildingBlockTypesForDocument],
  (blocksSet) => {
    return [...blocksSet].map((z) => {
      const config = getNodeTypeConfig(z);
      return {
        type: z,
        icon: config.information.ribonIcon,
        name: config.information.ribbonTitle || config.information.single,
      } as BuildingBlockTile;
    });
  }
);
