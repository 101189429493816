import {
  closeCreateContentModal,
  validateCreateContent,
} from '@newStore/documentList/newDocumentListState';
import { selectUser } from '@newStore/user/userSelectors';
import * as constants from '@store/constants/constants';
import { getNodeTypeConfig } from '@nodeTypeConfig/index';
import * as newsletterTypes from '../../../reduxLoop/constants/newsletterTypes';
import { readFileInputEventAsArrayBuffer } from '../../../services/import';

require('./createContent.scss');
const kovsentry = require('../../../app/kovSentry');

class createContentModal {
  constructor($scope, $ngRedux, $translate, searchApi, contentApi, newsletterApi) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.searchApi = searchApi;
    this.contentApi = contentApi;
    this.newsletterApi = newsletterApi;
    this.$translate = $translate;
    this.uploadAttachment = null;
    this.importDocx = null;

    this.$scope.resourcePickerAuthorTemplate = require('../../optionTemplates/authorOption.html');
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.newDocumentList.createContentModalOpen) {
        this.close();
      }

      const createType = getNodeTypeConfig(state.newDocumentList.createType);

      return {
        loggedUser: selectUser(state),
        createType,
        createConfig: createType.createModal,
        saveAttempted: state.newDocumentList.saveAttempted,
        canBeImported: Boolean(createType.isImportable),
        createContentModalOpen: state.newDocumentList.createContentModalOpen,
        queryAuthorsParameters:
          createType.createModal?.authors?.queryParams || constants.defaultAuthorsParameters,
      };
    })(this);

    this.newContent = {
      authors: {
        selected: [],
      },
    };

    if (!this.createConfig || !this.createConfig.authors || this.createConfig.authors.addUser) {
      this.newContent.authors.selected = [{ ...this.loggedUser }];
    }

    if (this.createConfig && this.createConfig.theme && this.createConfig.theme.filter) {
      this.themesParameters = this.createConfig.theme.filter;
    }

    angular
      .element(document.querySelector('#docx'))
      .on('change', this.handleImportFileSelect.bind(this));
    angular
      .element(document.querySelector('#uploadFile'))
      .on('change', this.handleUploadFileSelect.bind(this));
  }

  clickBrowseForUpload() {
    console.warn('browse clicked');
    angular.element(document.querySelector('#uploadFile')).click();
  }

  clickBrowseForImport() {
    angular.element(document.querySelector('#docx')).click();
  }

  $onDestroy() {
    this.unsubscribe();
  }

  async handleImportFileSelect(event) {
    const file = event.currentTarget.files[0];
    if (!file) {
      return;
    }
    kovsentry.startLog('Import Document', {
      filename: file,
    });
    const importDocx = await readFileInputEventAsArrayBuffer(file);
    this.$scope.$apply(() => {
      this.importDocx = importDocx;
    });
    kovsentry.endLog('Import Document');
  }

  async handleUploadFileSelect(event) {
    const file = event.currentTarget.files[0];
    if (!file) {
      return;
    }
    kovsentry.startLog('Import Document', {
      filename: file,
    });
    console.warn('upload trigered', event);
    const uploadAttachment = await readFileInputEventAsArrayBuffer(file);
    this.$scope.$apply(() => {
      this.uploadAttachment = uploadAttachment;
      this.uploadAttachmentError = null;
    });
    kovsentry.endLog('Import Document');
  }

  titleChanged() {
    if (this.newContent.title) {
      this.titleError = null;
    } else {
      this.titleError = 'De titel is verplicht';
    }
  }

  changeNewsLetterTemplate(e, item) {
    this.newsLetterTemplateError = null;
    this.newContent.newsLetterTemplate = item;
  }

  changeNewsLetterType(e, item) {
    this.newsLetterTypeError = null;
    this.newContent.newsLetterType = item;
  }

  changeDateToSend(e, item) {
    this.dateToSendError = null;
    this.newContent.dateToSend = new Date(item).toISOString();
  }

  themesSelected(e, model) {
    this.newContent.themes = model.map((t) => t.$$meta.permalink);
  }

  validate() {
    this.titleError = null;
    this.authorsError = null;
    this.uploadAttachmentError = null;
    this.newsLetterTemplateError = null;
    this.newsLetterTypeError = null;
    this.dateToSendError = null;

    // the title is always required
    if (!this.newContent.title) {
      this.titleError = 'De titel is verplicht.';
    }

    if (
      this.createConfig &&
      this.createConfig.authors &&
      this.createConfig.authors.required &&
      this.newContent.authors.selected.length === 0
    ) {
      this.authorsError = this.$translate.instant(
        this.createConfig.authors.errorMessageKey || 'createContentModal.authors'
      );
    }
    if (this.createConfig?.uploadAttachment?.required && !this.uploadAttachment) {
      this.uploadAttachmentError = 'Kies een bestand om up te loaden.';
    }
    if (this.createConfig?.newsLetterTemplate?.required && !this.newContent.newsLetterTemplate) {
      this.newsLetterTemplateError = 'Kies een template.';
    }
    if (
      this.createConfig &&
      this.createConfig.newsLetterType &&
      this.createConfig.newsLetterType.required &&
      !this.newContent.newsLetterType
    ) {
      this.newsLetterTypeError = 'Kies een nieuwsbrief type.';
    }
    if (
      this.createConfig &&
      this.createConfig.dateToSend &&
      this.createConfig.dateToSend.required &&
      !this.newContent.dateToSend
    ) {
      this.dateToSendError = 'Kies een verwachte verzenddatum.';
    }
  }

  submit() {
    kovsentry.startLog('Create Document', {
      ...this.newContent,
      type: this.createType.type,
    });
    this.validate();
    if (
      this.titleError ||
      this.authorsError ||
      this.uploadAttachmentError ||
      this.newsLetterTemplateError ||
      this.newsLetterTypeError ||
      this.dateToSendError
    ) {
      return;
    }
    console.warn('The new content we will create', this.newContent);
    this.$ngRedux.dispatch(
      validateCreateContent({
        ...this.newContent,
        nodeType: this.createType.type,
        webconfiguration: this.createType.webconfiguration,
        uploadAttachment: this.uploadAttachment,
        importDocx: this.importDocx,
      })
    );
  }

  cancel() {
    this.$ngRedux.dispatch(closeCreateContentModal());
  }

  placeholder() {
    let article = 'nieuw';

    if (!this.createType.information.definiteArticle) {
      article = 'nieuwe';
    }

    const text = `Voeg een ${article} ${this.createType.information.single.toLowerCase()} toe...`;

    return text.replace(' zill ', ' ZILL ');
  }

  author2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return `${resource.firstName} ${resource.lastName}`;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }
    }

    return '<NONE>';
  }

  theme2String(resource) {
    return resource.title;
  }

  displayNewsletterType(item) {
    if (!item) {
      return '';
    }
    let translationKey = 'create.newsletterType.';
    switch (item.key) {
      case newsletterTypes.subjectSpecific.key:
        translationKey += 'subjectSpecific';
        break;
      case newsletterTypes.thematic.key:
        translationKey += 'thematic';
        break;
      default:
        return '';
    }
    return this.$scope.$parent.$parent.ctrl.$translate.instant(translationKey);
  }
}

export const createContentComponent = {
  template: require('./createContent.html'),
  controllerAs: 'ctrl',
  controller: createContentModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
