import { Content, ContentHref, isRequiresRelation, RequiresRelation } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import {
  LlinkidCurriculumNode,
  LlinkidGoalSubItemNode,
} from '@newStore/documentUI/documentUITypes';
import { referenceFrameLabels, referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { settings } from '../../../config/settings';
import { selectNodeType } from '../nodeTypeConfigSelectors';

function getNameFromType(type: string) {
  switch (type) {
    case 'LLINKID_PEDAGOGICAL_TIP':
      return 'Wenk';
    case 'LLINKID_EXTRA_GOAL_INFORMATION':
      return 'Extra';
    case 'LLINKID_GOAL_EXPLANATION':
      return 'Duiding';
    case 'LLINKID_INITIAL_SITUATION':
      return 'Beginsituatie';
    default:
      return '';
  }
}

export const selectGoalSubItem = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
  ],
  (content, type): LlinkidGoalSubItemNode => {
    if (
      !(
        type === 'LLINKID_PEDAGOGICAL_TIP' ||
        type === 'LLINKID_EXTRA_GOAL_INFORMATION' ||
        type === 'LLINKID_GOAL_EXPLANATION' ||
        type === 'LLINKID_INITIAL_SITUATION'
      )
    ) {
      throw new Error('only call selectGoalSubItem for the correct types');
    }

    return {
      href: content.$$meta.permalink,
      key: content.key,
      type,
      name: getNameFromType(type),
      description: content.description || '',
      customRender: true,
    };
  }
);

const strengthSizes = {
  LOW: '25',
  MEDIUM: '30',
  HIGH: '38',
};

export const selectLlinkidCurriculumNode = createTypedSelector(
  [
    (state, href) => selectContentItem(state, href),
    (state, href) => selectApiWithPendingChangesRelationsToAndFromMap(state).to[href],
    (state) => state.referenceFrames,
  ],
  (content: Content, toRelations, referenceFrameData): LlinkidCurriculumNode => {
    const references = (toRelations || []).filter(isRequiresRelation);
    const educationalGroups = ['educationalPointers', 'educationalComponents'].map(
      (educationalType) => {
        const referenceFrameMap = referenceFrameData[referenceFrames[educationalType]].content;
        const themes = references.flatMap((relation: RequiresRelation) => {
          const theme: Content = referenceFrameMap[relation.from.href];
          return theme
            ? {
                key: theme.key,
                title: theme.title || '',
                imgSrc:
                  (theme.attachments?.length &&
                    settings.apisAndUrls.contentApi + theme.attachments[0].href) ||
                  '',
                imgSize: (relation.strength && strengthSizes[relation.strength]) || '30',
              }
            : [];
        });
        return {
          key: referenceFrames[educationalType],
          label: referenceFrameLabels[referenceFrames[educationalType]],
          themes,
        };
      }
    );
    const version = `v${Number(content.version?.major)}`;
    // if (content.version?.minor || content.version?.patch) {
    //   version += `${Number(content.version?.minor)}.${Number(content.version?.patch)}`;
    // }
    return {
      href: content.$$meta.permalink,
      key: content.key,
      identifier: content.identifiers?.[0],
      title: content.title || '',
      version,
      educationalGroups,
      type: NodeType.LLINKID_CURRICULUM,
      customRender: true,
    };
  }
);
