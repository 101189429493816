import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRole, UserState } from './userTypes';

const initialState: UserState = {
  personHref: null,
  securityData: null,
  userRoles: [],
  areUserRolesFetched: false,
  isLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initUser(state) {
      state.isLoading = true;
    },
    userFetched(state, action: PayloadAction<{ uuid: string; username: string }>) {
      state.personHref = `/persons/${action.payload.uuid}`;
    },
    securityDataFetched(state, action: PayloadAction<{ securityData: Record<string, string[]> }>) {
      state.isLoading = false;
      state.securityData = action.payload.securityData;
    },
    userRolesFetched(state, action: PayloadAction<{ userRoles: UserRole[] }>) {
      state.userRoles = action.payload.userRoles;
      state.areUserRolesFetched = true;
    },
    initUserFailed(state) {
      state.isLoading = false;
    },
  },
});

export const { initUser, userFetched, securityDataFetched, userRolesFetched, initUserFailed } =
  userSlice.actions;
export default userSlice;
