import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import {
  authorsComponent,
  publishComponent,
  titleComponent,
} from '@nodeTypeConfig/helpers/genericComponents';

const WEBPAGE: RootNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'Zill-Web Pagina',
    plural: "Zill-Web Pagina's",
    icon: require('../../../../img/icons/webpage.svg'),
  },
  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.WORD_IMPORT },
  ],
  tocTypes: [NodeType.SECTION],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
    authorsComponent,
    { component: 'themes', label: "Thema's" },
  ],
  allowSuggestions: false,
  isCreatable: true,
  isSearchable: true,
};

export default WEBPAGE;
