import { documentAsDocx, readFileInputEventAsArrayBuffer } from '../../../reduxLoop/helpers/import';

class documentImportModal {
  constructor($scope, $notification, $translate) {
    'ngInject';

    this.$scope = $scope;
    this.notificationService = $notification;
    this.translationService = $translate;
    this.loading = false;
    const self = this;
    this.$scope.$watch('docx.name', async () => {
      if (self.$scope.docx && self.$scope.docx.size) {
        const resp = await readFileInputEventAsArrayBuffer(event);
        self.buffer = resp.arrayBuffer;
      }
    });
  }

  async $onInit() {
    this.parentDocument = this.resolve.modalData.parentDocument;
    this.positionIndex = this.resolve.modalData.positionIndex;
  }

  isDocumentSelected() {
    if (this.$scope.docx && this.$scope.docx.name) {
      return true;
    }
    this.notificationService.error(
      this.translationService.instant('components.documentImportModal.error.noDocumentSelected')
    );
    return false;
  }

  async submit() {
    if (this.isDocumentSelected()) {
      this.loading = true;
      const result = await documentAsDocx(this.buffer, this.parentDocument, this.positionIndex);
      this.loading = false;
      this.modalInstance.close(result);
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }
}

export default {
  template: require('./documentImportModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: documentImportModal,
};
