import { nodeTypeDefinitions } from '@nodeTypeConfig/nodeTypeConfig';
import { NodeType, RootNodeCategory } from '@nodeTypeConfig/configTypes';
import { settings } from '../../config/settings';
import { nodeTypeConfigurations } from '../../config/nodeTypeConfigurations/index';

export const getUrlsForPath = (resourcesRaw: string[], pathname: string): any[] => {
  return resourcesRaw
    .map((rawUrl) => new URL(`${settings.apisAndUrls.api}${rawUrl}`))
    .filter((url) => url.pathname === pathname);
};

const getAllowedContentTypesAndTags = (resourcesRaw: string[]): any => {
  const rRawContent = getUrlsForPath(resourcesRaw, '/content');

  let allowedContentTags: string[] = [];
  let allowedContentTypes: string[] = [];
  const allowedPublishedEdits: string[] = [];
  rRawContent.forEach((rawUrl) => {
    [...rawUrl.searchParams.entries()].forEach(([key, value]) => {
      if (['tagsIn', 'tags', 'rootTag'].includes(key)) {
        allowedContentTags = [...allowedContentTags, ...value.split(',')];
      } else if (['typeIn', 'type', 'rootType'].includes(key)) {
        // TODO: what if a security rule has both rootType and rootTag?
        allowedContentTypes = [...allowedContentTypes, ...value.split(',')];
      }
    });

    const rootTypeOrTag = rawUrl.searchParams.get('rootType') || rawUrl.searchParams.get('rootTag');
    const rootPublished = rawUrl.searchParams.get('rootPublished');

    if (rootTypeOrTag && rootPublished !== 'false') {
      allowedPublishedEdits.push(...rootTypeOrTag.split(','));
    }
  });
  return {
    allowedContentTags: [...new Set(allowedContentTags)],
    allowedContentTypes: [...new Set(allowedContentTypes)],
    allowedPublishedEdits: [...new Set(allowedPublishedEdits)],
    hasFullAccess: rRawContent.some((rawUrl) =>
      (rawUrl.pathname + rawUrl.search).match(/^\/content(?:\?\$\$meta.deleted=any)?$/g)
    ),
  };
};

export const getAllowedTypes = (
  securityData: Record<string, string[]> | null
): Array<{
  type: NodeType;
  isSearchable: boolean;
  isCreatable: boolean;
  isCloneable: boolean;
  isPublishedEditable: boolean;
  category: RootNodeCategory | undefined;
}> => {
  const resourcesRawForUpdate = securityData ? securityData.UPDATE_CONTENT : [];
  const { hasFullAccess, allowedContentTags, allowedContentTypes, allowedPublishedEdits } =
    getAllowedContentTypesAndTags(resourcesRawForUpdate);

  return Object.entries(nodeTypeDefinitions)
    .filter(
      ([_type, typeDef]: [string, any]) =>
        hasFullAccess ||
        (typeDef.node?.type && allowedContentTypes.includes(typeDef.node.type)) ||
        (typeDef.node?.tags && allowedContentTags.includes(typeDef.node.tags[0]))
    )
    .map(([type, typeDef]: [string, any]) => {
      const typeConfig = nodeTypeConfigurations[type as NodeType];
      return {
        type: type as NodeType,
        isSearchable: 'isSearchable' in typeConfig && typeConfig.isSearchable,
        isCreatable: 'isCreatable' in typeConfig && typeConfig.isCreatable,
        isCloneable: 'isCloneable' in typeConfig && typeConfig.isCloneable,
        isPublishedEditable: Boolean(
          hasFullAccess ||
            (typeDef.node?.type && allowedPublishedEdits.includes(typeDef.node.type)) ||
            (typeDef.node?.tags && allowedPublishedEdits.includes(typeDef.node.tags[0]))
        ),
        category:
          ('category' in typeConfig.information && typeConfig.information.category) || undefined,
      };
    });
};
