import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideConcordanties {
  constructor(ModalWindowService, ConfirmationService, $scope, $ngRedux) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const documentState = state.document;
      const { concordanties } = documentState.viewModel.aside.editDocument;
      const { zillOdetCurriculum } = documentState;
      const zillOdetCurriculumEvaluated = typeof zillOdetCurriculum !== 'undefined';

      return {
        concordanties,
        loadingConcordanties: !zillOdetCurriculumEvaluated,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  editConcordantie(relationToEdit, selectedRelation) {
    const relationToEditKey = relationToEdit.key;
    const patchToApply = {
      to: {
        href: selectedRelation.$$meta.permalink,
        $$expanded: selectedRelation,
      },
    };

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchRelationAction(relationToEditKey, patchToApply));
  }

  addConcordanties(concordantiesToAdd) {
    const baseRelation = {
      relationtype: 'IS_VERSION_OF',
      strength: 'MEDIUM',
      from: {
        href: this.sDocument.$$meta.permalink,
      },
    };

    concordantiesToAdd.forEach((target) => {
      const newRelation = {
        ...baseRelation,
        to: {
          href: target.$$meta.permalink,
          $$expanded: target,
        },
      };

      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation));
    });
  }

  editFrameDocument(relationToEdit, selectedZillCurriculum) {
    const relationToEditKey = relationToEdit.key;
    const patchToApply = {
      to: {
        href: selectedZillCurriculum.$$meta.permalink,
        $$expanded: selectedZillCurriculum,
      },
    };

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.editZillCurriculumAsFrame(relationToEditKey, patchToApply)
    );
  }

  addFrameDocument(selectedZillCurriculum) {
    const relationToCreate = {
      relationtype: 'IS_VERSION_OF',
      strength: 'MEDIUM',
      from: {
        href: this.sDocument.$$meta.permalink,
      },
      to: {
        href: selectedZillCurriculum.$$meta.permalink,
        $$expanded: selectedZillCurriculum,
      },
    };

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.setSelectedZillCurriculumAsFrame(relationToCreate));
  }

  async manageConcordanties(relation) {
    const opts = {
      component: 'concordantieModal',
      dataForModal: {
        fromType: this.sDocument.type,
        isRoot: this.sConfig.options && this.sConfig.options.isRoot,
        relation,
      },
    };
    const response = await this.modalWindowService.open(opts);

    const { initialRelation } = response;
    // TIP: this is not executed until the modal is closed..
    if (this.sConfig.options && this.sConfig.options.isRoot) {
      // this means we are making changes in the root element of the document,
      // meaning, changing the ODET document
      // HINT: Only one selection is permitted here, meaning, you will only have
      // one relation
      const selectedZillCurriculum = response.odet;

      if (initialRelation) {
        // editing zillCurriculum
        this.editFrameDocument(initialRelation, selectedZillCurriculum);
      } else {
        // adding zillCurriculum
        this.addFrameDocument(selectedZillCurriculum);
      }
    } else {
      // we are working on children nodes
      // eslint-disable-next-line no-lonely-if
      if (initialRelation) {
        // editing a concordantie
        // HINT: You can only edit one by one conconrdantie
        const selectedRelation = response.endterm;
        this.editConcordantie(initialRelation, selectedRelation);
      } else {
        // adding concordantie/s
        const relationsToAdd = response.endterms;
        this.addConcordanties(relationsToAdd);
      }
    }
  }

  async deleteConcordantie(relation) {
    const confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(relation.key));
    }
  }

  editConcordantieDisabled() {
    if (
      this.sDocument.type === 'CURRICULUM_ZILL' &&
      this.concordanties &&
      this.concordanties.length > 0
    ) {
      return true;
    }

    return false;
  }
}

export default {
  template: require('./asideConcordanties.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideConcordanties,
};
