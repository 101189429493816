import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

import './rowLlinkidPedagogicalTip.scss';

class rowLlinkidPedagogicalTip {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.icon = require('../../../../img/pedagogical-tip.svg');
  }

  saveInline(scope, event, editorData) {
    const patch = {
      [editorData.field]: editorData.value,
    };
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(editorData.key, patch));
  }
}

export const rowLlinkidPedagogicalTipComponent = {
  template: require('./rowLlinkidPedagogicalTip.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
  },
  controller: rowLlinkidPedagogicalTip,
};
