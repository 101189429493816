require('./facet.scss');
// this is a "dumb" component

class Facet {
  $onInit() {}

  deleteFacet() {
    this.onFacetDelete({ $event: { facet: this.sFacet } });
  }

  editFacet() {
    this.onFacetEdit({ $event: { facet: this.sFacet } });
  }
}

module.exports = {
  template: require('./facet.html'),
  controllerAs: 'ctrl',
  bindings: {
    sFacet: '<',
    onFacetDelete: '&',
    onFacetEdit: '&',
  },
  controller: Facet,
};
