/* eslint-disable func-names */
const sanitize = require('sanitize-html');

module.exports = [
  '$compile',
  ($compile) => {
    const filterHtml = (string) => {
      string = sanitize(string, {
        allowedTags: ['br', 'ul', 'ol', 'li', 'b'],
      });
      return string;
    };

    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        const noHtml = scope.$eval(attrs.compileNoHtml);
        let prevValue;
        scope.$watch(
          (_scope) => {
            return _scope.$eval(attrs.compile);
          },
          (value) => {
            if (prevValue !== value) {
              if (typeof value !== 'undefined' && value !== null) {
                if (noHtml) {
                  value = filterHtml(value);
                }
                element.html(value);
                $compile(element.contents())(scope);
              }
            }
          }
        );
      },
    };
  },
];
