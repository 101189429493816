import { Content, ContentHref, ContentRelation } from '@generalTypes/apiTypes';
import { ContentRelationsMap } from '@generalTypes/generalTypes';
import { isPersonHref } from '@generalTypes/personApiTypes';
import { isOrganisationalUnitHref, isVosOrganisationalUnitHref } from '@generalTypes/samTypes';
import { ContentRecord } from '@newStore/documentApi/documentApiTypes';
import { parentChildRelationFilter } from '@newStore/genericHelpers';

export const getPathToRootHrefs = (relationsMap: ContentRelationsMap, href: ContentHref) => {
  const path: ContentHref[] = [];
  let currentHref: ContentHref | undefined = href as ContentHref;
  while (currentHref) {
    path.push(currentHref);
    const parentRelations: ContentRelation[] = relationsMap.from[currentHref];
    if (!parentRelations || parentRelations.length === 0) {
      break;
    }
    const parentRelation = parentRelations.find((r) => r.relationtype === 'IS_PART_OF');
    currentHref = parentRelation?.to.href as ContentHref;
  }
  return path;
};

export const getPathsToRootWithIsIncludedIn = (
  relationsMap: ContentRelationsMap,
  href: ContentHref
) => {
  const paths: ContentHref[][] = [];
  const currentHref: ContentHref | undefined = href as ContentHref;
  const pathToRootHrefs = getPathToRootHrefs(relationsMap, currentHref);
  const rootHref = pathToRootHrefs.at(-1);
  if (!rootHref) {
    return [pathToRootHrefs];
  }
  const parentRelations: ContentRelation[] = relationsMap.from[rootHref].filter(
    (rel) => parentChildRelationFilter(rel) && relationsMap.to[rel.to.href]?.length > 1
  );
  // a teaser can have IS_INCLUDED_IN relations to other documents that are not relevant here.
  // to only have the IS_INCLUDED_IN relevant to this document, we check if the to of the relation also has relations in this document.
  // that means, the to of the relation exists in the document.

  if (parentRelations.length === 0) {
    return [pathToRootHrefs];
  }

  parentRelations.forEach((parentRelation) => {
    const pathToRootFromParent = getPathToRootHrefs(
      relationsMap,
      parentRelation.to.href as ContentHref
    );
    paths.push([...pathToRootHrefs, ...pathToRootFromParent]);
  });

  return paths;
};

export const getPathToRoot = (
  relationsMap: ContentRelationsMap,
  content: ContentRecord,
  href: string
) => {
  const hrefs = getPathToRootHrefs(relationsMap, href as ContentHref);
  const path: Content[] = hrefs.map((phref) => content[phref]);
  return path;
};

export const getRoot = (
  relationsMap: ContentRelationsMap,
  content: ContentRecord,
  href: string
) => {
  const path = getPathToRoot(relationsMap, content, href);
  return path[path.length - 1];
};

export const isEntityHref = (s: string) =>
  isPersonHref(s) || isOrganisationalUnitHref(s) || isVosOrganisationalUnitHref(s);
