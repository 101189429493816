import uuidv4 from 'uuid/v4';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { getEmbedVideoLinkFrom } from '../../../reduxLoop/helpers/documentHelpers';

class asideVideoGroup {
  constructor(Utils, $scope, $ngRedux, ModalWindowService, ConfirmationService) {
    'ngInject';

    this.Utils = Utils;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        editDocument: state.document.viewModel.aside.editDocument,
        description:
          state.document.viewModel.aside.editDocument.description &&
          state.document.viewModel.aside.editDocument.description.value,
        videos: state.document.viewModel.aside.editDocument.$$children,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(
        this.sDocument.key,
        {
          description: this.description,
        },
        true,
        this.sDocument.key
      )
    );
  }

  async addVideo() {
    const response = await this.modalWindowService.open({ component: 'videoGroupModal' });

    if (response) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.addNodeToParentNodeAction(this.sDocument.key, null, 'VIDEO', {
          attachments: [{ key: uuidv4(), type: 'VIDEO', playbackUrl: response }],
        })
      );
    }
  }

  async deleteVideo(video) {
    const confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.removeNodeAction(video.key, true, this.sDocument.key)
      );
    }
  }

  videoSource(video) {
    if (video.attachments && video.attachments.length > 0) {
      return getEmbedVideoLinkFrom(video.attachments[0].playbackUrl);
    }

    return '';
  }
}

export default {
  template: require('./asideVideoGroup.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideVideoGroup,
};
