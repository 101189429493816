import { Content, ContentHref } from '@generalTypes/apiTypes';
import { getGoalIdentifier } from '@newStore/llinkid/llinkidHelpers';

import { selectPathToRoot, selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import {
  OdetKeyCompetenceNode,
  OdetNode,
  OdetSubSectionNode,
  OdetTitleNode,
} from '../documentUITypes';
import { selectNodeType } from '../nodeTypeConfigSelectors';

export const selectOdetTitleNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
  ],
  (content, type): OdetTitleNode => {
    if (type !== NodeType.CURRICULUM_ODET && type !== NodeType.CURRICULUM_ODET_LEVEL) {
      throw new Error('only call selectOdetNode for CURRICULUM_ODET or CURRICULUM_ODET_LEVEL');
    }
    return {
      href: content.$$meta.permalink,
      key: content.key,
      title: content.title || '',
      type,
    };
  }
);

export const selectOdetLeergebiedNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): OdetNode => ({
    href: content.$$meta.permalink,
    key: content.key,
    identifier: content.identifiers?.[0] || '',
    title: content.title || '',
    type: NodeType.CURRICULUM_ODET_LEERGEBIED,
  })
);

export const selectOdetKeyCompetenceNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): OdetKeyCompetenceNode => ({
    href: content.$$meta.permalink,
    description: content.description || '',
    key: content.key,
    identifier: content.identifiers?.[0] || '',
    type: NodeType.CURRICULUM_ODET_KEY_COMPETENCE,
  })
);

export const selectOdetSubsectionNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): OdetSubSectionNode => ({
    href: content.$$meta.permalink,
    key: content.key,
    identifier: content.identifiers?.[0],
    title: content.title || '',
    type: NodeType.CURRICULUM_ODET_SUBSECTION,
  })
);

export const selectOdetDevelopmentGoalorEindtermNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectPathToRoot(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
  ],
  (content, ancestors, type): OdetNode => {
    if (
      type !== NodeType.CURRICULUM_ODET_DEVELOPMENT_GOAL &&
      type !== NodeType.CURRICULUM_ODET_ENDTERM
    ) {
      throw new Error(
        'only call selectOdetDevelopmentGoalorEindtermNode for type CURRICULUM_ODET_DEVELOPMENT_GOAL or CURRICULUM_ODET_ENDTERM'
      );
    }
    return {
      href: content.$$meta.permalink,
      key: content.key,
      identifier: getGoalIdentifier(ancestors.slice(0, -2)) || '',
      title: content.title || '',
      type,
    };
  }
);
