import { BuildingBlock, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/genericComponents';

export const wwwDefaultBuildingBlocks: Array<BuildingBlock> = [
  { type: NodeType.SECTION },
  { type: NodeType.PARAGRAPH },
  { type: NodeType.IMAGE },
  { type: NodeType.IMAGE_GROUP },
  { type: NodeType.ATTACHMENTS_GROUP },
  { type: NodeType.VIDEO },
  { type: NodeType.QUOTE },
  { type: NodeType.WORD_IMPORT },
];

export const wwwTitleAndDescriptionComps = [
  titleComponent,
  {
    component: 'description',
    property: 'description',
    reactComponent: 'AsideDescription',
    label: 'Inleiding',
  },
] as const;

// Question @Greet/Isabelle WWW and WWW_SECTION has cropOptions, is there meant to be a difference or just sloppiness?
export const wwwOmslagAndTegelFotoComps = [
  {
    component: 'image',
    property: 'attachments',
    width: '6',
    label: 'Omslagfoto',
    options: {
      type: 'COVER_IMAGE',
      fields: [],
    },
  },
  {
    component: 'image',
    property: 'attachments',
    width: '6',
    label: 'Tegelfoto',
    options: {
      type: 'THUMBNAIL',
      fields: ['alt'],
    },
  },
] as const;
