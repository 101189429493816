import { WebSiteHref, WebTemplateHref } from '@generalTypes/apiTypes';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { NodeType, RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import * as apiRoutes from '../../../reduxLoop/api/apiRoutes';
import constants from '../../../reduxLoop/constants/constants';
import { settings } from '../../settings';
import { authorsComponent, publishComponent } from '../helpers/genericComponents';
import {
  wwwDefaultBuildingBlocks,
  wwwOmslagAndTegelFotoComps,
  wwwTitleAndDescriptionComps,
} from './wwwGenericConfigProps';

export const JOB_OFFER: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Vacature',
    plural: 'Vacatures',
    icon: require('../../../../img/icons/document.svg'),
  },
  onLoadActions: [loadWebConfigurations()],
  webconfiguration: {
    type: 'WWW_NEWS_DETAIL',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.www}` as WebSiteHref,
    },
    template: {
      href: '/web/templates/514777b4-4657-4590-835e-7ff9d6e99b22' as WebTemplateHref,
    },
  },
  createModal: {
    authors: {
      required: RequiredType.ERROR,
      addUser: false,
      queryParams: constants.jobofferAuthorsParameters,
      label: 'Instelling',
      errorMessageKey: 'createContentModal.institution',
    },
  },
  buildingBlocks: wwwDefaultBuildingBlocks,
  tocTypes: [NodeType.SECTION],
  edit: [
    ...wwwTitleAndDescriptionComps,
    ...wwwOmslagAndTegelFotoComps,
    {
      ...authorsComponent,
      label: 'Instelling',
      options: {
        queryParams: constants.jobofferAuthorsParameters,
      },
    },
    publishComponent,
  ],
  customEditorOptions: {
    hideTermButton: true,
  },
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  allowSuggestions: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
};
