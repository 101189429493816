import './inlineTerm.scss';

class inlineTerm {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const allTerms = state.document.viewModel.terms.global.concat(
        state.document.viewModel.terms.local
      );
      const term = allTerms.find((t) => `/content/${t.key}` === this.sHref);

      if (term) {
        this.term = this.termAsHtml(term);
        this.unsubscribe();
      } else {
        this.term = this.sHref;
      }
      return {};
    })(this);
  }

  termAsHtml(term) {
    return (
      term.title +
      (term.description
        ? `<br><span class="tooltip-popover-description">${term.description}</span>`
        : '')
    );
  }

  $onInit() {}

  $onChanges() {}
}

export const inlineTermComponent = {
  transclude: true,
  template: require('./inlineTerm.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHref: '@',
  },
  controller: inlineTerm,
};
