import uuidv4 from 'uuid/v4';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import {
  getEmbedVideoLinkFrom,
  isValidMicrosoftstreamUrl,
  isValidVimeoUrl,
  isValidYouTubeUrl,
} from '../../../reduxLoop/helpers/documentHelpers';

class asideVideo {
  constructor(Utils, $scope, $ngRedux) {
    'ngInject';

    this.Utils = Utils;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const nodeVM = state.document.viewModel.aside.editDocument;
      const video = nodeVM.$$attachments.has('VIDEO')
        ? { ...nodeVM.$$attachments.get('VIDEO').original }
        : {};

      return {
        videoAttachment: video,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  updateVideo() {
    if (
      isValidYouTubeUrl(this.videoAttachment.playbackUrl) ||
      isValidVimeoUrl(this.videoAttachment.playbackUrl) ||
      isValidMicrosoftstreamUrl(this.videoAttachment.playbackUrl)
    ) {
      this.showInvalidYoutubeLink = false;

      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.patchNodeAttachmentAction(
          this.sDocument.key,
          this.videoAttachment.key,
          {
            key: this.videoAttachment.key || uuidv4(),
            type: 'VIDEO',
            playbackUrl: this.videoAttachment.playbackUrl,
          },
          true
        )
      );
    } else if (this.videoAttachment.playbackUrl !== undefined) {
      this.showInvalidYoutubeLink = true;
    }
  }

  removeVideo() {
    this.videoAttachment.playbackUrl = undefined;
    this.showInvalidYoutubeLink = false;

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.removeNodeAttachmentAction(this.sDocument.key, this.videoAttachment.key)
    );
  }

  videoSource() {
    if (this.videoAttachment && this.videoAttachment.playbackUrl) {
      return getEmbedVideoLinkFrom(this.videoAttachment.playbackUrl);
    }
    return '';
  }
}

export default {
  template: require('./asideVideo.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideVideo,
};
