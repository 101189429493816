class selectCurriculumVersionModal {
  constructor($ngRedux, $scope, contentApi) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.contentApi = contentApi;
    this.resourcePickerZillCurriculumTemplate = require('../../../screen/optionTemplates/zillCurriculum.html');
    this.loading = true;
  }

  async open() {
    this.modalInstance.close(`/content/${this.selectedVersion.key}`);
  }

  display(d) {
    return d ? d.title : '<GEEN>';
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  async $onInit() {
    const zillVersions = await this.contentApi.getRaw('/content', {
      limit: 1,
      descending: true,
      orderBy: '$$meta.created',
      typeIn: 'CURRICULUM_ZILL',
    });
    this.selectedVersion = zillVersions.results[0] ? zillVersions.results[0].$$expanded : null;
    this.loading = false;
    this.$scope.$apply();
  }
}

export default {
  template: require('./selectCurriculumVersionModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: selectCurriculumVersionModal,
};
