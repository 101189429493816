import { Content, ContentHref, ContentType } from '@generalTypes/apiTypes';
import { ageRangeToString } from '@kathondvla/age-range-manager';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectPathToRoot,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import { getAttachmentUrl, createTypedSelector } from '@newStore/genericHelpers';
import { getGoalIdentifier } from '@newStore/llinkid/llinkidHelpers';
import { isEqual } from 'lodash';
import { NodeType } from '@nodeTypeConfig/configTypes';
import {
  ZillClusterNode,
  ZillCurriculumNode,
  ZillDevelopmentContentNode,
  ZillDevelopmentFieldNode,
  ZillDevelopmentThemeNode,
  ZillGenericGoalNode,
  ZillGenericGoalReference,
  ZillLeerlijnReferenceStepNode,
  ZillLeerlijnStepNode,
} from '../documentUITypes';
import { selectNodeType } from '../nodeTypeConfigSelectors';

export const selectZillCurriculumNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): ZillCurriculumNode => ({
    href: content.$$meta.permalink,
    key: content.key,
    title: content.title || '',
    description: content.description || '',
    type: NodeType.CURRICULUM_ZILL,
  })
);

export const selectZillClusterNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): ZillClusterNode => ({
    href: content.$$meta.permalink,
    key: content.key,
    identifier: content.identifiers?.[0] || '',
    title: content.title || '',
    description: content.description || '',
    type: NodeType.CURRICULUM_ZILL_CLUSTER,
  })
);

export const selectZillDevelopmentFieldNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): ZillDevelopmentFieldNode => {
    const icon = content.attachments?.find((att) => att.type === 'ICON');
    return {
      href: content.$$meta.permalink,
      key: content.key,
      identifier: content.identifiers?.[0] || '',
      color: content.color || undefined,
      title: content.title || '',
      description: content.description || '',
      type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_FIELD,
      text: content.$$html || '',
      icon: icon ? { url: getAttachmentUrl(icon) } : null,
    };
  }
);

export const selectZillDevelopmentThemeNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href), (state, href) => selectPathToRoot(state, href)],
  (content, ancestors): ZillDevelopmentThemeNode => {
    const zillDevelopmentField = ancestors.find(
      (path) => path.type === ContentType.CURRICULUM_ZILL_DEVELOPMENT_FIELD
    );
    return {
      href: content.$$meta.permalink,
      key: content.key,
      identifier: getGoalIdentifier(ancestors.slice(0, -2)) || '',
      color: zillDevelopmentField?.color || undefined,
      title: content.title || '',
      description: content.description || '',
      type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_THEME,
      text: content.$$html || '',
    };
  }
);

export const selectZillGenericGoalNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href), (state, href) => selectPathToRoot(state, href)],
  (content, ancestors): ZillGenericGoalNode => {
    const zillDevelopmentField = ancestors.find(
      (path) => path.type === ContentType.CURRICULUM_ZILL_DEVELOPMENT_FIELD
    );
    return {
      href: content.$$meta.permalink,
      key: content.key,
      identifier: getGoalIdentifier(ancestors.slice(0, -2)) || '',
      color: zillDevelopmentField?.color || undefined,
      title: content.title || '',
      type: NodeType.CURRICULUM_ZILL_GENERIC_GOAL,
    };
  }
);

export const selectZillDevelopmentContentNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): ZillDevelopmentContentNode => ({
    href: content.$$meta.permalink,
    key: content.key,
    title: content.title || '',
    type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT,
  })
);

export const selectZillLeerlijnStepNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): ZillLeerlijnStepNode => ({
    href: content.$$meta.permalink,
    key: content.key,
    description: content.description || '',
    ageRangeLabel: ageRangeToString(content.startage, content.endage) || '',
    startAge: content.startage || 0,
    endAge: content.endage || 18,
    type: NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER,
    customRender: true,
  })
);

export const selectZillLeerlijnReferenceStepNode = createTypedSelector(
  [
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state, href: ContentHref) => selectNodeType(state, href),
    (state, href) => href,
  ],
  (content, relationsMap, type, href: string): ZillLeerlijnReferenceStepNode => {
    const referenceNode = content[href];
    if (
      type !== NodeType.CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE &&
      type !== NodeType.CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE
    ) {
      throw new Error(
        'only call selectZillLeerlijnReferenceStepNode for type CURRICULUM_ZILL_LEERLIJN_{PRE/POST}_REFERENCE'
      );
    }
    const referenceRelation = relationsMap.from[href].find(
      (rel) => rel.relationtype === 'REFERENCES'
    );
    const genericGoal = referenceRelation ? content[referenceRelation.to.href] : null;
    let transformedGoal: ZillGenericGoalReference | null = null;
    if (genericGoal) {
      const pathToRoot = getPathToRoot(relationsMap, content, genericGoal.$$meta.permalink);
      const zillDevelopmentField = pathToRoot.find(
        (path) => path.type === ContentType.CURRICULUM_ZILL_DEVELOPMENT_FIELD
      );
      transformedGoal = {
        href: genericGoal.$$meta.permalink,
        identifier: getGoalIdentifier(pathToRoot.slice(0, -2)) || '',
        color: zillDevelopmentField?.color || undefined,
        title: genericGoal.title || '',
      };
    }
    return {
      href: referenceNode.$$meta.permalink,
      key: referenceNode.key,
      type,
      genericGoal: transformedGoal,
      customRender: true,
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual, // deep compare because the input selector is way too wide
    },
  }
);
