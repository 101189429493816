import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';

const LLINKID_GOAL_LIST: BuildingBlockNodeConfig = {
  information: {
    single: 'Doelenlijst',
    plural: 'Doelenlijsten',
  },

  buildingBlocks: [
    {
      type: NodeType.LLINKID_STATIC_PART,
      max: 1,
    },
    { type: NodeType.LLINKID_GOAL_SECTION },
    { type: NodeType.LLINKID_GOAL },
    {
      type: NodeType.LLINKID_CONCORDANCE,
      max: 1,
      position: 1,
    },
  ],
  edit: [],
  showPlaceholder: true,
  readOnlyNode: true,
  createInModal: false,
  isNotDraggable: true,
  isNotDeletable: true,
  isCollapsible: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default LLINKID_GOAL_LIST;
