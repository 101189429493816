export const config = {
  $$identifier: {
    hideToolbar: true,
  },
  title: {
    customButtons: ['term', 'marker'],
    reducedToolbar: true,
    blockedKeystrokes: [13, 2228224 + 13],
  },
  description: {
    customButtons: ['term', 'demarcation', 'customLink', 'marker'],
    height: 75,
  },
  shortdescription: {
    customButtons: ['term', 'customLink'],
    reducedToolbar: true,
    height: 60,
  },
  html: {
    customButtons: ['term', 'footnote', 'customLink', 'marker'],
    height: 150,
  },
  text: {
    customButtons: [],
    reducedToolbar: true,
    height: 100,
  },
  callToAction: {
    hideToolbar: true,
  },
  staticHtml: {
    extraConfig: {
      allowedContent: true,
      startupMode: 'source',
    },
    customButtons: [],
    height: 500,
  },
};
