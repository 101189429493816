import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
// this is "smart" component

class FoundationalSuggestions {
  constructor(ConfirmationService, $ngRedux) {
    'ngInject';

    this.confirmationService = ConfirmationService;
    this.$ngRedux = $ngRedux;
  }

  initRelations(nodeRelations, activities) {
    activities.forEach((activity) => {
      const hasActivitySelected = nodeRelations.find(
        (rel) => rel.to.href === activity.$$meta.permalink
      );
      activity.checked = !!hasActivitySelected;
    });
  }

  updateRelation(activity) {
    if (!activity.checked) {
      // new relation to educational activity
      const newRelation = {
        relationtype: this.sConfig.options.relationTypes[0],
        strength: 'MEDIUM',
        from: {
          href: this.sDocument.$$meta.permalink,
        },
        to: {
          href: activity.$$meta.permalink,
        },
      };

      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation, true, false));
    } else {
      // remove relation
      const relation = this.relations.find((rel) => rel.to.href === activity.$$meta.permalink);
      if (relation) {
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(relation.key));
      }
    }
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const activities = state.document.viewModel.aside.llinkid.educationalActivityTypes;
      let { relations } = this;
      if (activities && !this.initDone) {
        relations = (
          state.document.apiWithPendingChanges.contentRelations.from[
            `/content/${this.sDocument.key}`
          ] || []
        ).filter((relation) => this.sConfig.options.relationTypes.includes(relation.relationtype));

        this.initRelations(relations, activities);
        this.initDone = true;
      }

      return {
        activities,
        relations,
        loading: !activities,
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.loadEducationalActivityTypesAction());
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./foundationalSuggestions.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<',
  },
  controller: FoundationalSuggestions,
};
