import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';

const QUOTE: BuildingBlockNodeConfig = {
  information: {
    single: 'Citaat',
    plural: 'Citaten',
    ribonIcon: require('../../../../img/buildingBlocks/quote_xl.svg'),
  },

  createDefaults: {
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html',
      },
    ],
  },
  edit: [
    {
      component: 'attachedContent',
      property: '$$html',
      label: 'Citaat',
      definiteArticle: true,
      required: RequiredType.ERROR,
    },
    {
      component: 'authors',
      property: 'creators',
      reactComponent: 'AsideFreeTextAuthor',
      label: 'Auteurs',
      // for QUOTE this is free text ("alia iacta est" - Julius Caesar) and not an href to /persons
      valueToString: (c) => (c.creators ? c.creators.join(', ') : ''),
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'html',
  },
};

export default QUOTE;
