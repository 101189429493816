class ProWebsite {
  constructor() {
    'ngInject';
  }

  $onInit() {}
}

module.exports = {
  template: require('./proWebsite.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sDisabled: '<',
  },
  controller: ProWebsite,
};
