import { RequiredType } from '@nodeTypeConfig/configTypes';
import { selectReferenceChild } from '@newStore/documentUI/transformContent/teaserSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { ContentHref } from '@generalTypes/apiTypes';
import { createError } from '../validationHelpers';
import { ValidationError } from '../validationTypes';
import { selectIsValidReferenceFromTeaser } from './externalLinkValidationRules';

const selectCallToActionTitleValidation = createTypedSelector(
  [(state, href: ContentHref) => selectReferenceChild(state, href)],
  (referenceChild): ValidationError | true => {
    if (referenceChild) {
      if (!referenceChild.title) {
        return createError(
          'teaserCallToActionContentInvalid',
          'selectCallToActionTitleValidation',
          'Voeg een <strong>Call to action</strong> toe',
          'teaserLinkedContent',
          RequiredType.ERROR
        );
      }
      if (referenceChild.title.split(' ').length > 5) {
        return createError(
          'teaserCallToActionContentInvalid',
          'selectCallToActionTitleValidation',
          '<strong>Call to action</strong> mag maximaal uit 5 woorden bestaan',
          'teaserLinkedContent',
          RequiredType.ERROR
        );
      }
    }
    return true;
  }
);

export const teaserCallToActionValidationRules = [
  selectIsValidReferenceFromTeaser,
  selectCallToActionTitleValidation,
].map((validationSelector) => ({ rule: validationSelector }));
