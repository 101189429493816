(function ($) {
  $.extend($.summernote.lang, {
    'nl-NL': {
      font: {
        bold: 'Vet',
        italic: 'Cursief',
        underline: 'Onderstrepen',
        clear: 'Stijl verwijderen',
        height: 'Regelhoogte',
        name: 'Lettertype',
        strikethrough: 'Doorhalen',
        subscript: 'Subscript',
        superscript: 'Superscript',
        size: 'Tekstgrootte'
      },
      image: {
        image: 'Afbeelding',
        insert: 'Afbeelding invoegen',
        resizeFull: 'Volledige breedte',
        resizeHalf: 'Halve breedte',
        resizeQuarter: 'Kwart breedte',
        floatLeft: 'Links uitlijnen',
        floatRight: 'Rechts uitlijnen',
        floatNone: 'Geen uitlijning',
        shapeRounded: 'Shape: Rounded',
        shapeCircle: 'Shape: Circle',
        shapeThumbnail: 'Shape: Thumbnail',
        shapeNone: 'Shape: None',
        dragImageHere: 'Sleep hier een afbeelding naar toe',
        dropImage: 'Drop image or Text',
        selectFromFiles: 'Selecteer een bestand',
        maximumFileSize: 'Maximum file size',
        maximumFileSizeError: 'Maximum file size exceeded.',
        url: 'URL van de afbeelding',
        remove: 'Verwijder afbeelding',
        original: 'Original'
      },
      video: {
        video: 'Video',
        videoLink: 'Video link',
        insert: 'Video invoegen',
        url: 'URL van de video',
        providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion of Youku)'
      },
      link: {
        link: 'Link',
        insert: 'Link invoegen',
        unlink: 'Link verwijderen',
        edit: 'Wijzigen',
        textToDisplay: 'Tekst van link',
        url: 'Naar welke URL moet deze link verwijzen?',
        openInNewWindow: 'Open in nieuw venster'
      },
      table: {
        table: 'Tabel',
        addRowAbove: 'Add row above',
        addRowBelow: 'Add row below',
        addColLeft: 'Add column left',
        addColRight: 'Add column right',
        delRow: 'Delete row',
        delCol: 'Delete column',
        delTable: 'Delete table'
      },
      hr: {
        insert: 'Horizontale lijn invoegen'
      },
      style: {
        style: 'Stijl',
        p: 'Normaal',
        blockquote: 'Quote',
        pre: 'Code',
        h1: 'Kop 1',
        h2: 'Kop 2',
        h3: 'Kop 3',
        h4: 'Kop 4',
        h5: 'Kop 5',
        h6: 'Kop 6'
      },
      lists: {
        unordered: 'Ongeordende lijst',
        ordered: 'Geordende lijst'
      },
      options: {
        help: 'Help',
        fullscreen: 'Volledig scherm',
        codeview: 'Bekijk Code'
      },
      paragraph: {
        paragraph: 'Paragraaf',
        outdent: 'Inspringen verkleinen',
        indent: 'Inspringen vergroten',
        left: 'Links uitlijnen',
        center: 'Centreren',
        right: 'Rechts uitlijnen',
        justify: 'Uitvullen'
      },
      color: {
        recent: 'Recente kleur',
        more: 'Meer kleuren',
        background: 'Achtergrond kleur',
        foreground: 'Tekst kleur',
        transparent: 'Transparant',
        setTransparent: 'Transparant',
        reset: 'Standaard',
        resetToDefault: 'Standaard kleur'
      },
      shortcut: {
        shortcuts: 'Toetsencombinaties',
        close: 'sluiten',
        textFormatting: 'Tekststijlen',
        action: 'Acties',
        paragraphFormatting: 'Paragraafstijlen',
        documentStyle: 'Documentstijlen',
        extraKeys: 'Extra keys'
      },
      help: {
        insertParagraph: 'Insert Paragraph',
        undo: 'Undoes the last command',
        redo: 'Redoes the last command',
        tab: 'Tab',
        untab: 'Untab',
        bold: 'Set a bold style',
        italic: 'Set a italic style',
        underline: 'Set a underline style',
        strikethrough: 'Set a strikethrough style',
        removeFormat: 'Clean a style',
        justifyLeft: 'Set left align',
        justifyCenter: 'Set center align',
        justifyRight: 'Set right align',
        justifyFull: 'Set full align',
        insertUnorderedList: 'Toggle unordered list',
        insertOrderedList: 'Toggle ordered list',
        outdent: 'Outdent on current paragraph',
        indent: 'Indent on current paragraph',
        formatPara: 'Change current block\'s format as a paragraph(P tag)',
        formatH1: 'Change current block\'s format as H1',
        formatH2: 'Change current block\'s format as H2',
        formatH3: 'Change current block\'s format as H3',
        formatH4: 'Change current block\'s format as H4',
        formatH5: 'Change current block\'s format as H5',
        formatH6: 'Change current block\'s format as H6',
        insertHorizontalRule: 'Insert horizontal rule',
        'linkDialog.show': 'Show Link Dialog'
      },
      history: {
        undo: 'Ongedaan maken',
        redo: 'Opnieuw doorvoeren'
      },
      specialChar: {
        specialChar: 'SPECIAL CHARACTERS',
        select: 'Select Special characters'
      }
    }
  });
}(jQuery));
