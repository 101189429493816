class ageLabel {
  constructor(LeerlijnService) {
    'ngInject';

    this.leerlijnService = LeerlijnService;
    this.showFullRange = false;
    this.showShortRange = false;
  }

  showRangeInYearsAndMonth() {
    const isCluster = this.sLeerlijn.type === 'CURRICULUM_ZILL_LEERLIJN_CLUSTER';
    const isEmpty =
      (this.dateOption.startageYear === 0 || this.dateOption.startageYear === undefined) &&
      (this.dateOption.startageMonth === 0 || this.dateOption.startageMonth === undefined) &&
      (this.dateOption.endageYear === 0 || this.dateOption.endageYear === undefined) &&
      (this.dateOption.endageMonth === 0 || this.dateOption.endageMonth === undefined);
    return isCluster && !isEmpty && this.showFullRange;
  }

  showRangeInMonths() {
    const isCluster = this.sLeerlijn.type === 'CURRICULUM_ZILL_LEERLIJN_CLUSTER';
    let isEmpty =
      (this.dateOption.startageMonth === 0 || this.dateOption.startageMonth === undefined) &&
      (this.dateOption.endageMonth === 0 || this.dateOption.endageMonth === undefined);
    if (isEmpty) {
      isEmpty =
        (this.dateOption.startageYear === 0 || this.dateOption.startageYear === undefined) &&
        (this.dateOption.endageYear === 0 || this.dateOption.endageYear === undefined);
    }
    return isCluster && !isEmpty && this.showShortRange;
  }

  showDevelopmentPhase() {
    return this.developmentPhase !== '';
  }

  $onChanges() {
    if (this.sLeerlijn) {
      if (this.sLeerlijn.startage >= 2.5) {
        this.showFullRange = true;
        this.showShortRange = false;
        this.dateOption = this.leerlijnService.getDateOptionsFrom(this.sLeerlijn);
      } else {
        this.showFullRange = false;
        this.showShortRange = true;
        this.dateOption = this.leerlijnService.getDateOptionsInMonthFrom(this.sLeerlijn);
      }

      this.developmentPhase = this.leerlijnService.getDevelopmentPhaseFrom(this.sLeerlijn);
    }
  }
}

export default {
  template: require('./ageLabel.html'),
  controllerAs: 'ctrl',
  bindings: {
    sLeerlijn: '<',
  },
  controller: ageLabel,
};
