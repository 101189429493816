/* eslint-disable func-names */
import {
  selectZillGoalByHref,
  selectLinkedZillGoalsForPracticalExample,
} from '@newStore/zill/zillSelectors';
import { getNow } from '@kathondvla/sri-client/date-utils';
import { addResourceAncestorsToLoad } from '@newStore/externalData/externalDataState';
import { addSubDetailToGoalOfExistingIllustration } from '@newStore/zill/zillSagas';
import { isLoadedZillGoal, isZillGoalSubDetail } from '@newStore/zill/zillTypes';
import {
  buildCurrentSelectionForZillSelector,
  groupSubDetailsByGoal,
  groupGoalsByZillCurriculum,
} from '@newStore/zill/zillHelpers';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class asideZillIllustrations {
  constructor(ZillSelectorService, ModalWindowService, ConfirmationService, $scope, $ngRedux) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
    this.zillSelectorService = ZillSelectorService;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.newSelectionHrefs = [];
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const { illustrationsAreLoaded, genericGoals, loadingZillGoals } =
        selectLinkedZillGoalsForPracticalExample(state);
      const goalsGroupedByZillCurriculum = groupGoalsByZillCurriculum(genericGoals);

      if (this.newSelectionHrefs.length) {
        this.processNewSelections(state, genericGoals);
      }

      return {
        zillCurriculumGroups: Object.values(goalsGroupedByZillCurriculum),
        zillCurriculumGroupMap: goalsGroupedByZillCurriculum,
        loading: !illustrationsAreLoaded || loadingZillGoals.length,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  /**
   * If there are new selections selected with the ZillSelector we process them here (called on every state call when there are new selections)
   * If user selected development content (is content below a generic goal) it needs to be grouped per goal or added to an existing goal if another development content of the same goal was previously selected (See monday #5919817257)
   * Funtionally an illustration is always linked to a "goal", even though this means linking it to (multiple) develoment content in the actual content data
   */
  processNewSelections(state, linkedGoals) {
    const newSelections = this.newSelectionHrefs.map((href) => selectZillGoalByHref(state, href));

    if (newSelections.every((item) => isLoadedZillGoal(item) || isZillGoalSubDetail(item))) {
      this.newSelectionHrefs = [];
      const subDetails = newSelections.filter((item) => isZillGoalSubDetail(item));
      const existingGoals = subDetails
        .map((subDetail) => ({
          subDetail,
          existingGoal: linkedGoals.find(
            (linkedGoal) => linkedGoal.href === subDetail.zillGoal.href
          ),
        }))
        .filter(({ existingGoal }) => existingGoal);
      if (existingGoals.length) {
        this.$ngRedux.dispatch(addSubDetailToGoalOfExistingIllustration(existingGoals));
      }

      const newGoals = [
        ...newSelections
          .filter((goal) => isLoadedZillGoal(goal))
          .map((goal) => ({ goal, subDetail: [] })),
        ...groupSubDetailsByGoal(
          subDetails.filter(
            (dc) => !linkedGoals.find((linkedGoal) => linkedGoal.href === dc.zillGoal.href)
          )
        ),
      ];
      this.showModalForNewIllustrations(newGoals);
    }
  }

  async openSelector() {
    const zillCurriculum = await this.modalWindowService.open({
      component: 'documentSelectorModal',
      dataForModal: {
        type: 'zillCurriculum',
        stateField: 'zillCurriculums',
        params: {
          type: 'CURRICULUM_ZILL',
          issuedBefore: getNow(),
          orderBy: 'issued',
          descending: true,
        },
      },
    });

    try {
      // after selecting a zill version, we will open the zill selector where you can adapt the
      // selected goals of that version. We do not touch the goals of different zill versions.

      const groupForSelectedVersion = this.zillCurriculumGroupMap[zillCurriculum.$$meta.permalink];
      const currentSelections = groupForSelectedVersion
        ? buildCurrentSelectionForZillSelector(groupForSelectedVersion.goals)
        : [];

      const selectedGoals = await this.zillSelectorService.openZillSelector(
        zillCurriculum.$$meta.permalink,
        currentSelections.map((sel) => sel.href),
        'praktijkvoorbeeld'
      );

      const deletedSelections = currentSelections.filter((cs) => !selectedGoals.includes(cs.href));

      // If a selection is deleted we need to make the distinction between: is an entire goal deleted and with it the illustration, or is just a subDetail removed
      const deletedSubDetails = deletedSelections.filter(
        (sel) => sel.type === 'ZILL_GOAL_SUB_DETAIL'
      );
      const subDetailsForWhichEntireGoalIsDeleted = deletedSubDetails.filter((subDetail) =>
        subDetail.zillGoal.subDetails.every((subDetailOfGoal) =>
          deletedSubDetails.some(
            (deletedSubDetail) => subDetailOfGoal.href === deletedSubDetail.href
          )
        )
      );
      const removedSubDetails = deletedSubDetails.filter(
        (subDetail) =>
          !subDetailsForWhichEntireGoalIsDeleted.some(
            (subDetailForWhichEntireGoalIsDeleted) =>
              subDetailForWhichEntireGoalIsDeleted.href === subDetail.href
          )
      );
      if (removedSubDetails.length) {
        console.log('removedSubDetails', removedSubDetails);
      }
      removedSubDetails.forEach((subDetail) => {
        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.removeRelationAction(
            subDetail.relationFromIllustrationHref.split('/').pop()
          )
        );
      });
      const deletedGoals = [
        ...deletedSelections.filter((sel) => sel.type === 'ZILL_GOAL'),
        ...Object.values(
          subDetailsForWhichEntireGoalIsDeleted.reduce(
            (goalMap, subDetail) => ({ ...goalMap, [subDetail.zillGoal.href]: subDetail }),
            {}
          )
        ),
      ];
      console.log('deleted goals', deletedGoals);

      deletedGoals.forEach((deletedSelection) => {
        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.removeZillIllustrationRelationAction(
            deletedSelection.zillGoal.illustration.href.split('/').pop(),
            deletedSelection.zillGoal.illustration.relationToPracticalExampleHref.split('/').pop()
          )
        );
      });

      const newSelections = selectedGoals.filter(
        (href) => !currentSelections.some((sel) => sel.href === href)
      );

      if (newSelections.length) {
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.expandZillGoalSelectionsAction(newSelections));
        this.$ngRedux.dispatch(addResourceAncestorsToLoad({ resources: newSelections }));
        this.newSelectionHrefs = newSelections;
      }
    } catch (error) {
      if (error === 'ZILL_SELECTOR_ABORTED') {
        console.log('The user closed the zill selector manually.');
      } else {
        console.error(
          'An unexpected error occured when communicating with the Zill selector',
          error
        );
      }
    }
  }

  showModalForNewIllustrations(newGoals) {
    newGoals.forEach(async ({ goal, subDetail }) => {
      // open modal, ask zill illustration title,
      // create node with linked goal selections to it
      const title = await this.modalWindowService.open({
        component: 'zillIllustrationModal',
        dataForModal: { goal, subDetail, goalHref: goal.href },
      });

      const zillIllustration = {
        title,
      };
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.addZillIllustrationAction(
          this.sDocument.key,
          zillIllustration,
          subDetail.length ? subDetail.map((dc) => dc.href) : [goal.href]
        )
      );
    });
  }

  async deleteIllustration(goal) {
    // delete illustration and relation to document
    const confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.removeZillIllustrationRelationAction(
          goal.illustration.href.split('/').pop(),
          goal.illustration.relationToPracticalExampleHref.split('/').pop()
        )
      );
    }
  }
}

export default {
  template: require('./asideZillIllustrations.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideZillIllustrations,
};
