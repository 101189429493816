import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { getRoot } from '@newStore/externalData/externalDataHelpers';
import {
  selectExternalContent,
  selectExternalContentRelationsMap,
} from '@newStore/externalData/externalDataSelectors';

import { Content, ContentHref, ContentType } from '@generalTypes/apiTypes';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { ProNewsItemNode } from '../documentUITypes';

export const selectProNewsItemNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectApiWithPendingChanges(state).content[href],
    (state) => selectExternalContent(state),
    (state) => selectExternalContentRelationsMap(state),
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => state.document.pendingActions,
    (state) => state.externalData.resourceAncestorsToLoad,
  ],
  (
    content: Content,
    externalContent,
    externalRelationsMap,
    relationsMap,
    pendingActions,
    externalResourcesToLoad
  ): ProNewsItemNode => {
    const references = relationsMap.to[content.$$meta.permalink]?.filter(
      (relation) => relation.relationtype === 'REFERENCES'
    );

    const resourcesLoading = references.some((ref) =>
      externalResourcesToLoad.includes(ref.from.href)
    );

    const referencedContent = references.map((z) =>
      getRoot(externalRelationsMap, externalContent, z.from.href)
    );
    const teasers =
      referencedContent
        ?.filter((refCont) => refCont?.type === ContentType.TEASER)
        .map((refCont) => ({
          title: refCont.title as string,
          key: refCont.key,
        })) || [];

    return {
      href: content.$$meta.permalink,
      key: content.key,
      title: content?.title || '',
      type: NodeType.PRONEWSITEM,
      linkedTeasers: teasers,
      customRender: true,
      arePendingActions: pendingActions.length > 0,
      resourcesLoading,
    };
  }
);
