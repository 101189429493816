import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';

const LLINKID_GOAL_DEMARCATION: BuildingBlockNodeConfig = {
  information: {
    single: 'Afbakening',
    plural: 'Afbakening',
  },

  buildingBlocks: [{ type: NodeType.SECTION }, { type: NodeType.PARAGRAPH }],
  edit: [],
  readOnlyNode: true,
  createInModal: false,
  isCollapsible: true,
  showPlaceholder: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default LLINKID_GOAL_DEMARCATION;
