import { TeaserNode } from '@newStore/documentUI/documentUITypes';
import { useDispatch, useSelector } from 'react-redux';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { updateAsideViewModelAction } from '@store/actions/documentActions';
import { RootState } from '@generalTypes/rootStateTypes';
import { createNewsitemFromTeaser } from '@newStore/documentApi/documentApiState';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import InlineEditor from '@UI/editor/InlineEditor';
import { useState } from 'react';
// eslint-disable-next-line
import LinkIcon from '!!raw-loader!@img/icons/link.svg';
// eslint-disable-next-line
import PlusIcon from '!!raw-loader!@img/icons/plus.svg';
import './TeaserRow.scss';
import { getPlaceHolderLabel } from '../../contentRowHelpers';

const TeaserRow: React.FC<{ contentNode: TeaserNode }> = ({ contentNode }) => {
  const [creating, setCreating] = useState(false);
  const dispatch = useDispatch();
  const $state = getAngularService('$state');
  const nodeTypeConfig = useSelector((state: RootState) =>
    selectAppliedNodeConfig(state, contentNode.href)
  );

  const canCreateNewsItem = useSelector(
    (state: RootState) =>
      state.document.pendingActions.length === 0 &&
      state.document.viewModel.isValid && // TODO: check validation with a selector
      state.documentUI.mode === 'EDIT'
  );

  const showLinkAndButtons = useSelector(
    (state: RootState) =>
      state.documentUI.currentDocument === contentNode.href && !contentNode.linkedContentLoading
  );

  const openAside = () => {
    const { key } = contentNode;
    $state.go('edit.aside', { editKey: key });
    dispatch(updateAsideViewModelAction(key));
    setTimeout(() => document.getElementById('linkedContent')?.scrollIntoView(), 500);
  };

  const createNewsItem = () => {
    setCreating(true);
    dispatch(createNewsitemFromTeaser({ teaserHref: contentNode.href }));
  };

  if (!contentNode) {
    return null;
  }

  return (
    <>
      {showLinkAndButtons && (
        <div className="teaser-header">
          <div className="teaser-link">
            {contentNode.linkedContent === null && <div>Niet gelinkt</div>}
            {contentNode.linkedContent?.documentKey && (
              <div>
                <a href={`/edit/${contentNode.linkedContent?.documentKey}`}>
                  {contentNode.linkedContent?.title}
                </a>
              </div>
            )}
            {contentNode.linkedContent?.url && (
              <div>
                <a href={contentNode.linkedContent?.url} target="_blank" rel="noreferrer">
                  {contentNode.linkedContent?.title}
                </a>
              </div>
            )}
          </div>
          {contentNode.linkedContent === null && (
            <div className="teaser-buttons">
              <button
                className="btn btn-teaser"
                onClick={createNewsItem}
                disabled={!canCreateNewsItem || creating}
              >
                {creating ? (
                  <>Laden...</>
                ) : (
                  <>
                    <span dangerouslySetInnerHTML={{ __html: PlusIcon }}></span>
                    <span className="btn-teaser-label">Nieuwsbericht maken</span>
                  </>
                )}
              </button>
              <button className="btn btn-teaser" onClick={openAside}>
                <span dangerouslySetInnerHTML={{ __html: LinkIcon }}></span>
                <span className="btn-teaser-label">Linken</span>
              </button>
            </div>
          )}
        </div>
      )}

      <div>
        <h4>
          <InlineEditor
            initial={contentNode.title}
            field="title"
            placeholder={getPlaceHolderLabel(nodeTypeConfig, 'title')}
            skey={contentNode.key}
            href={contentNode.href}
          ></InlineEditor>
        </h4>
        <div className="teaser-content">
          {'image' in contentNode && contentNode.image && <img src={contentNode.image.url} />}
          <div className="teaser-content-description" style={{ paddingTop: '5px' }}>
            <InlineEditor
              initial={contentNode.description}
              field="description"
              placeholder={getPlaceHolderLabel(nodeTypeConfig, 'description')}
              skey={contentNode.key}
              href={contentNode.href}
            />

            {contentNode.linkedContent && (
              <div>
                <strong>[ {contentNode.linkedContent.callToAction}]</strong>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeaserRow;
