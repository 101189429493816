import { ContentHref } from '@generalTypes/apiTypes';
import { selectApiWithPendingChangesWithoutDeletes } from '@newStore/documentApi/documentApiSelectors';
import { selectNodeType } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { getNodeTypeConfig } from '@nodeTypeConfig/index';
import { createError, getNodeTypeLabel } from '../validationHelpers';
import { ValidationResult } from '../validationTypes';

const getMessage = (nodeType: NodeType) => {
  if (nodeType === NodeType.SHARED_ATTACHMENTS_GROUP) {
    return 'Upload een document of selecteer een gedeeld document.';
  }
  const nodeTypeConfig = getNodeTypeConfig(nodeType);
  return `Voeg minstens één ${getNodeTypeLabel(
    nodeTypeConfig.information.single.replace(/s?groep$/, '')
  )} toe`;
};

/**
 * Any type of node that groups children (ATTACHMENTS_GROUP, IMAGE_GROUP, SHARED_ATTACHMETNS_GROUP, Zill leerlijn)
 * But that does not make sense to have no children can be given this selector.
 * There is a simple message generator which just strips the 'groep' of the parent label to get the child name,
 * but if that message does not make sense add a specific message for the NodeType to the getMessage method.
 */
const selectNoChildrenErrors = createTypedSelector(
  [
    (state, href: ContentHref) =>
      selectApiWithPendingChangesWithoutDeletes(state).relationsToAndFromMap.to[href],
    (state, href: ContentHref) => selectNodeType(state, href),
  ],
  (childRelations, nodeType): ValidationResult => {
    if (childRelations.length) {
      return true;
    }
    return createError('childrenRequired', 'selectNoChildrenErrors', getMessage(nodeType));
  }
);
export const validateChildrenAreRequired = { rule: selectNoChildrenErrors };
