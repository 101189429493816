import { GlossaryNode } from '@newStore/documentUI/documentUITypes';
import { useEffect, useState } from 'react';
import { Editor, EditorEvent } from 'tinymce/tinymce';
import { stripHtml } from '@newStore/genericHelpers';

const formatContent = (currentItem) => {
  let description = stripHtml(currentItem.description);
  if (description.length > 200) {
    description = `${description.substring(0, 200)}...`;
  }
  return (
    currentItem.title +
    (description ? `<br><span class="tooltip-popover-description">${description}</span>` : '')
  );
};

const useEditorPopOver = (
  findItem: (target: HTMLElement) => GlossaryNode | undefined,
  cssSelector: string,
  editor: Editor
) => {
  const [popOverData, setPopOverData] = useState<{
    target: HTMLElement | null;
    content: string;
  }>({ target: null, content: '' });

  useEffect(() => {
    const showPopOver = (event: EditorEvent<MouseEvent>) => {
      const target = event.target as HTMLElement;
      if (target?.matches(cssSelector)) {
        const currentItem = findItem(target);
        if (currentItem) {
          setPopOverData({ target, content: formatContent(currentItem) });
        }
      }
    };

    const hidePopOver = (event: EditorEvent<MouseEvent>) => {
      const target = event.target as HTMLElement;
      if (target?.matches(cssSelector)) {
        setPopOverData({ target: null, content: '' });
        event.preventDefault();
      }
    };

    /* We want this event fire once for the case when the user overs the mouse to a term but
      the editor is still loading, so the regular mouseover will not fire until the mouse enter
      and leaves the term again. Mousemove is triggered too often and throttle it could cause
      issues with the order of the mouseout event making the popover not to disappear. */
    const mousemove = (event: EditorEvent<MouseEvent>) => {
      const target = event.target as HTMLElement;
      if (target?.matches(cssSelector)) {
        editor.off('mousemove', mousemove);
        showPopOver(event);
      }
    };

    if (editor) {
      editor.on('mouseover', showPopOver);
      editor.on('mouseout', hidePopOver);
      editor.on('click', hidePopOver, true);
      editor.on('mousemove', mousemove);
    }

    return () => {
      if (editor) {
        editor.off('mouseover', showPopOver);
        editor.off('mouseout', hidePopOver);
        editor.off('click', hidePopOver);
        editor.off('mousemove', mousemove);
      }
    };
  }, [findItem, cssSelector, editor]);

  return popOverData;
};

export default useEditorPopOver;
