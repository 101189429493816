export const regions = [
  {
    $$meta: {
      permalink: '/dioceses/1',
    },
    name: 'Antwerpen',
  },
  {
    $$meta: {
      permalink: '/dioceses/2',
    },
    name: 'Mechelen-Brussel',
  },
  {
    $$meta: {
      permalink: '/dioceses/3',
    },
    name: 'Limburg',
  },
  {
    $$meta: {
      permalink: '/dioceses/4',
    },
    name: 'Oost-Vlaanderen',
  },
  {
    $$meta: {
      permalink: '/dioceses/5',
    },
    name: 'West-Vlaanderen',
  },
];
