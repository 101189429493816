import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { RequiredType } from '@nodeTypeConfig/configTypes';
import { createError } from '../validationHelpers';

/**
 * TODO remove this validation rule and instead create saga that when you change webconfig to download list or faq page => remove description
 * and just don't show the field, because it is very stupid to show an input field that you are NOT allowed to fill in.
 * We will work on the saga when taking over the pending actions.
 * There might also be other actions to do on changing web configuration (like changing tags on attachments group from DOWNLOAD to INLINE_ATTACHMENTS_GROUP and other way round)
 */
const selectIsDescriptionEmpty = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (contentItem) =>
    !contentItem.description
      ? true
      : createError(
          'downloadShouldNotHaveDescription',
          'selectEmptyDescription',
          "Je mag de inleiding niet invullen voor Pagina's met sjabloon Downloadlijst of Veelgestelde vragen.",
          'description',
          RequiredType.ERROR
        )
);

export const validateDownloadListAndFaqPageShouldNotHaveDescription = {
  rule: selectIsDescriptionEmpty,
};
