// eslint-disable-next-line import/no-webpack-loader-syntax
import TermIcon from '!!raw-loader!../../../../img/termIcon.svg';
import { Editor } from 'tinymce/tinymce';
import { openModal, registerButton } from './editorHelpers';

const modalOptions = {
  component: 'termSelectionModal',
  dataForModal: {
    term: null,
  },
};

const renderFunction = (modalResult, selectedText) =>
  `<a href="/content/${modalResult.key}" rel="term">${selectedText}</a>`;

const onAction = async (editor: Editor) => {
  openModal(editor, modalOptions, renderFunction);
};

const isEnabledButton = (editor: Editor) =>
  editor.selection.getNode().getAttribute('rel') === 'term' ||
  (!editor.selection.isCollapsed() && editor.selection.getNode().tagName !== 'A');

registerButton('term', 'Begrip', TermIcon as string, onAction, isEnabledButton);
