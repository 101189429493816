import { ContentHref, WebPage } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectApiWithPendingChangesWithoutDeletes,
  selectContentItem,
  selectWebPage,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { getNodeType } from '@nodeTypeConfig/nodeTypeConfig';
import { getNodeTypeConfig } from '@nodeTypeConfig/index';
import { createError, getNodeTypeLabel } from '../validationHelpers';
import { ValidationError, ValidationResult } from '../validationTypes';
import { noDuplicateWebPaths } from './noDuplicateWebPaths';

const reservedWebPaths = [
  '/zoeken',
  '/nieuwsoverzicht',
  '/professionalisering',
  '/localnews',
  '/newsDetail',
  '/view-options',
];
const unAllowedCharsRegex = /[<>%{}|\\^`:?#[\]@!$&()*+,;="]/;

const isWebPathUnique = (
  webPage: WebPage,
  allWebPages: Array<WebPage>,
  state: RootState,
  href: ContentHref
): ValidationResult => {
  const duplicateWebPagePathInThisDoc = allWebPages.find(
    (wp) => wp.key !== webPage.key && wp.path === webPage.path
  );
  if (duplicateWebPagePathInThisDoc) {
    const otherContentItem = selectContentItem(state, duplicateWebPagePathInThisDoc.source.href);
    const nodeTypeOfOtherItem = getNodeType(otherContentItem, webPage, null);
    const nodeTypeConfigForOtherItem = getNodeTypeConfig(nodeTypeOfOtherItem);
    return createError(
      'duplicatePathInDocument',
      'isWebPathUnique',
      `Binnen dit thema is het pad <strong>${
        webPage.path
      }</strong> al toegekend aan ${getNodeTypeLabel(
        nodeTypeConfigForOtherItem.information.single.split(':')[0]
      )} "${otherContentItem.title}". Kies een ander pad`,
      'webPage.path'
    );
  }
  // if path is unique within this document we will check if it is unique within all webPages
  // @Stefan the most logical place to validate the noDuplicateWebPath is here, but then this selector depends on the whole state
  return noDuplicateWebPaths.selectValidation(state, href);
};

const getInvalidCharacterMsg = (path: string, label: string) =>
  `<em>${path}</em> bevat een teken dat niet is toegelaten in <strong>${label}</strong>. Niet toegelaten tekens zijn: ? # [ ] @ ! $ & ( ) * + , ; = \\" < > % { } | \\ ^ \` `;

const isPathValid = (webPage: WebPage) => {
  // validate paths that are reserved
  if (reservedWebPaths.some((p) => webPage.path.endsWith(p))) {
    return createError(
      'reservedPath',
      'isPathValid',
      `<em>${webPage.path}</em> is voorbehouden voor een andere pagina. Kies een ander <strong>pad</strong>`,
      'webPage.path'
    );
  }

  // valdiate characters that are not allowed
  if (unAllowedCharsRegex.test(webPage.path)) {
    return createError(
      'illegalCharactersInPath',
      'isPathValid',
      getInvalidCharacterMsg(webPage.path, 'het pad'),
      'webPage.path'
    );
  }
  return true;
};

const areOldUrlPathsValid = (webPage: WebPage): Array<ValidationResult> => {
  if (!webPage.oldLocations) {
    return [true];
  }
  return webPage.oldLocations.map((oldLoc) =>
    unAllowedCharsRegex.test(oldLoc.path)
      ? createError(
          'illegalCharactersInOldLocations',
          'areOldUrlPathsValid',
          getInvalidCharacterMsg(oldLoc.path, "de vorige url's"),
          'webPage.oldLocations'
        )
      : true
  );
};

// validate if webPage is the only one for this webSite (only one not related to path, so move or make name more generic?)
const isOnlyWebPageForThisNodeInSameWebsite = (
  webPage: WebPage,
  allWebPages: Array<WebPage>
): ValidationError | true => {
  if (
    allWebPages.some(
      (wp) =>
        wp.key !== webPage.key &&
        wp.source.href === webPage.source.href &&
        wp.website.href === webPage.website.href
    )
  ) {
    return createError(
      'duplicateWebConfig',
      'isOnlyWebPageForThisNodeInSameWebsite',
      `Een pagina mag <strong>geen twee webconfiguraties</strong> hebben voor dezelfde website.`,
      'webPage.website'
    );
  }
  return true;
};

/**
 * If the contentNode has WebConfiguration, then the webpage must be valid.
 */
export const selectWebPageErrors = createTypedSelector(
  [(state) => state, (state, href: ContentHref) => href],
  (state, href): Array<ValidationResult> => {
    const webPage = selectWebPage(state, href);
    if (!webPage || webPage.$$meta.deleted) {
      return [];
    }
    const allWebPages = selectApiWithPendingChangesWithoutDeletes(state).webPages;

    return [
      isWebPathUnique(webPage, allWebPages, state, href),
      isPathValid(webPage),
      isOnlyWebPageForThisNodeInSameWebsite(webPage, allWebPages),
      ...areOldUrlPathsValid(webPage),
    ];
  }
);
