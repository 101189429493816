import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  EditAsideDateToSendDatePicker,
  EditAsideExpiryDatePicker,
  EditAsideIssuedDatePicker,
} from '@nodeTypeConfig/configTypes';
import { nlBE } from 'date-fns/locale/nl-BE';
import React from 'react';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';

const noDisableDate = () => false;

const DatePickerElement: React.FC<{
  config: EditAsideExpiryDatePicker | EditAsideDateToSendDatePicker | EditAsideIssuedDatePicker;
  value: Date | null;
  onChange: (value: Date | null) => void;
  readOnly?: boolean;
}> = ({ config, value, onChange, readOnly: readOnlyFromAbove = false }) => {
  const readOnly = useReadOnly(readOnlyFromAbove);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nlBE}>
      <AsideValidation property={config.property} component={config.component}>
        <div className={`${readOnly ? 'aside-component-disabled' : ''}`}>
          <label className="control-label">{config.label}</label>
          <DatePicker
            format="dd/MM/yyyy"
            value={value}
            onChange={onChange}
            readOnly={readOnly}
            openTo={config.options.openTo}
            shouldDisableDate={config.options.shouldDisableDate || noDisableDate}
            slotProps={{
              field: { clearable: true },
              layout: {
                sx: {
                  '& .MuiPickersCalendarHeader-labelContainer': {
                    fontSize: '1.6rem',
                  },
                  '& .MuiButtonBase-root': {
                    fontSize: '1.2rem',
                  },
                  '& .MuiPickersArrowSwitcher-button': {
                    fontSize: '2.5rem !important',
                  },
                  '& .MuiTypography-root': {
                    fontSize: '1.2rem',
                  },
                  '& .MuiPickersYear-yearButton': {
                    fontSize: '1.2rem',
                  },
                },
              },
              textField: {
                sx: {
                  height: '3.12rem',
                  width: '100%',
                  color: '#555555',
                  boxShadow: 'inset 0 2px 1px rgba(0, 0, 0, 0.075)',
                  '& .MuiInputBase-root': {
                    height: '3.12rem',
                    borderRadius: '0',
                  },
                  '& .MuiInputBase-input': {
                    padding: '0 10px',
                    fontSize: '14px',
                  },
                },
              },
            }}
          />
        </div>
      </AsideValidation>
    </LocalizationProvider>
  );
};

export default DatePickerElement;
