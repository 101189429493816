import { selectUserHref } from '@newStore/user/userSelectors';
import { getRelatedContentHref } from './documentStateHelpers';
import { proposalStatuses } from '../constants/proposalStatuses';

function areSharingAuthors(group1, group2) {
  return group1.authors.some((a) => group2.authors.includes(a));
}

export const groupProposalsToSubmitByAuthors = (proposalsToSubmit, rootState) => {
  const groups = [];
  const me = selectUserHref(rootState);

  proposalsToSubmit.forEach((proposal) => {
    const proposalOnlyIncludesCurrentUser =
      proposal.creators.length === 1 && proposal.creators.includes(me);
    const proposalCreatorsWithoutCurrentUser = proposal.creators.filter((c) => c !== me);

    let group;
    if (proposalOnlyIncludesCurrentUser) {
      // try to find a group containing only the current user
      group = groups.find(
        (g) =>
          g.authors.every((a) => proposal.creators.includes(a)) &&
          g.authors.length === proposal.creators.length
      );
    } else {
      // try to find a group that share some author(s) and doesn't include the current user
      group = groups.find(
        (g) =>
          g.authors.some((a) => proposalCreatorsWithoutCurrentUser.includes(a)) &&
          !g.authors.includes(me)
      );
    }

    if (group) {
      group.proposals.push(proposal);
      // add those proposal creators that are not yet in the found group
      proposalCreatorsWithoutCurrentUser.forEach((creator) => {
        if (!group.authors.includes(creator)) {
          if (proposal.expandedCreators) {
            group.expandedCreators.push(
              proposal.expandedCreators.find((e) => e.$$meta.permalink === creator)
            );
          }
          group.authors.push(creator);
        }
      });
    } else {
      groups.push({
        key: groups.length,
        authors: proposalOnlyIncludesCurrentUser
          ? proposal.creators
          : proposalCreatorsWithoutCurrentUser,
        expandedCreators: proposal.expandedCreators
          ? proposal.expandedCreators.filter(
              (e) =>
                proposalOnlyIncludesCurrentUser ||
                proposalCreatorsWithoutCurrentUser.includes(e.$$meta.permalink)
            )
          : [],
        proposals: [proposal],
        selected: true,
      });
    }
  });

  // merge groups that share some author(s) and the current user is not on any of them
  let mergedGroups = [];
  const finalGroups = [];
  [...groups].forEach((group) => {
    if (!mergedGroups.find((mg) => mg.key === group.key)) {
      const groupsSharingAuthors = groups.filter(
        (g) => g.key !== group.key && areSharingAuthors(g, group)
      );
      group = {
        ...group,
        proposals: [
          ...group.proposals,
          ...groupsSharingAuthors.reduce((proposals, gr) => [...proposals, ...gr.proposals], []),
        ],
      };
      // add authors from the merged groups
      groupsSharingAuthors
        .reduce((creators, gr) => {
          return [...creators, ...gr.authors];
        }, [])
        .forEach((creator) => {
          if (!group.authors.includes(creator)) {
            if (group.expandedCreators) {
              group.expandedCreators.push(
                groupsSharingAuthors
                  .reduce((expAuthors, gr) => [...expAuthors, ...gr.expAuthors], [])
                  .find((e) => e.$$meta.permalink === creator)
              );
            }
            group.authors.push(creator);
          }
        });

      mergedGroups = [...mergedGroups, ...groupsSharingAuthors];
      group.expandedCreators = group.expandedCreators
        .map((creator) => {
          return creator ? `${creator.firstName} ${creator.lastName}` : '[onbekend]';
        })
        .join(', ');
      finalGroups.push(group);
    }
  });

  return finalGroups;
};

export const getProposalForIssuedDate = (proposals) => {
  return proposals.find((proposal) => {
    return proposal.listOfRequestedChanges.filter(
      (change) => change.patch && change.patch.filter((patch) => patch.path === '/issued').length
    ).length;
  });
};

const getPatchProposal = (proposal, patch) => {
  return {
    type: 'PATCH_PROPOSAL',
    resources: [
      {
        href: getRelatedContentHref(proposal),
        patch,
        patchOlderProposals: true,
      },
    ],
  };
};

export const createPatchProposalPendingActions = (proposal, patch, contentMap, addAcceptAction) => {
  const pendingActions = [getPatchProposal(proposal, patch)];

  if (addAcceptAction) {
    pendingActions.push({
      type: 'ACCEPT_PROPOSAL',
      resources: proposal.listOfRequestedChanges.map((c) => {
        let actionResource = {
          ...c,
          href: c.appliesTo.href,
          body: c.resource,
        };

        if (c.type === 'UPLOAD') {
          actionResource = {
            ...actionResource,
            body: {
              ...c.attachment,
              ...c.metadata,
              href: c.attachment.href,
              $$url: c.attachment.href,
            },
            node: contentMap.get(c.relatedTo.href),
            relatedTo: {
              href: c.relatedTo.href,
            },
          };
        }

        return actionResource;
      }),
    });
  }

  return pendingActions;
};

/**
 * Generates pending actions to update the status of not applicable proposals.
 * @param {Map} notApplicableProposalsMap Map of node keys and their not applicable proposals.
 * @returns {Array} An array of pending actions of type PATCH_PROPOSAL.
 */
export const getNapPendingActions = (notApplicableProposalsMap) => {
  const patch = [
    {
      op: 'replace',
      path: '/status',
      value: proposalStatuses.notApplicable,
    },
  ];

  return [...notApplicableProposalsMap.values()].flat().map((proposal) => {
    const pendingAction = getPatchProposal(proposal, patch);
    pendingAction.updateProposalsInEditMode = true;
    return pendingAction;
  });
};
