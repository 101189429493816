import { any } from 'prop-types';
import React from 'react';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { EditAsideTitle } from '@nodeTypeConfig/configTypes';
import AsideEditor from '@UI/editor/AsideEditor';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';

const AsideTitle: React.FC<{
  config: EditAsideTitle;
}> = ({ config }) => {
  const editHref = useEditHref();
  const nodeKey = getResourceKey(editHref);

  const initialValue = useInitialValue(config.property);

  const readOnly = useReadOnly();

  return (
    <AsideValidation property={config.property} component={config.component}>
      <div className={`${readOnly ? 'aside-component-disabled' : ''}`}>
        <label className="control-label">{config.label}</label>
        <div className="form-control">
          <AsideEditor
            field={config.property}
            initial={initialValue}
            skey={nodeKey}
            readOnly={readOnly}
            inline
            placeholder={`Vul een ${config.label.toLowerCase()} in...`}
          />
        </div>
      </div>
    </AsideValidation>
  );
};

AsideTitle.displayName = 'AsideTitle';

export default AsideTitle;

AsideTitle.propTypes = {
  config: any,
};
