import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { isEmpty } from '../../../reduxLoop/helpers/documentHelpers';

class rowTeaser {
  constructor(constants, settings, Utils, $ngRedux) {
    'ngInject';

    this.constants = constants;
    this.settings = settings;
    this.$ngRedux = $ngRedux;
    this.utils = Utils;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        linkedContent: this.getLinkedContent(state.document.viewModel.flat),
      };
    })(this);
  }

  getLinkedContent(flat) {
    const teaser = flat.find((f) => f.key === this.sItem.key);
    return teaser ? teaser.linkedContent : undefined;
  }

  hasEditSection(name, value) {
    const component = this.sItem.$$editSections.filter((o) => o.component === name);
    if (component.length > 0) {
      if (component[0].showPlaceholder) {
        return false;
      }
      if (component[0].view && component[0].view.hideIfEmpty && isEmpty(value)) {
        return false;
      }
      return true;
    }
    return false;
  }

  saveInline(e, editorData) {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(editorData.key, { [editorData.field]: editorData.value })
    );
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export const rowTeaserComponent = {
  template: require('./rowTeaser.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<',
    sConfig: '<',
  },
  controller: rowTeaser,
};
