import React, { useState } from 'react';
import { Popover, Typography } from '@mui/material';
import { TeaserImportance } from '../AsideTeaserImportance';

const labels = {
  LOW: 'Niet opnemen in nieuwsbrief',
  MEDIUM: 'Gewone teaser',
  HIGH: 'Prioritaire teaser',
};

const AsideTeaserImportanceLabel: React.FC<{ importance: TeaserImportance }> = ({ importance }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (importance === 'HIGH') {
    return (
      <>
        <span style={{ marginRight: '.5rem' }}>Prioritaire teaser</span>
        <sup>
          <span
            className="glyphicon glyphicon-question-sign"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          ></span>
        </sup>

        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            iedereen zal die deze teaser ontvangen in de nieuwsbrief, ook als ze niet ingeschreven
            zijn op jouw thema
          </Typography>
        </Popover>
      </>
    );
  }

  return labels[importance];
};

export default AsideTeaserImportanceLabel;
