import { Content, ContentHref } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector, getAttachmentUrl } from '@newStore/genericHelpers';

import { NodeType } from '@nodeTypeConfig/configTypes';
import { ImageGroupNode, ImageNode } from '../documentUITypes';
import { selectProposedContentHrefsToDelete } from '../transformProposal/proposalSelectors';
import { getIllustration } from './helpers';

const selectImageGroupAttachments = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectProposedContentHrefsToDelete(state),
    (state, href: string) => href,
  ],
  (relationsMap, content, proposedHrefsToDelete, href) =>
    relationsMap.to[href].flatMap((child) => {
      const contentImage = content[child.from.href];
      const image = getIllustration(contentImage.attachments, 800);
      return image
        ? {
            key: image.key,
            isDeletedByProposal: proposedHrefsToDelete.includes(contentImage.$$meta.permalink),
            src: getAttachmentUrl(image),
          }
        : [];
    })
);

export const selectImageNode = createTypedSelector(
  [(state, href: ContentHref) => selectContentItem(state, href)],
  (content: Content): ImageNode => {
    const image = getIllustration(content.attachments, 800);
    return {
      href: content.$$meta.permalink,
      key: content.key,
      type: NodeType.IMAGE,
      src: image && getAttachmentUrl(image),
      customRender: true,
    };
  }
);

export const selectImageGroupNode = createTypedSelector(
  [(state, href: ContentHref) => selectContentItem(state, href), selectImageGroupAttachments],
  (content: Content, images): ImageGroupNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      description: content?.description || '',
      type: NodeType.IMAGE_GROUP,
      images,
      customRender: true,
    };
  }
);
