import { $$pathToRoot, ContentHref, ContentWith$$Relations, WebPage } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { BatchItem } from '@generalTypes/sriTypes';
import { ContentNodeId } from '@newStore/documentApi/documentApiTypes';

export type ValidationRuleName = 'referencedInProHomeCannotUnpublish' | 'noDuplicateWebPaths';

export type ReferencedInProHomeCannotUnpublishData = Array<
  ContentWith$$Relations & { $$pathToRoot: $$pathToRoot }
>;

export type ContentItemWithWebPage = Array<
  ContentWith$$Relations & {
    $$webPages: Array<{ href: string; $$expanded: WebPage }>;
    $$pathToRoot: $$pathToRoot;
  }
>;

type ValidationDataTypes = {
  referencedInProHomeCannotUnpublish: ReferencedInProHomeCannotUnpublishData;
  noDuplicateWebPaths: ContentItemWithWebPage;
  // anotherRule: AnotherRuleData;
  // moreRules: MoreRulesData;
  // Add more rules data types as needed
};

export type ValidationDataResult<T extends ValidationRuleName> = ValidationDataTypes[T];

type ValidationStateBase = {
  [key: string]: Partial<{
    [rule in ValidationRuleName]: {
      isLoading: boolean;
      data: ValidationDataResult<rule>;
    };
  }>;
};

export type ValidationDataState = Readonly<ValidationStateBase>;

export type ValidationError = {
  code: string;
  type: 'ERROR' | 'WARNING';
  message: string;
  property?: string | undefined;
  rule: string;
};

export type ValidationErrorWithNode = ValidationError & {
  node: {
    href: ContentHref;
    parentHref: ContentHref | undefined;
    nodeId: ContentNodeId;
  };
};

export type ValidationPending = { pending: true };

export type ValidationResult = true | ValidationError | ValidationPending;

export const isValidationErrorOrWarning = (r: ValidationResult): r is ValidationError =>
  r !== true && !('pending' in r);

export type AsyncValidationRule = {
  /**
   *
   * @returns the name of the validation rule
   */
  getName: () => ValidationRuleName;
  isAsync: true;

  /**
   * A selector that returns a boolean indicating if data needs to be fetched for this validation rule.
   * it can be used to optimize the fetching of data, or decide if/when an update needs to be fetched.
   * @param rootState
   * @param href content href
   * @returns
   */
  selectNeedsData: (rootState: RootState, href: ContentHref) => boolean;

  /**
   * this optional selector is used to monitor the content-node.
   * when the result of this selector changes, the validation data will be invalidated and refetched.
   * @param rootState
   * @param href
   * @returns a value that will be compared with === to see if the data needs to be refetched.
   */
  selectInvalidateDataOnChangeOf?: (rootState: RootState, href: ContentHref) => unknown;

  /**
   * A selector that returns a batch item that can be used to fetch the data for this validation rule.
   * if no data needs to be fetched, it will return null.
   * @param rootState
   * @param href
   * @returns
   */
  selectDataBatch: (rootState: RootState, href: ContentHref) => BatchItem<'GET'> | null;

  /**
   * A selector that returns true if the content is valid, A validationError if it is invalid, and null if the validation is still waiting for data.
   * @param rootState
   * @param href
   * @returns
   */
  selectValidation: (rootState: RootState, href: ContentHref) => ValidationResult;
};

export type ValidationRule = (
  state: RootState,
  href: ContentHref,
  parentHref: ContentHref | undefined
) => ValidationResult;

export const isAsyncValidationRule = (
  r: ValidationRule | AsyncValidationRule
): r is AsyncValidationRule => 'isAsync' in r;

export type ValidationStatus = 'VALID' | 'VALID_WITH_WARNINGS' | 'INVALID' | 'UNKNOWN';

export type ValidationInfo = {
  status: ValidationStatus;
  validationErrors: Array<ValidationErrorWithNode>;
};

export type ValidationMapIndex = `index-${ContentHref}-${ContentHref}`;
