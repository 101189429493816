import { HiddenContentItemConfig } from '@nodeTypeConfig/configTypes';

const PAGE_ATTACHMENTS_GROUP: HiddenContentItemConfig = {
  information: {
    single: 'Pagina-bijlagen',
    plural: 'Pagina-bijlagen',
    description:
      'These are downloads which can not be added as building blocks but managed from the aside. In Pro they will not be shown inline but at the bottom of the page in the "download section" of the page.',
    ribonIcon: require('../../../../img/buildingBlocks/ATTACHMENT.svg'),
  },
  hideInDocument: true,
  hideChildrenInDocument: true,
};

export default PAGE_ATTACHMENTS_GROUP;
