require('./configuration.scss');
// this is a "dumb" component

class Configuration {
  constructor(ProWebsiteConfigurationsService, $translate) {
    'ngInject';

    this.configurationsService = ProWebsiteConfigurationsService;
    this.translationService = $translate;
  }

  $onInit() {
    this.setBanner();
  }

  $onChanges() {
    this.setBanner();
  }

  setBanner() {
    this.banner = (this.sConfiguration.options && this.sConfiguration.options.banner) || {};
  }

  showFacets() {
    return this.configurationsService.typeHasFacets(this.sConfiguration.template);
  }

  showMultipleAlternativeMenus() {
    return this.configurationsService.typeHasMultipleAlternativeMenus(this.sConfiguration.template);
  }

  deleteConfiguration() {
    this.onConfigurationDelete({ $event: { key: this.sConfiguration.key } });
  }

  editConfiguration() {
    if (!this.sDisabled) {
      this.onConfigurationEdit({ $event: { configuration: this.sConfiguration } });
    }
  }

  displayHighlight() {
    return this.banner.highlight
      ? this.translationService.instant(
          'components.proWebsite.configurationModalForm.banner.highlightTrue'
        )
      : this.translationService.instant(
          'components.proWebsite.configurationModalForm.banner.highlightFalse'
        );
  }

  displayDate(date) {
    return date ? new Date(date).toLocaleDateString() : '';
  }
}

module.exports = {
  template: require('./configuration.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfiguration: '<',
    sDisabled: '<',
    onConfigurationDelete: '&',
    onConfigurationEdit: '&',
    showBanner: '<',
  },
  controller: Configuration,
};
