import { Cmd, loop } from 'redux-loop';

const initialState = {
  dispatch: null,
};

export const reduxLoopUtilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT': {
      return loop(
        state,
        Cmd.run(
          (dispatch) => {
            dispatch({ type: 'expose_dispatch', payload: dispatch });
          },
          { args: [Cmd.dispatch] }
        )
      );
    }

    case 'expose_dispatch': {
      return { ...state, dispatch: action.payload };
    }

    default:
      return state;
  }
};
