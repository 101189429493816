import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { selectExternalGoalOrItem } from '@newStore/llinkid/llinkidSelectors';
import { formatVersion } from '@store/helpers/documentHelpers';
import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';

const AsideLlinkidItem = ({ href }) => {
  const $translate = getAngularService('$translate');

  const goal = useSelector((state: RootState) => selectExternalGoalOrItem(state, href));

  return (
    <>
      <div className="col-md-11 indented">
        <span
          dangerouslySetInnerHTML={{
            __html: goal?.descriptionText,
          }}
        ></span>
      </div>
      <div className="col-md-1 indented">
        {goal?.version &&
          $translate.instant('components.llinkid.goalRelations.fromVersion', {
            version: formatVersion(goal?.version),
          })}
      </div>
    </>
  );
};

export default AsideLlinkidItem;
