import { selectBuildingBlocksForDocument } from '@newStore/documentUI/transformContent/appliedConfigSelectors';
import { useSelector } from 'react-redux';

import './BuildingBlocks.scss';
import BuildingBlock from './BuildingBlock';

const BuildingBlocks: React.FC<void> = () => {
  const buildingBlocks = useSelector(selectBuildingBlocksForDocument);

  if (!buildingBlocks.length) {
    return null;
  }

  return (
    <div className="ribbon">
      <div className="ribbon-inner">
        {/* 
    <div class="ribbon-row" ng-repeat="buttonsRow in ctrl.ribbonButtonsRows">
        <div ng-repeat="button in buttonsRow"
             class="ribbon-button"
             type="{{::button.type}}"
             dragType="RIBBON"
             ng-style="::{'background-image': 'url('+ button.icon +')'}"
             >
            <div class="ribbon-label" ng-if="::!button.icon">{{::button.name}}</div>
        </div>
    </div> */}
        <div className="ribbon-row">
          {buildingBlocks.map((block) => (
            <BuildingBlock key={block.type} {...block} />
          ))}
        </div>
        {/* <div class="is-overflow" ng-if="ctrl.ribbonButtonsRows.length > 1 && !ctrl.hideOverflowButton" ng-click="ctrl.clickedOnMore()">
        <img src="img/three-dots.svg">
    </div> */}
      </div>
    </div>
  );
};

export default BuildingBlocks;
