export const titleNotEmptyOnNewDocumentUpload = (node) => {
  if (node.$$children.length > 0 && node.$$children[0].type === 'ATTACHMENT') {
    if (node.$$children[0].title === undefined) {
      return {
        type: 'ERROR',
        message: 'labels.download.titleNotEmptyOnNewDocumentUpload',
      };
    }
    return true;
  }
  return true;
};
