class CurriculumSelector {
  constructor() {
    'ngInject';
  }
}

export default {
  template: require('./asideCurriculumSelector.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: CurriculumSelector,
};
