const kovsentry = require('@kathondvla/kovsentry');
const { settings } = require('../config/settings');

if (settings.logging.sentry.enabled) {
  const Sentry = require('@sentry/browser');

  const Integrations = require('@sentry/integrations');
  const tracking = require('@sentry/tracing').Integrations;

  Sentry.init({
    dsn: settings.logging.sentry.url,
    enabled: true,
    debug: true,
    release: settings.projectVersion,
    environment: settings.environment === '' ? 'PROD' : settings.environment,
    maxValueLength: 2000,
    tracesSampleRate: settings.logging.sentry.sentryPerformanceSampleRate,
    integrations: [new Integrations.Angular()],
    beforeSend(event) {
      console.log('beforeSend', event);
      return event;
    },
  });
  kovsentry.init(Sentry, {
    addFetchInterceptor: true,
  });
  kovsentry.startLog('Load Redactie');
}

module.exports = kovsentry;
