/* eslint-disable max-len */
import {
  selectAudienceTabForNode,
  selectEditConfigForNode,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';
import constants from '../../reduxLoop/constants/constants';

require('./asideScreen.scss');

export class asideScreenController {
  constructor($ngRedux, $scope, $timeout, $state, $compile) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.$compile = $compile;
    this.$scope = $scope;
    this.$timeout = $timeout;

    this.editOption = 'metadata';

    this.unsubscribe = this.$ngRedux.connect((state) => {
      let editConfig = null;
      let audienceTab = null;
      if (state.documentUI.currentEditingNode) {
        editConfig = selectEditConfigForNode(state, state.documentUI.currentEditingNode);
        audienceTab = selectAudienceTabForNode(state, state.documentUI.currentEditingNode);
      }

      if (!this.initialEditConfig && editConfig) {
        // when the editConfig change we need to reload the components
        this.initialEditConfig = editConfig;
      }

      return {
        ...state.document.viewModel.aside,
        showAudiencesTab: Boolean(audienceTab?.length),
        audienceTab,
        editConfig,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  close() {
    this.$state.go('edit');
  }

  termAdded(scope, event, data) {
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.addTermReferenceAction(data.term));
  }

  filterSections(sections, asideTab) {
    return (sections || []).filter((section) => {
      // only edit section that corresponds to the given asideTab (if tab is not defined for a section then take metadata) (#18468)
      return (
        (!section.tab && constants.asideTabs.METADATA === asideTab) || section.tab === asideTab
      );
    });
  }

  getSectionWidth(sectionConfig) {
    if (sectionConfig) {
      if (typeof sectionConfig === 'string') {
        return 'col-md-12';
      }
      if (sectionConfig.width) {
        return `col-md-${sectionConfig.width}`;
      }
    }
    return 'col-md-12';
  }

  getEditSection(component, label) {
    return (
      // since we two tabs, we need to check both. an overlap should not be possible.
      this.editConfig.find((c) => c.component === component && (!label || c.label === label)) ||
      this.audienceTab.find((c) => c.component === component && (!label || c.label === label))
    );
  }

  reloadComponents(asideTab) {
    if (this.initialEditConfig !== this.editConfig) {
      this.initialEditConfig = this.editConfig;
      this.loadComponents(asideTab);
    }
  }

  getNewConfig(index, type) {
    if (type === 'audience') {
      return this.audienceTab[index];
    }
    if (type === 'metadata') {
      return this.editConfig[index];
    }
    return undefined;
  }

  loadComponents(asideTab) {
    const editSections = angular.element(
      document.querySelector(`#${asideTab.toLowerCase()}EditSections`)
    );
    editSections.empty();

    let sections = [];

    if (asideTab === constants.asideTabs.AUDIENCE) {
      sections = this.audienceTab;
    } else if (asideTab === constants.asideTabs.METADATA) {
      sections = this.editConfig;
    }

    sections.forEach((section, index) => {
      const sectionComponent = section.type ? section.type : section.component;
      const angularSectionComponent = sectionComponent
        .replace(/([A-Z])/g, '-$1')
        .toLowerCase()
        .trim();
      const angularComponentName = `aside-${angularSectionComponent}`;

      const reactComponentName = (section.reactComponent ? `react${section.reactComponent}` : '')
        .replace(/([A-Z])/g, '-$1')
        .toLowerCase()
        .trim();

      const sectionComponentName = reactComponentName || angularComponentName;

      const componentClass = this.getSectionWidth(section);
      const label = section.label ? `, '${section.label.replace("'", "\\'")}'` : '';

      const componentToCompile = `<${sectionComponentName}
            class="${componentClass}"
            s-document="::ctrl.editDocument"
            s-config="::ctrl.getEditSection('${section.component}'${label})"
            config="ctrl.getNewConfig(${index}, '${asideTab.toLowerCase()}')"
          ></${sectionComponentName}>`;

      // property="'${section.property}'"
      // component="'${sectionComponent}'"

      editSections.append(this.$compile(componentToCompile)(this.$scope));
    });
  }
}
