import { selectProposalMetaInfoForToc } from '@newStore/documentUI/transformProposal/proposalSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
import { useSelector } from 'react-redux';
import { ContentHref } from '@generalTypes/apiTypes';
import ProposalIndicator from './ProposalIndicator';

const ProposalIndicatorForToc: React.FC<{
  href: ContentHref;
}> = ({ href }) => {
  const proposalMetaInfo = useSelector((state: RootState) =>
    selectProposalMetaInfoForToc(state, href)
  );

  if (!proposalMetaInfo) {
    return null;
  }

  return <ProposalIndicator href={href} proposalMetaInfo={proposalMetaInfo} />;
};

export default ProposalIndicatorForToc;
