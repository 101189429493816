import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';
import * as DOCUMENT_DRAG_ACTIONS from '../../reduxLoop/actions/documentDragActions';

class terms {
  constructor($scope, $ngRedux, $state, $element) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.$element = $element;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      this.createDropZone();
      return {
        allTerms: state.document.viewModel.terms.local.concat(
          state.document.viewModel.terms.global
        ),
        localTerms: state.document.viewModel.terms.local,
        globalTerms: state.document.viewModel.terms.global,
        llinkIdLayout: state.document.tree.$$type === 'LLINKID_CURRICULUM',
        root: state.document.tree,
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.loadTermReferencesAction(this.root.key));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  createDropZone() {
    this.$element.find('.droppable').droppable({
      refreshPositions: true,
      tolerance: 'pointer',
      drop: () => {
        this.$ngRedux.dispatch(DOCUMENT_DRAG_ACTIONS.finishDraggingNodeAction());
        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.addNodeToParentNodeAction(
            this.root.key,
            this.root.$$children.length + 1,
            'TERM'
          )
        );

        this.dropLineLevel = undefined;
        this.$scope.$broadcast('$$rebind::dropZones');
        this.$scope.$apply();
      },
      over: (event, ui) => {
        const isNewNode = ui.draggable.context.attributes.dragType.value === 'RIBBON';
        const typesToDrop = isNewNode
          ? [ui.draggable.context.attributes.type.value]
          : this.$ngRedux.getState().document.selectionTypes;
        const allowedDrop = typesToDrop.includes('TERM');
        if (allowedDrop) {
          this.dropLineLevel = Math.max(1);
          this.$scope.$broadcast('$$rebind::dropZones');
          this.$scope.$apply();
        }
      },
      out: () => {
        this.dropLineLevel = undefined;
        this.$scope.$broadcast('$$rebind::dropZones');
        this.$scope.$apply();
      },
    });
  }

  getDescription(term) {
    return term.description
      ? term.description.split('<table>').join('<table class="table table-bordered">')
      : '';
  }

  openEdit(term) {
    this.$state.go('edit.aside', { editKey: term.key });
  }

  deleteTerm(term) {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeNodeAction(term.key));
  }
}

export default {
  template: require('./terms.html'),
  controllerAs: 'ctrl',
  bindings: {},
  controller: terms,
};
