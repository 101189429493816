import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '../helpers/genericComponents';
import { extendsSection } from '../helpers/genericConfigHelpers';

const VISION_TEXT_SECTION: ExtendedBuildingBlockNodeConfig = {
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../img/buildingBlocks/SECTION.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: false,
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.ATTACHMENTS_GROUP },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.MUSIC },
    { type: NodeType.VIDEO },
    { type: NodeType.VIDEO_GROUP },
    { type: NodeType.QUOTE },
    { type: NodeType.EXAMPLE },
    {
      type: NodeType.WORD_IMPORT,
      options: {
        openImportModal: true,
      },
    },
    { type: NodeType.REFERENCE_GROUP },
  ],
  edit: [
    {
      ...titleComponent,
      width: '10',
    },
    {
      component: 'image',
      label: 'Ikoon',
      property: 'attachments',
      options: {
        type: 'THUMBNAIL',
        fields: [],
      },
      hideInAncestorTypes: [NodeType.VISION_TEXT_SECTION], // only show it on the first level [to check when this becomes active in aside, now aside just takes SECTION.js]
    },
  ],
};
export default VISION_TEXT_SECTION;
