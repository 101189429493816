/* eslint-disable max-len */
import { attachmentDocumentTypes } from '@newStore/documentUI/transformContent/attachmentHelpers';
import uuidv4 from 'uuid/v4';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import {
  getResourceKey,
  isAttachmentsGroupOnNodeLevelAllowed,
} from '../../../reduxLoop/helpers/documentHelpers';
import { documentTags } from '../../../reduxLoop/constants/documentTags';
import { isGlobalDocumentAllowedFromAside } from '../../../validations/isGlobalDocumentAllowed';
import { isGlobalDocument as isGlobalDocumentHelper } from '../../../reduxLoop/viewmodels/viewModelHelpers';

require('./asideAttachmentsGroup.scss');

class asideAttachmentsGroup {
  constructor(ModalWindowService, ConfirmationService, $ngRedux, settings, Utils, constants) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.utils = Utils;
    this.constants = constants;
  }

  async $onInit() {
    this.sConfig.options = this.sConfig.options ?? {};

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const asideNode = state.document.viewModel.aside.editDocument;
      const allAttachments = asideNode.attachmentResources || [];
      // ""`AttachmentGroups` can be of two different types":
      //   - "normal" attachmentsGroup : In this case the user can push as many documents
      //     he wants in the same box
      //   - (*) downloadAttachmentsGroup: In this case the user is forced to add first a "main"
      //     document and then he can add as many "extra" documents as he wants (the same that
      //     in the first type)

      // So, here, if the case is the second one (*) we don't take into account for this component the
      // first element
      // DISCLAIMER: Changing this to make it right needs a refactor that is out of the scope of the
      // task I'm working on.

      const { isDownloadAttachmentsGroup } = asideNode;
      const attachments = isDownloadAttachmentsGroup ? allAttachments.slice(1) : allAttachments;
      const attachmentsGroupOnNodeLevelAllowed = isAttachmentsGroupOnNodeLevelAllowed(asideNode);

      const globalAttachmentsGroup =
        this.sConfig.options && this.sConfig.options.global && allAttachments.length > 0
          ? allAttachments[0].$$parent
          : undefined;

      return {
        globalDocumentAllowed: isGlobalDocumentAllowedFromAside(state.document, attachments),
        anyGlobalDocumentAttached: attachments.find((node) => isGlobalDocumentHelper(node)),
        fromAttachmentGroup: true,
        allAttachments,
        attachments,
        globalAttachmentsGroup,
        showImportance: !isDownloadAttachmentsGroup && !attachmentsGroupOnNodeLevelAllowed,
        lowImportance: asideNode.importance.value === 'LOW',
        editDocumentImportance: asideNode.importance,
        showAttachmentsGroup:
          this.sDocument.type === 'ATTACHMENTS_GROUP' ||
          this.sDocument.type === 'SHARED_ATTACHMENTS_GROUP' ||
          attachmentsGroupOnNodeLevelAllowed,
        loading: false,
        isReadOnly:
          state.document.viewModel.aside.isReadOnly ||
          asideNode.attachmentResources.some(
            (attach) =>
              attach.proposal &&
              attach.proposal.isSubmitted &&
              !state.document.viewModel.suggestions.isReviewing
          ),
        isDownloadAttachmentsGroup,
        isAddAttachmentDisabled: isDownloadAttachmentsGroup && allAttachments.length < 1,
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.expandRelationsToAction(this.sDocument.key));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  async editAttachment(row) {
    const originalRow = { ...row };

    const opts = {
      component: 'attachmentModal',
      dataForModal: {
        sAttachment: row,
        sConfig: {
          avoidDispatchDownloadTag: true,
          globalDocumentAllowed: this.globalDocumentAllowed,
        },
      },
    };

    const response = await this.modalWindowService.open(opts);
    if (!response) {
      return;
    }

    const changedFields = {};
    const { attachment } = response;
    if (originalRow.title !== attachment.title) {
      changedFields.title = attachment.title;
    }

    originalRow.tags = originalRow.tags || [];
    attachment.tags = attachment.tags || [];

    const addedOrRemovedTag = originalRow.tags.length !== attachment.tags.length;
    const changedTag =
      originalRow.tags.length === attachment.tags.length &&
      originalRow.tags.length === 1 &&
      attachment.tags[0] !== originalRow.tags[0];

    if (addedOrRemovedTag || changedTag) {
      changedFields.tags = attachment.tags ? attachment.tags.map((t) => t.value) : [];
    }

    const thereAreAttachments =
      originalRow.attachments.length > 0 && attachment.attachments.length > 0;
    if (thereAreAttachments && originalRow.attachments[0].key !== attachment.attachments[0].key) {
      changedFields.attachments = attachment.attachments;
    }

    const previousIsGlobal = row.$$relation.relationtype === 'IS_INCLUDED_IN';

    if (response.globalDocument) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.editGlobalDocumentRelationAction(
          row.key,
          response.globalDocument,
          row.$$relation,
          this.sConfig.options.global
        )
      );
    } else if (Object.keys(changedFields).length > 0) {
      if (previousIsGlobal) {
        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.replaceGlobalDocumentRelationWithAttachmentAction(
            getResourceKey(row.$$relation.to.href),
            row.$$relation.key,
            {
              tags: attachment.tags ? attachment.tags.map((t) => t.value) : [],
              title: attachment.title,
            },
            attachment.attachments[0],
            response.file
          )
        );
      } else {
        if (changedFields.attachments) {
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeAttachment(row.key, attachment.file.key));
          this.$ngRedux.dispatch(
            DOCUMENT_ACTIONS.addAttachment(
              row.key,
              {
                ...response.attachment.attachments[0],
                newKey: uuidv4(),
              },
              response.file,
              this.sDocument.key
            )
          );
        }

        delete changedFields.attachments;
        if (Object.keys(changedFields).length > 0) {
          // if there are other changes for the meta data of the attachment different to the file
          this.$ngRedux.dispatch(
            DOCUMENT_ACTIONS.patchNodeAction(row.key, changedFields, true, this.sDocument.key)
          );
        }
      }
    }
  }

  async addAttachment() {
    const opts = {
      component: 'attachmentModal',
      dataForModal: {
        sConfig: {
          avoidDispatchDownloadTag: true,
          globalDocumentAllowed: this.globalDocumentAllowed,
        },
      },
    };

    const response = await this.modalWindowService.open(opts);

    if (!response) {
      return;
    }

    const { attachment } = response;

    if (response.globalDocument) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.addGlobalDocumentRelationAction(
          this.sDocument.key,
          response.globalDocument,
          this.sConfig.options.global
        )
      );
    } else {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.addAttachmentToNodeAction(
          this.sDocument.key,
          {
            tags: attachment.tags ? attachment.tags.map((t) => t.value) : [],
            title: attachment.title,
          },
          attachment.attachments[0],
          response.file
        )
      );
    }
  }

  async deleteAttachment(attachment) {
    const confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      const isLastAttachment = this.attachments.length - 1 === 0;
      if (attachment.isGlobalDocument) {
        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.removeRelationAction(attachment.$$relation.key, true, true)
        );
      } else {
        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.removeNodeAction(attachment.key, true, this.sDocument.key)
        );
      }

      if (isLastAttachment && this.globalAttachmentsGroup) {
        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.removeNodeAction(this.globalAttachmentsGroup.key, true)
        );
      }
    }
  }

  updateGlobalAttachmentsGroupTitle() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(this.globalAttachmentsGroup.key, {
        title: this.globalAttachmentsGroup.title,
      })
    );
  }

  isGlobalDocument(row) {
    return row.isGlobalDocument;
  }

  getNewPosition(currentPosition, moveType) {
    let newPosition = 0;

    const minPosition = this.isDownloadAttachmentsGroup ? 2 : 1;

    const maxPosition = this.allAttachments[this.allAttachments.length - 1]
      ? this.allAttachments[this.allAttachments.length - 1].position
      : 0;

    if (moveType === 'up' && currentPosition > minPosition) {
      // dont allow if its already the max position in list
      newPosition = currentPosition - 1;
    } else if (moveType === 'down' && currentPosition < maxPosition) {
      // dont allow if its already the max position in list
      newPosition = currentPosition + 2;
    }

    return newPosition;
  }

  move(row, movementType) {
    const newPosition = this.getNewPosition(row.position, movementType);
    const attachmentGroupKey = getResourceKey(row.$$relation.to.href);
    if (newPosition > 0) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.moveAttachmentPositionAction(
          row.$$relation.key,
          attachmentGroupKey,
          !row.isGlobalDocument ? row.key : undefined,
          newPosition
        )
      );
    }
  }

  changeImportance() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(this.sDocument.key, {
        importance: this.lowImportance ? 'LOW' : 'MEDIUM',
      })
    );
  }

  changeTitle(scope, event, title) {
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(scope.ctrl.sDocument.key, {
        title: title.value,
      })
    );
  }

  iconSrc(row) {
    if (!row.file) {
      return '-';
    }
    const attachment = row.file;
    const { contentType } = attachment;
    const icon = this.utils.getIconFor(contentType);
    if (icon !== '') {
      return icon;
    }
    return 'img/icons/file.svg';
  }

  getAttachmentHref(attachment) {
    if (!attachment || !attachment.file) {
      return '';
    }
    return this.settings.apisAndUrls.contentApi + attachment.file.href;
  }

  getStableLink(attachment) {
    if (!attachment || !attachment.file) {
      return '';
    }
    const href = attachment.proposal
      ? `/content/${attachment.key}/attachments/${attachment.file.name}`
      : attachment.file.href;

    return this.sDocument.$$root.tags.includes(documentTags.webPage2) ||
      this.sDocument.$$root.tags.includes(documentTags.proNewsItem)
      ? `${this.settings.apisAndUrls.proWebsite}/download${href}`
      : this.settings.apisAndUrls.contentApi + href;
  }

  copyStableLink(attachment) {
    const copyText = document.getElementById(`copy-${attachment.key}`);
    copyText.select();
    document.execCommand('copy');
  }

  removeExtensionFromName(row) {
    const attachment = row.file;

    if (attachment.title && attachment.title.trim() !== '') {
      return this.utils.compact(attachment.title);
    }

    const { name } = attachment;
    const n = name.lastIndexOf('.');
    return n > -1 ? name.substr(0, n) : name;
  }

  attachmentTitle(row) {
    return row.title || row.description;
  }

  showFileType(row) {
    const attachment = row.file;
    const { contentType } = attachment;
    const label = this.utils.getLabelFor(contentType);
    if (label !== '') {
      return label;
    }
    if (contentType.length > 25) {
      return `${contentType.substring(0, 23)}...`;
    }
    return contentType;
  }

  showAttachmentCreateDate(row) {
    if (!row.file) {
      return '-';
    }
    const attachment = row.file;

    let creationDate;

    if (attachment.$$meta && attachment.$$meta.created) {
      creationDate = new Date(attachment.$$meta.created);
    }

    if (creationDate === undefined) {
      return '-';
    }

    return this.utils.timeToString(creationDate);
  }

  showAttachmentFileSize(row) {
    if (!row.file) {
      return '-';
    }
    const attachment = row.file;
    const sizeInBytes = attachment.size;

    if (sizeInBytes === undefined) {
      return '-';
    }

    if (sizeInBytes >= 1048576) {
      let sizeInMB = Number(sizeInBytes / 1024 / 1024);
      sizeInMB = sizeInMB.toFixed(0);
      return `${sizeInMB} MB`;
    }

    let sizeInKB = Number(sizeInBytes / 1024);
    sizeInKB = sizeInKB.toFixed(0);
    return `${sizeInKB} KB`;
  }

  showFileTag(row) {
    if (row.tags && row.tags.length > 0) {
      let value = '';
      row.tags.forEach((tag) => {
        const attachType = attachmentDocumentTypes.find((t) => t.value === tag);
        if (attachType) {
          value = attachType.display;
        }
      });
      return value;
    }
    return '-';
  }

  proposalLineStyle(row) {
    if (row.proposal) {
      const line = row.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      const color = row.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = row.proposal.isDeleted ? ' is-deleted' : '';
      const isReviewing = row.proposal.isReviewingMode ? ' is-reviewing' : '';
      return `${line} ${color}${isDeleted}${isReviewing}`;
    }
    return '';
  }

  hasDeleteProposal(row) {
    return (row.proposal && row.proposal.type === 'DELETE') || row.deleteProposal;
  }
}

export default {
  template: require('./asideAttachmentsGroup.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<',
  },
  controller: asideAttachmentsGroup,
};
