class rowImageGroup {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {}

  $onChanges() {
    this.images = this.sItem.imagesInGroup;
  }
}

export const rowImageGroupComponent = {
  template: require('./rowImageGroup.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<?',
    sItem: '<?',
  },
  controller: rowImageGroup,
};
