import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { isEmpty } from '../../../reduxLoop/helpers/documentHelpers';

class rowLlinkidConcordance {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {}

  $onDestroy() {}

  saveInline(e, editorData) {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(editorData.key, { [editorData.field]: editorData.value })
    );
  }
}

export const rowLlinkidConcordanceComponent = {
  template: require('./rowLlinkidConcordance.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<',
  },
  controller: rowLlinkidConcordance,
};
