import { initUser } from '@newStore/user/userState';
import { configureStore } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { install } from 'redux-loop';
import createSagaMiddleware from 'redux-saga';
// import { batchedSubscribe } from 'redux-batched-subscribe';
import { batchedSubscribe } from '@newStore/refactor/redux-batched-subscribe-ignore-ng-redux';
import { settings } from '../config/settings';
import initSagas from '../store/saga';
import * as ACTION_TYPES from './constants/actionTypes';
import { rootReducer } from './reducers';

export const initState = (store) => {
  store.dispatch({ type: ACTION_TYPES.INIT });
  store.dispatch(initUser());
};

/**
 * This function will initiate the store of the application.
 */
const setupStore = (preloadedState?: any) => {
  const sagaMiddleware = createSagaMiddleware();

  if (process.env.NODE_ENV === 'development' && settings.enableMswInBrowser) {
    const { worker } = require('../mocks/browser');
    const workerConfig = {
      // turn off MSW warnings for specific routes
      onUnhandledRequest(req, print) {
        if (req.url.pathname.startsWith('/fonts/')) {
          return;
        }

        const excludedRoutes = [
          '/favicon-32x32.png',
          '/sockjs-node/info',
          '/resource/errors_nl.json',
          '/images/drag',
          '/images/expand',
          '.woff2',
          '.svg',
        ];

        const isExcluded = excludedRoutes.some((route) => req.url.pathname.includes(route));
        if (isExcluded) {
          return;
        }

        print.warning();
      },
    };

    worker.start(workerConfig);
  }

  const triggerLoopToRun = debounce((store) => {
    const loopDispatch = store.getState().reduxLoopUtils.dispatch;
    if (loopDispatch) {
      loopDispatch({ type: 'triggerLoopToRun' });
    }
  }, 50);

  /**
   * We have a problem with redux-saga and redux loop.
   * when we dispatch an action from redux saga, that has to run a redux-loop, the action gets handled in the reducer, but the loop is never triggered.
   * the workaround is to listen for these actions in the middleware, and dispatch any action with the redux-loop dispatch, which we have places in a part of the state
   * in the reduxLoopUtils slice.
   * @param store
   * @returns
   */
  const sagaDispatchActionsToReduxLoopMiddleware = (store) => (next) => (action) => {
    // If the action has @@redux-saga/SAGA_ACTION set to true, and it's one to the old redux loop actions
    if (action['@@redux-saga/SAGA_ACTION'] === true && ACTION_TYPES[action.type]) {
      triggerLoopToRun(store);
    }

    // Pass the action to the next middleware in the chain
    return next(action);
  };

  const debounceNotify = debounce((notify) => notify(), 5);
  const batchedSub = batchedSubscribe(debounceNotify);

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: false,
        // immutableCheck: false, // TODO: enable this once we stop crashing on it, or configure it to ignore some paths.
        immutableCheck: {
          ignoredPaths: [
            'documentList',
            'document',
            'security',
            'documentDrag',
            'user',
            'security',
            'notifications',
          ],
        },
        serializableCheck: false,
        /* serializableCheck: {
          ignoredPaths: [],
          ignoredActions: [],
        }, */
      })
        .concat(sagaMiddleware)
        .concat(sagaDispatchActionsToReduxLoopMiddleware);
    },
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers({
        // autobatch didn't seem to help with the validation performance and insame amount of function calls it triggers.
        // autoBatch: { type: 'callback', queueNotification: debounceNotify },
        autoBatch: false,
      })
        .concat(batchedSub)
        .prepend(install()),

    preloadedState,
    devTools: {
      serialize: {
        replacer: (key, value) => {
          if (value instanceof Map) {
            return {
              dataType: 'Map',
              value: [...value.entries()],
            };
          }

          const keysToOverwrite = [
            '$$root',
            '$$parent',
            'sriClient',
            '$$children',
            '$$dropZones',
            '$$relationsTo',
            '$$relationsFrom',
          ];

          if (value && keysToOverwrite.includes(key)) {
            if (['$$root', '$$parent'].includes(key)) {
              // @ts-ignore
              return { key: value.key };
            }
            if (key === '$$children') {
              // @ts-ignore
              return value.map((x) => ({ key: x.key }));
            }
            if (['$$relationsFrom', '$$relationsTo'].includes(key)) {
              // @ts-ignore
              return value.map((x) => ({
                href: x.href,
                $$expanded: {
                  from: x.$$expanded && x.$$expanded.from && x.$$expanded.from.href,
                  to: x.$$expanded && x.$$expanded.to && x.$$expanded.to.href,
                },
              }));
            }
            return `<<${key}-HIDDEN>>`;
          }
          return value;
        },
      },
    },
  });

  initSagas(sagaMiddleware);

  if (process?.env?.JEST_WORKER_ID === undefined) {
    // TODO: test without this IF
    // temporarily disable these inits for jest tests

    // Start with an INIT action
    initState(store);
  }

  return store;
};

export default setupStore;
