import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';

const CURRICULUM_ZILL_LEERLIJN_CLUSTER: BuildingBlockNodeConfig = {
  information: {
    single: 'Stap',
    plural: 'Stappen ',
  },

  edit: [
    {
      component: 'ageRange',
      label: 'Leeftijd',
      options: {
        editMonth: true,
      },
    },
    {
      component: 'developmentPhase',
      label: 'Ontwikkelingsfase',
      showInAncestorTypes: [NodeType.CURRICULUM_ZILL_LEERLIJN_AN],
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
    },
    { component: 'concordanties', label: 'Concordanties' },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default CURRICULUM_ZILL_LEERLIJN_CLUSTER;
