import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/genericComponents';

const SUMMARY: BuildingBlockNodeConfig = {
  information: {
    single: 'Samenvatting',
    plural: 'Samenvattingen',
    ribonIcon: require('../../../../img/buildingBlocks/SUMMARY.svg'),
  },

  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.IMAGE },
    { type: NodeType.VIDEO },
  ],
  edit: [titleComponent],
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default SUMMARY;
