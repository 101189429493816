import { attachmentDocumentTypes } from '@newStore/documentUI/transformContent/attachmentHelpers';
import { isEmpty } from 'lodash';

class GlobalDocumentSelector {
  constructor($scope, $ngRedux, contentApi) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.contentApi = contentApi;
  }

  $onInit() {
    this.filter = {
      type: 'UNSTRUCTURED_DOCUMENT',
    };

    this.anyGlobalDocumentSelected = !isEmpty(this.sGlobalDocument);
  }

  documentSelected(e, model) {
    e.targetScope.$emit('changed_global_document', model);
  }

  getFileTag(item) {
    if (item.tags && item.tags.length > 0) {
      let value = '';
      item.tags.forEach((tag) => {
        const attachType = attachmentDocumentTypes.find((t) => t.value === tag);
        if (attachType) {
          value = attachType.display;
        }
      });
      return value;
    }
    return '-';
  }

  getFileSize(file) {
    if (!file) {
      return '';
    }

    const sizeInBytes = file.size;

    if (sizeInBytes === undefined) {
      return '-';
    }

    if (sizeInBytes >= 1048576) {
      let sizeInMB = Number(sizeInBytes / 1024 / 1024);
      sizeInMB = sizeInMB.toFixed(0);
      return `${sizeInMB} MB`;
    }

    let sizeInKB = Number(sizeInBytes / 1024);
    sizeInKB = sizeInKB.toFixed(0);
    return `${sizeInKB} KB`;
  }

  getFileExtension(file) {
    if (!file) {
      return '';
    }

    return file.name.substr(file.name.lastIndexOf('.'));
  }

  displayName(item) {
    const { ctrl } = this.$scope.$parent;
    if (!item) {
      return '';
    }
    const file = item.attachments.find((a) => a.type === 'CONTENT');
    return `${item.title} (${ctrl.getFileExtension(file)}), ${ctrl.getFileTag(
      item
    )}, ${ctrl.getFileSize(file)}${item.description ? ` - ${item.description}` : ''}`;
  }
}

export default {
  template: require('./globalDocumentSelector.html'),
  controllerAs: 'ctrl',
  bindings: {
    sGlobalDocument: '<',
    sGlobalDocumentAllowed: '<',
    sDisabled: '<',
    sGlobalRadioButtonChecked: '<',
  },
  controller: GlobalDocumentSelector,
};
