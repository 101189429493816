import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/genericComponents';

const LLINKID_CONCORDANCE: BuildingBlockNodeConfig = {
  information: {
    single: 'Concordantie (S)MD/BK',
    plural: 'Concordanties (S)MD/BK',
  },
  createDefaults: {
    title: 'Minimumdoelen, specifieke minimumdoelen of doelen die leiden naar BK',
  },
  buildingBlocks: [],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Concordaties',
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default LLINKID_CONCORDANCE;
