import { any } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';

import { EditAsideNewsletterSender } from '@nodeTypeConfig/configTypes';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import {
  selectIsNewsletterSenderComponentLoading,
  selectNewsletterSenderOptions,
  selectSelectedNewsletterSender,
} from './asideNewsletterSenderSelectors';
import { patchNodeAction } from '@store/actions/documentActions';

const AsideNewsletterSender: React.FC<{
  config: EditAsideNewsletterSender;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const isReadonly = useReadOnly();

  const isLoading = useSelector(selectIsNewsletterSenderComponentLoading);
  const senderOptions = useSelector(selectNewsletterSenderOptions);
  const selectedSender = useSelector(selectSelectedNewsletterSender);
  const editingHref = useEditHref();

  const onChange = (selection) => {
    dispatch(
      patchNodeAction(getResourceKey(editingHref), {
        newsletterSender: { href: selection.href },
      })
    );
  };

  const addCursorStyle = (provided) => ({ ...provided, cursor: 'pointer' });

  return (
    <div className="form-group">
      <label className="control-label">{config.label}</label>
      {isLoading ? (
        <div className="form-group shimmer narrowLine"></div>
      ) : (
        <KovResourcePicker
          value={selectedSender}
          localData={senderOptions}
          display="display"
          clearable={false}
          disabled={isReadonly}
          disableSearch={true}
          style={{ option: addCursorStyle, valueContainer: addCursorStyle }}
          onChange={onChange}
        />
      )}
    </div>
  );
};

AsideNewsletterSender.displayName = 'AsideNewsletterSender';

AsideNewsletterSender.propTypes = { config: any };

export default AsideNewsletterSender;
