import tinymce, { Editor } from 'tinymce/tinymce';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { stripHtml } from '@newStore/genericHelpers';
import wordFilter from 'tinymce-word-paste-filter';
import juice from 'juice';

export const setupButton = (
  editor: Editor,
  name: string,
  tooltip: string,
  svgIcon: string,
  // eslint-disable-next-line no-shadow
  onAction: (editor: Editor) => void,
  // eslint-disable-next-line no-shadow
  isEnabledButton: (editor: Editor) => boolean
) => {
  const iconName = `${name}Icon`;
  editor.ui.registry.addIcon(iconName, svgIcon);

  editor.ui.registry.addButton(name, {
    icon: iconName,
    tooltip,
    onAction: () => {
      onAction(editor);
    },
    onSetup: (buttonApi) => {
      const editorEventCallback = () => {
        buttonApi.setEnabled(isEnabledButton(editor));
      };
      editor.on('NodeChange SelectionChange', editorEventCallback);
      return () => {
        editor.off('NodeChange SelectionChange', editorEventCallback);
      };
    },
  });

  editor.ui.registry.addMenuItem(name, {
    icon: iconName,
    onAction: () => {
      onAction(editor);
    },
  });
};

export const registerButton = (
  name: string,
  tooltip: string,
  svgIcon: string,
  onAction: (editor: Editor) => void,
  isEnabledButton: (editor: Editor) => boolean
) => {
  const exists = tinymce.PluginManager.get(name);
  if (!exists) {
    tinymce.PluginManager.add(name, (editor) =>
      setupButton(editor, name, tooltip, svgIcon, onAction, isEnabledButton)
    );
  }
};

export const openModal = async (
  editor: Editor,
  modalOptions: {
    component: string;
    dataForModal?: object;
  },
  renderFunction: (modalResult: object, selectedText: string) => string
) => {
  editor.setDirty(false);
  if (!editor.selection.getContent()) {
    // If the user clicked on a button without selected text, we get the node which contains the caret
    editor.selection.select(editor.selection.getNode());
  }
  const bookmark = editor.selection.getBookmark(2);
  const modalWindowService = getAngularService('ModalWindowService');

  const modalResult = await modalWindowService.open(modalOptions);
  editor.selection.moveToBookmark(bookmark);
  editor.selection.setContent(
    renderFunction(modalResult, stripHtml(editor.selection.getContent()))
  );
  editor.setDirty(true);
  editor.focus();
};

export const pasteHtml = async (evt: ClipboardEvent, editor: Editor | null) => {
  evt.preventDefault();
  evt.stopPropagation();
  let pastedData = evt.clipboardData?.getData('text/html');
  if (!pastedData) {
    // Fallback for content which is not copied as HTML
    pastedData = await navigator.clipboard.readText();
  }
  if (editor && pastedData) {
    // Remove MS styles that can break the parsing
    pastedData = pastedData.replace(/(mso-[\w-]*:[^'";]*;?)/gim, '');
    const filteredData = juice(
      `<style>table{min-width: 50%; max-width: 100%; display: table}</style>${wordFilter(
        pastedData
      )}`
    );
    editor.selection.setContent(filteredData);
    editor.setDirty(true);
  }
};
