import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { identifierComponent, titleComponent } from '@nodeTypeConfig/helpers/genericComponents';

const CURRICULUM_ODET_LEERGEBIED: BuildingBlockNodeConfig = {
  information: {
    single: 'Leergebied',
    plural: 'Leergebieden',
  },

  buildingBlocks: [{ type: NodeType.CURRICULUM_ODET_SUBSECTION }],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
      view: {
        hideIfEmpty: true,
      },
    },
  ],
  isCollapsible: true,
  isCollapsedByDefault: true,
  isDeprecated: true,
  documentViewIdentifier: 'i',
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ODET_LEERGEBIED;
