require('./referenceLink.scss');

class ReferenceLink {
  constructor($ngRedux, $state, $scope) {
    'ngInject';

    this.referenceIcon = require('../../../../img/reference.svg');
    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.$scope = $scope;
  }

  getReferenceLink(referenceRelation) {
    // get the reference link according to reference relation if present
    if (referenceRelation) {
      this.$scope.$broadcast('$$rebind::referenceLink');
      if (referenceRelation.to.$$expanded) {
        return referenceRelation.to.$$expanded.title;
      }
      return referenceRelation.to.href;
    }
    return undefined;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      let referenceLink;
      const item = state.document.viewModel.flat.find((n) => n.key === this.sItem.key);
      const referenceRelation =
        item && item.referenceRelationsFrom.length ? item.referenceRelationsFrom[0] : undefined;

      referenceLink = this.getReferenceLink(referenceRelation);

      return {
        referenceLink,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export const referenceLinkComponent = {
  template: require('./referenceLink.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<',
  },
  controller: ReferenceLink,
};
