import { selectUser } from '@newStore/user/userSelectors';
import { getStore } from '../reduxLoop/store';

export default [
  '$http',
  '$q',
  'contentApi',
  '$timeout',
  'settings',
  'Utils',
  '$window',
  '$location',
  'sriClient',
  function ($http, $q, contentApi, $timeout, settings, Utils, $window, $location, sriClient) {
    //eslint-disable-line

    const that = {};

    let cachedPerson;

    const handleErrors = function (errorBody, status, url, cancelPromise) {
      errorBody.cancelled = false;
      if (cancelPromise) {
        cancelPromise.then(function () {
          errorBody.cancelled = true;
        });
      }
      if (!errorBody) {
        errorBody = {};
      }
      if (!status) {
        errorBody.status = 0;
      } else {
        errorBody.status = status;
      }
    };

    function removeNulls(obj) {
      let k;

      for (k in obj) {
        if (obj.hasOwnProperty(k) && obj[k] === null) {
          delete obj[k];
        }
      }

      return obj;
    }

    that.getResource = function (href, params, ownErrorHandling, useCache) {
      params = params || {};
      const d = $q.defer();
      const api =
        href.indexOf('/content') !== -1 || href.indexOf('/relations') !== -1
          ? settings.apisAndUrls.contentApi
          : settings.apisAndUrls.api;

      api
        .get(api + href, params)
        .then(function (resp) {
          d.resolve(resp.data);
        })
        .catch(function (error) {
          d.reject({
            error,
            status: error.status,
          });
          if (!ownErrorHandling) {
            handleErrors(error, error.status, href);
          } else if (error) {
            error.status = error.status;
          } else {
            error = { status: error.status };
          }
        });
      return d.promise;
    };

    const getAllFromResults = function (data, getExpandedResources, useCache) {
      const defer = $q.defer();
      let i = 0;
      let results = [];
      if (getExpandedResources) {
        for (; i < data.results.length; i++) {
          results.push(data.results[i].$$expanded);
        }
      } else {
        results = data.results;
      }

      if (data.$$meta.next) {
        that.getResource(data.$$meta.next, null, null, useCache).then(function (next) {
          getAllFromResults(next, getExpandedResources).then(function (nextResults) {
            results = results.concat(nextResults);
            defer.resolve(results);
          });
        });
      } else {
        defer.resolve(results);
      }

      return defer.promise;
    };

    that.getResources = function (url, params, cancelPromise, useCache) {
      const d = $q.defer();
      // console.log('GET resources', url); //eslint-disable-line
      const api =
        url.indexOf('/content') !== -1 || url.indexOf('/relations') !== -1
          ? settings.apisAndUrls.contentApi
          : settings.apisAndUrls.api;

      api
        .get(api + href, params)
        .then(function (resp) {
          let results = [];
          if (params && params.expand && params.expand === 'NONE') {
            results = resp.data.results;
          } else {
            results = resp.data.results.map(function (result) {
              return result.$$expanded;
            });
          }
          d.resolve({ results, meta: resp.data.$$meta });
        })
        .catch(function (error) {
          handleErrors(error, error.status, url, cancelPromise);
          d.reject(error);
        });

      return d.promise;
    };

    that.getAllResources = function (url, params, cancelPromise, useCache) {
      const d = $q.defer();
      const api =
        url.indexOf('/content') !== -1 || url.indexOf('/relations') !== -1
          ? settings.apisAndUrls.contentApi
          : settings.apisAndUrls.api;

      api
        .get(api + url, params)
        .then(function (resp) {
          const getExpandedResource = !(params && params.expand && params.expand === 'NONE');
          getAllFromResults(resp.data, getExpandedResource, useCache).then(function (allResults) {
            d.resolve({ results: allResults, meta: resp.data.$$meta });
          });
        })
        .catch(function (error) {
          handleErrors(error, error.status, url);
          d.reject(error);
        });

      return d.promise;
    };

    that.getAllResourcesConcurrent = function (
      url,
      params,
      offset,
      times,
      cancelPromise,
      useCache
    ) {
      const d = $q.defer();
      const api =
        url.indexOf('/content') !== -1 || url.indexOf('/relations') !== -1
          ? settings.apisAndUrls.contentApi
          : settings.apisAndUrls.api;

      const promises = [];
      for (let i = 0; i < times; i++) {
        const offsetCondition = `&offset=${offset * i}`;
        promises.push(that.getResources(url + offsetCondition, params));
      }

      $q.all(promises).then(function (results) {
        let resources = [];
        results.map(function (response) {
          resources = resources.concat(response.results);
        });
        d.resolve({ results: resources });
      });

      return d.promise;
    };

    that.createResources = function (resourceName, batch) {
      const defer = $q.defer();
      let i = 0;

      $http({
        method: 'POST',
        url: `${settings.apisAndUrls.api}/${resourceName}/batch`,
        data: batch,
        contentType: 'application/json',
        dataType: 'json',
      })
        .then(function (data) {
          const resp = {
            status: data.status,
          };
          defer.resolve(resp);

          // $http.get(settings.oauthMe, {withCredentials: true}).then(function (me) {
          //   for (; i < batch.length; i++) {
          //     resourceName = batch[i].href.split('/')[1];
          //     that.audit(batch[i].body, resourceName, '/security/' + resourceName, 'CREATE', '/persons/' + me.uuid);
          //   }
          // });
        })
        .catch(function (resp) {
          resp.status = resp.status;
          if (resp.constructor === Array) {
            for (i = 0; i < batch.length; i++) {
              if (resp[i].body) {
                resp[i].body.document = batch[i].body;
              }
            }
          } else {
            handleErrors(resp, resp.status, batch[0].body.$$meta.permalink);
          }

          defer.resolve(resp);
        });

      return defer.promise;
    };

    that.putResource = function (resource) {
      const defer = $q.defer();

      removeNulls(resource);

      $http({
        method: 'PUT',
        url: settings.apisAndUrls.contentApi + resource.$$meta.permalink, // TODO replace .contentApi for .api
        data: JSON.stringify(resource), // we need to stringify data otherwise angular will strip $$ attributes
        contentType: 'application/json',
        dataType: 'json',
      })
        .then(function (data) {
          const resp = {
            status: data.status,
            resource,
          };
          defer.resolve(resp);
        })
        .catch(function (resp) {
          if (!resp) {
            resp = 'No response';
          } else if (resp.errors) {
            resp.document = resource;
          }
          defer.resolve(resp);
        });

      return defer.promise;
    };

    // using contentApi
    that.putResource2 = function (resource) {
      removeNulls(resource);

      return contentApi.put(resource.$$meta.permalink, resource);
    };

    that.batch = function (batch) {
      return batch.send('/content/batch', contentApi);
    };

    that.validateResource = function (path, resource) {
      const defer = $q.defer();
      // var resourceName;

      removeNulls(resource);

      $http({
        method: 'POST',
        url: settings.apisAndUrls.contentApi + path, // TODO replace .contentApi for .api
        data: resource,
        contentType: 'application/json',
        dataType: 'json',
      })
        .then(function (data) {
          const resp = {
            status: data.status,
            resource,
          };
          defer.resolve(resp);
        })
        .catch(function (resp) {
          if (!resp) {
            resp = 'No response';
          } else if (resp.errors) {
            resp.document = resource;
          }
          defer.resolve(resp);
        });

      return defer.promise;
    };

    that.deleteResource = function (uri) {
      const defer = $q.defer();

      $http({
        method: 'DELETE',
        url: settings.apisAndUrls.contentApi + uri,
        dataType: 'json',
      })
        .then(function (data) {
          const resp = {
            status: data.status,
          };
          defer.resolve(resp);
        })
        .catch(function (resp) {
          defer.resolve(resp);
        });

      return defer.promise;
    };

    that.putAttachment = function (resource, attachment) {
      const defer = $q.defer();

      const fd = new FormData();
      if (attachment.file) {
        fd.append('data', attachment.file, attachment.file.name);
      }

      const attachmentField = {
        key: attachment.key,
        type: attachment.type,
      };

      if (attachment.description) {
        attachmentField.description = attachment.description;
      }
      if (attachment.alt) {
        attachmentField.alt = attachment.alt;
      }
      if (attachment.rightsHolder) {
        attachmentField.rightsHolder = JSON.stringify(attachment.rightsHolder);
      }

      if (attachment.size) {
        attachmentField.size = attachment.size;
      }

      const body = [
        {
          file: attachment.file.name,
          attachment: attachmentField,
          resource: {
            href: resource.$$meta.permalink,
          },
        },
      ];

      fd.append('body', JSON.stringify(body));

      $http
        .post(`${settings.apisAndUrls.contentApi}/content/attachments`, fd, {
          transformRequest: angular.identity, //eslint-disable-line
          headers: { 'Content-Type': undefined }, //eslint-disable-line
        })
        .then(function (data) {
          const resp = {
            status: data.status,
          };
          defer.resolve(resp);
        })
        .catch(function (resp) {
          if (!resp) {
            resp = 'No response';
          } else if (resp.errors) {
            resp.document = resource;
          }
          defer.resolve(resp);
        });

      return defer.promise;
    };

    // TODO still need it ???
    that.putVideoInS3 = function (signedRequest, file) {
      const defer = $q.defer();

      $http({
        method: 'PUT',
        url: signedRequest,
        data: file,
        processData: false,
        headers: {
          'Content-Type': file.type,
        },
      })
        .then(function (data) {
          const resp = {
            status: data.status,
          };
          defer.resolve(resp);
        })
        .catch(function (resp) {
          if (!resp) {
            resp = 'No response';
          }
          defer.resolve(resp);
        });

      return defer.promise;
    };

    that.getMe = async function () {
      async function waitUntilUser() {
        return new Promise((resolve) => {
          const interval = setInterval(() => {
            if (selectUser(getStore().getState())) {
              resolve('foo');
              clearInterval(interval);
            }
          }, 500);
        });
      }
      await waitUntilUser();
      return selectUser(getStore().getState());
    };

    that.getMeHref = function () {
      const d = $q.defer();
      sriClient
        .get(settings.oauth.me)
        .then(function (me) {
          d.resolve(`/persons/${me.data.uuid}`);
        })
        .catch(function (error) {
          d.reject(error);
        });
      return d.promise;
    };

    that.searchPersons = function (query, limit, cancelPromise) {
      let words;
      if (query && query !== '') {
        words = query.split(' ').join(' ');
      }
      const params = {
        q: words,
        limit,
        expand: 'FULL',
      };
      return that.getResources('/persons', params, cancelPromise);
    };

    that.log = function (message, type) {
      $http({
        method: 'PUT',
        url: '/log',
        data: {
          message,
          type,
        },
        contentType: 'application/json',
        dataType: 'json',
      });
    };

    that.audit = function (resource, type, component, operation, me) {
      const defer = $q.defer();

      const auditResource = {
        key: Utils.getUUID(),
        timestamp: new Date().toISOString(),
        person: me,
        component,
        operation,
        type,
        resource: resource.$$meta.permalink,
        document: resource,
      };

      $http({
        method: 'PUT',
        url: `${settings.apisAndUrls.auditApi}/versions/${auditResource.key}`,
        data: auditResource,
        contentType: 'application/json',
        dataType: 'json',
      })
        .then(function (data) {
          const resp = {
            status: data.status,
          };
          defer.resolve(resp);
        })
        .catch(function (resp) {
          if (!resp) {
            resp = 'No response';
          } else if (resp.errors) {
            resp.document = resource;
          }
          defer.resolve(resp);
        });

      return defer.promise;
    };

    return that;
  },
];
