export const subjectSpecific = {
  key: '974c6b12-2b90-4d8d-a98f-67b2daab0d8b',
  get href() {
    return `/newsletter/types/${this.key}`;
  },
};

export const thematic = {
  key: 'f64b33d8-dbd9-49b8-845b-4f3f2a6c2b78',
  get href() {
    return `/newsletter/types/${this.key}`;
  },
};
