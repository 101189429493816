import './inlineFootnote.scss';

class inlineFootnote {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {}

  $onChanges() {}
}

export const inlineFootnoteComponent = {
  transclude: true,
  template: require('./inlineFootnote.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHref: '@',
  },
  controller: inlineFootnote,
};
