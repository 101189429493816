import { PrivateState } from '@generalTypes/personApiTypes';
import {
  DropExistingNode,
  DropType,
  HoverPositionEnum,
  InlineField,
} from '@nodeTypeConfig/configTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BACK_TO_DOCUMENTS_LIST,
  CLOSE_ASIDE,
  SAVE_DOCUMENT,
  UPDATE_ASIDE_VIEW_MODEL,
} from '@store/constants/actionTypes';
import { ContentHref } from '@generalTypes/apiTypes';
import { settings } from '../../config/settings';

export type Mode = 'EDIT' | 'SUGGEST' | 'REVIEW' | 'READ_ONLY';
export type DocumentUIState = {
  currentDocument: ContentHref | null;
  currentEditingNode: ContentHref | null;
  mode: Mode | null;
  privateState: PrivateState | null;
  collapsedNodes: Record<string, boolean>;
  dropBelowItem: ContentHref | null;
  nodeThatShouldBeFocused: { field: InlineField; href: ContentHref } | null;
  draggingNodes: DropExistingNode['items'];
};

export type CollapseAction = PayloadAction<{ href: string; collapsedByDefault: boolean }>;
export type UpdateLastReadAction = PayloadAction<{ doNotShowLastReadUpdatedNotification: boolean }>;
export type DropItemAction = PayloadAction<{
  droppedItems: DropType['items'];
  parentHref: string;
  siblingHref: string | null;
  position: HoverPositionEnum;
}>;

const initialState = (): DocumentUIState => ({
  currentDocument: null,
  mode: null,
  currentEditingNode: null,
  privateState: null,
  collapsedNodes: {},
  dropBelowItem: null,
  nodeThatShouldBeFocused: null,
  draggingNodes: [],
});

const documentUISlice = createSlice({
  name: 'documentUI',
  initialState: initialState(),
  reducers: {
    setSelectedDocument(state, action: PayloadAction<{ documentKey: string | undefined }>) {
      const { documentKey } = action.payload;
      // state = initialState();
      state.currentDocument = `/content/${documentKey}` as ContentHref;
      state.mode = null;
    },
    setMode(state, action: PayloadAction<Mode | 'SUGGESTING' | 'REVIEWING'>) {
      if (action.payload === 'SUGGESTING') {
        state.mode = 'SUGGEST';
      } else if (action.payload === 'REVIEWING') {
        state.mode = 'REVIEW';
      } else {
        state.mode = action.payload;
      }
    },
    openPreview: () => {},
    setPrivateState(state, action: PayloadAction<{ privateState: PrivateState }>) {
      state.privateState = action.payload.privateState;
    },
    toggleCollapseNode(state, action: PayloadAction<{ nodeHref: string }>) {
      const { nodeHref } = action.payload;
      state.collapsedNodes[nodeHref] = !state.collapsedNodes[nodeHref];
    },
    setCollapsedNodes(state, action: PayloadAction<{ collapsedNodes: Record<string, boolean> }>) {
      const { collapsedNodes } = action.payload;
      state.collapsedNodes = { ...state.collapsedNodes, ...collapsedNodes };
    },
    toggleAllCollapsed() {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    unCollapseNode(state, action: PayloadAction<{ nodeHref: ContentHref }>) {},
    updateLastRead(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: UpdateLastReadAction
    ) {
      if (state.privateState) {
        state.privateState.state.lastRead = new Date(
          new Date().getTime() + settings.CLOCK_OUT_OF_SYNC_SAFETY_DELAY
        ).toISOString();
      }
    },
    setDropBelowItem: (state, action: PayloadAction<{ href: ContentHref } | null>) => {
      if (action.payload === null) {
        state.dropBelowItem = null;
        return;
      }
      state.dropBelowItem = action.payload.href;
    },
    dropItem: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: DropItemAction
    ) => {
      state.dropBelowItem = null;
      state.draggingNodes = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newNodeDropped: (state, action: PayloadAction<{ newHref: ContentHref }>) => {},
    setNodeThatShouldBeFocused: (
      state,
      action: PayloadAction<{ field: InlineField; href: ContentHref } | null>
    ) => {
      state.nodeThatShouldBeFocused = action.payload;
    },
    setDraggingNodes: (state, action: PayloadAction<{ items: DropExistingNode['items'] }>) => {
      state.draggingNodes = action.payload.items;
    },
  },
  selectors: {
    selectAreSomeNodesCollapsed: (state) => {
      return Object.values(state.collapsedNodes).some((isCollapsed) => isCollapsed === true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CLOSE_ASIDE, (state) => {
      state.currentEditingNode = null;
    });
    builder.addCase(SAVE_DOCUMENT, (state) => {
      state.currentEditingNode = null;
    });
    builder.addCase(BACK_TO_DOCUMENTS_LIST, () => {
      return initialState();
    });
    // @ts-expect-error old actions aren't liked by TS
    builder.addCase(UPDATE_ASIDE_VIEW_MODEL, (state, { payload }) => {
      state.currentEditingNode = `/content/${payload.editKey}` as ContentHref;
    });
  },
});

export const {
  setSelectedDocument,
  setMode,
  openPreview,
  setPrivateState,
  toggleCollapseNode,
  toggleAllCollapsed,
  unCollapseNode,
  setCollapsedNodes,
  updateLastRead,
  setDropBelowItem,
  dropItem,
  newNodeDropped,
  setNodeThatShouldBeFocused,
  setDraggingNodes,
} = documentUISlice.actions;
export default documentUISlice.reducer;

export const { selectAreSomeNodesCollapsed } = documentUISlice.selectors;
