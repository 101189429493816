import { ContentRelation, Content } from '@generalTypes/apiTypes';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { referenceFrames, referenceFrameLabels } from '@newStore/referenceFrames/referenceFrames';
import { EditAsideReferenceFrameReferences } from '@nodeTypeConfig/configTypes';

export const openLlinkidReferenceModal = async ({
  asideOptions,
  relations,
  item = {},
}: {
  asideOptions: EditAsideReferenceFrameReferences['options'];
  relations: Array<ContentRelation>;
  item?: { relation: ContentRelation; item: Content } | object;
}): Promise<Content & { strength: string }> => {
  const modalWindowService = getAngularService('ModalWindowService');

  const { modal } = asideOptions;

  const modalData = {
    component: modal,
    dataForModal: {
      parameters: {
        type: 'THEME',
        root: asideOptions.referenceFrame,
        orderBy: 'title',
      },
      showStrength: asideOptions.referenceFrame === referenceFrames.educationalComponents,
      typeTranslation: referenceFrameLabels[asideOptions.referenceFrame],
      edit: item,
      alreadySelectedReferences: relations.map((r) => r.from.href),
    },
  };

  return modalWindowService.open(modalData);
};
