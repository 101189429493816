import { QuoteNode } from '@newStore/documentUI/documentUITypes';
import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import InlineEditor from '@UI/editor/InlineEditor';
// eslint-disable-next-line
import QuoteIcon from '!!raw-loader!@img/icons/quote.svg';
import './QuoteRow.scss';
import { getPlaceHolderLabel } from '../../contentRowHelpers';

const QuoteRow: React.FC<{ contentNode: QuoteNode }> = ({ contentNode }) => {
  const nodeTypeConfig = useSelector((state: RootState) =>
    selectAppliedNodeConfig(state, contentNode.href)
  );

  return (
    <div className="quote-row">
      <span className="quote-icon" dangerouslySetInnerHTML={{ __html: QuoteIcon }}></span>
      <InlineEditor
        initial={contentNode.text}
        field="html"
        placeholder={getPlaceHolderLabel(nodeTypeConfig, 'html')}
        skey={contentNode.key}
        href={contentNode.href}
      ></InlineEditor>
    </div>
  );
};

export default QuoteRow;
