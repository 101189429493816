import { nanoid } from 'nanoid';
import React, { ReactNode, useRef } from 'react';
import './SimpleRadioButton.scss';

interface AsideSimpleRadioButtonProps {
  name: string;
  label: string | ReactNode;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
  readOnly: boolean;
}

const SimpleRadioButton: React.FC<AsideSimpleRadioButtonProps> = ({
  name,
  label,
  value,
  checked,
  onChange,
  readOnly,
}) => {
  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const inputIdRef = useRef(`simple-radio-${nanoid()}`);
  const inputId = inputIdRef.current;

  return (
    <>
      <input
        id={inputId}
        type="radio"
        className="form-control"
        name={name}
        value={value}
        checked={checked}
        disabled={readOnly}
        onChange={change}
      />
      <label className="radio-label" htmlFor={inputId}>
        {label}
      </label>
    </>
  );
};

export default SimpleRadioButton;
