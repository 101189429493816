import { BuildingBlockNodeConfig, NodeType, RequiredType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { identifierComponent } from '../helpers/genericComponents';

const LLINKID_GOAL: BuildingBlockNodeConfig = {
  information: {
    single: 'Leerplandoel',
    plural: 'Leerplandoelen',
  },

  createDefaults: {
    llinkidGoalType: 'REGULAR',
  },
  edit: [
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
      required: RequiredType.WARNING,
      options: {
        showMarkerButton: true,
      },
    },
    {
      ...identifierComponent,
      required: RequiredType.ERROR,
    },
    {
      component: 'previousVersionItems',
      reactComponent: 'AsidePreviousVersionItems',
      label: 'Vervangt (uit vorige versie)',
      options: {
        relationTypes: ['REPLACES'],
        modal: 'llinkidGoalRelationsModal',
        modalTitle: 'Vervangt (uit vorige versie)',
        placeholder: 'Kies een te vervangen doel',
        relation: 'Doel',
        errorMessage: 'Je moet een geldig doel selecteren',
        type: ContentType.LLINKID_GOAL,
      },
    },
    {
      component: 'llinkidGoalType',
      property: 'llinkidGoalType',
      reactComponent: 'AsideSimpleDropDown',
      label: 'Soort leerplandoel',
      required: RequiredType.ERROR,
      options: {
        clearable: false,
        options: [
          {
            key: 'REGULAR',
            label: 'Gewoon',
          },
          {
            key: 'DEEPENING',
            label: 'Verdiepend',
          },
          {
            key: 'EXTRA',
            label: 'Extra',
          },
          {
            key: 'CHOICE',
            label: 'Keuze',
          },
        ],
      },
    },
    {
      component: 'attitudinal',
      property: 'attitudinal',
      label: 'Attitude',
      reactComponent: 'AsideCheckbox',
    },
    {
      component: 'goalRelations',
      reactComponent: 'AsideGoalRelations',
      label: 'Gerelateerde doelen ander leerplan',
      options: {
        relationTypes: ['RELATION'],
        modal: 'llinkidGoalRelationsModal',
        listColumn: 'description',
        toType: ContentType.LLINKID_GOAL,
      },
    },
    {
      component: 'goalOdetEndTermReferences',
      reactComponent: 'AsideGoalRelations',
      label: 'Realiseert basisgeletterdheid',
      options: {
        relationTypes: ['REFERENCES'],
        toType: ContentType.CURRICULUM_ODET_DEVELOPMENT_GOAL,
        modal: 'llinkidGoalOdetEndTermReferencesModal',
        listColumn: 'title',
      },
    },
    {
      component: 'llinkidFoundationalSuggestions',
      label: 'components.llinkid.goalSuggestions.title',
      options: {
        relationTypes: ['SUGGESTED_EDUCATIONAL_ACTIVITY'],
        listColumn: 'description',
      },
      showInAncestorTypes: [NodeType.LLINKID_FOUNDATIONAL_CURRICULUM],
    },
    {
      component: 'goalImplements',
      reactComponent: 'AsideGoalRelations',
      label: 'Wordt (geheel of gedeeltelijk) gerealiseerd door',
      options: {
        relationTypes: ['IMPLEMENTS', 'PARTIALLY_IMPLEMENTS'],
        modal: 'llinkidGoalRelationsModal',
        listColumn: 'description',
        revertedRelationDirection: true,
        toType: ContentType.LLINKID_GOAL,
      },
      showInAncestorTypes: [NodeType.LLINKID_FOUNDATIONAL_CURRICULUM],
    },
    {
      component: 'goalImplements',
      reactComponent: 'AsideGoalRelations',
      label: 'Realiseert (geheel of gedeeltelijk)',
      options: {
        relationTypes: ['IMPLEMENTS', 'PARTIALLY_IMPLEMENTS'],
        modal: 'llinkidGoalRelationsModal',
        readOnly: true,
        listColumn: 'description',
        toType: ContentType.LLINKID_GOAL,
      },
      showInAncestorTypes: [NodeType.LLINKID_CURRICULUM],
      hideInAncestorTypes: [NodeType.LLINKID_FOUNDATIONAL_CURRICULUM],
    },
  ],
  buildingBlocks: [
    { type: NodeType.LLINKID_GOAL_DEMARCATION },
    { type: NodeType.LLINKID_PEDAGOGICAL_TIP },
    { type: NodeType.LLINKID_EXTRA_GOAL_INFORMATION },
    { type: NodeType.LLINKID_GOAL_EXPLANATION },
    { type: NodeType.LLINKID_GOAL },
    { type: NodeType.LLINKID_INITIAL_SITUATION },
  ],
  documentViewIdentifier: '(p)i.i',
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'description',
  },
};

export default LLINKID_GOAL;
