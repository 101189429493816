import { RootState } from '@generalTypes/rootStateTypes';
import { useSelector } from 'react-redux';
import { ContentHref } from '@generalTypes/apiTypes';
import { selectValidationInfoForTocRow } from '@newStore/validation/validationSelectors';
import ValidationIndicator from './ValidationIndicator';

const ValidationIndicatorForToc: React.FC<{
  href: ContentHref;
  parentHref: ContentHref | undefined;
}> = ({ href, parentHref }) => {
  const validation = useSelector((state: RootState) =>
    selectValidationInfoForTocRow(state, href, parentHref)
  );

  if (!validation?.validationErrors?.length) {
    return null;
  }

  return (
    <ValidationIndicator
      href={href}
      status={validation.status}
      validationErrors={validation.validationErrors}
    ></ValidationIndicator>
  );
};

export default ValidationIndicatorForToc;
