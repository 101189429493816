import { ContentHref } from '@generalTypes/apiTypes';
import { ValidationErrorWithNode, ValidationStatus } from '@newStore/validation/validationTypes';
// eslint-disable-next-line
import ErrorWarningIcon from '!!raw-loader!@img/error-warning-icon.svg';
import ValidationErrors from './ValidationErrors';
import './ValidationIndicator.scss';

const ValidationIndicator: React.FC<{
  href: ContentHref;
  status: ValidationStatus;
  validationErrors: Array<ValidationErrorWithNode>;
  onIconClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}> = ({ href, status, validationErrors, onIconClick }) => {
  return (
    <>
      <span
        className={`error-warning-icon ${status === 'INVALID' ? 'error' : 'warning'}`}
        dangerouslySetInnerHTML={{ __html: ErrorWarningIcon }}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (onIconClick) {
            onIconClick(event);
          }
        }}
      ></span>

      <div className={`validation-message-hover-container`}>
        <ValidationErrors
          href={href}
          validationErrors={validationErrors}
          defaultMinimized={false}
        />
      </div>
    </>
  );
};

export default ValidationIndicator;
