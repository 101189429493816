import { any } from 'prop-types';
import React from 'react';
import { EditAsideLinks } from '@nodeTypeConfig/configTypes';
import './asideLinks.scss';
import { useSelector, useDispatch } from 'react-redux';
import { selectAsideLinks } from '@newStore/documentAside/documentAsideSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  addLinkReferenceNodeAction,
  editLinkReferenceNodeAction,
  removeNodeAction,
} from '@store/actions/documentActions';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useEditHref } from '../../../hooks/UseEditHref';
import { deletedProposalLineStyle } from '../asideHelpers';

const AsideLinks: React.FC<{
  config: EditAsideLinks;
}> = ({ config }) => {
  const editHref = useEditHref();
  const nodeKey = getResourceKey(editHref);

  const dispatch = useDispatch();
  const confirmationService = getAngularService('ConfirmationService');
  const modalWindowService = getAngularService('ModalWindowService');
  const links = useSelector((state: RootState) => selectAsideLinks(state, editHref));
  const readOnly = useReadOnly(false);

  const addLink = async () => {
    const opts = {
      component: 'linkModal',
      dataForModal: {
        modalTitle: config.options?.modalTitle,
      },
    };

    const response = await modalWindowService.open(opts);

    if (response) {
      dispatch(
        addLinkReferenceNodeAction(
          nodeKey,
          response.title,
          response.url,
          config.options?.type === 'group'
        )
      );
    }
  };

  const editLink = async (link) => {
    const opts = {
      component: 'linkModal',
      dataForModal: {
        title: link.title,
        url: link.href,
        modalTitle: config.options?.modalTitle,
      },
    };

    const response = await modalWindowService.open(opts);

    if (response) {
      dispatch(editLinkReferenceNodeAction(nodeKey, link.key, response.title, response.url));
    }
  };

  const deleteLink = async (link) => {
    const confirmed = await confirmationService.confirmDeletion();
    if (confirmed) {
      dispatch(removeNodeAction(link.key, true, nodeKey));
    }
  };

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="panel-controls panel-controls-left">
            <h3 className="panel-title">{config.label}</h3>
          </div>
          <div className="panel-controls panel-controls-right">
            <div className="btn-toolbar">
              {!readOnly && (
                <button className="btn btn-default" onClick={addLink}>
                  <span className="glyphicon glyphicon-add"></span>
                  <span>Voeg toe</span>
                </button>
              )}
            </div>
          </div>
        </div>

        {links.length !== 0 && (
          <div className="structure">
            {links.map((link) => (
              <div className="row table-row" key={link.key}>
                {link.deletedProposalFlags ? (
                  <div className="col-md-12 delete-proposal-row">
                    {/* TODO <AsideProposalIndicator href={editHref}></AsideProposalIndicator> */}
                    <div
                      className={`delete-proposal-line ${deletedProposalLineStyle(
                        link.deletedProposalFlags
                      )}`}
                    ></div>
                  </div>
                ) : (
                  <div className="col-md-12 link-row">
                    {/* TODO <AsideProposalIndicator href={editHref}></AsideProposalIndicator> */}
                    <div>
                      <a href={link.href} target="_blank" rel="noreferrer">
                        {link.href}
                      </a>
                      <br />
                      {link.title}
                    </div>
                  </div>
                )}

                {!readOnly && !link.deletedProposalFlags && (
                  <div className="btn-group btn-group-xs row-actions" role="group" aria-label="...">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => editLink(link)}
                    >
                      <span className="glyphicon glyphicon-edit"></span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => deleteLink(link)}
                    >
                      <span className="glyphicon glyphicon-delete"></span>
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

AsideLinks.displayName = 'AsideLinks';

export default AsideLinks;

AsideLinks.propTypes = {
  config: any,
};
