import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector, getAttachmentUrl } from '@newStore/genericHelpers';
import { getProposalType } from '@store/viewmodels/proposalViewModel';
import { ContentWith$$Relations, ContentHref } from '@generalTypes/apiTypes';
import { add$$RelationsToContent, makeProposalMap } from '../documentApi/documentApiHelpers';
import { selectCurrentDocumentHref } from '../documentUI/documentUISelectors';

export const selectPostmessageData = createTypedSelector(
  [
    (state) => selectApiWithPendingChanges(state),
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectCurrentDocumentHref(state),
  ],
  (apiWithPendingChanges, apiWithPendingRelationsMap, rootHref) => {
    if (!rootHref || !apiWithPendingChanges.content[rootHref]) {
      return null;
    }

    const contentMapWithRelations = add$$RelationsToContent(
      apiWithPendingChanges.content,
      apiWithPendingRelationsMap.from,
      apiWithPendingRelationsMap.to
    );

    const oldProposalsMap = makeProposalMap(apiWithPendingChanges.proposals, {});

    const content = Object.values(contentMapWithRelations)
      .filter((item) => {
        // filter out nodes suggested to be deleted
        const proposal = oldProposalsMap.get(`/content/${item.key}`);
        return !proposal || getProposalType(proposal) !== 'DELETE';
      })
      .map((item) => {
        if (item.$$meta) {
          return item;
        }

        const $$meta = { permalink: `/content/${item.key}` as ContentHref, type: 'CONTENT' };
        return { ...item, $$meta };
      })
      .map((item) => {
        const attachments = item.attachments?.map((attachment) => {
          const $$url = getAttachmentUrl(attachment);

          return { ...attachment, href: $$url, $$url };
        });
        // get html from content type attachment
        const contentWithTransformedAtt = { ...item, attachments };
        return contentWithTransformedAtt;
      }) as ContentWith$$Relations[];

    const data = {
      items: content,
      documentHref: rootHref,
      webpages: apiWithPendingChanges.webPages,
    };

    return { type: 'REDACTIE_SUGGESTIONS', suggestions: data };
  }
);
