class Editor {
  constructor() {
    'ngInject';
  }
}

export default {
  controllerAs: 'ctrl',
  bindings: {
    sModel: '<?',
    sInitial: '@?',
    sField: '@?',
    sKey: '@?',
    sDisabled: '<',
    inline: '<',
    showCharacterCount: '<',
    plainText: '<',
    onTextChange: '=',
  },
  template:
    '<react-aside-editor initial="ctrl.sInitial" field="ctrl.sField" skey="ctrl.sKey" read-only="ctrl.sDisabled" inline="ctrl.inline" show-character-count="ctrl.showCharacterCount" plain-text="ctrl.plainText" on-text-change="ctrl.onTextChange"></react-aside-editor>',
  controller: Editor,
};
