import { any } from 'prop-types';
import React from 'react';
import { EditAsideVersion, EditComponentProperty } from '@nodeTypeConfig/configTypes';
import SimpleInput from './SimpleInput';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';

const AsideVersion: React.FC<{ config: EditAsideVersion }> = ({ config }) => {
  const initialValue = useInitialValue(config.property as EditComponentProperty);

  const readOnly = useReadOnly(config.readonly);

  return (
    <AsideValidation property={config.property} component={config.component}>
      <SimpleInput
        defaultValue={initialValue}
        label={config.label}
        readOnly={readOnly}
        onChange={(_value) => {}}
      />
    </AsideValidation>
  );
};

AsideVersion.displayName = 'AsideVersion';

export default AsideVersion;

AsideVersion.propTypes = {
  config: any,
};
