import { getGoalPathText } from '../../reduxLoop/helpers/documentHelpers';

class referenceDisplay {
  constructor() {
    'ngInject';

    this.loading = true;
  }

  $onChanges() {
    if (this.sDocument && this.sDocument.$$treeAsLeaf) {
      this.root = this.sDocument.$$treeAsLeaf.find((node) => node.type === 'CURRICULUM_ZILL');
      this.documentPath = getGoalPathText(this.sDocument.$$treeAsLeaf);
      this.loading = false;
    }
  }
}

export default {
  template: require('./referenceDisplay.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
  },
  controller: referenceDisplay,
};
