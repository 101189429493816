import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class documentSelectorModal {
  constructor($notification, $translate, $ngRedux, $scope) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
  }

  hasError() {
    return false;
  }

  async submit() {
    if (!this.hasError()) {
      this.modalInstance.close(this.selectedVersion);
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        choices: state.document[this.resolve.modalData.stateField],
        loading: !state.document[this.resolve.modalData.stateField],
      };
    })(this);

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.getAllOfTypeAction(this.resolve.modalData.params, {
        stateField: this.resolve.modalData.stateField,
      })
    );
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./documentSelectorModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: documentSelectorModal,
};
