import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

/* eslint-disable func-names */
class termSelectionModal {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.template = require('../../../screen/optionTemplates/termOption.html');
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        allLocalTerms: state.document.viewModel.terms.local,
        termOptions: state.document.viewModel.termOptions,
        loadingTerms: state.document.viewModel.termOptions.length === 0,
      };
    })(this);

    this.term = this.resolve.modalData.term;
    this.rootDocument = this.resolve.modalData.rootDocument;

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.getAllOfTypeAction(
        {
          type: 'TERM',
          limit: 6000,
        },
        { stateField: 'allTerms', sortBy: 'title' }
      )
    );
  }

  $onDestroy() {
    this.unsubscribe();
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  submit() {
    if (!this.term) {
      // $notification.error('You need to select 1 term');
    } else {
      this.modalInstance.close(this.term);
    }
  }

  term2String(d) {
    if (d && d.title && d.status) {
      return `${d.title} ${d.status}`;
    }
    return d ? d.title : '<NONE>';
  }
}

export default {
  template: require('./termSelectionModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: termSelectionModal,
};
