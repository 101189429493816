import { ContentType } from '@generalTypes/apiTypes';
import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';

const VIDEO: BuildingBlockNodeConfig = {
  information: {
    single: 'Video',
    plural: "Video's",
    ribonIcon: require('../../../../img/buildingBlocks/VIDEO.svg'),
  },
  buildingBlocks: [],
  edit: [
    {
      component: 'video',
      label: 'Video',
      property: 'attachments',
      required: RequiredType.ERROR,
      options: { type: ContentType.VIDEO },
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default VIDEO;
