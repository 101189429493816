import { Content, ContentHref, isReferencesRelation } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { getRoot } from '@newStore/externalData/externalDataHelpers';
import {
  selectAllExternalData,
  selectExternalContent,
  selectExternalContentRelationsMap,
} from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { referenceGroupTypes } from '@store/constants/constants';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { ReferenceGroupNode, ReferenceGroupReference } from '../documentUITypes';
import { selectProposedContentHrefsToDelete } from '../transformProposal/proposalSelectors';

const selectReferenceNodes = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state, href: ContentHref) => href,
    (state) => selectAllExternalData(state),
    (state) => selectExternalContentRelationsMap(state),
    (state) => selectExternalContent(state),
    (state) => selectProposedContentHrefsToDelete(state),
  ],
  (
    relationsMap,
    href,
    externalData,
    externalRelationsMap,
    externalContent,
    proposedHrefsToDelete
  ): Array<ReferenceGroupReference> =>
    relationsMap.to[href].flatMap((childRel) => {
      const referenceHref =
        relationsMap.from[childRel.from.href].find(isReferencesRelation)?.to.href;
      const reference = referenceHref && (externalData[referenceHref] as Content);
      if (!reference) {
        return [];
      }
      const type =
        referenceGroupTypes[reference.type] ||
        referenceGroupTypes[reference.$$meta.type] ||
        referenceGroupTypes.WEBPAGE2;
      const displayFuncOrProp = type.display;
      let title =
        typeof displayFuncOrProp === 'function'
          ? displayFuncOrProp(reference)
          : reference[displayFuncOrProp];
      const root = getRoot(externalRelationsMap, externalContent, referenceHref);
      if (root) {
        title += `(${root.title})`;
      }
      return {
        href: referenceHref,
        title,
        identifier: Array.isArray(reference.identifiers) ? reference.identifiers[0] : undefined,
        color: reference.color,
        isDeletedByProposal: proposedHrefsToDelete.includes(childRel.from.href),
      };
    })
);

export const selectReferenceGroupNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectReferenceNodes(state, href),
  ],
  (content: Content, references): ReferenceGroupNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      description: content?.description,
      type: NodeType.REFERENCE_GROUP,
      customRender: true,
      references,
    };
  }
);
