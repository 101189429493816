/* eslint-disable max-len */
import { pathToRootTemplate } from '@store/helpers/documentAsideHelpers';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import {
  getResourceKey,
  isExternalLinkHttp,
  isValidEmail,
} from '../../../reduxLoop/helpers/documentHelpers';

require('./linkButtonModalForm.scss');

class LinkButtonModalForm {
  constructor($notification, $scope, $translate, contentApi, settings, $ngRedux) {
    'ngInject';

    this.notificationService = $notification;
    this.translationService = $translate;
    this.$scope = $scope;
    this.backend = contentApi;
    this.settings = settings;
    this.loading = false;
    this.otherSections = [];
    // the model is a link
    this.link = {
      option: undefined,
      external: undefined,
      email: undefined,
      currentDocument: undefined,
      otherDocument: undefined,
      otherDocumentSection: undefined,
    };
    this.resourcePickerTemplate = require('../../../screen/optionTemplates/themeOption.html');
    this.contentApi = contentApi;
    this.$ngRedux = $ngRedux;
  }

  isValidLink() {
    if (!this.link.option) {
      this.notificationService.error(
        this.translationService.instant(
          'components.proWebsite.linkButtonModalForm.error.invalidOption'
        )
      );
      return false;
    }

    if (this.link.option === 'external') {
      if (!this.link.external || this.link.external.trim() === '') {
        this.notificationService.error(
          this.translationService.instant(
            'components.proWebsite.linkButtonModalForm.error.invalidExternalLink'
          )
        );
        return false;
      }

      if (this.link.external && !isExternalLinkHttp(this.link.external)) {
        this.notificationService.error(
          this.translationService.instant(
            'components.proWebsite.linkButtonModalForm.error.invalidExternalLinkHttp'
          )
        );
        return false;
      }
    }

    if (this.link.option === 'email' && !isValidEmail(this.link.email)) {
      this.notificationService.error('De e-mail is niet juist');
      return false;
    }

    if (this.link.option === 'currentDocument' && !this.link.currentDocument) {
      this.notificationService.error(
        this.translationService.instant(
          'components.proWebsite.linkButtonModalForm.error.invalidCurrentDocument'
        )
      );
      return false;
    }

    if (
      this.link.option === 'otherDocument' &&
      !this.link.otherDocumentSection &&
      !this.link.otherDocument
    ) {
      this.notificationService.error(
        this.translationService.instant(
          'components.proWebsite.linkButtonModalForm.error.invalidOtherDocument'
        )
      );
      return false;
    }

    return true;
  }

  getLinkValue() {
    if (this.link.option === 'external') {
      return {
        href: this.link.external,
        rel: '',
      };
    }

    if (this.link.option === 'email') {
      return {
        href: `mailto:${this.link.email}`,
        rel: '',
      };
    }

    if (this.link.option === 'currentDocument') {
      return {
        href: this.link.currentDocument.$$meta
          ? this.link.currentDocument.$$meta.permalink
          : `/content/${this.link.currentDocument.key}`, // no $$meta if proposed section selected
        rel: 'webpage2-link',
      };
    }

    if (this.link.option === 'otherDocument') {
      const document = this.link.otherDocumentSection ?? this.link.otherDocument;
      return {
        href: document.$$meta ? document.$$meta.permalink : `/content/${document.key}`, // no $$meta if proposed section selected
        root: this.link.otherDocument.$$meta.permalink,
        rel: 'webpage2-ext-link',
      };
    }
    return {};
  }

  async submit() {
    if (this.isValidLink()) {
      this.modalInstance.close(this.getLinkValue());
    }
  }

  webapage2String(t) {
    return t ? t.title : '<NONE>';
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  fillEmailLinkValue() {
    if (this.resolve.modalData.href.includes('mailto:')) {
      this.link.option = 'email';
      this.link.email = this.resolve.modalData.href.replace('mailto:', '');
      return true;
    }
    return false;
  }

  fillExternalLinkValue() {
    if (!this.resolve.modalData.href.includes('/content')) {
      this.link.option = 'external';
      this.link.external = this.resolve.modalData.href;
      return true;
    }
    return false;
  }

  fillCurrentDocumentLinkValue() {
    const sectionOfCurrentDocument = this.sections.find((section) => {
      const sectionHref = section.$$meta ? section.$$meta.permalink : `/content/${section.key}`;
      return sectionHref === this.resolve.modalData.href;
    });
    if (sectionOfCurrentDocument) {
      this.link.option = 'currentDocument';
      this.link.currentDocument = sectionOfCurrentDocument;
      return true;
    }
    return false;
  }

  async fillOtherDocumentLinkValue() {
    this.loading = true;
    this.link.option = 'otherDocument';

    if (this.resolve.modalData.root) {
      this.loadExternalDocumentSectionsAction(this.resolve.modalData.root, this.$ngRedux);
    }

    // we need to get the section + the document where the section belongs to in order to fill both pickers
    const params = {
      leaf: this.resolve.modalData.href,
    };

    try {
      // TODO get of root document should be done in redux action aswell
      let response = await this.backend.getAll(this.settings.resourcesNames.content, params);
      if (response && response.length > 0) {
        this.link.otherDocument = response[0];
      } else if (this.resolve.modalData.root) {
        response = await this.backend.getRaw(this.resolve.modalData.root);
        this.link.otherDocument = response;
      }
      this.filledOtherDocument = true;
      this.$scope.$apply();
    } catch (error) {
      console.error(error);
      this.notificationService.error(
        this.translationService.instant(
          'components.proWebsite.linkButtonModalForm.error.otherDocumentNotAvailable'
        )
      );
      this.cancel();
    }
  }

  otherDocumentRemoved() {
    this.$parent.ctrl.link.otherDocumentSection = undefined;
    this.$parent.ctrl.otherSections = [];
  }

  otherDocumentChange(selectedDocument) {
    this.$parent.ctrl.loadExternalDocumentSectionsAction(
      selectedDocument.$$meta.permalink,
      this.$parent.ctrl.$ngRedux
    );
  }

  loadExternalDocumentSectionsAction(externalDocumentHref, ngRedux) {
    ngRedux.dispatch(
      DOCUMENT_ACTIONS.loadExternalDocumentSectionsAction(getResourceKey(externalDocumentHref))
    );
  }

  display(item) {
    return pathToRootTemplate(item);
  }

  displayInternalPath(item) {
    // build pathToRoot with our own document.
    const $$pathToRoot = [];
    let parent = item;
    while (parent) {
      $$pathToRoot.push(parent.title);
      parent = parent.$$parent;
    }
    return pathToRootTemplate({ ...item, $$pathToRoot });
  }

  fillLinkValue() {
    if (this.resolve.modalData.href) {
      if (this.fillEmailLinkValue()) {
        return;
      }
      if (this.fillExternalLinkValue()) {
        return;
      }
      if (this.fillCurrentDocumentLinkValue()) {
        return;
      }

      this.fillOtherDocumentLinkValue();
    }
  }

  selectLinkOption(option) {
    this.link.option = option;
  }

  $onInit() {
    this.sections = this.resolve.modalData.sections;
    this.fillLinkValue();

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const otherSections = state.document.selectChoices.externalDocumentSections;
      if (this.filledOtherDocument) {
        this.link.otherDocumentSection = otherSections.find((section) => {
          const sectionHref = section.$$meta ? section.$$meta.permalink : `/content/${section.key}`;
          return sectionHref === this.resolve.modalData.href;
        });
      }

      return {
        otherSections,
        loading: state.document.selectChoices.externalDocumentSectionsLoading,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./linkButtonModalForm.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: LinkButtonModalForm,
};
