import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { identifierComponent, titleComponent } from '@nodeTypeConfig/helpers/genericComponents';

const CURRICULUM_ODET_ENDTERM: BuildingBlockNodeConfig = {
  information: {
    single: 'Eindterm',
    plural: 'Eindtermen',
  },

  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving / Afbakening',
      view: {
        hideIfEmpty: true,
      },
    },
  ],
  isCollapsible: false,
  documentViewIdentifier: 'o(p*)(.)i',
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ODET_ENDTERM;
