import {
  Content,
  ContentHref,
  ContentRelation,
  ContentRelationHref,
  IsIncludedInRelation,
  IsPartOfRelation,
  Proposal,
  ProposalHref,
  WebPage,
} from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { getAllDescendantHrefs } from '@newStore/documentUI/documentUIHelpers';
import { Mode } from '@newStore/documentUI/documentUIState';
import {
  selectAllNodeTypesMap,
  selectGenericDocumentRootConfig,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import {
  getPathsToRootWithIsIncludedIn,
  getPathToRoot,
  getPathToRootHrefs,
} from '@newStore/externalData/externalDataHelpers';
import { selectAllExternalData } from '@newStore/externalData/externalDataSelectors';
import { pathMap } from '@newStore/externalData/externalDataTypes';
import {
  createTypedSelector,
  getChildRelationsOfRelation,
  isHref,
  parentChildRelationFilter,
} from '@newStore/genericHelpers';
import { selectUser } from '@newStore/user/userSelectors';
import { getNodeTypeConfig } from '@nodeTypeConfig/index';
import { createDocumentTree } from '@store/createDocumentTree';
import { updateApiPending } from '@store/helpers/documentStateHelpers';
import { conditionalLogTime, isSetEqual } from '@store/helpers/generalUtils';
import { cloneDeep, isEqual } from 'lodash';
import { shallowEqual } from 'react-redux';
import { findExternalRelationType } from '../../reduxLoop/helpers/documentHelpers';
import {
  ApiPending,
  applyApiPending,
  applyProposals,
  update$$html,
} from './calculateApiWithPendingChanges';
import {
  add$$RelationsToContent,
  createLRUSelector,
  getRelationsToAndFromMap,
  makeProposalMap,
} from './documentApiHelpers';
import {
  selectApiProposalsMap,
  selectNodeHrefsThatArePartOfSubmittedProposal,
} from './documentApiOldSliceSelectors';
import { loadWebConfigurations } from './documentApiState';
import { ApiWithPendingChanges, ResourceMap } from './documentApiTypes';

export const selectDocumentRoot = (state: RootState) => {
  const { currentDocument } = state.documentUI;
  if (!currentDocument) {
    return null;
  }
  const root = state.documentApi.content[currentDocument]
    ? state.documentApi.content[currentDocument]
    : null;
  return root;
};

/**
 * We can also make sure apiWithPendingChanges returns this map?
 * BUT then we would acknowledge that a content item can only have one webConfiguration
 * which is the case I think
 */
export const selectWebPagePerContentMap = createTypedSelector(
  [(state) => selectApiWithPendingChanges(state).webPages],
  (webPages: WebPage[]): Record<ContentHref, WebPage> => {
    return Object.fromEntries(webPages.map((wp) => [wp.source.href, wp]));
  }
);

export const selectAreContentAndProposalsLoaded = (state: RootState) =>
  state.documentApi.isContentAndRelationsFetched && state.documentApi.isProposalsFetched;

export const selectIsWebpagesFetchedOrNotRequired = (state: RootState) => {
  if (state.documentApi.isWebPagesFetched === true) {
    return true;
  }

  const typeConfig = selectGenericDocumentRootConfig(state);
  if (!typeConfig) {
    return false;
  }

  return (
    !typeConfig.onLoadActions ||
    !typeConfig.onLoadActions.some(
      (onLoadAction) => onLoadAction.type === loadWebConfigurations.type
    )
  );
};

export const selectApiFromRelations = createTypedSelector(
  [(state) => state.documentApi.content, (state) => state.documentApi.relations],
  (content, relations): Record<string, ContentRelation[]> => {
    const objMap: Record<string, ContentRelation[]> = Object.fromEntries(
      Object.keys(content).map((href) => [href, []])
    );

    const relationValues: ContentRelation[] = Object.values(relations);
    relationValues.forEach((rel) => {
      const contentHref = rel.from.href;
      if (!objMap[contentHref]) {
        objMap[contentHref] = [rel];
      } else {
        objMap[contentHref].push(rel);
      }
    });
    return objMap;
  }
);

const selectApiToRelations = createTypedSelector(
  [(state) => state.documentApi.content, (state) => state.documentApi.relations],
  (content, relations): Record<string, ContentRelation[]> => {
    const objMap: Record<string, ContentRelation[]> = Object.fromEntries(
      Object.keys(content).map((href) => [href, []])
    );
    Object.values(relations).forEach((rel) => {
      const contentHref = rel.to.href;
      if (!objMap[contentHref]) {
        objMap[contentHref] = [rel];
      } else {
        objMap[contentHref].push(rel);
      }
    });
    return objMap;
  }
);

/**
 * Selector to mock the new document Slice in the same format as the old document.api part (as Maps)
 * Used to fit that into the old viewModel. As soon als the old viewModel is gone, this selector can go.
 * We have to spread some of these properties because old code is mutating these state properties (= redux antipattern)
 */
export const selectNewDocumentApi = createTypedSelector(
  [
    (state) => state.documentApi.content,
    (state) => state.documentApi.relations,
    (state) => state.documentApi.webPages,
    (state) => state.documentApi.newsletterSettings,
    (state) => selectApiProposalsMap(state),
    (state) => selectApiFromRelations(state),
    (state) => selectApiToRelations(state),
  ],
  (
    content,
    relations,
    webPages,
    newsletterSettings,
    proposals,
    apiFromRelations,
    apiToRelations
  ) => {
    const newContent = add$$RelationsToContent(content, apiFromRelations, apiToRelations);

    return {
      content: new Map(Object.values(newContent).map((elem) => [elem.$$meta.permalink, elem])),
      relations: new Map(
        Object.entries(relations).map(([relHref, rel]) => [
          relHref,
          { ...rel, to: { ...rel.to }, from: { ...rel.from } },
        ])
      ),
      proposals,
      webpages: new Map(Object.entries(webPages)),
      newsletterSettings: newsletterSettings
        ? new Map([[newsletterSettings.$$meta.permalink, newsletterSettings]])
        : new Map(),
    };
  }
);

export const selectApiPendingOldSlice = createTypedSelector(
  [
    (state) => selectNewDocumentApi(state),
    (state) => selectNodeHrefsThatArePartOfSubmittedProposal(state),
    (state) => state.document.pendingActions,
    (state) => state.document.mode,
    (state) => state.document.tree.key,
    (state) => selectUser(state),
  ],
  (api, nodeHrefsThatArePartOfSubmittedProposal, pendingActions, mode, key, user) => {
    const apiPending = updateApiPending(
      api,
      nodeHrefsThatArePartOfSubmittedProposal,
      pendingActions,
      mode,
      key,
      user
    );
    return apiPending;
  }
);

const getProposalsForMode = (mode: Mode | null, proposalMap: Record<ProposalHref, Proposal>) => {
  if (!mode || mode === 'EDIT' || mode === 'READ_ONLY') {
    return [];
  }
  if (mode === 'REVIEW') {
    return Object.values(proposalMap).filter(
      (proposal) => proposal.status === 'SUBMITTED_FOR_REVIEW'
    );
  }
  return Object.values(proposalMap);
};

const initialApiWithPendingChangs: ApiWithPendingChanges = {
  content: {},
  relations: [],
  webPages: [],
  proposals: {},
  relationsToAndFromMap: { to: {}, from: {} },
  webPagePerContentMap: {},
  newsletterSettings: null,
  relationsMap: {},
};

export const selectApiWithPendingChanges = createTypedSelector(
  [
    (state) => state.documentApi.content,
    (state) => state.documentApi.relations,
    (state) => state.documentApi.webPages,
    (state) => state.documentApi.proposals,
    (state) => state.documentApi.newsletterSettings,
    (state) => selectApiPendingOldSlice(state),
    (state) => state.documentUI.mode,
  ],
  (
    rawContent,
    rawRelations,
    rawWebPages,
    proposals,
    newsletterSettings,
    apiPending: ApiPending,
    mode: Mode | null
  ): ApiWithPendingChanges => {
    if (mode === null) {
      // no use in calculating api with pending changes if default mode is not calculated yet
      return initialApiWithPendingChangs; // in const to always return the same object
    }
    console.log(`[calculate api with pending changes] in mode ${mode}`);
    // if (!isContentAndRelationsFetched || !isProposalsFetched) {
    //   return { content: {}, relations: [], webPages: [], proposals: {}, newsletterSettings: null };
    // }
    let resourceMap: ResourceMap = {
      CONTENT: { ...rawContent },
      RELATION: { ...rawRelations },
      WEBPAGE: { ...rawWebPages },
      NEWSLETTER_SETTINGS: newsletterSettings
        ? { [newsletterSettings.$$meta.permalink]: newsletterSettings }
        : {},
    };

    // 1. update apiWithPendingChanges with data in apiPending
    // this function mutates the resourceMap
    const newProposals = applyApiPending(resourceMap, proposals, apiPending);

    const proposalsForMode = getProposalsForMode(mode, newProposals);

    // 2. apply proposals info only if certain mode is active
    resourceMap = applyProposals(proposalsForMode, resourceMap);

    // 3. add $$html to content like it would come from the API (necessary in all modes).
    // After we take over pendingActions -> intergrate in applyProposals / applyApiPending because it is their responsibility to make sure api is correct after applying
    resourceMap = update$$html(resourceMap);

    console.log('[apiWithPendingChanges] calculated', {
      content: resourceMap.CONTENT,
      relations: Object.values(resourceMap.RELATION),
      webPages: Object.values(resourceMap.WEBPAGE),
      proposals: newProposals,
    });
    const webPages = Object.values(resourceMap.WEBPAGE);
    const relations = Object.values(resourceMap.RELATION);
    return {
      content: resourceMap.CONTENT,
      relations,
      relationsMap: resourceMap.RELATION,
      relationsToAndFromMap: getRelationsToAndFromMap(resourceMap.CONTENT, relations),
      webPages,
      webPagePerContentMap: Object.fromEntries(webPages.map((wp) => [wp.source.href, wp])),
      proposals: newProposals,
      newsletterSettings:
        Object.values(resourceMap.NEWSLETTER_SETTINGS).length > 0
          ? Object.values(resourceMap.NEWSLETTER_SETTINGS)[0]
          : null,
    };
  }
);

export const selectApiWithPendingChangesWithoutDeletes = createTypedSelector(
  [selectApiWithPendingChanges],
  (awpc): ApiWithPendingChanges => {
    const content = Object.fromEntries(
      Object.entries(awpc.content).filter(([, c]) => !c.$$meta.deleted)
    );
    const relations = awpc.relations.filter((rel) => !rel.$$meta.deleted);
    const webPages = awpc.webPages.filter((wp) => !wp.$$meta.deleted);
    return {
      content,
      relations,
      relationsMap: Object.fromEntries(
        Object.entries(awpc.relationsMap).filter(([, r]) => !r.$$meta.deleted)
      ),
      relationsToAndFromMap: getRelationsToAndFromMap(content, relations),
      webPages,
      webPagePerContentMap: Object.fromEntries(webPages.map((wp) => [wp.source.href, wp])),
      proposals: awpc.proposals, // we don't keep deleted proposals,
      newsletterSettings: awpc.newsletterSettings, // newslettersettings will not bedeleted,
    };
  }
);

const selectProposalsForMode = createTypedSelector(
  [(state) => selectApiWithPendingChanges(state).proposals, (state) => state.documentUI.mode],
  (proposals, mode) => {
    const proposalsForMode = getProposalsForMode(mode, proposals);
    return proposalsForMode;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectProposalsForContentMap = createTypedSelector(
  [(state) => selectProposalsForMode(state)],
  (proposalsForMode) => {
    const proposalForContentMap: Record<ContentHref, Proposal> = {};
    proposalsForMode.forEach((proposal) => {
      const anyChange = proposal.listOfRequestedChanges[0];
      const href = anyChange.relatedTo?.href || anyChange.appliesTo?.href;
      const existingValue = proposalForContentMap[href];
      if (!existingValue || existingValue.$$meta.created <= proposal.$$meta.created) {
        // `<=` because the old reducer clones a proposal if a second person add something.
        proposalForContentMap[href] = proposal;
      }
    });

    return proposalForContentMap;
  }
);

export const selectApiWithPendingChangesRelationsToAndFromMap = createTypedSelector(
  [(state) => selectApiWithPendingChanges(state)],
  (apiWithPendingChanges) => {
    return getRelationsToAndFromMap(apiWithPendingChanges.content, apiWithPendingChanges.relations);
  }
);

export const selectLoadedContentHrefsSet = createTypedSelector(
  [(state) => selectApiWithPendingChanges(state).content],
  (content) => new Set<ContentHref>(Object.keys(content) as ContentHref[]),
  {
    memoizeOptions: {
      resultEqualityCheck: isSetEqual,
    },
  }
);

/**
 * returns an output like updateApiWithPendingChanges
 */
export const selectApiWithPendingChangesOldSlice = createLRUSelector(
  [
    (state) => selectApiWithPendingChanges(state),
    (state) => selectAllExternalData(state),
    (state) => state.externalData.data[pathMap.persons].items,
  ],
  (api, externalData, personsMap) => {
    const newsletterSettings = api.newsletterSettings
      ? new Map([api.newsletterSettings].map((ns) => [ns.$$meta.permalink, ns]))
      : new Map();

    const relationsTo: Record<string, Array<ContentRelation>> = {};
    const relationsFrom: Record<string, Array<ContentRelation>> = {};

    // this is copied from fillRelationsWithExpandedResources, which is called in FILL_RELATIONS_WITH_EXPANDED_RESOURCES and updates the apiWithPendingChanges
    const relationsWithExpandedTo = api.relations.map((relation) => {
      if (relation.relationtype !== 'IS_PART_OF') {
        return {
          ...relation,
          to: {
            ...relation.to,
            $$expanded: externalData[relation.to.href],
          },
        };
      }
      return relation;
    });

    relationsWithExpandedTo.forEach((relation) => {
      if (!relationsTo[relation.to.href]) {
        relationsTo[relation.to.href] = [];
      }
      relationsTo[relation.to.href].push(relation as any); // we're hacking the type for the old reducer.

      if (!relationsFrom[relation.from.href]) {
        relationsFrom[relation.from.href] = [];
      }
      relationsFrom[relation.from.href].push(relation as any); // we're hacking the type for the old reducer.
    });

    const contentRelations = {
      from: relationsFrom,
      to: relationsTo,
    };

    // expand those external resources that are part of the document tree aswell
    // (eg. /sam subjects, domain, etc)
    const nonContentResourcesInDocumentTree = Object.values(api.relations)
      .filter(
        (rel) =>
          rel.relationtype === 'IS_PART_OF' &&
          rel.from.href.startsWith('/') &&
          !rel.from.href.startsWith('/content')
      )
      .map((rel) => externalData[rel.from.href])
      .filter((externalResource) => externalResource) // in early stage external Data will not be loaded so we will not add it to the content yet
      .map((externalResource: any) => ({
        ...externalResource,
        $$relationsFrom: [],
        $$relationsTo: [],
        attachments: [],
        type:
          externalResource.type && externalResource.type.href
            ? findExternalRelationType(externalResource.type.href)
            : externalResource.$$meta.type,
        label: externalResource.$$meta.type === 'VAK' ? '(oude structuur)' : undefined,
        $$meta: {
          ...externalResource.$$meta,
          type:
            externalResource.$$meta.type === 'VAK'
              ? 'COMMONS_SUBJECT'
              : externalResource.$$meta.type,
        },
      }));

    const newContent = { ...api.content };
    nonContentResourcesInDocumentTree.forEach((externalResource) => {
      newContent[externalResource.$$meta.permalink] = externalResource;
    });

    const contentWith$$Relations = add$$RelationsToContent(
      newContent,
      contentRelations.from,
      contentRelations.to
    );

    const apiWithPendingChanges = {
      content: new Map(
        Object.entries(contentWith$$Relations).map(([key, value]) => [key, { ...value }])
      ),
      relations: new Map(
        relationsWithExpandedTo.map((rel) => [`/content/relations/${rel.key}`, { ...rel }]) // when they make new relations they don't give it a $$meta sections
      ),
      proposals: makeProposalMap(api.proposals, personsMap),
      webpages: new Map(
        api.webPages.map((webpage) => [`/web/pages/${webpage.key}`, cloneDeep(webpage)]) // same here
      ),
      fileUploads: new Map(), // TODO: fileuploads are not done yet in the apiWithPendingChanges
      newsletterSettings,
      contentRelations,
    };
    return apiWithPendingChanges;
  },
  {
    memoizeOptions: {
      // because one of the input selectors (selectAllExternalData) will change when ANY data changes on the external data slice,
      // we can further optimize this selector by comparing the result of the selector with the previous result, and with that
      // prevent other selectors (which rely on this selector) to be recalculated.
      // resultEqualityCheck: deepEqualWithLog('selectApiWithPendingChangesOldSlice'),
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectDocumentTreeForOldSlice = createTypedSelector(
  [(state) => state.document.key, selectApiWithPendingChangesOldSlice],
  (key, apiWithPendingChanges) => {
    const calcultateDocumentTreeEnd = conditionalLogTime('calculate NEW document tree', 100);
    const tree = createDocumentTree(
      key,
      apiWithPendingChanges.content,
      apiWithPendingChanges.contentRelations.to,
      true
    );
    calcultateDocumentTreeEnd();
    return tree;
  }
);

export const selectInternalContentToLoad = createTypedSelector(
  [
    (state) => selectApiWithPendingChanges(state),
    (state) => state.documentApi.failedResources,
    (state) => state.documentApi.resourcesToLoad,
  ],
  ({ content, relations }, failedResources, resourcesToLoad) => {
    const handledResources = new Set<string>([...failedResources, ...resourcesToLoad]);

    const newResourcesToLoad = relations
      .filter(
        (z) =>
          z.relationtype === 'IS_INCLUDED_IN' &&
          !(content[z.from.href] || handledResources.has(z.from.href) || !isHref(z.from.href))
      )
      .map((z) => z.from.href);

    const newResourcesToLoadSet = new Set<string>(newResourcesToLoad);

    return Object.freeze([...newResourcesToLoadSet]);
  }
);

export const selectIncludedNodesThatNeedWebConfigs = createTypedSelector(
  [
    (state) => selectApiWithPendingChanges(state).relations,
    (state) => state.documentApi.includedWebPagesFetched,
    (state) => selectAllNodeTypesMap(state),
  ],
  (relations, includedWebPagesFetched, allNodeTypesMap): ContentHref[] => {
    const hrefs = relations
      .filter((rel) => {
        if (rel.relationtype !== 'IS_INCLUDED_IN') {
          return false;
        }
        if (includedWebPagesFetched[rel.from.href] !== undefined) {
          return false;
        }
        const nodeType = allNodeTypesMap[rel.from.href];
        if (!nodeType) {
          // GLOBAL DOCUMENTS can have many IS_INCLUDED_IN, and some will point to things we don't know.
          return false;
        }
        const typeConfig = getNodeTypeConfig(nodeType);
        if (!typeConfig || !('onLoadActions' in typeConfig)) {
          return false;
        }
        return typeConfig.onLoadActions.some(
          (onLoadAction) => onLoadAction.type === loadWebConfigurations.type
        );
      })
      .map((rel) => rel.from.href);

    return [...new Set(hrefs)];
  }
);

export const selectPathToRoot = createTypedSelector(
  [
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state, href: string) => href,
  ],
  (content, relationsMap, href) => {
    return getPathToRoot(relationsMap, content, href);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual, // compares the reference of each item in the array
    },
  }
);

export const selectPathToRootHrefs = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state, href: ContentHref) => href,
  ],
  (relationsMap, href): ContentHref[] => {
    return getPathToRootHrefs(relationsMap, href);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual, // compares the reference of each item in the array
    },
  }
);

/** @returns selects the content item from the apiWithPendingChanges so like it needs to be shown in the document. */
export const selectContentItem = (state: RootState, href: ContentHref) =>
  selectApiWithPendingChanges(state).content[href];

/** @returns the content item like it is in the Content Api. */
export const selectRawContentItem = (state: RootState, href: ContentHref) =>
  state.documentApi.content[href];

export const selectWebPage = (state: RootState, href: ContentHref) =>
  selectWebPagePerContentMap(state)[href];

export const selectHasChildren = createTypedSelector(
  [(state, href: ContentHref) => selectApiWithPendingChangesRelationsToAndFromMap(state).to[href]],
  (relationsTo): boolean => {
    return Boolean(relationsTo?.some(parentChildRelationFilter));
  }
);

export const selectRootItem = (state: RootState) => {
  const { currentDocument } = state.documentUI;
  if (!currentDocument) {
    return null;
  }
  return selectContentItem(state, currentDocument);
};

export const selectChildren = createTypedSelector(
  [
    (state, href: ContentHref) => selectApiWithPendingChangesRelationsToAndFromMap(state).to[href],
    (state) => selectApiWithPendingChanges(state).content,
  ],
  (relationsTo, contentMap) => {
    return relationsTo
      .filter(parentChildRelationFilter)
      .sort((a, b) => (a.readorder || 0) - (b.readorder || 0))
      .map((childRel) => contentMap[childRel.from.href])
      .filter(Boolean) as Content[];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual, // compares the reference of each item in the array
    },
  }
);

/**
 * this selector gets the parent hrefs of a content item.
 * because of the IS_INCLUDED_IN relation, an item could have multiple parents.
 * (in practice, this is only the case for TEASER)
 */
export const selectParentHrefs = createTypedSelector(
  [selectApiWithPendingChangesRelationsToAndFromMap, (state, href: ContentHref) => href],
  (relationsToAndFromMap, href) => {
    const paths: ContentHref[][] = getPathsToRootWithIsIncludedIn(relationsToAndFromMap, href);
    return paths.map((path) => path[1]);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectPathToRootWebConfigurations = createTypedSelector(
  [
    (state, href: ContentHref) => selectPathToRootHrefs(state, href),
    (state) => selectWebPagePerContentMap(state),
  ],
  (pathToRoot, webPageMap) => {
    return pathToRoot.map((href) => webPageMap[href]);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual, // compares the reference of each item in the array
    },
  }
);

export const selectRelationToParent = createTypedSelector(
  [
    (state: RootState, href: ContentHref, parentHref: ContentHref) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state).to[parentHref],
    (state: RootState, href: ContentHref) => href,
  ],
  (relationsToParent, href) => {
    return relationsToParent?.find(
      (rel) => rel.from.href === href && parentChildRelationFilter(rel)
    ) as IsPartOfRelation | IsIncludedInRelation | undefined;
  }
);

export const selectChildRelationsOfRelation = createTypedSelector(
  [
    (state, relationHref) => selectApiWithPendingChanges(state).relationsMap[relationHref],
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
  ],
  (relation, toFromRelationsMap): Record<ContentRelationHref, ContentRelation> => {
    if (!relation) {
      return {};
    }

    const allChildrenRelations = getChildRelationsOfRelation(toFromRelationsMap, relation);

    return Object.fromEntries(allChildrenRelations.map((rel) => [rel.$$meta.permalink, rel]));
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual, // compares the reference of each item in the array
    },
  }
);
export const selectAllNodeHrefs = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state).to,
    (state) => selectApiWithPendingChanges(state).content,
    (state: RootState) => state.documentUI.currentDocument, // used this instead of selectRootHref to avoid circular...
  ],
  (toRelationsMap, content, rootHref) => {
    if (!rootHref || !Object.keys(content).length) {
      return [];
    }

    const allHrefs = new Set<ContentHref>();
    allHrefs.add(rootHref);

    // get all descendant hrefs down from the root, following the parent-child relations
    // we do this to include the external resources that are IS_PART_OF. the ONE example of this is the PRO Reference frame.
    const allDescendants = getAllDescendantHrefs(toRelationsMap, rootHref);
    allDescendants.forEach((href) => allHrefs.add(href));

    // get ALL content hrefs that we have. for a ZILL_PRACTICAL_EXAMPLE we are also dumping ZILL_ILLUSTRATIONS into the content, which is not part of this document.
    Object.keys(content).forEach((href) => allHrefs.add(href as ContentHref));

    return [...allHrefs];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);
