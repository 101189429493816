import './proposalDiff.scss';

class proposalDiff {
  constructor($scope, $ngRedux, $translate) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$translate = $translate;
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  diffText() {
    function diffsToHtml(diffs) {
      const html = [];
      const patternBR = /\n/g;
      for (let x = 0; x < diffs.length; x += 1) {
        const op = diffs[x][0];
        const data = diffs[x][1];
        const text = unescape(data).replace(patternBR, '<br/>');
        switch (op) {
          case 1:
            html[x] = `<ins>${text}</ins>`;
            break;
          case -1:
            html[x] = `<del>${text}</del>`;
            break;
          case 0:
            html[x] = `<span>${text}</span>`;
            break;
          default:
            break;
        }
      }
      return html.join('');
    }

    function diffMessageItem(message) {
      return `<li>${message}</li>`;
    }

    const { proposal } = this.sItem;
    if (proposal) {
      if (proposal.hasMainFieldsModifications) {
        // TODO check how to use the import for text-diff, I couldn't make it work
        const Diff = require('text-diff');

        const diff = new Diff();
        // produces diff array
        const textDiffs = diff.main(proposal.oldValue, proposal.newValue);
        diff.cleanupSemantic(textDiffs);
        // produces a formatted HTML string
        return diffsToHtml(textDiffs);
      }

      if (proposal.type === 'RELOCATE') {
        return diffMessageItem(this.$translate.instant('proposals.relocated'));
      }

      return diffMessageItem(this.$translate.instant('proposals.onlyMetadata'));
    }

    return '';
  }
}

export const proposalDiffComponent = {
  transclude: true,
  template: require('./proposalDiff.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<',
  },
  controller: proposalDiff,
};
