import { Content, ContentHref } from '@generalTypes/apiTypes';
import { selectContentItem, selectPathToRoot } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector, getAttachmentUrl } from '@newStore/genericHelpers';
import { getGoalIdentifier } from '@newStore/llinkid/llinkidHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import {
  DescriptionNode,
  LegalNode,
  LlinkidGoalNode,
  ParagraphNode,
  PlaceholderNode,
  QuoteNode,
  TermNode,
  ThemeItemNode,
  TitleNode,
  TitleWithDescriptionNode,
} from '../documentUITypes';
import {
  selectEditConfigForNode,
  selectNodeType,
  selectTypeNameSingle,
} from '../nodeTypeConfigSelectors';
import { getIllustration } from './helpers';

export const selectParagraphNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectEditConfigForNode(state, href),
  ],
  (content: Content, editConfig): ParagraphNode => {
    const isStaticHtml = editConfig.some((e) => e.component === 'staticHtml');
    const image = getIllustration(content.attachments, 300);
    return {
      href: content.$$meta.permalink,
      key: content.key,
      text: content.$$html || '',
      type: NodeType.PARAGRAPH,
      image: image
        ? {
            url: getAttachmentUrl(image),
          }
        : null,
      isStaticHtml,
    };
  }
);

export const selectLegalNode = createTypedSelector(
  [(state, href: ContentHref) => selectContentItem(state, href)],
  (content: Content): LegalNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      text: content.$$html || '',
      title: content.title || '',
      type: NodeType.LEGAL,
      customRender: true,
    };
  }
);

export const selectQuoteNode = createTypedSelector(
  [(state, href: ContentHref) => selectContentItem(state, href)],
  (content: Content): QuoteNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      text: content.$$html || '',
      type: NodeType.QUOTE,
      customRender: true,
    };
  }
);

export const selectLlinkidGoalNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectPathToRoot(state, href),
  ],
  (content: Content, nodesToRoot: Content[]): LlinkidGoalNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      type: NodeType.LLINKID_GOAL,
      description: content.description || '',
      attitudinal: content.attitudinal || false,
      llinkidGoalType: content.llinkidGoalType || null,
      identifier: getGoalIdentifier(nodesToRoot) || '',
      mandatory: ['REGULAR', 'EXTRA'].some((goalType) => goalType === content.llinkidGoalType),
      customRender: true,
    };
  }
);

export const selectTitleNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
  ],
  (content, type): TitleNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      title: content?.title || '',
      type,
    };
  }
);

export const selectTitleWithDescriptionNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
  ],
  (content, type): TitleWithDescriptionNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      title: content?.title || '',
      description: content.description || '',
      type,
    };
  }
);

export const selectPlaceholderNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
    (state, href: ContentHref) => selectTypeNameSingle(state, href),
  ],
  (content, type, name): PlaceholderNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      placeholder: name,
      type,
    };
  }
);

export const selectTermNode = createTypedSelector(
  [(state, href: ContentHref) => selectContentItem(state, href)],
  (content: Content): TermNode => {
    const image = getIllustration(content.attachments, 300);
    return {
      href: content.$$meta.permalink,
      key: content.key,
      title: content?.title || '',
      description: content.description || '',
      type: NodeType.TERM,
      image: image
        ? {
            url: getAttachmentUrl(image),
          }
        : null,
    };
  }
);

export const selectThemeItemNode = createTypedSelector(
  [(state, href: ContentHref) => selectContentItem(state, href)],
  (content: Content): ThemeItemNode => {
    const icon = content.attachments?.find((att) => att.type === 'ICON');
    return {
      href: content.$$meta.permalink,
      key: content.key,
      type: NodeType.THEME,
      title: content?.title || '',
      color: content.color || undefined,
      icon: icon ? { url: getAttachmentUrl(icon) } : null,
    };
  }
);

export const selectDescriptionNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
  ],
  (content, type): DescriptionNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      type,
      description: content.description || '',
    };
  }
);
