import { HiddenContentItemConfig } from '@nodeTypeConfig/configTypes';

const SOURCE: HiddenContentItemConfig = {
  information: {
    single: 'Bron',
    plural: 'Bronnen',
  },
  hideInDocument: true,
};

export default SOURCE;
