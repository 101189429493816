import setupStore from './storeSetup';

const store = setupStore();


/**
 * Getter for the store of the application
 * @returns {function}
 */
export const getStore = () => store;

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>; // this doesn't work yet with redux loop in the way.
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
