import {
  openPreview,
  setMode,
  setSelectedDocument,
  updateLastRead,
} from '@newStore/documentUI/documentUIState';
import { updateLastReadNewsletter } from '@newStore/documentApi/documentApiState';
import { selectDocumentRootType } from '@newStore/documentUI/nodeTypeConfigSelectors';
import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';
import * as NOTIFICATION_ACTIONS from '../../reduxLoop/actions/notificationActions';
import { getTitle } from '../../services/types';

require('./structureScreen.scss');
const kovsentry = require('../../app/kovSentry');

class structureScreen {
  constructor(
    $scope,
    $ngRedux,
    $stateParams,
    $state,
    $transitions,
    $translate,
    settings,
    $window,
    $timeout,
    ConfirmationService,
    ModalWindowService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$transitions = $transitions;
    this.$translate = $translate;
    this.$window = $window;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$timeout = $timeout;
    this.settings = settings;
    this.confirmationService = ConfirmationService;
    this.modalWindowService = ModalWindowService;
  }

  async $onInit() {
    kovsentry.startLog('Load Document', {
      'document key': this.$stateParams.key,
    });

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.backToDocumentsListAction());
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.loadDocumentAction(this.$stateParams.key, this.$stateParams.editKey)
    );
    this.$ngRedux.dispatch(setSelectedDocument({ documentKey: this.$stateParams.key }));
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.document.viewModel.loading) {
        if (state.document.proposalsToReview.length === 0) {
          kovsentry.endLog('Accept Suggestions');
        }
        if (!state.document.isRefreshing) {
          kovsentry.endLog('Refresh Newsletter');
        }
        kovsentry.endLog('Save Document');
        kovsentry.endLog('Create Document');
        kovsentry.endLog('Load Document');
      }
      const rootType = selectDocumentRootType(state);
      // const documentValidationStatus = selectDocumentValidationStatus(state);
      return {
        key: state.document.viewModel.document && state.document.viewModel.document.key,
        ...state.document.viewModel,
        ...state.document, // to be removed
        // initialLoadOngoing: !selectIsDocmentFinishedLoading(state), // enable to no longer wait for old VM to be loaded
        titleWithVersion: state.document.viewModel.document
          ? getTitle(state.document.viewModel.document)
          : null,
        // isValid: documentValidationStatus !== 'INVALID' && documentValidationStatus !== 'UNKNOWN',
        isSaving: state.document.viewModel.isValid && state.document.saving.length > 0,
        rootType,
      };
    })(async (selectedState) => {
      Object.assign(this, selectedState);
      if (this.viewModel.napModal && this.viewModel.napModal.open) {
        this.modalWindowService.open({
          component: 'notApplicableProposalsModal',
        });
      }
    });

    window.addEventListener('beforeunload', (event) => {
      if (this.itemsToSave > 0 && this.settings.environment != 'local') {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
      }
    });

    this.transitionUnsb = this.$transitions.onStart(
      {
        exiting: 'edit',
        entering: 'list',
      },
      () => {
        if (this.itemsToSave > 0 && this.settings.environment != 'local') {
          if (!confirm(this.$translate.instant('pageDirty'))) {
            return false;
          }
          setTimeout(() => {
            /*  This timeout ensures the user has navigated to the list screen before
            reseting the store in order to prevent rendering errors #6219583167 */
            this.$ngRedux.dispatch(DOCUMENT_ACTIONS.backToDocumentsListAction());
          }, 0);
          return true;
        }
        setTimeout(() => {
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.backToDocumentsListAction());
        }, 0);
      },
      {
        bind: this,
      }
    );
  }

  $onDestroy() {
    this.unsubscribe();
    this.transitionUnsb();
  }

  save() {
    this.isSaving = true;
    kovsentry.startLog('Save Document', {
      document: this.document.title,
    });
    this.$timeout(() => {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.saveDocumentAction());
    }, 600);
  }

  async removeSelectedNodes() {
    let confirmed = true;
    if (this.confirmDeleteMessages.size > 0) {
      confirmed = await this.confirmationService.confirmDeletion(
        Array.from(this.confirmDeleteMessages).join('<br>')
      );
    }
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.validateAndRemoveSelectionsAction());
    }
  }

  async undoAll() {
    const confirmed = await this.confirmationService.confirm({
      confirmationMessage: 'undo.confirm',
    });
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.undoAllAction());
    }
  }

  termAdded(scope, event, data) {
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.addTermReferenceAction(data.term));
  }

  async submitSuggestions() {
    if (this.suggestions.isValidToSubmit) {
      const opts = {
        template: require('../modals/submitSuggestions/submitSuggestions.html'),
        controller: 'submitSuggestionsModal as ctrl',
      };

      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openSubmitSuggestionsModalAction());

      await this.modalWindowService.open(opts);
    } else {
      this.$ngRedux.dispatch(
        NOTIFICATION_ACTIONS.addNotificationAction({
          type: 'ERROR',
          message: 'proposals.error.invalidToSubmit',
        })
      );
    }
  }

  async openModal() {
    const opts = {
      template: require('../modals/reviewSuggestions/reviewSuggestions.html'),
      controller: 'reviewSuggestionsModal as ctrl',
    };

    await this.modalWindowService.open(opts);
  }

  async publish() {
    const opts = {
      template: require('../modals/publishDate/publishDate.html'),
      controller: 'publishDateModal as ctrl',
      resolve: {
        modalData: () => {
          return {
            type: 'DOCUMENT',
            document: this.document,
          };
        },
      },
    };

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openedPublishModal());

    await this.modalWindowService.open(opts);
  }

  setLastRead() {
    // for pro newsletter this is stored in newsletterSettings which is one shared resource for all users
    // so the lastRead is shared
    // while for all other documents lastRead is for every individual user and saved in private state.
    if (this.rootType === 'PRONEWSLETTER') {
      this.$ngRedux.dispatch(updateLastReadNewsletter());
    } else {
      this.$ngRedux.dispatch(updateLastRead());
    }
  }

  refresh() {
    kovsentry.startLog('Refresh Newsletter', {
      documentName: this.document.title,
      key: this.document.key,
    });
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.refreshNewsletter());
  }

  rejectSuggestions() {
    if (this.suggestions.isAllPendingSuggestionsToReview) {
      this.openModal();
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openReviewSuggestionsModalAction('REJECT'));
    } else {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.rejectSuggestionsAction());
    }
  }

  acceptSuggestions() {
    if (this.suggestions.isAllPendingSuggestionsToReview) {
      this.openModal();
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openReviewSuggestionsModalAction('ACCEPT'));
    } else {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.acceptSuggestionsAction());
    }
  }

  unsubmitSuggestions() {
    if (this.suggestions.isAllPendingSuggestionsToReview) {
      this.openModal();
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openReviewSuggestionsModalAction('CANCEL'));
    } else {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.cancelSuggestionsAction());
    }
  }

  changeSuggestMode() {
    let newMode = 'EDIT';
    if (this.suggestions.isSuggesting) {
      newMode = 'SUGGESTING';
    }
    this.suggestions.isReviewing = false;
    this.$ngRedux.dispatch(setMode(newMode));
  }

  changeReviewMode() {
    let newMode = 'EDIT';
    if (this.suggestions.isReviewing) {
      newMode = 'REVIEWING';
    }
    this.suggestions.isSuggesting = false;
    this.$ngRedux.dispatch(setMode(newMode));
  }

  async previewNewsletter() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openWindowAction(this.$window, this.key));
  }

  async openPreview(previewLocation) {
    if (previewLocation.type === 'URL') {
      this.$ngRedux.dispatch(
        openPreview({
          previewLocation,
          key: this.key,
        })
      );
    }

    if (previewLocation.type === 'ZILL') {
      const opts = {
        component: 'selectCurriculumVersionModal',
        dataForModal: { selecting: false },
      };
      const versionHref = await this.modalWindowService.open(opts);

      this.$window.open(
        previewLocation.location
          .replace('{%key}', this.$stateParams.key)
          .replace('{%version}', versionHref),
        '_blank'
      );
    }
  }

  back() {
    this.$state.go('list');
  }
}

export default {
  template: require('./structureScreen.html'),
  controllerAs: 'ctrl',
  bindings: {},
  controller: structureScreen,
};
