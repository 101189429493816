import { selectExternalResourcesToLoad } from '@newStore/externalData/externalDataToLoadSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  selectApiPendingOldSlice,
  selectApiWithPendingChangesOldSlice,
  selectDocumentTreeForOldSlice,
  selectIsWebpagesFetchedOrNotRequired,
  selectNewDocumentApi,
} from '@newStore/documentApi/documentApiSelectors';
import { RootState } from '@generalTypes/rootStateTypes';

export const selectIsDocumentAndExternalResourcesLoaded = (state: RootState) => {
  return (
    state.documentApi.isContentAndRelationsFetched &&
    state.documentApi.isProposalsFetched &&
    selectIsWebpagesFetchedOrNotRequired(state) &&
    selectExternalResourcesToLoad(state).length === 0
  );
};

export const selectShouldUpdateDocumentViewmodalBeCalled = createTypedSelector(
  [
    (state) => selectDocumentTreeForOldSlice(state),
    (state) => selectNewDocumentApi(state),
    (state) => selectApiPendingOldSlice(state),
    (state) => selectApiWithPendingChangesOldSlice(state),
  ],
  (documentTree, newDocumentApi, apiPending, apiWithPendingChanges) => {
    // this selector will return a new object when any of the input values change
    return {
      documentTree,
      newDocumentApi,
      apiPending,
      apiWithPendingChanges,
    };
  }
);
