import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';

require('./newsItemButtons.scss');

class newsItemButtons {
  constructor($scope, $state, $ngRedux, constants) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$ngRedux = $ngRedux;

    this.teaserLinkOptions = constants.teaserLinkOptions;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (state.document.redirect) {
        this.$state.go(state.document.redirect.path, { key: state.document.redirect.key });
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.clearRedirect());
      }

      return {
        key: state.document.viewModel.document && state.document.viewModel.document.key,
        title: state.document.viewModel.document && state.document.viewModel.document.title,
        linkedTeasers:
          state.document.viewModel.document && state.document.viewModel.document.linkedTeasers,
        attachments:
          state.document.viewModel.document && state.document.viewModel.document.attachments,
        itemsToSave: state.document.viewModel.itemsToSave,
        creators: state.document.viewModel.document && state.document.viewModel.document.creators,
      };
    })(this);
  }

  createTeaser() {}

  goToItem(key) {
    this.$state.go('edit', { key });
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./newsItemButtons.html'),
  controllerAs: 'ctrl',
  bindings: {},
  controller: newsItemButtons,
};
