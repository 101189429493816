import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import {
  selectExternalContent,
  selectPathToRootFromExternalData,
  selectStudyProgrammes,
} from '@newStore/externalData/externalDataSelectors';
import { clearDemarcationLinks, formatVersion } from '@store/helpers/documentHelpers';
import { Content, ContentHref, ContentRelation, ContentType } from '@generalTypes/apiTypes';

import { createTypedSelector } from '@newStore/genericHelpers';
import { StudyProgramme } from '@generalTypes/samTypes';
import { getGoalIdentifier } from './llinkidHelpers';

const emptyArray = [];

export const selectExternalGoalOrItem = createTypedSelector(
  [(state, goalHref: string) => selectPathToRootFromExternalData(state, goalHref)],
  (pathToRoot) => {
    if (!pathToRoot || pathToRoot.length === 0) {
      return undefined;
    }

    const item = pathToRoot[0];
    let descriptionText;
    let identifier;
    if (item.type === ContentType.CURRICULUM_ODET_DEVELOPMENT_GOAL) {
      descriptionText = clearDemarcationLinks(item.title);
    } else {
      descriptionText = clearDemarcationLinks(item.description);
    }
    if (
      item.type === ContentType.LLINKID_GOAL ||
      item.type === ContentType.CURRICULUM_ODET_DEVELOPMENT_GOAL
    ) {
      identifier = getGoalIdentifier(pathToRoot);
    }
    const root = pathToRoot[pathToRoot.length - 1] as Content;
    const version = formatVersion(root.$$version);
    return { key: item.key, type: item.type, descriptionText, version, identifier };
  }
);

export const selectLlinkidGoalsFromRelations = createTypedSelector(
  [
    (state) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state).to[
        `/content/${state.document.viewModel.aside.editDocument.key}`
      ],
    (state) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state).from[
        `/content/${state.document.viewModel.aside.editDocument.key}`
      ],
    (state) => selectExternalContent(state),
    (state, options) => options,
  ],
  (toRelations, fromRelations, externalContent, options) => {
    const relationOrigin = !options.revertedRelationDirection ? 'from' : 'to';
    const relationEnd = !options.revertedRelationDirection ? 'to' : 'from';
    const isRelationTypeRelationIncluded = options.relationTypes.includes('RELATION');

    // get all relations for the current node of the right type and
    let relations = (relationOrigin === 'from' ? fromRelations : toRelations).filter((relation) => {
      return (
        options.relationTypes.includes(relation.relationtype) &&
        externalContent[relation[relationEnd].href] &&
        (!options.toType || options.toType === externalContent[relation[relationEnd].href].type)
      );
    });

    // special case of RELATION relations: get to relations and reverse
    if (isRelationTypeRelationIncluded) {
      const extraRelations = (relationOrigin === 'from' ? fromRelations : toRelations)?.filter(
        (r) =>
          r.relationtype === 'RELATION' &&
          externalContent[r[relationOrigin].href] &&
          (!options.toType || options.toType === externalContent[r[relationOrigin].href].type)
      );

      if (extraRelations) {
        const reversedToRelations = extraRelations.map((r) => ({
          ...r,
          from: r.to,
          to: r.from,
          $$reversed: true,
        })) as ContentRelation[];

        relations = [...relations, ...reversedToRelations];
      }
    }

    return relations;
  }
);

export const selectContentItemStudyProgrammes = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state) => selectStudyProgrammes(state),
  ],
  (contentItem, studyProgrammes): StudyProgramme[] => {
    const studyProgrammesHrefs = contentItem?.applicability?.studyProgrammes?.map(
      (studyProgObj) => studyProgObj.href
    );

    if (studyProgrammesHrefs && studyProgrammesHrefs.length > 0) {
      return studyProgrammesHrefs.map((studyProgHref) => studyProgrammes[studyProgHref]);
    }
    return emptyArray;
  }
);

export const selectPreviousVersionHrefs = createTypedSelector(
  [
    (state, href: ContentHref) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state).from[href],
  ],
  (relations): ContentHref[] => {
    return (
      relations?.filter((relation) => relation.relationtype === 'REPLACES').map((r) => r.to.href) ||
      emptyArray
    );
  }
);

export const selectPreviousVersionContent = createTypedSelector(
  [
    (state, href: ContentHref) => selectPreviousVersionHrefs(state, href),
    (state) => selectExternalContent(state),
  ],
  (previousVersionHrefs, externalContent): Content[] => {
    return previousVersionHrefs.map((href) => externalContent[href]);
  }
);
