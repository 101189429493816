import { getBase64, getImage } from '../../../reduxLoop/helpers/documentHelpers';

class rowImage {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {}

  async $onChanges() {
    if (this.sItem && this.sItem.$$attachments.has('ILLUSTRATION')) {
      const image = this.sItem.$$attachments.get('ILLUSTRATION');
      if (image.original && image.original.file && image.original.file.name) {
        this.imageHref = await getBase64(image.original.file);
      } else {
        this.imageHref = getImage(this.sItem.$$attachments, 'ILLUSTRATION', 800).$$url;
      }
    } else {
      this.imageHref = null;
    }
    this.$scope.$broadcast('$$rebind::rebind');
  }
}

export default {
  template: require('./rowImage.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
  },
  controller: rowImage,
};
