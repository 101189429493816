import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { IApiResouce } from '@generalTypes/sriTypes';
import {
  AsyncKovResourcePicker,
  useLoadSriOptions,
} from '@kathondvla/react-shared-components/src/components';
import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { EditAsideContactsOrCreators } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { searchApi } from '@store/api/apiConfig';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllExternalData } from '@newStore/externalData/externalDataSelectors';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import { PersonOption } from './PersonOption';

const selectSelectedContacts = createTypedSelector(
  [
    (state) => selectAllExternalData(state),
    (state, property) =>
      selectContentItem(state, state.documentUI.currentEditingNode as ContentHref)[property],
  ],
  (externalData, currentValue) => {
    return currentValue?.map((href) => externalData[href]);
  }
);

const selectAreContactsLoaded = createTypedSelector(
  [
    (state) => selectAllExternalData(state),
    (state, property) =>
      selectContentItem(state, state.documentUI.currentEditingNode as ContentHref)[property],
    (state) => state.externalData.failedResources,
  ],
  (externalData, currentValue, failedResources) => {
    return (currentValue || []).every(
      (href) => externalData[href] || failedResources.includes(href)
    );
  }
);

const AsidePersonPicker: React.FC<{
  config: EditAsideContactsOrCreators;
}> = ({ config }) => {
  const sriLoadOptionsFn = useLoadSriOptions({
    sriClient: searchApi,
    href: '/search',
    parameters: config.options.queryParams,
  });

  const [defaultContactsLoaded, setDefaultContactsLoaded] = useState(false);

  const dispatch = useDispatch();

  const currentUsers = useSelector((state: RootState) =>
    selectSelectedContacts(state, config.property)
  );

  const areContactsLoaded = useSelector((state: RootState) =>
    selectAreContactsLoaded(state, config.property)
  );

  useEffect(() => {
    /**
     * default contacts can only be set ONCE
     * if we render the component before all contact persons are in the state,
     * then we are missing values, and further updates to the defaultValue are not reflected
     * so we wait until all contacts are loaded before rendering the component (which sets the default values)
     *
     * the useState and this useEffect is to prevent the component from disappearing when adding a new contact, which causes
     * selectAreContactsLoaded to return false whilst that new contact is loading into the state.
     * so we use a useState to keep the boolean to true once it has been set to true
     */
    if (areContactsLoaded) {
      setDefaultContactsLoaded(true);
    }
  }, [areContactsLoaded]);

  const editHref = useEditHref();

  const readOnly = useReadOnly();

  const change = (contactList: Array<IApiResouce>) => {
    dispatch(
      patchNodeAction(getResourceKey(editHref), {
        [config.property]: contactList.map((c) => c.$$meta.permalink),
      })
    );
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <label className="control-label">{config.label}</label>
      {!defaultContactsLoaded ? (
        <div className="form-group shimmer narrowLine"></div>
      ) : (
        <AsyncKovResourcePicker
          defaultValue={currentUsers}
          onChange={change}
          loadOptions={sriLoadOptionsFn}
          disabled={readOnly}
          optionValue={(option) => option.key}
          placeholder={`Zoek ${config.label.toLowerCase()}`}
          clearable={false}
          multiple={true}
          optionTemplate={(option) => <PersonOption option={option} />}
        />
      )}
    </AsideValidation>
  );
};

export default AsidePersonPicker;

AsidePersonPicker.displayName = 'AsidePersonPicker';

AsidePersonPicker.propTypes = {
  config: any,
};
