import { pathToRootTemplate } from '@store/helpers/documentAsideHelpers';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class asideTeaserLinkedContent {
  constructor($ngRedux, constants, contentApi) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.contentApi = contentApi;
    this.teaserLinkOptions = constants.teaserLinkOptions;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        key: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
        linkedContent: state.document.viewModel.aside.editDocument.linkedContent,
        linkRelation: state.document.viewModel.aside.editDocument.linkRelations.find((l) => l),
      };
    })(this);
  }

  changeLinkedContent(type) {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.setLinkedContentTypeAction(this.key, this.linkedContent.referenceKey, type)
    );
  }

  callAddEditReference(referencedResourceHref) {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.addEditLinkReferenceNodeAction(
        this.key,
        this.linkedContent.referenceKey,
        this.linkedContent.callToAction,
        referencedResourceHref
      )
    );
  }

  changeExternalLink() {
    this.callAddEditReference(this.linkedContent.referencedResourceHref);
  }

  changeLinkedContentHref(e, model) {
    if (model.$$meta) {
      this.callAddEditReference(model.$$meta.permalink);
    }
  }

  changeCallToAction() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.setLinkedContentCallToActionAction(
        this.key,
        this.linkedContent.referenceKey,
        this.linkedContent.callToAction,
        this.linkedContent.referencedResourceHref
      )
    );
  }

  $onDestroy() {
    this.unsubscribe();
  }

  displayName(item) {
    const name = pathToRootTemplate(item);
    if (item && item.identifier) {
      return `${item.identifier} - ${name}`;
    }
    return name;
  }
}

export default {
  template: require('./asideTeaserLinkedContent.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
  },
  controller: asideTeaserLinkedContent,
};
