import { WebSiteHref, WebTemplateHref } from '@generalTypes/apiTypes';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { loadIncomingReferences } from '@newStore/externalData/externalDataState';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
// import { newsItemDeleteValidationCmd } from '../../../reduxLoop/commands/documentListCommands';
import * as apiRoutes from '../../../../reduxLoop/api/apiRoutes';
import { accessRights } from '../../../../reduxLoop/helpers/accessRights';
import { settings } from '../../../settings';
import {
  authorsComponent,
  publishComponent,
  titleComponent,
} from '../../helpers/genericComponents';

const PRONEWSITEM: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'PRO.-nieuwsbericht',
    plural: 'PRO.-nieuwsberichten',
    icon: require('../../../../../img/icons/document.svg'),
    category: 'PRO',
  },
  onLoadActions: [loadWebConfigurations(), loadIncomingReferences()],
  createDefaults: {
    accessRights: accessRights.publiek.namedsets,
    descendantsAccessRights: accessRights.publiek.namedsets,
  },
  webconfiguration: {
    type: 'NEWS_ITEM_AND_TEASER_FALLBACK',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.pro}` as WebSiteHref,
    },
    template: {
      href: '/web/templates/d153f5d5-78b3-4de7-a231-281da633a8bb' as WebTemplateHref,
    },
  },
  createModal: {
    authors: {
      addUser: false,
    },
  },
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.ATTACHMENTS_GROUP },
    { type: NodeType.VIDEO },
    { type: NodeType.QUOTE },
    { type: NodeType.SUMMARY },
    { type: NodeType.EXAMPLE },
    { type: NodeType.WORD_IMPORT },
  ],
  tocTypes: [NodeType.SECTION],
  edit: [
    {
      ...titleComponent,
    },
    authorsComponent,
    publishComponent,
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
};

export default PRONEWSITEM;
