import { debounce, isEqual } from 'lodash';
import { shallowEqual } from 'react-redux';

// isEqual is slow in lodash for Sets
export const isSetEqual = (as, bs) => {
  if (!(as instanceof Set) || !(bs instanceof Set) || as.size !== bs.size) {
    return false;
  }
  for (const a of as) {
    if (!bs.has(a)) {
      return false;
    }
  }
  return true;
};

export const conditionalLogTime = (label, printIfTimeAbove = 0) => {
  const start = performance.now();
  return () => {
    const time = performance.now() - start;
    if (time > printIfTimeAbove) {
      console.log(`${label} took more than ${printIfTimeAbove}ms: ${time}ms`);
    }
  };
};

export const plainEqualWithLog = (functionName) => {
  return (a, b) => {
    const logTimeEnd = conditionalLogTime(`${functionName} plainEqual`, 10);
    const res = a === b;
    logTimeEnd();
    console.log(`${functionName} changed: ${!res}`);
    if (!res) {
      // console.log(diffString(a, b));
      // console.log('before', a, 'after', b);
    }
    return res;
  };
};

export const deepEqualWithLog = (functionName) => {
  return (a, b) => {
    const logTimeEnd = conditionalLogTime(`${functionName} deepEqual`, 10);
    const res = isEqual(a, b);
    logTimeEnd();
    console.log(`${functionName} changed: ${!res}`);
    if (!res) {
      // console.log(diffString(a, b));
      // console.log('before', a, 'after', b);
    }
    return res;
  };
};

export const shallowEqualWithLog = (functionName) => {
  return (a, b) => {
    const logTimeEnd = conditionalLogTime(`${functionName} shallowEqual`, 10);
    const res = shallowEqual(a, b);
    logTimeEnd();
    console.log(`${functionName} changed: ${!res}`);
    return res;
  };
};

const functionLogMap = {};

export const logPerformance = (functionName, functionToLog) => {
  functionLogMap[functionName] = functionLogMap[functionName] || {
    executionTimes: [],
  };

  const debouncedLog = debounce(() => {
    const { executionTimes } = functionLogMap[functionName];
    const callCount = executionTimes.length;
    const totalTimeSpent = executionTimes.reduce((a, b) => a + b, 0);
    const sortedTimes = executionTimes.sort((a, b) => a - b);
    const averageTime = totalTimeSpent / callCount;
    const medianTime = sortedTimes[Math.floor(callCount / 2)];
    const maxTime = sortedTimes.at(-1);

    console.log(
      `[PERF] ${functionName} has been called ${callCount} times. Total time spent: ${totalTimeSpent} ms.`
    );
    if ('recomputations' in functionToLog && 'dependencyRecomputations' in functionToLog) {
      console.log(
        `[PERF] ${functionName} Recomputations: ${functionToLog.recomputations()}, Dependency recomputations: ${functionToLog.dependencyRecomputations()}`
      );
    }
    console.log(
      `[PERF] ${functionName} Average time spent per call: ${averageTime} ms. Median time: ${medianTime} ms. Max time: ${maxTime} ms.`
    );
    functionLogMap[functionName] = {
      executionTimes: [],
    };
    if ('recomputations' in functionToLog) {
      functionToLog.resetRecomputations();
    }
    if ('dependencyRecomputations' in functionToLog) {
      functionToLog.resetDependencyRecomputations();
    }
  }, 5000); // Adjust delay as needed

  return (...args) => {
    const startTime = performance.now();
    const result = functionToLog(...args);
    const endTime = performance.now();
    functionLogMap[functionName].executionTimes.push(endTime - startTime);

    debouncedLog();
    return result;
  };
};
