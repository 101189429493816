import { getOldNodeType } from '@nodeTypeConfig/nodeTypeConfig';
import constants from '../constants/constants';

export const getVersionErrorMsg = (apiError, items) => {
  const errorsToFormat = ['llinkid.curriculum.not.published', 'new.version.already.exists'];
  const errors = apiError?.body?.errors.map((error) => {
    if (errorsToFormat.includes(error.code)) {
      const item = items.find((curr) => curr.key === error.key);
      return { code: `versioning.error.${error.code}`, params: { title: item.$$titleAsHtml } };
    }
    return { code: 'versioning.error.generic' };
  });
  return {
    ...apiError,
    body: {
      ...apiError.body,
      errors: errors || [{ code: 'versioning.error.generic' }],
    },
  };
};

const isValidSelection = (selectedItems, allowedTypes) => {
  if (!selectedItems) {
    return false;
  }

  return (
    selectedItems.length &&
    selectedItems.length <= constants.maxOperationsAllowed &&
    selectedItems.every((item) => allowedTypes.includes(getOldNodeType(item)))
  );
};

export const allowVersionItemSelected = (selectedItems) => {
  return isValidSelection(selectedItems, [constants.llinkidCurriculum]);
};

export const isCloneableItemSelected = (selectedItems, allowedTypes) => {
  return isValidSelection(
    selectedItems,
    allowedTypes.map((o) => o.type)
  );
};
