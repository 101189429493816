import {
  Content,
  AttachmentType,
  Proposal,
  isUploadChange,
  isDeleteUploadChange,
  ContentAttachmentKey,
  AttachmentMetaInfo,
  isPatchChange,
} from '@generalTypes/apiTypes';
import { NodeTypeConfigApplied, isEditAttachmentsComponent } from '@nodeTypeConfig/configTypes';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { DiffMessage } from '../documentUITypes';
import { wrapPropertyLabel } from './proposalHelpers';

/**
 * We are never interested in information about resized attachments
 * Nor are we interested in attachments of type CONTENT, for that we look at the $$html field
 */
export const filterRelevantAttachments = (a: AttachmentMetaInfo) =>
  !('resized' in a && a.resized) && a.type !== 'CONTENT';

const getResizedAttachmentKeys = (
  content: Content,
  rawContent: Content | undefined
): Set<ContentAttachmentKey> => {
  const contentAtt = content.attachments || [];
  const rawContentAtt = rawContent && rawContent.attachments ? rawContent.attachments : [];
  return new Set(
    [...contentAtt, ...rawContentAtt].filter((a) => 'resized' in a && a.resized).map((a) => a.key)
  );
};

type AttachmentsOperationsMap = Partial<Record<AttachmentType, 'ADD' | 'DELETE' | 'REPLACE'>>;
const getAttachmentsOperationsMap = (
  proposal: Proposal,
  content: Content,
  rawContent: Content | undefined
) => {
  const resizedAttachmentKeys = getResizedAttachmentKeys(content, rawContent);
  const operationsMap: AttachmentsOperationsMap = {};
  proposal.listOfRequestedChanges.forEach((c) => {
    if (isUploadChange(c)) {
      const attKey = c.metadata?.key || c.attachment?.key;
      if (resizedAttachmentKeys.has(attKey)) {
        return;
      }
      const attType = c.metadata?.type || c.attachment?.type;

      if (operationsMap[attType]) {
        if (operationsMap[attType] === 'DELETE') {
          operationsMap[attType] = 'REPLACE';
        } else {
          // we should not come here
          console.warn(
            `Strange... there is another upload change for attachment type ${attType} (${operationsMap[attType]})`
          );
        }
      } else {
        operationsMap[attType] = 'ADD';
      }
    } else if (isDeleteUploadChange(c)) {
      const attKey = getResourceKey(c.appliesTo.href) as ContentAttachmentKey;
      if (!attKey || resizedAttachmentKeys.has(attKey)) {
        return;
      }
      const deletedAtt = rawContent?.attachments?.find((a) => a.key === attKey);
      if (!deletedAtt) {
        // we should not come here but need to make Typescript happy
        return;
      }
      const attType = deletedAtt.type;
      // use case where it would already be added should not occur because inverse operations should be removed from proposals
      operationsMap[attType] = 'DELETE';
    } else if (
      isPatchChange(c) &&
      c.patch[0].path === '/attachments' &&
      c.patch[0].value[0].type === 'VIDEO'
    ) {
      operationsMap.VIDEO = 'REPLACE';
    }
  });
  return operationsMap;
};

const attachmentOperationTranslation = {
  ADD: 'toegevoegd',
  DELETE: 'verwijderd',
  REPLACE: 'vervangen',
};
export const getDiffMessagesForAttachments = (
  proposal: Proposal,
  content: Content,
  rawContent: Content | undefined,
  nodeTypeConfig: NodeTypeConfigApplied
): Array<DiffMessage> => {
  const attachmentComponents = nodeTypeConfig.edit.filter(isEditAttachmentsComponent);
  const attachmentsOperations = getAttachmentsOperationsMap(proposal, content, rawContent);
  if (Object.keys(attachmentsOperations).length === 0) {
    // there are no UPLOAD or DELETE_UPLOAD operations on attachments so it is just the metadata (alt, description, ...)
    if (attachmentComponents.length === 1) {
      const component = attachmentComponents[0];
      const propertyLabel = component.label;
      return [
        {
          message: wrapPropertyLabel`${propertyLabel}: metadata aangepast`,
          property: 'attachments',
          attachmentType: component.options.type,
        },
      ];
    }
    // there are multiple components to modify the attachments array so we can not determine which component has changed.
    // We can not generate a very good message so we will not mention this small change
    return [];
  }
  return attachmentComponents.flatMap((component) => {
    const operation = attachmentsOperations[component.options.type];
    if (!operation) {
      return [];
    }
    const propertyLabel = component.label;
    return {
      message: wrapPropertyLabel`${propertyLabel} ${attachmentOperationTranslation[operation]}`,
      property: 'attachments',
      attachmentType: component.options.type,
    };
  });
};
