import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

require('./asideImageGroup.scss');

class asideImageGroup {
  constructor($scope, $ngRedux, ModalWindowService, ConfirmationService) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        images: state.document.viewModel.aside.editDocument.imagesInGroup,
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);
  }

  imageSource(row) {
    return row.$$url;
  }

  proposalLineStyle(row) {
    if (row.proposal) {
      const line = row.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      const color = row.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = row.proposal.isDeleted ? ' is-deleted' : '';
      const isReviewing = row.proposal.isReviewingMode ? ' is-reviewing' : '';
      return `${line} ${color}${isDeleted}${isReviewing}`;
    }
    return '';
  }

  hasDeleteProposal(row) {
    return row.proposal && row.proposal.type === 'DELETE';
  }

  async addImage() {
    const response = await this.modalWindowService.open({ component: 'imageSelectionModal' });

    if (response.selectedRightsHolder) {
      response.rightsHolder = {
        href: response.selectedRightsHolder.$$meta.permalink,
      };
    }

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addImageInGroupAction(this.editKey, response));
  }

  async editImage(imageResource) {
    const opts = {
      component: 'imageSelectionModal',
      dataForModal: {
        image: imageResource.imageAttachment,
      },
    };

    const response = await this.modalWindowService.open(opts);

    if (response) {
      if (response.selectedRightsHolder) {
        response.rightsHolder = {
          href: response.selectedRightsHolder.$$meta.permalink,
        };
      }

      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.addAttachment(
          imageResource.key,
          response,
          response.file,
          null,
          this.sDocument.key
        )
      );
    }
  }

  async deleteImage(imageResource) {
    const confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.removeNodeAction(imageResource.key, true, this.sDocument.key)
      );
    }
  }
}

export default {
  template: require('./asideImageGroup.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<',
  },
  controller: asideImageGroup,
};
