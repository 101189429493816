import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectIsNodeReadOnly } from '@newStore/documentUI/documentUISelectors';
import { useEditHref } from './UseEditHref';

export const useReadOnly = (readonly?: boolean) => {
  const editHref = useEditHref();
  return useSelector((state: RootState) =>
    Boolean(!editHref || readonly || selectIsNodeReadOnly(state, editHref))
  );
};
