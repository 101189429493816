import { RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../../reduxLoop/constants/constants';
import { proThemeAudienceTab } from '../proGenericConfigProps';
import { leerplanSitesThemeComponents } from './leerplansitesGenericConfigProps';
import {
  titleComponent,
  authorsComponent,
  publishComponent,
} from '../../helpers/genericComponents';

export const SHARED_FAQ: RootNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'Gedeelde FAQ (leerplansites so)',
    plural: 'Gedeelde FAQ (leerplansites so)',
    category: 'LEERPLANSITES_SO',
  },
  onLoadActions: [loadReferenceFrame({ referenceFrame: referenceFrames.pro })],
  tocTypes: [],
  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.IMAGE },
    { type: NodeType.VIDEO },
  ],
  edit: [
    {
      ...titleComponent,
      label: 'Vraag',
    },
    {
      ...authorsComponent,
      options: {
        queryParams: constants.allAuthorsParameters,
      },
    },
    publishComponent,
    ...leerplanSitesThemeComponents,
    {
      component: 'themes',
      label: 'Categorie FAQ-groep',
      options: {
        referenceFrameKey: constants.faqCategoriesKey,
        types: [ContentType.THEME],
      },
      valueToString: themeHrefsToString,
    },
  ],
  audienceTab: proThemeAudienceTab,
  createModal: {
    title: {
      label: 'Vraag',
    },
    authors: {
      addUser: false,
      queryParams: constants.allAuthorsParameters,
    },
  },
  isCreatable: true,
  isCloneable: true,
  isSearchable: true,
  allowSuggestions: true,
};
