/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
export const isExternalRelationUniqueInDocument = (permalink, relations) => {
  for (const relation of relations.values()) {
    if (relation.from.href === permalink) {
      return false;
    }
  }
  return true;
};
