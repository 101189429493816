import { isPerson, Person } from '@generalTypes/personApiTypes';
import React from 'react';
import { OrganisationalUnit } from '@generalTypes/samTypes';
// eslint-disable-next-line
import UserIcon from '!!raw-loader!@img/icon_user.svg';
// eslint-disable-next-line
import GroupIcon from '!!raw-loader!@img/icon_people.svg';

import './PersonOption.scss';

type Option =
  | Pick<Person, '$$meta' | 'firstName' | 'lastName'>
  | (Pick<OrganisationalUnit, '$$meta' | '$$displayName'> & { name?: string; $$name?: string });

// why is the type for the person option so messy?
// because we get the initial data from our externalData state slice, and newly selected items come back from search-api, which have a different format.

export const PersonOption: React.FC<{ option: Option }> = ({ option }) => {
  const icon = isPerson(option) ? UserIcon : GroupIcon;
  let title = '';

  if (isPerson(option)) {
    title = `${option.firstName} ${option.lastName}`;
  } else if ('$$displayName' in option) {
    title = option.$$displayName;
  } else if ('name' in option) {
    title = option.name as string;
  } else if ('$$name' in option) {
    title = option.$$name as string;
  }

  return (
    <>
      {option.$$meta ? (
        <div className="person-option">
          <span dangerouslySetInnerHTML={{ __html: icon }}></span>
          <div>
            <div className="title">{title}</div>
          </div>
        </div>
      ) : (
        <div>
          <div className="subtitle">{title}</div>
        </div>
      )}
    </>
  );
};
