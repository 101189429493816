import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';

const WORD_IMPORT: BuildingBlockNodeConfig = {
  information: {
    single: 'Importeren',
    plural: 'Importeren',
    ribonIcon: require('../../../../img/buildingBlocks/WORD_IMPORT.svg'),
  },
  buildingBlocks: [],
  edit: [],
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default WORD_IMPORT;
