import { patchNodeAction } from '@store/actions/documentActions';
import { Editor as ReactEditor } from '@tinymce/tinymce-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// TinyMCE so the global var exists
import tinymce, { Editor, EditorEvent, RawEditorOptions } from 'tinymce/tinymce';
// DOM model

import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
// Content styles, including inline UI like fake cursors
import { RootState } from '@generalTypes/rootStateTypes';
import { useHoverIntent } from 'react-use-hoverintent';
import { InlineField } from '@nodeTypeConfig/configTypes';

// eslint-disable-next-line import/extensions
import './Editor.css';

import {
  selectEditorAutofocusField,
  selectIsNodeReadOnly,
} from '@newStore/documentUI/documentUISelectors';
import { selectGenericDocumentRootConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { ContentHref } from '@generalTypes/apiTypes';
import { config as fieldConfig } from '@store/constants/editor';
import './MarkButton';
import MarkPopover from './MarkPopover';
import './TermButton';
import TermPopover from './TermPopover';
import './CustomLink';
import { pasteHtml } from './editorHelpers';

const saveOnViewportExit = false;

const InlineEditor: React.FC<{
  initial: string | undefined;
  field: InlineField;
  skey: string;
  href: ContentHref;
  placeholder: string;
  readOnly?: boolean;
}> = ({ initial, field, skey, href, placeholder, readOnly = false }) => {
  const dispatch = useDispatch();
  const [editor, setEditor] = useState<Editor | null>(null);
  const rowRef = useRef<HTMLDivElement>(null);

  const [isHovering, intentRef, setIsHovering] = useHoverIntent<HTMLDivElement>({
    // isHovering returns true once the user has intent to click.
    // it remains true even when the user leaves.
    timeout: 100,
    sensitivity: 30,
    interval: 30,
  });
  const isReadOnly = useSelector((state: RootState) => selectIsNodeReadOnly(state, href));
  const customEditorOptions = useSelector(
    (state: RootState) => selectGenericDocumentRootConfig(state)?.customEditorOptions
  );
  const hasTerm =
    !customEditorOptions?.hideTermButton && fieldConfig[field].customButtons.includes('term');
  const hasMark =
    customEditorOptions?.showMarkerButton && fieldConfig[field].customButtons.includes('marker');

  const config: RawEditorOptions & { selector?: undefined; target?: undefined } = useMemo(() => {
    // eslint-disable-next-line no-unused-expressions
    tinymce; // used to make sure tinymce exists ( bundler )
    let toolbar = 'bold italic subscript superscript | removeformat undo redo | ';
    if (!fieldConfig[field].reducedToolbar) {
      toolbar += 'numlist bullist | customLink unlink | table | ';
    }
    if (hasTerm) {
      toolbar += 'term ';
    }
    if (hasMark) {
      toolbar += 'mark ';
    }
    return {
      height: 500,
      menubar: false,
      plugins: ['anchor', 'link', 'lists', 'table', 'term', 'mark', 'customLink'],
      inline: true,
      skin: false,
      content_css: false,
      contextmenu: false,
      convert_urls: false,
      link_quicklink: true,
      license_key: 'gpl',
      table_cell_advtab: true,
      ui_mode: 'split',
      invalid_styles: {
        td: 'white-space position font-family font-size font mso-ignore mso-font-charset mso-number-format mso-background-source mso-rotate mso-pattern mso-protection',
      },
      table_default_styles: {
        'min-width': '50%',
        'max-width': '100%',
        display: 'table',
      },
      table_sizing_mode: 'relative',
      object_resizing: true,
      table_resize_bars: false,

      placeholder,
      toolbar,
    };
  }, [field, hasMark, hasTerm, placeholder]);

  const shouldAutoFocus = useSelector(
    (state: RootState) => selectEditorAutofocusField(state, href) === field
  );

  useEffect(() => {
    if (shouldAutoFocus) {
      setIsHovering(true);
    }
  }, [shouldAutoFocus, setIsHovering]);

  useEffect(() => {
    const node = rowRef.current;
    if (node && saveOnViewportExit) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            console.log('inlineEdit has scrolled out of the window.');
            if (editor) {
              const dirty = editor.isDirty();
              console.log('dirty', dirty);
              if (dirty) {
                onBlur();
              }
            }
            // Trigger your event here...
          }
        },
        {
          root: null, // observing intersections with respect to the viewport
          threshold: 0, // callback will run when inlineEdit is not visible
        }
      );

      observer.observe(node);

      // Cleanup function to disconnect the observer
      return () => {
        observer.disconnect();
      };
    }
    return undefined;
  }, [isHovering]);

  const onBlur = () => {
    if (!editor || !editor.isDirty()) {
      return;
    }
    editor.setDirty(false);
    const patch = {
      [field]: editor.getContent(),
    };
    dispatch(patchNodeAction(skey, patch));
  };

  // disables following links when clicking in editor
  const onClick = (event: EditorEvent<MouseEvent>) => {
    const target = event.target as HTMLElement;
    if (target?.matches('a')) {
      event.preventDefault();
    }
  };

  const renderEditor = isHovering && !isReadOnly;

  return (
    <div className={`inlineEdit ${isReadOnly ? 'readonly' : ''}`} ref={rowRef}>
      {renderEditor ? (
        <>
          {hasTerm && <TermPopover editor={editor} />}
          {hasMark && <MarkPopover editor={editor} />}
          <ReactEditor
            scriptLoading={{ delay: 250 }}
            init={config}
            disabled={readOnly}
            onInit={(evt, newEditor: Editor) => {
              console.log('tinymce init', skey);
              setEditor(newEditor);
              if (shouldAutoFocus) {
                newEditor.focus();
              }
            }}
            onBlur={onBlur}
            onClick={onClick}
            onDragOver={() => {
              // Prevents showing the toolbar when dragging to reorder the content
              setIsHovering(false);
            }}
            onPaste={(evt) => pasteHtml(evt, editor)}
            initialValue={initial}
          />
        </>
      ) : (
        <div id={skey} ref={intentRef}>
          {initial ? (
            <div dangerouslySetInnerHTML={{ __html: initial }} />
          ) : (
            <div className="placeholder">{placeholder}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default InlineEditor;
