import uuidv4 from 'uuid/v4';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { getBase64 } from '../../../reduxLoop/helpers/documentHelpers';

class asideAttachmentsUpload {
  constructor($ngRedux, constants) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.constants = constants;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        attachment: state.document.viewModel.aside.editDocument.downloadAttachment,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  attachmentRemoved(scope) {
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.removeAttachment(scope.ctrl.sDocument.key, scope.ctrl.attachment.key)
    );
  }

  async attachmentChanged(scope, event, data) {
    const base64 = await getBase64(data.file);
    const newAttachment = {
      key: scope.ctrl.attachment ? scope.ctrl.attachment.key : uuidv4(),
      type: data.type,
      name: data.file.name,
      size: data.file.size,
      $$base64: base64,
      isNew: true,
      created: new Date(),
      contentType: data.file.type,
    };

    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.addAttachment(scope.ctrl.sDocument.key, newAttachment, data.file)
    );
  }
}

export default {
  template: require('./asideAttachmentsUpload.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<',
  },
  controller: asideAttachmentsUpload,
};
