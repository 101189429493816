import { RootState } from '@generalTypes/rootStateTypes';
import { selectApiWithPendingChangesRelationsToAndFromMap } from '@newStore/documentApi/documentApiSelectors';
import { selectReferenceFrameContent } from '@newStore/referenceFrames/referenceFramesSelectors';
import { EditAsideReferenceFrameReferences } from '@nodeTypeConfig/configTypes';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideLlinkidReferenceItem from '../asideLlinkidReferenceItem/asideLlinkidReferenceItem';
import AsideRelationsPanel from '../asideRelationsPanel/AsideRelationsPanel';
import { openLlinkidReferenceModal } from './openLlinkidReferenceModal';
import { useDeleteRelationHook } from '../asideRelationsPanel/useDeleteRelationHook';

const AsideReferenceFrameReferences: React.FC<{
  config: EditAsideReferenceFrameReferences;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const onDelete = useDeleteRelationHook();

  const currentEditingHref = useEditHref();

  const relationOrigin = !config.options.revertedRelationDirection ? 'from' : 'to';
  const relationEnd = !config.options.revertedRelationDirection ? 'to' : 'from';

  const allRelations = useSelector(
    (state: RootState) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state)[relationOrigin][currentEditingHref]
  );

  const referenceFrameContent = useSelector((state: RootState) =>
    selectReferenceFrameContent(state, config.options.referenceFrame)
  );

  const readOnly = useReadOnly();

  const relevantRelations = allRelations.filter(
    (relation) =>
      config.options.relationTypes.some((z) => z === relation.relationtype) &&
      referenceFrameContent[relation[relationEnd].href]
  );

  const onCreate = async () => {
    const response = await openLlinkidReferenceModal({
      asideOptions: config.options,
      relations: relevantRelations,
    });

    if (response) {
      const newRelation = {
        relationtype: config.options.relationTypes[0],
        strength: response.strength,
        from: {
          href: response.$$meta.permalink,
        },
        to: {
          href: currentEditingHref,
        },
      };

      dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation));
    }
  };

  const onEdit = async (relation) => {
    const response = await openLlinkidReferenceModal({
      asideOptions: config.options,
      relations: relevantRelations,
      item: { relation, item: referenceFrameContent[relation[relationEnd].href] },
    });

    if (response) {
      dispatch(
        DOCUMENT_ACTIONS.patchRelationAction(relation.key, {
          from: { href: response.$$meta.permalink },
          strength: response.strength,
        })
      );
    }
  };

  // here we show the replaces relations for things like pedagogical tips, initial situation, ...
  return (
    <AsideRelationsPanel
      title={config.label}
      items={relevantRelations}
      readOnly={readOnly}
      onCreate={onCreate}
      onDelete={onDelete}
      onEdit={onEdit}
    >
      {(item) => (
        <AsideLlinkidReferenceItem
          href={item[relationEnd].href}
          options={config.options}
          rel={item}
        />
      )}
    </AsideRelationsPanel>
  );
};

AsideReferenceFrameReferences.displayName = 'AsideReferenceFrameReferences';

AsideReferenceFrameReferences.propTypes = {
  config: any,
};

export default AsideReferenceFrameReferences;
