import { Content } from '@generalTypes/apiTypes';
import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { GlobalDocumentNode } from '../documentUITypes';
import { settings } from '../../../config/settings';

export const selectGlobalDocumentNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): GlobalDocumentNode => {
    const contentAttachment = content.attachments.find(
      (attachment) => attachment.type === 'CONTENT'
    );
    const previewPages = content.attachments
      .filter((attachment) => attachment.type === 'CONTENT_PREVIEW')
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .map((attachment) => ({
        key: attachment.key,
        src: settings.apisAndUrls.contentApi + attachment.href,
      }));

    return {
      href: content.$$meta.permalink,
      key: content.key,
      title: content.title,
      attachmentName: contentAttachment?.name,
      downloadUrl:
        contentAttachment?.href &&
        `${settings.apisAndUrls.proWebsite}/download${contentAttachment.href}`,
      embedUrl: contentAttachment?.href && settings.apisAndUrls.contentApi + contentAttachment.href,
      isPdf: contentAttachment?.contentType === 'application/pdf',
      previewPages,
      type: NodeType.GLOBAL_DOCUMENT,
      customRender: true,
    };
  }
);
