import uuidv4 from 'uuid/v4';
import { getBase64 } from '../../../../reduxLoop/helpers/documentHelpers';

class attachmentModal {
  constructor($notification, $translate, $ngRedux, $scope) {
    'ngInject';

    this.notificationService = $notification;
    this.translationService = $translate;
    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
  }

  hasError() {
    return this.attachmentFile === undefined && !this.selectedGlobalDocument;
  }

  emptyTitle() {
    return (
      this.attachmentFile &&
      (!this.localUpload || !this.localUpload.title) &&
      !this.selectedGlobalDocument
    );
  }

  attachmentRemoved() {
    // this.attachmentFile = undefined;
  }

  tagChanged(scope, event, data) {
    scope.ctrl.localUpload.tags = [data];
    scope.ctrl.type = 'upload';
  }

  globalDocumentSelected(e, model) {
    this.selectedGlobalDocument = model;
    if (model) {
      this.type = 'global';
      delete this.attachmentFile;
      this.localUpload = {
        tags: [],
      };
    }
  }

  changeAttachmentType(type) {
    this.type = type;
  }

  async attachmentChanged(scope, event, data) {
    const base64 = await getBase64(data.file);
    if (
      scope.ctrl.localUpload &&
      scope.ctrl.localUpload.key &&
      scope.ctrl.attachmentFile &&
      scope.ctrl.attachmentFile.name !== data.file.name &&
      scope.ctrl.attachmentFile.originalName !== data.file.name
    ) {
      scope.ctrl.changedFileName = true;
    }

    const newAttachment = {
      key: uuidv4(),
      type: data.type,
      name: data.file.name,
      size: data.file.size,
      $$base64: base64,
      isNew: true,
      created: new Date(),
      contentType: data.file.type,
    };

    scope.ctrl.attachmentFile = data.file;

    scope.ctrl.localUpload = scope.ctrl.localUpload || {};
    scope.ctrl.localUpload.attachments = [newAttachment];

    scope.ctrl.selectedGlobalDocument = null;
    scope.ctrl.type = 'upload';

    scope.$apply();
  }

  async submit() {
    if (this.hasError()) {
      this.notificationService.error(
        this.translationService.instant('labels.attachment.errors.noAttachment')
      );
    } else if (this.emptyTitle()) {
      this.notificationService.error(
        this.translationService.instant('labels.attachment.errors.emptyTitle')
      );
    } else {
      this.modalInstance.close({
        attachment: this.selectedGlobalDocument || this.localUpload,
        file: this.attachmentFile,
        globalDocument: this.selectedGlobalDocument,
      });
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  $onInit() {
    this.sConfig = this.resolve.modalData.sConfig;
    this.attachment = this.resolve.modalData.sAttachment ? this.resolve.modalData.sAttachment : {};
    this.attachmentFile = undefined;
    this.localUpload = {};

    if (this.attachment.attachments && this.attachment.attachments.length > 0) {
      if (this.attachment.$$relation.relationtype === 'IS_INCLUDED_IN') {
        this.selectedGlobalDocument = this.attachment;
        this.type = 'global';
      } else {
        this.localUpload = this.attachment;
        this.attachmentFile = this.attachment.attachments.find((a) => a.type === 'ATTACHMENT');
        this.type = 'upload';
      }
    } else {
      this.type = 'upload'; // default
    }
    this.globalDocumentAllowed = this.sConfig.globalDocumentAllowed;
  }
}

export default {
  template: require('./attachmentModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: attachmentModal,
};
