import { selectDocumentsRelatedToTerm } from '@newStore/documentUI/transformContent/termSelectors';
import { useSelector } from 'react-redux';

const spanStyle = {
  cursor: 'default',
};

const DocumentsRelatedToTerm: React.FC = () => {
  const documents = useSelector(selectDocumentsRelatedToTerm);
  return (
    documents.length > 0 && (
      <div id="termsList">
        <div className="title">Gebruikt in </div>
        <div className="content-row no-border">
          {documents.map((document) => (
            <div key={document.key}>
              <a
                target="_blank"
                href={document.redactieLink}
                className="content-row-title"
                rel="noreferrer"
              >
                {document.title}
              </a>
              <span
                style={spanStyle}
                title={`Komt ${document.count} maal voor in ${document.title}`}
              >
                {document.count > 1 && ` (${document.count}x)`}
              </span>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default DocumentsRelatedToTerm;
