import { HiddenContentItemConfig } from '@nodeTypeConfig/configTypes';
/**
 * Not a real NodeType because always hidden
 * Can be child of REFERENCE_GROUP or can be child of LINK_GROUP (in which case information.single should be 'Link' but we don't have a mechanism for that...)
 * TODO investigate if this can be removed. Why do we need it?
 *
 * answer: sometimes REFERENCE is just a child of a paragraph or a section, it seems, in old documents like VISITEKST
 * see:
 * http://localhost:8085/edit/173b5727-4eed-456b-9c25-f8a4e1f453de/61d863ab-bbd1-455c-9f37-af724d4ea9c8
 * http://localhost:8085/edit/194b5727-4eb9-456b-9c25-f8a4e1f4678d/07bd6f2a-9ca7-4d63-aafa-288b7ad89c6c
 * http://localhost:8085/edit/62153b2c-05b5-41ef-8cf6-65c6b9529488/c738c12e-91e4-4089-956b-ad5b95a2af79
 */
export const REFERENCE: HiddenContentItemConfig = {
  information: {
    single: 'Verwijzing',
    plural: 'Verwijzingen',
  },

  hideInDocument: true, // needed for VISIETEKST which has REFERENCE children to random nodes, something that you can't even recreate today. attachmentGroup has property hideChildrenInDocument this is for LINK_GROUP and SOURCE: things that are hidden, not because of their parent but because of themself
};
