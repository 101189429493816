import { createTypedSelector } from '@newStore/genericHelpers';
import { orderBy } from 'lodash';

import { RootState } from '@generalTypes/rootStateTypes';
import { selectNewsletterSendersArray } from '@newStore/externalData/externalDataSelectors';
import { selectCurrentEditingNode } from '@store/selectors/asideSelectors';

export const selectIsNewsletterSenderComponentLoading = (state: RootState) =>
  selectNewsletterSendersArray(state).length === 0;

export const selectNewsletterSenderOptions = createTypedSelector(
  [(state) => selectNewsletterSendersArray(state)],
  (newsletterSenders) => {
    return orderBy(newsletterSenders, 'department').map((sender) => ({
      key: sender.key,
      href: sender.$$meta.permalink,
      display: `${sender.department} - ${sender.name} <${sender.email}>`,
    }));
  }
);

export const selectSelectedNewsletterSender = createTypedSelector(
  [selectCurrentEditingNode, selectNewsletterSenderOptions],
  (currentNode, senderOptions) =>
    senderOptions.find((o) => o.href === currentNode.newsletterSender?.href)
);
