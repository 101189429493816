import { VideoGroupNode } from '@newStore/documentUI/documentUITypes';

const VideoGroupRow: React.FC<{ contentNode: VideoGroupNode }> = ({ contentNode }) => {
  return (
    <div className="images-group center">
      {contentNode.videos.map((videoContent) => (
        <div
          className={`image ${videoContent.isDeletedByProposal ? 'deleted-hidden-child' : ''}`}
          key={videoContent.key}
        >
          <iframe
            width="320"
            height="193"
            frameBorder="0"
            allowFullScreen
            style={{ marginBottom: '10px', maxWidth: '100%' }}
            src={videoContent.videoSrc}
          ></iframe>
        </div>
      ))}
    </div>
  );
};

export default VideoGroupRow;
