/* eslint-disable func-names */
module.exports = [
  '$compile',
  function ($compile) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        element.html(scope.$eval(attrs.compileInline));
        $compile(element.contents())(scope);
      },
    };
  },
];
