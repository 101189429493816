import { newsHashKey } from '@store/constants/constants';
import uuidv4 from 'uuid/v4';
import { contentApi, mailerApi, proposalApi } from '@store/api/apiConfig';
import { Person } from '@generalTypes/personApiTypes';
import { BatchRequest } from '@generalTypes/sriTypes';
import { settings } from '../../config/settings';

export const copyContent = async (keys: string[]) => contentApi.post('/content/copy', { keys });

/* to create a new version of CURRICULUM_ZILL use the script createNewZillVersion in content-api, as it will timeout here */
export const newVersion = async (keys: string[]) =>
  contentApi.post('/content/newVersion', { keys });

export const updateNewsHash = async () => {
  const hash = {
    $$meta: { permalink: `/content/hashes/${newsHashKey}` },
    key: newsHashKey,
    hash: uuidv4(),
    title: `hash for pronewsitems and teasers on ${new Date().toISOString()}`,
  };
  return contentApi.put(`/content/hashes/${newsHashKey}`, hash);
};

export const sendPublishContent = async (
  resources,
  publishDate,
  message,
  locationUrl,
  creator: Person
) => {
  const batch: BatchRequest = [];
  let isPublishProposal = false;

  resources.forEach((resource) => {
    if (resource.$$typeConfig.allowSuggestions) {
      // submit a publish proposal that needs to be accepted
      const key = uuidv4();

      const change = {
        type: 'PATCH',
        appliesTo: { href: `/content/${resource.key}` },
        creator: { href: creator.$$meta.permalink },
        patch: [
          {
            op: 'add',
            path: '/issued',
            value: new Date(publishDate).toISOString(),
          },
        ],
      };

      const proposal = {
        key,
        status: 'SUBMITTED_FOR_REVIEW',
        creators: [creator.$$meta.permalink],
        expandedCreators: [creator],
        externalReferences: [`/content/${resource.key}`],
        listOfRequestedChanges: [change],
        $$meta: { permalink: `/proposals/${key}` },
      };

      batch.push({
        verb: 'PUT',
        href: `/proposals/${proposal.key}`,
        body: proposal,
      });

      isPublishProposal = true;
    } else {
      const resourceWithDate = {
        ...resource,
        issued: new Date(publishDate).toISOString(),
      };

      batch.push({
        verb: 'PUT',
        href: `/content/${resourceWithDate.key}`,
        body: resourceWithDate,
      });
    }
  });

  if (isPublishProposal) {
    await proposalApi.put('/proposals/batch', batch);
    sendPublishedMails(resources, message, locationUrl, creator);
  } else {
    await contentApi.put('/content/batch', batch);
  }

  return batch.map((b) => b.body);
};

const sendPublishedMails = async (
  publishingDocuments,
  publishMessage,
  locationUrl,
  creator: Person
) => {
  const emailjobs: Array<any> = [];

  publishingDocuments.forEach((document, index) => {
    const emailBody = `${publishMessage}<p>
        <a href="${locationUrl.replace('/documents', `/edit/${document.key}`)}">
          (${document.$$typeConfig.information.single}) ${document.title}
        </a>
        <p><br>Gebruiker die verzond voor publicatie: 
          ${creator.lastName} ${creator.firstName}`;

    const emailjob = {
      ...settings.emails.template,
      ...settings.emails.publishDocument,
      recipients: [
        {
          emailAddress: settings.emails.tagAddressMap.get(document.tags[0]),
          ...(index === 0 && {
            mergeVariables: [
              {
                name: 'EMAIL',
                content: emailBody,
              },
            ],
          }),
        },
      ],
      key: uuidv4(),
      subject: `(${document.$$typeConfig.information.single}) ${document.title}`,
      replyTo: creator.$$email,
    };
    emailjobs.push(emailjob);
  });

  return Promise.all(
    emailjobs.map((emailjob) => mailerApi.put(`/mailer/emailjobs/${emailjob.key}`, emailjob))
  );
};
