import { LlinkidGoalNode } from '@newStore/documentUI/documentUITypes';
import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import InlineEditor from '@UI/editor/InlineEditor';
import './llinkidGoalRow.scss';
import { getPlaceHolderLabel } from '../../contentRowHelpers';

const LlinkidGoalRow: React.FC<{ contentNode: LlinkidGoalNode }> = ({ contentNode }) => {
  const nodeTypeConfig = useSelector((state: RootState) =>
    selectAppliedNodeConfig(state, contentNode.href)
  );

  let imageSourceSrc;
  if (contentNode.llinkidGoalType === 'DEEPENING') {
    imageSourceSrc = '/images/icon_deepening.svg';
  } else if (contentNode.llinkidGoalType === 'CHOICE') {
    imageSourceSrc = '/images/icon_choice.svg';
  } else if (contentNode.llinkidGoalType === 'EXTRA') {
    imageSourceSrc = '/images/icon_extra.svg';
  }

  return (
    <div className="llinkid-goal">
      <div className="identifier">
        <div>{contentNode.identifier}</div>
        {imageSourceSrc && (
          <div
            className={`goal-identifier-image${
              contentNode.llinkidGoalType === 'DEEPENING' ? ' deepening' : ''
            }`}
          >
            <img src={imageSourceSrc} height="30" width="30" />
          </div>
        )}
      </div>

      <div className={`divider${contentNode.mandatory ? ' divider-mandatory' : ''}`}></div>

      {contentNode.attitudinal && (
        <div className="attitudinal">
          <div>
            <img src="/images/icon_attitude.svg" height="10" width="10" />
          </div>
        </div>
      )}
      <div className="description">
        <InlineEditor
          initial={contentNode.description}
          field="description"
          placeholder={getPlaceHolderLabel(nodeTypeConfig, 'description')}
          skey={contentNode.key}
          href={contentNode.href}
        />
      </div>
    </div>
  );
};

export default LlinkidGoalRow;
