class rowLeerlijnReference {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        referenceRelations: this.sItem.referenceRelationsFrom,
      };
    })(this);
  }

  $onChanges() {}

  $onDestroy() {
    this.unsubscribe();
  }

  referenceTitle(reference) {
    return reference.title;
  }

  getReferenceIdentifier(reference) {
    if (reference.$$prefix) {
      return reference.$$prefix;
    }
    return '';
  }

  referenceColor(reference) {
    return {
      color: reference.$$color,
    };
  }
}

export const rowLeerlijnReferenceComponent = {
  template: require('./rowLeerlijnReference.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
  },
  controller: rowLeerlijnReference,
};
