import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

import './rowLlinkidGoal.scss';

class rowLlinkidGoal {
  constructor(constants, settings, $ngRedux) {
    'ngInject';

    this.constants = constants;
    this.settings = settings;
    this.$ngRedux = $ngRedux;
  }

  getSubgoalStyle() {
    return this.sItem.$$type === this.sItem.$$parent.$$type ? 'llinkid-subgoal' : '';
  }

  imageSources() {
    const sources = [];
    const { llinkidGoalType } = this.sItem;

    if (llinkidGoalType === 'DEEPENING') {
      sources.push(require('../../../../img/icon_deepening.svg'));
    } else if (llinkidGoalType === 'CHOICE') {
      sources.push(require('../../../../img/icon_choice.svg'));
    } else if (llinkidGoalType === 'EXTRA') {
      sources.push(require('../../../../img/icon_extra.svg'));
    }

    return sources;
  }

  mandatory() {
    const { llinkidGoalType } = this.sItem;
    const mandatory = !!(llinkidGoalType === 'REGULAR' || llinkidGoalType === 'EXTRA');

    return mandatory;
  }

  getPrefixToShow() {
    if (this.sItem.$$prefix && this.sItem.identifiers && this.sItem.identifiers.length > 0) {
      const isSubgoal = this.sItem.$$type === this.sItem.$$parent.$$type;
      const prefix = this.sItem.$$prefix.slice(
        0,
        this.sItem.$$prefix.length - this.sItem.identifiers[0].length
      );

      if (isSubgoal) {
        const rootPrefix = this.sItem.$$root.$$prefix;
        return prefix.replace(rootPrefix, `${rootPrefix}&nbsp;&nbsp;`);
      }

      return `${prefix}&nbsp;&nbsp;`;
    }
    return '';
  }

  saveInline(scope, event, editorData) {
    const patch = {
      [editorData.field]: editorData.value,
    };
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(editorData.key, patch));
  }
}

export const rowLlinkidGoalComponent = {
  template: require('./rowLlinkidGoal.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
  },
  controller: rowLlinkidGoal,
};
