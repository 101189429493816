import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';

const REFERENCE_FRAME: RootNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'Ordeningskader',
    plural: 'Ordeningskaders',
    category: 'GENERAL',
  },

  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [{ type: NodeType.THEME }],
  tocTypes: [NodeType.THEME],
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      label: 'Titel',
    },
  ],

  isSearchable: true,
  isCreatable: true,
  allowSuggestions: false,
};

export default REFERENCE_FRAME;
