import { isAgeRangeFacetRequired } from '../../../../services/proWebsite/configurationsFacets';

require('./ageRange.scss');

class AgeRange {
  constructor($scope, ProWebsiteConfigurationsService, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.showAgeRange = false;
    this.configurationsService = ProWebsiteConfigurationsService;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.start = this.sStartage ? this.sStartage.value : undefined;
    this.end = this.sEndage ? this.sEndage.value : undefined;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const startProposal = state.document.viewModel.aside.editDocument.startage
        ? state.document.viewModel.aside.editDocument.startage
        : {};

      const endProposal = state.document.viewModel.aside.editDocument.endage
        ? state.document.viewModel.aside.editDocument.endage
        : {};

      return {
        startProposal,
        endProposal,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  ageHasChanged() {
    this.$scope.$emit('ageRangeChanged', {
      startage: this.start,
      endage: this.end,
    });
  }

  $onChanges(values) {
    if (values.sConfigurations && values.sConfigurations.currentValue) {
      this.sConfigurations = values.sConfigurations.currentValue;
      this.showAgeRange = isAgeRangeFacetRequired(this.sConfigurations);
    }

    if (values.sStartage) {
      // this.sStartage = values.sStartage.currentValue;
    }

    if (values.sEndage) {
      // this.sEndage = values.sEndage.currentValue;
    }
  }
}

export default {
  template: require('./ageRange.html'),
  controllerAs: 'ctrl',
  bindings: {
    sStartage: '<',
    sEndage: '<',
    sConfigurations: '<',
    sDisabled: '<',
  },
  controller: AgeRange,
};
