import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object } from 'prop-types';

import AsideCurriculumSelectorButton from './button/AsideCurriculumSelectorButton';
import AsideCurriculumSelectorModal from './modals/AsideCurriculumSelectorModal';

import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';

const AsideCurriculumSelector = ({ options }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const editKey = useSelector((state) => state.document.viewModel.aside.editDocument.key);
  const nodeThemes =
    useSelector((state) => state.document.viewModel.aside.editDocument.themes?.value) || [];
  const curriculumThemesSelected = useSelector((state) =>
    state.document.viewModel.aside.editDocument.selectedThemes
      .filter((t) => {
        return options?.filter ? options.filter(t) : true;
      })
      .map((l) => l.$$meta.permalink)
  );

  const openCurriculumSelectorModal = async (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const submitCurriculumSelectorModal = (data) => {
    if (data) {
      const dataHref = data.map((o) => o.href);
      const nonCurriculumThemesSelected = nodeThemes.filter(
        (x) => !curriculumThemesSelected.includes(x)
      );
      const newSelection = nonCurriculumThemesSelected.concat(dataHref);
      dispatch(DOCUMENT_ACTIONS.patchThemes(editKey, newSelection));
    }

    setIsModalOpen(false);
  };

  return (
    <div>
      <AsideCurriculumSelectorButton
        icon="glyphicon-add"
        label="edit.curriculumSelectorButton"
        onClick={(event) => openCurriculumSelectorModal(event)}
      />

      {isModalOpen && (
        <AsideCurriculumSelectorModal
          handleSubmit={submitCurriculumSelectorModal}
          handleClose={() => setIsModalOpen(false)}
          selectedThemes={curriculumThemesSelected}
        />
      )}
    </div>
  );
};

AsideCurriculumSelector.propTypes = {
  options: object,
};

export default AsideCurriculumSelector;
