import { ContentHref } from '@generalTypes/apiTypes';
import { selectReferenceDetails } from '@newStore/documentUI/transformContent/contentNodeReferences';
import { useSelector } from 'react-redux';

import './ContentReferencesLinks.scss';
import { RootState } from '@generalTypes/rootStateTypes';

const ContentReferencesLinks: React.FC<{ contentNodeHref: ContentHref }> = ({
  contentNodeHref,
}) => {
  const referenceNodes = useSelector((state: RootState) =>
    selectReferenceDetails(state, contentNodeHref)
  );
  return (
    referenceNodes.length > 0 && (
      <div id="content-references-link">
        {referenceNodes.map((referenceNode, index) => (
          <div
            key={`${index}_${referenceNode.referenceLink}`}
            className="reference-link clear justify"
          >
            <span className="reference">
              <a
                href={referenceNode.referenceLink}
                target="_blank"
                rel="noreferrer"
                title={referenceNode.title}
              >
                {referenceNode.shortTitle}
              </a>
            </span>
            <span className="reference-icon">
              <img src="images/reference.svg" />
            </span>
          </div>
        ))}
      </div>
    )
  );
};

export default ContentReferencesLinks;
