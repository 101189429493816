class sourceModal {
  constructor($notification, $translate, $ngRedux, $scope) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
  }

  hasError() {
    return false;
  }

  async submit() {
    if (!this.hasError()) {
      this.modalInstance.close(this.link);
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  $onInit() {}
}

export default {
  template: require('./sourceModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: sourceModal,
};
