import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { selectApiWithPendingChanges } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';

export const selectRelationsToSources = createTypedSelector(
  [(state) => selectApiWithPendingChanges(state), (state) => state.documentUI.currentEditingNode],
  (apiWithPendingChanges, currentEditingNode) => {
    const relations = apiWithPendingChanges.relationsToAndFromMap.to[
      currentEditingNode as ContentHref
    ].filter(
      (z) =>
        z.relationtype === 'IS_PART_OF' &&
        apiWithPendingChanges.content[z.from.href].type === ContentType.SOURCE
    );
    return relations;
  }
);
