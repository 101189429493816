import teaserButtons from './teaserButtons';
import newsItemButtons from './newsItemButtons';
import redactieAsideComponents from './asideComponents';
import redactieDocumentListComponents from './documentListComponents';
import redactieRowComponents from './rowComponents';
import editor from './editor';
import ribbon from './ribbon';
import terms from './terms';
import localTermRow from './localTermRow';
import dropZone from './dropZone';
import ageLabel from './ageLabel';
import imageSelector from './imageSelector';
import referenceDisplay from './referenceDisplay';
import attachmentUpload from './attachmentUpload';
import globalDocumentSelector from './globalDocumentSelector';
import linkButtonModalForm from './proWebsite/linkButtonModalForm';
import proWebsite from './proWebsite';
import proWebsiteConfigurations from './proWebsite/configurations';
import proWebsiteConfiguration from './proWebsite/configuration';
import proWebsiteConfigurationModalForm from './proWebsite/configurationModalForm';
import selectCurriculumVersionModal from './modals/selectCurriculumVersionModal';
import termSelectionModal from './modals/termSelectionModal';
import documentImportModal from './modals/documentImportModal';
import teaserModal from './modals/teaserModal';
import selectExternalRelationModal from './modals/selectExternalRelationModal';
import { browseFileModalComponent } from './modals/browseFileModal';
import selectMarkExplanationModal from './modals/selectMarkExplanationModal';
import markExplanations from './markExplanations';

export default angular
  .module('redactieComponents', [
    redactieAsideComponents.name,
    redactieDocumentListComponents.name,
    redactieRowComponents.name,
  ])
  .component('ribbon', ribbon)
  .component('editor', editor)
  .component('dropZone', dropZone)
  .component('ageLabel', ageLabel)
  .component('teaserButtons', teaserButtons)
  .component('newsItemButtons', newsItemButtons)
  .component('renderTerms', terms)
  .component('renderMarkExplanations', markExplanations)
  .component('localTermRow', localTermRow)
  .component('imageSelector', imageSelector)
  .component('attachmentUpload', attachmentUpload)
  .component('globalDocumentSelector', globalDocumentSelector)
  .component('referenceDisplay', referenceDisplay)
  .component('proWebsiteLinkButtonModalForm', linkButtonModalForm)
  .component('proWebsite', proWebsite)
  .component('proWebsiteConfigurations', proWebsiteConfigurations)
  .component('proWebsiteConfiguration', proWebsiteConfiguration)
  .component('proWebsiteConfigurationModalForm', proWebsiteConfigurationModalForm)
  .component('selectCurriculumVersionModal', selectCurriculumVersionModal)
  .component('termSelectionModal', termSelectionModal)
  .component('documentImportModal', documentImportModal)
  .component('teaserModal', teaserModal)
  .component('selectExternalRelationModal', selectExternalRelationModal)
  .component('selectMarkExplanationModal', selectMarkExplanationModal)
  .component('browseFileModal', browseFileModalComponent)
  .component('source', require('./source'))
  .component('applicabilityPicker', require('./llinkid/applicabilityPicker'));
