class rowLlinkidDemarcation {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {}

  $onChanges() {
    const annotation =
      this.sItem.$$parent && this.sItem.$$parent.$$annotations
        ? this.sItem.$$parent.$$annotations.filter(
            (o) => o.href === `/content/${this.sItem.key}`
          )[0]
        : null;
    this.demarcation = annotation ? annotation.text : this.sItem.$$parent.$$prefix;
    this.$scope.$broadcast('$$rebind::demarcationChange');
  }
}

export const rowLlinkidDemarcationComponent = {
  template: require('./rowLlinkidDemarcation.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
  },
  controller: rowLlinkidDemarcation,
};
