import { contentApi } from '@store/api/apiConfig';
import { UPDATE_DOCUMENT_VIEW_MODEL } from '@store/constants/actionTypes';
import { debounce, put, select } from 'redux-saga/effects';
import { BatchItem, BatchResult } from '@generalTypes/sriTypes';
import {
  SelectFlatValidationRulesReturnType,
  selectFlatValidationRules,
  selectValidationRulesThatNeedData,
} from './asyncValidationLoadDataSelectors';
import {
  invalidateData,
  loadValidationDataForBatch,
  setValidationDataFromBatch,
} from './validationState';
import { ValidationRuleName } from './validationTypes';

const validationChangeMap: Record<string, unknown> = {};

function* handleDataInvalidationSaga() {
  const allRules: SelectFlatValidationRulesReturnType = yield select(selectFlatValidationRules);

  const toInvalidate = [] as Array<{
    href: string;
    validationRuleName: ValidationRuleName;
  }>;

  for (const { href, validation } of allRules) {
    if (typeof validation.selectInvalidateDataOnChangeOf === 'function') {
      const selectorResult: unknown = yield select((state) =>
        validation.selectInvalidateDataOnChangeOf?.(state, href)
      );

      if (selectorResult !== validationChangeMap[href]) {
        // eslint-disable-next-line no-prototype-builtins
        if (validationChangeMap.hasOwnProperty(href)) {
          // only push to invalidate if we have a changed value, not on the first run.
          toInvalidate.push({
            href,
            validationRuleName: validation.getName(),
          });
        }

        validationChangeMap[href] = selectorResult;
      }
    }
  }

  if (!toInvalidate.length) {
    return;
  }

  yield put(invalidateData(toInvalidate));
}

function* handleValidationBatchSaga() {
  try {
    yield handleDataInvalidationSaga();

    const dataItems = [] as Array<{
      href: string;
      validationRuleName: ValidationRuleName;
      batchItem: BatchItem<'GET'>;
    }>;

    const validationsThatNeedData = yield select(selectValidationRulesThatNeedData);

    for (const { href, validation } of validationsThatNeedData) {
      const selectorResult: BatchItem<'GET'> | null = yield select((state) =>
        validation.selectDataBatch(state, href)
      );
      if (selectorResult !== null) {
        dataItems.push({
          href,
          validationRuleName: validation.getName(),
          batchItem: selectorResult,
        });
      }
    }

    if (!dataItems.length) {
      return;
    }

    const batch = dataItems.map((z) => z.batchItem);
    yield put(loadValidationDataForBatch({ items: dataItems }));

    const response: BatchResult = yield contentApi.put('/batch', batch);

    const results = dataItems.map(({ href, validationRuleName, batchItem }) => {
      /**
       * here we map the batch response to what it would look like for a regular contentApi.getAll
       * This might not always be what you prefer. depending on what call is being done in the batch.
       * if we need a custom mapping for some validation, we can add an optional mapping function to the validation rule.
       * this could also be useful if you want to add some extra data to the validation result.
       * for example: a timestamp of when this data was fetched, so you can make the data expire,
       * or some value of your content-node, at the time of fetching the validation data.
       */
      const data = response
        .find((z) => z.href === batchItem.href)
        ?.body?.results?.map((z) => z.$$expanded);
      return { href, validationRuleName, data };
    });

    yield put(setValidationDataFromBatch(results));

    yield put({ type: UPDATE_DOCUMENT_VIEW_MODEL });
  } catch (e) {
    console.log(e);
  }
}

export function* validationWatcherSaga() {
  yield debounce(250, '*', handleValidationBatchSaga);
}
