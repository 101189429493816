import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { Popover } from '@mui/material';
import { ProposalMetaInfo } from '@newStore/documentUI/documentUITypes';
import { selectProposalDiffMessages } from '@newStore/documentUI/transformProposal/proposalSelectors';
import { useSelector } from 'react-redux';
import './ProposalDiffMessage.scss';

const ProposalDiffMessage: React.FC<{
  href: ContentHref;
  proposalMetaInfo: ProposalMetaInfo;
  userColor: string;
  anchorEl: HTMLElement;
}> = ({ href, proposalMetaInfo, userColor, anchorEl }) => {
  const diffMessages = useSelector((state: RootState) => selectProposalDiffMessages(state, href));

  let diffText = diffMessages
    .map(
      (dm) =>
        `<li>${dm.message}</li>${
          dm.subItems ? `<ul>${dm.subItems.map((si) => `<li>${si}</li>`).join('')}</ul>` : ''
        }`
    )
    .join('');
  if (proposalMetaInfo.childProposalsMessage) {
    diffText += `<li>${proposalMetaInfo.childProposalsMessage.message}</li>`;
  }

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={true}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disableRestoreFocus
    >
      <div className="proposal-diff">
        <div className="proposal-diff-header">
          <span style={{ color: userColor }}>
            {`Wijzigingen door:`}
            <strong>{` ${proposalMetaInfo.authorsDescription}${
              proposalMetaInfo.submittedOn ? ` - ${proposalMetaInfo.submittedOn}` : ''
            }`}</strong>
          </span>
        </div>
        <div className="proposal-diff-text" dangerouslySetInnerHTML={{ __html: diffText }} />
      </div>
    </Popover>
  );
};

export default ProposalDiffMessage;
