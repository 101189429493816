import {
  Content,
  ContentHref,
  ContentRelation,
  ContentRelationHref,
  ContentWith$$Relations,
  Proposal,
  WebPage,
  isCreateChange,
  isPatchChange,
} from '@generalTypes/apiTypes';
import { Person } from '@generalTypes/personApiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { IApiResouce } from '@generalTypes/sriTypes';
import { createSelector, createSelectorCreator, lruMemoize } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { shallowEqual } from 'react-redux';
import type { SelectorArray, UnknownMemoizer, weakMapMemoize } from 'reselect';
import { ContentNodeId, RelationsToAndFromMap } from './documentApiTypes';

// eslint-disable-next-line import/prefer-default-export
export const add$$RelationsToContent = (
  content: Record<string, Content>,
  apiFromRelations: Record<string, ContentRelation[]>,
  apiToRelations: Record<string, ContentRelation[]>
): Record<string, ContentWith$$Relations> => {
  const newContent = { ...content } as Record<string, ContentWith$$Relations>;

  Object.keys(newContent).forEach((href) => {
    const contentItem = newContent[href];
    const $$relationsFrom =
      apiFromRelations[href]?.map((rel) => ({
        href: `/content/relations/${rel.key}` as ContentRelationHref,
        $$expanded: rel,
      })) || [];
    const $$relationsTo =
      apiToRelations[href]?.map((rel) => ({
        href: `/content/relations/${rel.key}` as ContentRelationHref,
        $$expanded: { ...rel },
      })) || [];

    newContent[href] = { ...contentItem, $$relationsFrom, $$relationsTo };
  });

  return newContent;
};

export const getNotFoundHrefs = (results: IApiResouce[], hrefs: string[]) => {
  const resultHrefsSet = new Set(results.map((result) => result.$$meta.permalink));
  const missingResults = hrefs.filter((href) => !resultHrefsSet.has(href));
  return missingResults;
};

export const makeProposalMap = (
  proposals: Record<string, Proposal>,
  personsMap: Record<string, Person>
) => {
  const proposalsArray = Object.values(proposals).map((proposal) => ({
    ...proposal,
    expandedCreators: proposal.creators
      .map((creatorHref) => personsMap[creatorHref])
      .filter(Boolean),
    listOfRequestedChanges: proposal.listOfRequestedChanges.map((change) => ({
      ...change,
      patch: isPatchChange(change)
        ? change.patch.map((patch) => ({
            ...patch,
            value: cloneDeep(patch.value),
          }))
        : undefined,
      resource: isCreateChange(change)
        ? {
            ...change.resource,
            attachments: change.resource.attachments
              ? change.resource.attachments.map((att) => ({ ...att }))
              : undefined,
          }
        : undefined,
    })),
  }));
  proposalsArray.sort((a, b) => (a.$$meta.modified <= b.$$meta.modified ? -1 : 1)); // ordering taken over from old code. So there can be multiple proposals for one content?
  const { fillApiProposalsMap } = require('@store/helpers/documentHelpers'); // eslint-disable-line global-require
  const proposalsMap = fillApiProposalsMap(proposalsArray); // in old api proposalsMap has as key not the permalink of the proposal but of the content resource the proposal is about
  return proposalsMap;
};

export const extractContentAndRelations = (
  results: ContentWith$$Relations[]
): { content: Content[]; relations: ContentRelation[] } => {
  const allContent: Content[] = [];
  const relations: ContentRelation[] = [];
  const existingRelations: Record<string, boolean> = {};
  results.forEach((content) => {
    [...(content.$$relationsFrom || []), ...(content.$$relationsTo || [])].forEach(
      ({ $$expanded: relation, href }) => {
        if (!existingRelations[href]) {
          existingRelations[href] = true;
          relations.push(relation);
        }
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { $$relationsFrom, $$relationsTo, ...contentWithoutRelations } = content;
    allContent.push(contentWithoutRelations);
  });
  return { content: allContent, relations };
};

export const getRelationsToAndFromMap = (
  content: Record<ContentHref, Content>,
  relations: Array<ContentRelation>
): RelationsToAndFromMap => {
  const relationsTo: Record<ContentHref, ContentRelation[]> = Object.fromEntries(
    Object.keys(content).map((href) => [href, []])
  );
  const relationsFrom: Record<ContentHref, ContentRelation[]> = Object.fromEntries(
    Object.keys(content).map((href) => [href, []])
  );

  relations.forEach((relation) => {
    if (!relationsTo[relation.to.href]) {
      relationsTo[relation.to.href] = [];
    }
    relationsTo[relation.to.href].push(relation);

    if (!relationsFrom[relation.from.href]) {
      relationsFrom[relation.from.href] = [];
    }
    relationsFrom[relation.from.href].push(relation);
  });

  return { to: relationsTo, from: relationsFrom };
};

type DeletedIsUndefinedType<T> = T extends Content[]
  ? (Content | undefined)[]
  : (WebPage | undefined)[];
export const mapDeletedNodesToUndefined = <T extends Content[] | WebPage[]>(
  pathToRoot: T
): DeletedIsUndefinedType<T> => {
  return pathToRoot.map((z) => (z?.$$meta.deleted ? undefined : z));
};

export const createLRUSelector = createSelectorCreator({
  memoize: lruMemoize,
  argsMemoize: lruMemoize,
}).withTypes<RootState>();

const currySelector = <State, Result, Params extends readonly any[], AdditionalFields>(
  selector: ((state: State, ...args: Params) => Result) & AdditionalFields
) => {
  const curriedSelector = (...args: Params) => {
    return (state: State) => {
      return selector(state, ...args);
    };
  };
  return Object.assign(curriedSelector, selector);
};

export const createCurriedSelector = <
  InputSelectors extends SelectorArray,
  Result,
  OverrideMemoizeFunction extends UnknownMemoizer = typeof weakMapMemoize,
  OverrideArgsMemoizeFunction extends UnknownMemoizer = typeof weakMapMemoize
>(
  ...args: Parameters<
    typeof createSelector<
      InputSelectors,
      Result,
      OverrideMemoizeFunction,
      OverrideArgsMemoizeFunction
    >
  >
) => {
  return currySelector(createSelector(...args));
};

// the above code could be useful for curried selectors in react-redux. https://www.npmjs.com/package/reselect/v/5.0.0-rc.1?activeTab=readme

export const createLRUSelectorForMappedInput = createSelectorCreator({
  memoize: lruMemoize,
  memoizeOptions: {
    equalityCheck: (a, b) => {
      const eq = shallowEqual(a, b);
      // if (!eq) {
      //   console.log('equality check failed', a, b);
      // } else {
      //   console.log('equality check passed', a, b);
      // }
      return eq;
    },
    resultEqualityCheck: (a, b) => {
      return a === b;
    },
  },
  argsMemoize: lruMemoize,
});

export const keySelectorHref = (state: RootState, href: string) => href;
export const keySelectorHrefAndParent = (
  state: RootState,
  href: string,
  parentHref: string | undefined
) => `${href}-${parentHref}`;

export const getContentNodeId = ({
  childHref,
  parentHref,
}: {
  childHref: string;
  parentHref: string | undefined;
}): ContentNodeId => `id-${childHref}-${parentHref || 'root'}` as ContentNodeId;
