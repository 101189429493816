import { escapeRegExp } from 'lodash';

class teaserModal {
  constructor(contentApi, Utils) {
    'ngInject';

    this.contentApi = contentApi;
    this.utils = Utils;
  }

  $onInit() {
    // To avoid algemeen filter error which has no themes
    if (this.resolve.modalData.parentDocumentThemes) {
      this.themes = this.resolve.modalData.referenceFrameThemes.options.filter((r) =>
        this.resolve.modalData.parentDocumentThemes.includes(r.$$meta.permalink)
      );
      this.childThemes(this.themes);
    }

    this.issuedAfter = this.issuedAfter();
  }

  childThemes(model) {
    this.themeHrefs = [
      ...model.reduce((themesMap, theme) => {
        themesMap.add(theme.$$meta.permalink);
        const recursiveChildren =
          this.resolve.modalData.referenceFrameThemes.itemsRecursiveChildren.get(
            theme.$$meta.permalink
          );
        recursiveChildren.forEach((t) => themesMap.add(t.href));
        return themesMap;
      }, new Set()),
    ];
    if (model.length === 0) {
      // deleting all themes provides all teasers
      this.themeHrefs = null;
    }
  }

  changeThemes(e, model) {
    this.childThemes(model);
  }

  changeTeaser(e, model) {
    this.teaser = model;
  }

  submit() {
    this.modalInstance.close({ teaser: this.teaser });
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  filterData(data, str) {
    if (str.length === 0) {
      return data;
    }
    return data.filter((elm) => {
      const regExp = new RegExp(escapeRegExp(str), 'gi');
      return regExp.test(elm.title) || regExp.test(elm.name);
    });
  }

  display(item) {
    if (!item) {
      return '';
    }
    const issued = item.issued ? this.$scope.$parent.ctrl.utils.formatDate(item.issued) : 'CONCEPT';
    return `${issued} - ${item.title}`;
  }

  issuedAfter() {
    const olderThan6Months = new Date();
    olderThan6Months.setMonth(olderThan6Months.getMonth() - 6);
    return olderThan6Months.toISOString();
  }
}

export default {
  template: require('./teaserModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: teaserModal,
};
