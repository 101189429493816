/**
 * Created by guntherclaes on 03/03/2017.
 */
require('angular-loading-bar');
require('../../../css/loadingBar.scss');

var vskoLoadingBar = angular.module('vskoLoadingBar', ['angular-loading-bar']);

vskoLoadingBar.directive('vskoLoadingBar', ['$timeout', function ($timeout) {
  return {
    restrict: 'E',
    template: require('./vsko-loading-bar.html'),
    replace: true,
    transclude: true,
    scope: {},
    link: function ($scope, element, attrs) {
      var completedTimeout; var
        loadingTimeout;
      var loading;
      $scope.$on('cfpLoadingBar:completed', function () {
        $timeout.cancel(loadingTimeout);
        completedTimeout = $timeout(function () {
          loading = false;
          element.find('.loading').on('animationiteration webkitAnimationIteration', function () {
            if (!loading) {
              element.find('.loading').removeClass('loading');
            }
          });
        }, 500);
      });
      $scope.$on('cfpLoadingBar:loading', function () {
        $timeout.cancel(completedTimeout);
        loadingTimeout = $timeout(function () {
          loading = true;
          element.children().addClass('loading');
        }, 200);
      });
    }
  };
}]);

vskoLoadingBar.factory('httpInterceptorLoadingBar', require('./httpInterceptorLoadingBarFactory'));

vskoLoadingBar.config(require('./httpInterceptorLoadingBarConfig'));
