import { AttachmentToUploadMetaInfo, Content } from '@generalTypes/apiTypes';
import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { selectDocumentRoot } from '@newStore/documentApi/documentApiSelectors';
import { addAttachment, removeAttachment } from '@store/actions/documentActions';

function* replaceContentAttachmentOfGlobalDocumentSaga(
  action: PayloadAction<{ newAttachment: AttachmentToUploadMetaInfo }>
) {
  const globalDocument: Content = yield select(selectDocumentRoot);
  const attachmentsToDelete = globalDocument.attachments.filter(
    (attachment) => attachment.type === 'CONTENT' || attachment.type === 'CONTENT_PREVIEW'
  );
  yield all(
    attachmentsToDelete.map((attachment) =>
      put(removeAttachment(globalDocument.key, attachment.key))
    )
  );
  yield put(addAttachment(globalDocument.key, action.payload.newAttachment));
}

export const replaceContentAttachmentOfGlobalDocument = createAction<{
  newAttachment: AttachmentToUploadMetaInfo;
}>('pendingActions/replaceContentAttachmentOfGlobalDocument');

export function* pendingActionsSaga() {
  yield takeEvery(
    replaceContentAttachmentOfGlobalDocument,
    replaceContentAttachmentOfGlobalDocumentSaga
  );
}
