import {
  ExtendedBuildingBlockNodeConfig,
  ExtendedRootNodeConfig,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import {
  proAliasesComponent,
  proDefaultBuildingBlocks,
  proDocumentStandardComponents,
  proLinksAndMenuReferences,
  proThemeAudienceTab,
  proThemeTocTypes,
  proTileStandardComponents,
} from '../proGenericConfigProps';

const proBlog = {
  information: {
    description:
      'Pro BLOG (webTemplate code BLOG) can be a SECTION (a tile) in a home or can be the root (single page pro theme)',
    single: 'PRO.-tegel: Blog',
    plural: 'Blogs',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  buildingBlocks: proDefaultBuildingBlocks,
};

// Extends SECTION => A SECTION inside a Pro Theme and WebConfig BLOG becomes a PRO_BLOG_TILE
export const PRO_BLOG_TILE: ExtendedBuildingBlockNodeConfig = {
  ...proBlog,
  ...extendsSection,
  isCollapsedByDefault: true,
  edit: [...proTileStandardComponents, proAliasesComponent, ...proLinksAndMenuReferences],
  addAccessRightsToAudienceTab: true,
  confirmDelete: true,
};

// Extends WEBPAGE2 => When adding BLOG WebConfig to a WEBPAGE2 it becomes a PRO_BLOG_STANDALONE
export const PRO_BLOG_STANDALONE: ExtendedRootNodeConfig = {
  ...proBlog,
  information: {
    ...proBlog.information,
    definiteArticle: true,
    single: 'PRO.-thema: Blog',
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  edit: [...proDocumentStandardComponents, proAliasesComponent, ...proLinksAndMenuReferences],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
};
