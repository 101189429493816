import {
  Content,
  ContentHref,
  ContentRelationHref,
  ContentType,
  RedactieHref,
} from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { createLRUSelector } from '@newStore/documentApi/documentApiHelpers';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectAreContentAndProposalsLoaded,
  selectChildRelationsOfRelation,
  selectContentItem,
  selectDocumentRoot,
  selectIsWebpagesFetchedOrNotRequired,
  selectLoadedContentHrefsSet,
  selectPathToRoot,
  selectRawContentItem,
  selectRelationToParent,
} from '@newStore/documentApi/documentApiSelectors';
import { selectExternalContent } from '@newStore/externalData/externalDataSelectors';
import {
  createTypedSelector,
  formatDate,
  getSortedChildHrefsFromRelations,
  parentChildRelationFilter,
  stripHtml,
} from '@newStore/genericHelpers';
import {
  selectHasUserReviewAbility,
  selectHasUserSuggestAbility,
  selectPublishedEditableTypes,
} from '@newStore/user/userSelectors';
import nodeTypeSelectorsMap from '@nodeTypeConfig/nodeTypeSelectorsMap';
import { isEqual } from 'lodash';
import { shallowEqual } from 'react-redux';
import { isSetEqual } from '@store/helpers/generalUtils';
import { settings } from '../../config/settings';
import {
  getHiddenChildrenHrefs,
  getHiddenDescendants,
  getVisibleTOCChildren,
} from './documentUIHelpers';
import { ContentNode, GlossaryNode, TableOfContentNode, TempFallbackNode } from './documentUITypes';
import {
  isBuildingBlockNodeConfig,
  NodeType,
} from '../../config/nodeTypeConfigurations/configTypes';
import {
  selectAllNodeTypesMap,
  selectAppliedDocumentRootConfig,
  selectAppliedNodeConfig,
  selectDocumentRootType,
  selectGenericDocumentRootConfig,
  selectNodeType,
} from './nodeTypeConfigSelectors';
import {
  selectNodeProposal,
  selectProposedContentHrefsToDelete,
} from './transformProposal/proposalSelectors';

export const selectCurrentEditingNodeHref = (state: RootState): ContentHref | null =>
  state.documentUI.currentEditingNode;

export const selectCurrentDocumentHref = (state: RootState) => state.documentUI.currentDocument;
export const selectRootHref = (state: RootState) => state.documentUI.currentDocument;

export const selectIsDocumentReadyToShow = (state: RootState) =>
  Boolean(
    selectRootHref(state) &&
      selectAreContentAndProposalsLoaded(state) &&
      selectIsWebpagesFetchedOrNotRequired(state) &&
      state.documentUI.mode
  );

const selectFallbackNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
  ],
  (content: Content, type): TempFallbackNode => ({
    href: content.$$meta.permalink,
    key: content.key,
    title: content.title,
    type,
    isFallback: true,
  })
);

export const selectContentNode = (state: RootState, href: ContentHref): ContentNode => {
  const nodeType = selectNodeType(state, href);

  const nodeTypeSelector = nodeTypeSelectorsMap[nodeType].nodeSelector;

  if (nodeTypeSelector) {
    return nodeTypeSelector(state, href);
  }

  return selectFallbackNode(state, href);
};

export const selectIsNodeProposedToDeleted = (state: RootState, href: ContentHref): boolean => {
  const proposedHrefsToDelete = selectProposedContentHrefsToDelete(state);
  return proposedHrefsToDelete.includes(href);
};

export const selectIsSomeParentSelected = (
  state: RootState,
  href: ContentHref,
  parentHref: ContentHref
) => {
  // the relation of the current content-node
  const relation = selectRelationToParent(state, href, parentHref);

  if (!relation) {
    return false;
  }

  // all selected relations from the state
  const hrefs: ContentRelationHref[] = state.document.selections.map(
    (z) => `/content/relations/${z}`
  );

  // check if the current relation is found in any children of the selected relations
  return hrefs.some((relationHref) => {
    const children = selectChildRelationsOfRelation(state, relationHref);
    if (children[relation.$$meta.permalink]) {
      return true;
    }
    return false;
  });
};

export const selectIsNodeSelected = (
  state: RootState,
  href: ContentHref,
  parentHref: ContentHref
) => {
  const relation = selectRelationToParent(state, href, parentHref);
  if (!relation) {
    return false;
  }

  if (state.document.selections.includes(relation.key)) {
    return true;
  }

  return selectIsSomeParentSelected(state, href, parentHref);
};

export const selectAllHiddenContenHrefs = createLRUSelector(
  [
    (state) => selectAllNodeTypesMap(state),
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectRootHref(state),
  ],
  (allNodeTypesMap, content, relationsMap, rootHref) => {
    if (!rootHref) {
      return new Set<ContentHref>();
    }

    const hiddenHrefs = new Set<ContentHref>();
    Object.keys(content).forEach((href) => {
      const hiddenChildrenHrefs = getHiddenChildrenHrefs(
        href as ContentHref,
        relationsMap.to,
        allNodeTypesMap
      );
      hiddenChildrenHrefs.forEach((href2) => {
        hiddenHrefs.add(href2);
      });
    });
    return hiddenHrefs;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isSetEqual,
    },
  }
);

/**
 * Hidden descendants are content items which are not shown as a seperate node in the document.
 * This means that those children become part of the first ancestor node that is not hidden.
 */
export const selectHiddenDescendants = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectAllNodeTypesMap(state),
    (state, href: ContentHref) => href,
  ],
  (relationsMap, contentMap, allNodeTypesMap, href): Content[] => {
    return getHiddenDescendants(href, relationsMap.to, contentMap, allNodeTypesMap);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectContentNodeChildren = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectAllHiddenContenHrefs(state),
    (state) => selectLoadedContentHrefsSet(state),
    (state, href: ContentHref) => href,
  ],
  (relationsMap, hiddenContentHrefs, loadedContent, href): ContentHref[] => {
    const childHrefs = getSortedChildHrefsFromRelations(relationsMap.to[href]);

    return childHrefs.filter(
      (childHref) => !hiddenContentHrefs.has(childHref) && loadedContent.has(childHref)
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

const selectTableOfContentMaxDepth = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectDocumentRootType(state),
    (state) => selectCurrentDocumentHref(state),
    (state) => selectAppliedDocumentRootConfig(state)?.tocTypes,
    (state) => selectAllNodeTypesMap(state),
  ],
  (relationsMap, content, rootType, rootHref, tocTypes, allNodeTypesMap) => {
    if (rootType === null || !rootHref) {
      return 0;
    }

    const maxDepth = 4;
    const maxItems = 150;

    const typesFilterList = tocTypes || [NodeType.SECTION];

    const childHrefs = relationsMap.to[rootHref]
      ?.filter(parentChildRelationFilter)
      .map((rel) => rel.from.href);

    if (childHrefs.length === 0) {
      return 0;
    }

    // Recursive function to get total visible children
    const getTotalVisibleChildren = (hrefs: ContentHref[], depth = 1) => {
      if (depth >= maxDepth) {
        return 0;
      }
      const visibleChildren = getVisibleTOCChildren(
        hrefs as ContentHref[], // since non-content will never make it into the TOC
        content,
        allNodeTypesMap,
        typesFilterList
      );
      let total = visibleChildren.length;

      visibleChildren.forEach((href) => {
        const childNodeChildren = relationsMap.to[href]
          ?.filter(parentChildRelationFilter)
          .map((rel) => rel.from.href);
        if (childNodeChildren) {
          total += getTotalVisibleChildren(childNodeChildren, depth + 1);
        }
      });

      return total;
    };

    const totalItems = getTotalVisibleChildren(childHrefs);

    return totalItems > maxItems ? maxDepth - 1 : maxDepth;
  }
);

const selectGlossaryNodes = createTypedSelector(
  [
    (state) => selectExternalContent(state),
    (state) => selectApiWithPendingChanges(state).content, // todo: can be removed once terms are disconnected from llinkid curricula
    (state) => selectApiWithPendingChanges(state).relations,
    (state) => selectCurrentDocumentHref(state),
    (state, type: ContentType.TERM | ContentType.MARK_EXPLANATION) => type,
  ],
  (externalContent, content, contentRelations, currentDocumentHref, type): GlossaryNode[] => {
    const termHrefs = contentRelations
      .filter(
        (rel) =>
          rel.relationtype === 'REFERENCES' &&
          rel.to.href !== currentDocumentHref && // exclude the current document term if you are in a term document
          (externalContent[rel.to.href]?.type === type || content[rel.to.href]?.type === type)
      )
      .map((z) => z.to.href);

    return [...new Set(termHrefs)]
      .map((href) => {
        const term = externalContent[href] || content[href];
        return {
          href: term.$$meta.permalink,
          key: term.key,
          title: term.title || '',
          description: term.description || '',
          redactieLink: `/edit/${term.key}` as RedactieHref,
        };
      })
      .sort((a, b) => (a.title > b.title ? 1 : -1));
  }
);

/* This cached selector is used for performance optimization, as the terms are recalculated 
everytime the content changes, this ends up making a cascade of mouse events un/registering
in the editor, and we need to recalculate this if the number of terms/marks change */
export const selectContentTermNodes = createTypedSelector(
  [(state) => selectGlossaryNodes(state, ContentType.TERM)],
  (terms) => terms,
  {
    devModeChecks: { identityFunctionCheck: 'never' },
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectContentMarkNodes = createTypedSelector(
  [(state) => selectGlossaryNodes(state, ContentType.MARK_EXPLANATION)],
  (terms) => terms,
  {
    devModeChecks: { identityFunctionCheck: 'never' },
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectTableOfContentChildren = createTypedSelector(
  [
    (state) => selectDocumentRootType(state),
    (state, href: ContentHref) => selectContentNodeChildren(state, href),
    (state, href) => selectPathToRoot(state, href).length,
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectTableOfContentMaxDepth(state),
    (state) => selectAppliedDocumentRootConfig(state)?.tocTypes,
    (state) => selectAllNodeTypesMap(state),
  ],
  (
    documentRootType,
    contentNodeChildren,
    depth,
    content,
    maxDepth,
    tocTypes,
    allNodeTypesMap
  ): ContentHref[] => {
    if (documentRootType === null || depth >= maxDepth || !contentNodeChildren) {
      return [];
    }

    const typesFilterList = tocTypes || [];

    return getVisibleTOCChildren(contentNodeChildren, content, allNodeTypesMap, typesFilterList);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectTableOfContentNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectAppliedNodeConfig(state, href),
    (state, href: ContentHref) => selectContentNode(state, href),
  ],
  (typeConfig, contentNode): TableOfContentNode => {
    if ('showPlaceholder' in typeConfig && typeConfig.showPlaceholder === true) {
      return {
        title: `[${typeConfig.information.single}]`,
        placeholder: true,
      };
    }

    const title = stripHtml('title' in contentNode && contentNode.title ? contentNode.title : '');
    const item: TableOfContentNode = { placeholder: false, title };

    if ('identifier' in contentNode && contentNode.identifier) {
      item.identifier = contentNode.identifier;
    }

    if ('color' in contentNode) {
      item.color = contentNode.color;
    }

    return item;
  }
);

export const selectDefaultCollapsedNodes = (state: RootState): Record<string, boolean> => {
  const documentHref = selectCurrentDocumentHref(state);
  if (!documentHref) {
    return {};
  }
  const { content } = selectApiWithPendingChanges(state);
  if (Object.keys(content).length < settings.defaultCollapseNodeCountTreshold) {
    return {};
  }

  const defaultCollapsedNodes = Object.keys(content).filter((href) => {
    const nodeTypeConfigForContentItem = selectAppliedNodeConfig(state, href as ContentHref);
    return (
      isBuildingBlockNodeConfig(nodeTypeConfigForContentItem) &&
      nodeTypeConfigForContentItem.isCollapsedByDefault
    );
  });

  return Object.fromEntries(defaultCollapsedNodes.map((href) => [href, true]));
};

export const selectIsNodeCollapsible = (state: RootState, href: ContentHref): boolean => {
  const nodeTypeConfig = selectAppliedNodeConfig(state, href);
  return isBuildingBlockNodeConfig(nodeTypeConfig) && nodeTypeConfig.isCollapsible;
};

export const selectAllCollapsibleNodes = (state: RootState): Record<ContentHref, boolean> => {
  const { content } = selectApiWithPendingChanges(state);
  const allContentHrefs = Object.keys(content) as Array<ContentHref>;
  const collapsibleNodes = allContentHrefs.filter((href) => {
    return selectIsNodeCollapsible(state, href);
  });

  return Object.fromEntries(collapsibleNodes.map((href) => [href, true]));
};

export const selectLastRead = (state: RootState) => {
  const rootType = selectDocumentRootType(state);
  if (rootType === NodeType.PRONEWSLETTER) {
    return state.documentApi.newsletterSettings?.approvalDate;
  }
  return state.documentUI.privateState?.state.lastRead;
};

/**
 * old conditions in contentRow.html:
 * ctrl.allReadButton.isVisible
 * && !ctrl.sItem.modificationAfterLastRead.isDisplayedOnlyInToc">
 */
export const selectModifiedSinceLastReadInfo = createTypedSelector(
  [
    (state, href: string) => selectContentItem(state, href as ContentHref),
    (state) => selectLastRead(state),
  ],
  (content, lastRead) => {
    if (lastRead && content?.$$meta.modified && content.$$meta.modified > lastRead) {
      return `laatste aanpassing: ${formatDate(content.$$meta.modified)}`;
    }
    return null;
  }
);

export const selectDocumentAllowsSuggestions = createTypedSelector(
  [(state) => selectDocumentRoot(state), (state) => selectGenericDocumentRootConfig(state)],
  (rootDocument, rootNodeTypeConfig) => {
    return !!(rootNodeTypeConfig?.allowSuggestions && rootDocument?.issued);
  }
);

export const selectIsDocumentReadOnly = createTypedSelector(
  [
    (state) => selectDocumentRoot(state),
    (state) => state.documentUI.mode,
    (state) => selectDocumentAllowsSuggestions(state),
    (state) => selectHasUserSuggestAbility(state),
    (state) => selectHasUserReviewAbility(state),
  ],
  (
    rootDocument,
    documentMode,
    documentAllowsSuggestions,
    hasUserSuggestAbility,
    hasUserReviewAbility
  ) => {
    if (!rootDocument) {
      return true;
    }

    if (
      documentMode === 'EDIT' &&
      documentAllowsSuggestions &&
      hasUserSuggestAbility &&
      !hasUserReviewAbility
    ) {
      return true;
    }

    return documentMode === 'READ_ONLY';
  }
);

export const selectIsNodeReadOnly = createTypedSelector(
  [
    (state) => selectIsDocumentReadOnly(state),
    (state) => selectDocumentRoot(state),
    (state, href: ContentHref) => selectNodeProposal(state, href),
    (state, href: ContentHref) => selectAppliedNodeConfig(state, href),
    (state) => state.documentUI.mode,
  ],
  (isDocumentReadOnly, rootDocument, proposal, nodeTypeConfig, documentMode) => {
    const isReadOnlyNode = 'readOnlyNode' in nodeTypeConfig && nodeTypeConfig.readOnlyNode;
    if (isDocumentReadOnly || !rootDocument || isReadOnlyNode) {
      return true;
    }

    if (documentMode === 'SUGGEST' && proposal?.status === 'SUBMITTED_FOR_REVIEW') {
      return true;
    }

    return false;
  }
);

export const selectIsNodeDeletable = createTypedSelector(
  [
    (state, href: ContentHref) => selectRawContentItem(state, href),
    (state, href: ContentHref) => selectAppliedNodeConfig(state, href),
    (state) => selectGenericDocumentRootConfig(state),
    (state) => selectDocumentRoot(state),
  ],
  (contentPersistedOnApi, nodeTypeConfig, rootTypeConfig, rootDocument) => {
    if (!rootDocument) {
      return false;
    }

    if (!contentPersistedOnApi) {
      // Some nodes are configured to not be allowed to delete (especially in some kind of documents that are published).
      // The reason for this is that other documents of erven external partners might have references to these nodes
      // But if the node is a pending node which is not persisted on the api, you are allowed to delete it
      return true;
    }

    if ('isNotDeletable' in nodeTypeConfig && nodeTypeConfig.isNotDeletable === true) {
      return false;
    }

    return !rootDocument.issued || !rootTypeConfig?.disableDeleteWhenIssued;
  }
);

export const selectIsRoot = (state: RootState, href: string) => {
  return state.documentUI.currentDocument === href;
};

export const selectEditorAutofocusField = createTypedSelector(
  [(state) => state.documentUI.nodeThatShouldBeFocused, (state, href: ContentHref) => href],
  (nodeThatShouldBeFocused, href) => {
    return nodeThatShouldBeFocused?.href === href && nodeThatShouldBeFocused.field;
  }
);

/**
 * The All-read button should be disabled when
 *  - the user has no lastRead in his private state (changeIndicator will not be shown if you have no privateState.lastRead)
 *  - or every content item is modified before last read
 */
export const selectIsAllReadButtonDisabled = createTypedSelector(
  [
    (state) => selectApiWithPendingChanges(state).content,
    (state) => state.documentUI.privateState?.state.lastRead,
  ],
  (contentMap, lastRead) => {
    return Object.values(contentMap).every(
      (content) => !lastRead || (content.$$meta.modified && content.$$meta.modified <= lastRead)
    );
  }
);

export const selectIsPublishedEditable = createTypedSelector(
  [(state) => selectPublishedEditableTypes(state), (state) => selectDocumentRootType(state)],
  (publishedEditableTypes, rootType) => {
    return publishedEditableTypes.some((type) => type === rootType);
  }
);
