import { ProNewsItemNode } from '@newStore/documentUI/documentUITypes';
import InlineEditor from '@UI/editor/InlineEditor';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { useDispatch, useSelector } from 'react-redux';
import { createTeaserFromNewsitem } from '@newStore/documentApi/documentApiState';
import { useState } from 'react';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
import { getPlaceHolderLabel } from '../../contentRowHelpers';

const ProNewsItemRow: React.FC<{ contentNode: ProNewsItemNode }> = ({ contentNode }) => {
  const [creating, setCreating] = useState(false);
  const dispatch = useDispatch();
  const nodeTypeConfig = useSelector((state: RootState) =>
    selectAppliedNodeConfig(state, contentNode.href)
  );

  return (
    <>
      {
        <div className="newsItemButtons">
          <div className="linkedTeasers">
            {!contentNode.resourcesLoading &&
              (contentNode.linkedTeasers.length === 0 ? (
                <div>
                  <div className="text-danger small">Nog geen teaser gelinkt</div>
                  <div className="text-info small">
                    (hierdoor kan je bericht niet gevonden worden op de website, en kan het niet
                    opgenomen worden in een nieuwsbrief)
                  </div>
                </div>
              ) : (
                <div className="linkedFrom">Gelinkt vanuit:</div>
              ))}
            {contentNode.linkedTeasers.map((teaser, index) => (
              <div key={teaser.key}>
                <a
                  className="eachLinkedTeaser"
                  onClick={() => {
                    getAngularService('$state').go('edit', { key: teaser.key });
                  }}
                >
                  {teaser.title}
                  {index === contentNode.linkedTeasers.length - 1 ? '' : ', '}
                </a>
              </div>
            ))}
          </div>
          <div>
            <button
              className="btn btn-teaser"
              onClick={() => {
                setCreating(true);
                dispatch(createTeaserFromNewsitem({ newsItemHref: contentNode.href }));
              }}
              disabled={contentNode.arePendingActions || creating}
            >
              {creating ? (
                <>Laden...</>
              ) : (
                <>
                  <span className="glyphicon glyphicon-plus"></span>
                  Teaser maken en linken
                </>
              )}
            </button>
          </div>
        </div>
      }
      <div className="node-title">
        <InlineEditor
          initial={contentNode.title}
          field="title"
          placeholder={getPlaceHolderLabel(nodeTypeConfig, 'title')}
          skey={contentNode.key}
          href={contentNode.href}
        ></InlineEditor>
      </div>
    </>
  );
};

export default ProNewsItemRow;
