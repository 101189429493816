import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';

const IMAGE: BuildingBlockNodeConfig = {
  information: {
    single: 'Afbeelding',
    plural: 'Afbeeldingen',
    ribonIcon: require('../../../../img/buildingBlocks/IMAGE.svg'),
  },

  buildingBlocks: [],
  edit: [
    {
      component: 'image',
      label: 'Afbeelding',
      property: 'attachments',
      required: RequiredType.ERROR,
      options: {
        type: 'ILLUSTRATION',
        fields: ['description', 'alt'],
      },
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default IMAGE;
