import { getNotFoundHrefs } from '@newStore/documentApi/documentApiHelpers';
import { selectApiWithPendingChangesRelationsToAndFromMap } from '@newStore/documentApi/documentApiSelectors';
import { getResourcePathFromHref } from '@newStore/genericHelpers';
import { addNotificationAction } from '@store/actions/notificationActions';
import { sendBatchCmd } from '@store/commands/documentCommands';

import { RootState } from '@generalTypes/rootStateTypes';
import { BatchRequest, BatchResult } from '@generalTypes/sriTypes';
import { waitFor } from '@store/helpers/sagaUtils';
import { chunk } from 'lodash';
import { call, debounce, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import en from '../../config/translations/en.json';
import * as externalDataApi from './externalDataDataAccess';
import { selectIsResourceLoaded } from './externalDataSelectors';
import {
  GetAllOfResourceAction,
  addResourceAncestors,
  addResourceAncestorsToLoad,
  addResourcesToLoad,
  getAllOfResource,
  loadIncomingReferences,
  setAllOfResource,
  setExternalData,
  setFailedResourceAncestors,
  setNotFoundHrefs,
} from './externalDataState';
import {
  selectExternalResourceAncestorsToLoad,
  selectExternalResourcesToLoad,
} from './externalDataToLoadSelectors';

function* getAllOfResourceSaga({ payload }: GetAllOfResourceAction) {
  const { resource } = payload;
  try {
    const isDataAlreadyLoaded = yield select(selectIsResourceLoaded, resource);
    if (isDataAlreadyLoaded) {
      return;
    }
    const data = yield call(externalDataApi.getAllOfResource, resource);
    yield put(setAllOfResource({ resource, data }));
  } catch (error) {
    console.error(error);
    let message = `loadingError.${resource}`;
    if (!en.loadingError[resource]) {
      message = 'loadingError.default';
    }
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message,
        removeAfter: 0,
      })
    );
  }
}

function* checkForExternalDataToLoadSaga() {
  const newAncestorsToLoad: string[] = yield select(selectExternalResourceAncestorsToLoad);

  if (newAncestorsToLoad.length > 0) {
    if (newAncestorsToLoad.length >= 10) {
      console.warn('loading many leaf requests', 'newAncestorsToLoad', newAncestorsToLoad);
    } else {
      console.log('newAncestorsToLoad', newAncestorsToLoad);
    }
    yield put(addResourceAncestorsToLoad({ resources: newAncestorsToLoad }));
  }

  const newResourcesToLoad: string[] = yield select(selectExternalResourcesToLoad);

  if (newResourcesToLoad.length > 0) {
    yield put(addResourcesToLoad({ resources: newResourcesToLoad }));
  }
}

function* loadHrefsForResourceSaga(hrefs, resource) {
  const results = yield call(externalDataApi.getAllHrefsForResource, resource, hrefs);

  if (results.length > 0) {
    yield put(setExternalData({ data: results }));
  }

  const missingResults = getNotFoundHrefs(results, hrefs);
  if (missingResults.length > 0) {
    yield put(setNotFoundHrefs({ hrefs: missingResults }));
  }
}

function* loadResourcesSaga({ payload }) {
  const { resources } = payload;

  const hrefsByResourcePath = resources.reduce((acc, href) => {
    const resourcePath = getResourcePathFromHref(href);
    if (!resourcePath) {
      console.error(`Could not get resource path from href: ${href}`);
      return acc;
    }
    if (!acc[resourcePath]) {
      acc = { ...acc, [resourcePath]: [] };
    }

    acc[resourcePath].push(href);
    return acc;
  }, {});

  // eslint-disable-next-line no-restricted-syntax
  for (const [resourcePath, hrefs] of Object.entries(hrefsByResourcePath)) {
    // check if we want to load some resource fully
    if (externalDataApi.loadResourceTypeFully(resourcePath)) {
      yield fork(getAllOfResourceSaga, {
        payload: { resource: resourcePath, refresh: false },
        type: getAllOfResource.type,
      });
    } else {
      yield fork(loadHrefsForResourceSaga, hrefs, resourcePath);
    }
  }
}

/**
 * this function selects and loads all the REFERENCES relations that are incoming to the root.
 * this is used for:
 * - showing in which documents a term is used
 * - showing which teasers are linked to a newsitem
 */
function* loadReferencedToRootItems() {
  // wait for the relations to the root to be in the state.
  yield call(
    waitFor,
    (state: RootState) =>
      state.documentUI.currentDocument &&
      selectApiWithPendingChangesRelationsToAndFromMap(state).to[state.documentUI.currentDocument]
  );
  // select relations to the root
  const relationsToRoot = yield select(
    (state: RootState) =>
      state.documentUI.currentDocument &&
      selectApiWithPendingChangesRelationsToAndFromMap(state).to[state.documentUI.currentDocument]
  );

  const contentHrefs = relationsToRoot
    .filter((rel) => rel.relationtype === 'REFERENCES')
    .map((rel) => rel.from.href);

  if (contentHrefs.length > 0) {
    yield put(addResourceAncestorsToLoad({ resources: contentHrefs }));
  }
}

function* loadResourceAncestorsSaga() {
  const resources: string[] = yield select(
    (state: RootState) => state.externalData.resourceAncestorsToLoad
  );

  const chunkedResourced = chunk(resources, 100);

  for (const chunkedResource of chunkedResourced) {
    const batch: BatchRequest = [];
    chunkedResource.forEach((resource) => {
      batch.push({
        verb: 'GET',
        href: `/content?limit=500&leaf=${resource}`,
      });
    });
    console.log('tree as leaf (=ancestor) batch', batch);
    const results: BatchResult = yield sendBatchCmd(batch);
    const failedResourceAncestors: string[] = [];
    const ancestorObjMap = chunkedResource.reduce((acc, resource) => {
      const batchResult = results.find((r) => r.href.indexOf(resource) !== -1);
      if (!batchResult || !batchResult.body.results?.length) {
        failedResourceAncestors.push(resource);
      } else {
        acc[resource] = batchResult.body.results.map((r) => r.$$expanded);
      }
      return acc;
    }, {});

    if (failedResourceAncestors.length > 0) {
      yield put(setFailedResourceAncestors({ hrefs: failedResourceAncestors }));
    }

    if (Object.keys(ancestorObjMap).length > 0) {
      yield put(addResourceAncestors({ data: ancestorObjMap }));
    }
  }
}

export function* watchExternalDataSaga() {
  yield takeEvery(getAllOfResource.match, getAllOfResourceSaga);
  yield takeLeading('*', checkForExternalDataToLoadSaga);
  yield takeEvery(addResourcesToLoad.match, loadResourcesSaga);
  yield takeEvery(loadIncomingReferences, loadReferencedToRootItems);
  yield debounce(250, addResourceAncestorsToLoad.match, loadResourceAncestorsSaga);
}
