import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import {
  authorsComponent,
  publishComponent,
  titleComponent,
} from '@nodeTypeConfig/helpers/genericComponents';

const VISION_TEXT: RootNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'Visietekst',
    plural: 'Visieteksten',
  },

  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.WORD_IMPORT },
  ],
  tocTypes: [NodeType.SECTION],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
    authorsComponent,
    {
      component: 'image',
      label: 'Omslagfoto',
      property: 'attachments',
      options: {
        type: 'COVER_IMAGE',
        fields: [],
      },
    },
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: false,
};

export default VISION_TEXT;
