import {
  IsPartOfRelation,
  IsIncludedInRelation,
  ContentRelationHref,
  ContentHref,
} from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import {
  getAllDescendantRelationHrefs,
  getBuildingBlockType,
} from '@newStore/documentUI/documentUIHelpers';
import {
  selectContentNodeChildren,
  selectIsNodeProposedToDeleted,
} from '@newStore/documentUI/documentUISelectors';
import {
  selectAllNodeTypesMap,
  selectBuildingBlocksForNode,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { isEqual } from 'lodash';

export const selectSelectedDragItems = createTypedSelector(
  [
    (state) => state.document.selections,
    (state) => selectApiWithPendingChanges(state),
    (state) => selectAllNodeTypesMap(state),
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state).to,
    (state) => state.documentUI.currentDocument,
  ],
  (selections, apiWithPendingChanges, allNodeTypesMap, relationsMap, rootHref) => {
    if (!rootHref) {
      return [];
    }

    const relations: Array<IsPartOfRelation | IsIncludedInRelation> = selections.map(
      (z) => apiWithPendingChanges.relationsMap[`/content/relations/${z}` as ContentRelationHref]
    );

    // the relations are in order of selection. however, you would want to preserve the order of the items the way you see them on screen.
    // so we need to sort the relations based on the order of the items in the document.
    // create a flattened tree of all relations
    const flatOrderedRelations: ContentRelationHref[] = getAllDescendantRelationHrefs(
      relationsMap,
      rootHref
    ).flat(Infinity);

    // then sort by index of that flattened tree
    const orderedRelations = relations.sort((a, b) => {
      return (
        flatOrderedRelations.indexOf(a.$$meta.permalink) -
        flatOrderedRelations.indexOf(b.$$meta.permalink)
      );
    });

    const dragTypes = orderedRelations.map((rel) => ({
      node: { href: rel.from.href, type: allNodeTypesMap[rel.from.href] },
      buildingBlockType: getBuildingBlockType(allNodeTypesMap[rel.from.href]),
      relation: rel,
    }));

    return dragTypes;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectIsLastChild = (
  state: RootState,
  href: string,
  parentHref: ContentHref | undefined,
  draggingHrefs: string[] | null
) => {
  if (!parentHref) {
    return false;
  }
  let childHrefs = selectContentNodeChildren(state, parentHref);
  if (!childHrefs) {
    return false;
  }
  if (draggingHrefs && draggingHrefs.length) {
    childHrefs = childHrefs.filter((z) => !draggingHrefs.includes(z));
  }
  return Boolean(childHrefs && childHrefs[childHrefs.length - 1] === href);
};

const emptyArray = [] as const;

export const selectBuildingBlocks = (state: RootState, nodeHref: ContentHref | undefined) =>
  (nodeHref &&
    !selectIsNodeProposedToDeleted(state, nodeHref) &&
    selectBuildingBlocksForNode(state, nodeHref)) ||
  emptyArray;
