import { ContentHref, ContentRelationType } from '@generalTypes/apiTypes';
import { createAction } from '@reduxjs/toolkit';

export const expandLlinkidGoalRelations = createAction<{
  key: string;
  relationTypes: Array<ContentRelationType>;
  originPart: 'from' | 'to';
}>('llinkid/expandLlinkidGoalRelations');

export const loadPreviousVersion = createAction<{
  href: ContentHref;
}>('llinkid/loadPreviousVersion');
