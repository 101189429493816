import { Content, isContent } from '@generalTypes/apiTypes';
import { AllExternalData } from '@newStore/externalData/externalDataTypes';
import { entityHrefToString } from '@newStore/genericHelpers';
import { accessRights } from '@store/helpers/accessRights';
import { regions } from '@store/helpers/dioceses';

export const entityHrefsArrayToString = (hrefs: Array<string> | undefined, a: AllExternalData) => {
  if (!hrefs) {
    return '';
  }
  return hrefs
    .flatMap((href) => {
      return entityHrefToString(href, a);
    })
    .join(', ');
};

export const themeHrefsToString = (c: Content, a: AllExternalData) => {
  if (!c.themes) {
    return '';
  }
  return c.themes
    .map((href) => {
      const theme = a[href];
      if (isContent(theme)) {
        return theme.title;
      }
      return '';
    })
    .join(', ');
};

export const coverageHrefsToString = (c: Content) => {
  if (!c.coverage) {
    return '';
  }
  return c.coverage
    .map((href) => {
      const region = regions.find((r) => r.$$meta.permalink === href);
      if (region) {
        return region.name;
      }
      return '';
    })
    .join(', ');
};

// TODO add namedSets to external data to get them from there (now only happens in old reducer)
/* export const msAndOuTypesHrefsToString = (c: Content) => {
  if (!c.mainstructuresOuTypeCombinations) {
    return '';
  }
  return c.mainstructuresOuTypeCombinations
    .map((href) => {
      const msOuType = .find((r) => r.$$meta.permalink === href);
      if (region) {
        return region.name;
      }
      return '';
    })
    .join(', ');
}; */

const accessRightsToString = (c: Content, property: 'descendantsAccessRights' | 'accessRights') => {
  const value = c[property];
  if (!value) {
    return '';
  }
  return Object.values(accessRights).find((ar) => ar.namedSetHref === value[0])?.label || '';
};

export const getAccessRightsToStringFunction = (
  property: 'descendantsAccessRights' | 'accessRights'
) => {
  return (c: Content) => accessRightsToString(c, property);
};
