import { compactTitle } from '../../../reduxLoop/helpers/documentHelpers';

class rowMusic {
  constructor($scope, $ngRedux, settings) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
  }

  $onInit() {}

  $onChanges() {
    this.attachment = this.sItem.$$attachments.has('ATTACHMENT')
      ? this.sItem.$$attachments.get('ATTACHMENT').original
      : null;
  }

  mp3Source() {
    if (!this.attachment) {
      return;
    }
    return this.settings.apisAndUrls.contentApi + this.attachment.href; //eslint-disable-line
  }

  getTitle() {
    if (this.sItem.description) {
      return compactTitle(this.sItem.description);
    }
    return this.attachment.name;
  }
}

export const rowMusicComponent = {
  template: require('./rowMusic.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
  },
  controller: rowMusic,
};
