import { referenceFrameLabels, referenceFrames } from '@newStore/referenceFrames/referenceFrames';

class rowEducationalReferences {
  constructor(settings, constants, $ngRedux) {
    'ngInject';

    this.settings = settings;
    this.constants = constants;
    this.$ngRedux = $ngRedux;

    this.loading = true;

    this.strengthSizes = {
      LOW: '25',
      MEDIUM: '30',
      HIGH: '38',
    };
  }

  imageSource(reference) {
    if (reference.attachments.length > 0) {
      return this.settings.apisAndUrls.contentApi + reference.attachments[0].href;
    }

    return '';
  }

  size(reference) {
    if (this.sReferenceFrame === referenceFrames.educationalComponents && reference.strength) {
      return this.strengthSizes[reference.strength];
    }
    return '30';
  }

  $onChanges() {
    this.setReferences();
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const relationsTo = { ...state.document.apiWithPendingChanges.contentRelations.to };
      const referencesRelations = relationsTo[`/content/${this.sItem.key}`]
        ? relationsTo[`/content/${this.sItem.key}`].filter((r) => r.relationtype === 'REQUIRES')
        : [];

      return {
        referencesRelations,
        referencesTitle: referenceFrameLabels[this.sReferenceFrame],
        referenceFrameNodes: state.referenceFrames[this.sReferenceFrame]?.content,
      };
    })(this);

    return (() => {
      this.setReferences();
    })();
  }

  setReferences() {
    if (this.referencesRelations && this.referenceFrameNodes) {
      this.references = this.referencesRelations
        .filter((relation) => this.referenceFrameNodes[relation.from.href])
        .map((relation) => ({
          ...this.referenceFrameNodes[relation.from.href],
          strength: relation.strength,
        }));
    }
  }
}

export const rowEducationalReferencesComponent = {
  template: require('./rowEducationalReferences.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
    sReferenceFrame: '@',
  },
  controller: rowEducationalReferences,
};
