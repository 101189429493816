import InlineEditor from '@UI/editor/InlineEditor';
import { ReferenceGroupNode } from '@newStore/documentUI/documentUITypes';
import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { getPlaceHolderLabel } from '../../contentRowHelpers';

const ReferenceGroupRow: React.FC<{ contentNode: ReferenceGroupNode }> = ({ contentNode }) => {
  const nodeTypeConfig = useSelector((state: RootState) =>
    selectAppliedNodeConfig(state, contentNode.href)
  );

  return (
    <div className="curriculumReference">
      <div className="referenced-group-title">
        <InlineEditor
          initial={contentNode.description}
          field="description"
          placeholder={getPlaceHolderLabel(nodeTypeConfig, 'description')}
          skey={contentNode.key}
          href={contentNode.href}
        ></InlineEditor>
      </div>
      {contentNode.references.map((reference) => (
        <div
          className={`referenced-item ${
            reference.isDeletedByProposal ? 'deleted-hidden-child' : ''
          }`}
          key={reference.href}
        >
          {reference.identifier && (
            <span style={{ minWidth: '60px', display: 'inline-block', color: reference.color }}>
              {reference.identifier}
            </span>
          )}
          <span dangerouslySetInnerHTML={{ __html: reference.title }}></span>
        </div>
      ))}
    </div>
  );
};

export default ReferenceGroupRow;
