import { ContentType } from '@generalTypes/apiTypes';
import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';

const IMAGE_GROUP: BuildingBlockNodeConfig = {
  information: {
    single: 'Afbeeldingsgroep',
    plural: 'Afbeeldingsgroepen',
    ribonIcon: require('../../../../img/buildingBlocks/IMAGE_GROUP.svg'),
  },

  buildingBlocks: [],
  edit: [
    {
      component: 'imageGroup',
      label: 'Afbeeldingen',
      hiddenChildren: {
        containerType: ContentType.IMAGE_GROUP,
        items: [
          {
            type: ContentType.IMAGE,
            label: 'afbeelding',
          },
        ],
      },
    },
  ],
  isCollapsible: false,
  hideChildrenInDocument: true,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default IMAGE_GROUP;
