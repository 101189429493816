export type AccessRightKey =
  | 'publiek'
  | 'gevoelige_informatie'
  | 'verslagen_directiecommissies'
  | 'informatie_voor_bestuurders';

export type AccessRight = {
  key: string;
  translationKey: string;
  label: string;
  namedsets: Array<string>;
  namedSetHref: string; // array didn't make sense so in refactor we just make it a single value;
};

// Access right are defined from broad to narrow
export const accessRights: Record<AccessRightKey, AccessRight> = {
  publiek: {
    translationKey: 'components.accessRights.dropdown.public',
    key: 'publiek',
    label: 'Publiek',
    namedsets: ['/namedsets/1ab3becd-1c84-5ada-c3d7-457e7033d612'],
    namedSetHref: '/namedsets/1ab3becd-1c84-5ada-c3d7-457e7033d612',
  },
  gevoelige_informatie: {
    translationKey: 'components.accessRights.dropdown.sensitiveInformation',
    key: 'gevoelige_informatie',
    label: 'Gevoelige informatie',
    namedsets: ['/namedsets/2bb3becd-1c84-5ada-c3d7-457e7033d612'],
    namedSetHref: '/namedsets/2bb3becd-1c84-5ada-c3d7-457e7033d612',
  },
  verslagen_directiecommissies: {
    translationKey: 'components.accessRights.dropdown.reportsBoardsCommittees',
    key: 'verslagen_directiecommissies',
    label: 'Verslagen directiecommissies',
    namedsets: ['/namedsets/4db3becd-1c84-5ada-c3d7-457e7033d612'],
    namedSetHref: '/namedsets/4db3becd-1c84-5ada-c3d7-457e7033d612',
  },
  informatie_voor_bestuurders: {
    translationKey: 'components.accessRights.dropdown.informationDirectors',
    key: 'informatie_voor_bestuurders',
    label: 'Informatie voor bestuurders',
    namedsets: ['/namedsets/4ff3a4f1-c252-4604-aa31-7cda279a8431'],
    namedSetHref: '/namedsets/4ff3a4f1-c252-4604-aa31-7cda279a8431',
  },
};

export const isMoreStrict = (a: string, b: string) => {
  return (
    Object.values(accessRights).findIndex((ar) => ar.namedSetHref === a) >
    Object.values(accessRights).findIndex((ar) => ar.namedSetHref === b)
  );
};

export const isInformatieVoorBestuurdersAccessRights = (currentAccessRights: string[] = []) => {
  // here we check if the permission that applies to our current node is of
  // type "Informatie voor bestuurders"

  const informatieVoorBestuurdersAccessRight = accessRights.informatie_voor_bestuurders;

  return (
    informatieVoorBestuurdersAccessRight &&
    currentAccessRights.some(
      (nsHref) => informatieVoorBestuurdersAccessRight.namedSetHref === nsHref
    )
  );
};
