import { RedactieHref } from '@generalTypes/apiTypes';
import { selectApiWithPendingChangesRelationsToAndFromMap } from '@newStore/documentApi/documentApiSelectors';
import { getRoot } from '@newStore/externalData/externalDataHelpers';
import {
  selectExternalContent,
  selectExternalContentRelationsMap,
} from '@newStore/externalData/externalDataSelectors';

import { createTypedSelector } from '@newStore/genericHelpers';
import { groupBy, isEqual } from 'lodash';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { selectDocumentRootType } from '../nodeTypeConfigSelectors';
import { getTitle } from '../../../services/types';

export const selectDocumentsRelatedToTerm = createTypedSelector(
  [
    (state) => selectDocumentRootType(state),
    (state) =>
      state.documentUI.currentDocument &&
      selectApiWithPendingChangesRelationsToAndFromMap(state).to[state.documentUI.currentDocument],
    (state) => selectExternalContent(state),
    (state) => selectExternalContentRelationsMap(state),
  ],
  (rootType, relationsMapTo, externalContent, externalRelationsMap) => {
    if (!rootType || rootType !== NodeType.TERM || !relationsMapTo) {
      return [];
    }
    const references = relationsMapTo?.filter((relation) => relation.relationtype === 'REFERENCES');
    const groupedReferencedContent = groupBy(
      references.flatMap((z) => {
        const root = getRoot(externalRelationsMap, externalContent, z.from.href);
        return root
          ? {
              title: getTitle(root) as string,
              redactieLink: `/edit/${root.key}` as RedactieHref,
              key: root.key,
            }
          : [];
      }),
      'key'
    );

    const referencedContent = Object.keys(groupedReferencedContent)
      .map((key) => {
        const documents = groupedReferencedContent[key];
        return {
          ...documents[0],
          count: documents.length,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));

    return referencedContent;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);
