import { HiddenContentItemConfig } from '@nodeTypeConfig/configTypes';

const LINK_GROUP: HiddenContentItemConfig = {
  information: {
    single: 'Link groep',
    plural: 'Link groepen',
  },
  hideInDocument: true,
  hideChildrenInDocument: true,
};

export default LINK_GROUP;
