import { closePublishModal, publishContent } from '@newStore/documentList/newDocumentListState';
import { selectSelectedResults } from '@newStore/documentList/newDocumentListSelectors';
import { selectUser, selectUserHref } from '@newStore/user/userSelectors';
import { selectCurrentDocumentHref } from '@newStore/documentUI/documentUISelectors';
import uuidv4 from 'uuid/v4';
import { setProposal } from '@newStore/documentApi/documentApiState';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

export class publishDateModal {
  constructor($scope, $ngRedux, $uibModalInstance, $location) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$uibModalInstance = $uibModalInstance;
    this.$location = $location;
    this.params = $scope.$resolve.modalData || {};
  }

  async $onInit() {
    this.change = (text) => {
      // key pressed
      this.message = text;
    };
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.newDocumentList.publishModalOpen && !state.document.publishModalOpen) {
        this.$uibModalInstance.close();
      }

      let selected = selectSelectedResults(state);
      if (this.params.document) {
        selected = [this.params.document];
      }

      return {
        rootState: state,
        selected,
        saveAttempted: state.newDocumentList.saveAttempted || state.document.publishAttempted,
        publishModalOpen: state.newDocumentList.publishModalOpen || state.document.publishModalOpen,
        allowSuggestions: selected.some((s) => s.$$typeConfig.allowSuggestions),
      };
    })(this);
  }

  updatePublishDate(event, date) {
    this.publishDate = date;
  }

  $onDestroy() {
    this.unsubscribe();
  }

  createProposal(publishDate) {
    const key = uuidv4();
    const currentUserHref = selectUserHref(this.rootState);
    const currentDocumentHref = selectCurrentDocumentHref(this.rootState);
    const currentUser = selectUser(this.rootState);

    const change = {
      type: 'PATCH',
      appliesTo: { href: currentDocumentHref },
      creator: { href: currentUserHref },
      patch: [
        {
          op: 'add',
          path: '/issued',
          value: new Date(publishDate).toISOString(),
        },
      ],
    };

    return {
      key,
      status: 'SUBMITTED_FOR_REVIEW',
      creators: [currentUserHref],
      expandedCreators: [currentUser],
      externalReferences: [currentDocumentHref],
      listOfRequestedChanges: [change],
      $$meta: { permalink: `/proposals/${key}`, modified: new Date().toISOString() },
    };
  }

  submit() {
    if (this.publishDate) {
      if (this.params.type === 'DOCUMENT') {
        const proposal = this.createProposal(this.publishDate);
        this.$ngRedux.dispatch(setProposal({ proposal }));
        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.publishDocumentAction(proposal, this.message, this.$location.absUrl())
        );
      } else {
        this.$ngRedux.dispatch(
          publishContent({
            resources: this.selected,
            publishDate: this.publishDate,
            message: this.message,
            locationUrl: this.$location.absUrl(),
          })
        );
      }
    }
  }

  cancel() {
    this.$ngRedux.dispatch(closePublishModal());
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.closePublishModal());
  }

  blur(scope, event, data) {
    scope.ctrl.message = data.value;
  }
}
