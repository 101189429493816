/* eslint-disable max-len */
import { orderBy, isEmpty, cloneDeep } from 'lodash';
import uuidv4 from 'uuid/v4';
import { Cmd, loop } from 'redux-loop';
import jsonPatch from 'json-patch';
import commonUtils from '@kathondvla/sri-client/common-utils/common-utils';
import { selectAllowedAbilities, selectUser } from '@newStore/user/userSelectors';

import {
  selectApiPendingOldSlice,
  selectApiWithPendingChangesOldSlice,
  selectDocumentTreeForOldSlice,
  selectAreContentAndProposalsLoaded,
  selectNewDocumentApi,
} from '@newStore/documentApi/documentApiSelectors';
import { setMode, newNodeDropped } from '@newStore/documentUI/documentUIState';
import { selectIsExternalDataLoading } from '@newStore/externalData/externalDataToLoadSelectors';
import { conditionalLogTime } from '@store/helpers/generalUtils';
import { selectIsNodeDeletable, selectLastRead } from '@newStore/documentUI/documentUISelectors';
import { formatDate } from '@newStore/genericHelpers';
import nodeTypeSelectorsMap from '@nodeTypeConfig/nodeTypeSelectorsMap';
import { selectIsDocumentValid } from '@newStore/validation/validationSelectors';
import { showValidationErrors } from '@newStore/documentList/newDocumentListState';
import { selectPersons } from '@newStore/externalData/externalDataSelectors';
import { nodeTypeDefinitions } from '@nodeTypeConfig/nodeTypeConfig';
import { getCreateDefaults } from '@nodeTypeConfig/helpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { expandLlinkidGoalRelations } from '@newStore/llinkid/llinkidActions';
import * as ACTION_TYPES from '../constants/actionTypes';
import { config as documentTypes } from '../constants/documentTypes';
import constants from '../constants/constants';
import { settings } from '../../config/settings';
import {
  fetchDocumentPrivateStateCmd,
  loadDocumentCmd,
  loadHrefsCmd,
  loadNamedSetsCmd,
  fetchThemeReferenceFramesMapCmd,
  fetchStudyProgrammesCmd,
  fetchLlinkidThemeReferencesCmd,
  fetchAllCmd,
  fetchTreeAsLeafCmd,
  fetchisIncludedInProThemeCmd,
  fetchRelationsWithExpandedPartCmd,
  loadPracticalExampleZillIllustrationsCmd,
  fetchDocumentProposalsCmd,
  sendProposalsBatchCmd,
  sendEmailCmd,
  fetchProposalsCreatorsCmd,
  fetchReferenceFramesExternalOptionsCmd,
  fetchProposalsExternalContentCmd,
  openWindowCmd,
  saveDocumentCmd,
  loadTeasersCmd,
  loadEventsCmd,
  fetchNewsletterSettingsCmd,
  loadSubjectsCmd,
  fetchEducationalActivityTypesCmd,
  fetchExternalDocumentCmd,
  getFacetSourceWebConfigsCmd,
  updateDocumentVmDebounced,
} from '../commands/documentCommands';
import {
  getPossibleDuplicateWebConfigs,
  getWebsitesAndTemplatesCmd,
  initDocumentWebsitesConfigurationCmd,
  recacheUrlsCmd,
} from '../commands/websitesCommands';
import {
  documentLoadingFailedAction,
  documentSavedAction,
  documentSaveFailedAction,
  setCollapesedNodesAction,
  setDocumentNodesAction,
  setPrivateStateAction,
  updateDocumentTreeAction,
  removeRelationAction,
  removeNodeAction,
  dirtyNodeAction,
  addNodeAction,
  addRelationAction,
  patchRelationAction,
  clearSelectionsAction,
  setDocumentAuthorsAction,
  patchNodeAction,
  setWebsiteConfigurationAction,
  setWebsiteThemeReferenceFramesAction,
  setFieldChoicesAction,
  setLlinkidThemeReferencesAction,
  setAllLlinkidCurriculumsAction,
  setLlinkidCurriculumGoalsAction,
  fillLlinkidCurriculumGoalsCompleteIdentifierAction,
  setLlinkidOdetGoalsAction,
  fillLlinkidOdetGoalsCompleteIdentifierAction,
  setPracticalExampleZillIllustrationsAction,
  setExpandedZillGoalSelectionsAction,
  setAllOfTypeAction,
  setTermReferencesAction,
  initWebsiteConfigurationAction,
  setDocumentProposalsAction,
  updateApiPendingAndWithChangesAction,
  suggestionsSubmittedAction,
  updateAsideViewModelAction,
  sendEmailOfSubmittedSuggestionsAction,
  closeSubmitSuggestionsModalAction,
  calculateSuggestionstoSubmitAction as calculateSuggestionsToSubmitAction,
  setExpandedProposalsCreatorsAction,
  setProposalsExternalContentAction,
  setExpandedAsideComponentsValuesAction,
  calculateSuggestionsToReviewAction,
  setExpandedResourcesValuesAction,
  expandResourcesAction,
  loadReferencesToDocumentAction,
  toggleCollapseAction,
  getTreeAsLeafForResourcesAction,
  setTreeAsLeafForResourcesAction,
  removeTermReferenceAction,
  proposedDeletionFailedAction,
  setExternalDocumentSectionsAction,
  sendDocumentPublishedMailAction,
  documentPublishedAction,
  setReferenceFrameExternalOptionsAction,
  addAttachment,
  fillRelationsWithExpandedResourcesAction,
  getReferenceFrameAction,
  setReferenceFrameAction,
  setSubjectsAction,
  setEducationalActivityTypesAction,
  loadNamedSetsAction,
  initWholeDocumentWebsiteThemeReferenceFramesAction,
  setNewsletterSettings,
  setLlinkidCurriculumPreviousVersionItemsAction,
  setSecondaryEducationTypes,
  initWebsiteThemeReferenceFramesAction,
  updateDocumentViewModelAction,
} from '../actions/documentActions';
import { createDocumentTree } from '../createDocumentTree.js';
import {
  sanitizeHTML,
  getReferenceFrameRelationDifferences,
  getGoalIdentifier,
  clearDemarcationLinks,
  getAnnotations,
  getKeyFromContentHref,
  getNewReadOrder,
  getResourceKey,
  getResourceType,
  findContent,
  getContentPermalink,
  addEditLinkReferenceNode,
  addLinkReferenceNode,
  editLinkReferenceNode,
  getProposedFileUploads,
  getResourcesToRemove,
  isTeaserAlreadyInSection,
  getRefFrameItemsMap,
  addNewNodeConditionalFields,
  getNewNodeConditionalWebconfigurations,
  getWebconfigurationPatch,
  getChildWebconfigurationsToUpdate,
  isValidProposalsSubmit,
  getRelationTree,
  getNodesByRelationKeys,
  getNodeTree,
  getTeaserPatchAction,
  getTeaserDeleteAction,
  deleteUploadsForDeletedNodes,
  getNamedSetsPatch,
  fillApiContentAndRelationsMap,
  fillApiProposalsMap,
  getExternalDocumentFlatTree,
  getRelationsToRemovedFacetReferenceFrame,
  hasToPatchNodeAttachments,
  getMaxReadOrder,
  createReferenceResources,
  getEventPatchAction,
  getRelationPatch,
  sortByReadorder,
  getRoot,
  getTeaserPosition,
  fillExpandedPartOfRelations,
  getPath,
  transformInput,
  getUniqueFileName,
} from '../helpers/documentHelpers';
import { addNotificationAction, removeNotificationAction } from '../actions/notificationActions';
import { nodeTypeConfigurations } from '../../config/nodeTypeConfigurations';
import {
  updateApiWithPendingChanges,
  isValidSuggestionDeleteAction,
  getRelatedContentHref,
  fillRelationsWithExpandedResources,
  getNotApplicableProposalsMap,
} from '../helpers/documentStateHelpers';
import { generateDocumentViewModel, treeToFlatVM } from '../viewmodels/createDocumentViewModel';
import { generateAsideViewModel } from '../viewmodels/createAsideViewModel';
import { getProposalType } from '../viewmodels/proposalViewModel';
import { publishProposalCmd, validateCmd } from '../commands/documentListCommands';
import {
  groupProposalsToSubmitByAuthors,
  getProposalForIssuedDate,
  createPatchProposalPendingActions,
  getNapPendingActions,
} from '../helpers/documentProposalsHelpers';
import { getOldLocations } from '../helpers/webConfigHelpers';
import { getDateOnly } from '../helpers/dateHelpers';
import { isExternalRelationUniqueInDocument } from '../../validations/isExternalRelationUniqueInDocument';
import * as apiRoutes from '../api/apiRoutes';

/**
 * This is the initial state of this reducer.
 */
export const initialState = {
  key: null,
  oldApi: {
    content: new Map(),
    relations: new Map(),
    proposals: new Map(),
    webpages: new Map(),
    fileUploads: new Map(),
    newsletterSettings: new Map(),
  },
  api: {
    content: new Map(),
    relations: new Map(),
    proposals: new Map(),
    webpages: new Map(),
    fileUploads: new Map(),
    newsletterSettings: new Map(),
  },
  pendingActions: [],
  apiPending: {
    content: [],
    relations: [],
    proposals: [],
    webpages: [],
    fileUploads: [],
    fileDeletes: [],
    newsletterSettings: new Map(),
  },
  apiWithPendingChanges: {
    content: new Map(), // content + content proposals CREATE
    relations: new Map(), // relations + relation proposals CREATE
    proposals: new Map(),
    webpages: new Map(),
    newsletterSettings: new Map(),
    contentRelations: {
      from: {},
      to: {},
    },
  },
  oldApiWithPendingChanges: {
    content: new Map(), // content + content proposals CREATE
    relations: new Map(), // relations + relation proposals CREATE
    proposals: new Map(),
    webpages: new Map(),
    newsletterSettings: new Map(),
    contentRelations: {
      from: {},
      to: {},
    },
  },
  tree: {},
  mode: 'EDIT', // 'EDIT',
  me: {},
  selections: [],
  allSelections: [],
  allSelectionsHref: [],
  saving: [],
  dirtyNodes: {},
  collapsedNodes: {},
  privateState: {},
  hoverOnCollapse: null,
  documentAuthors: [],
  authors: new Map(), // => (/content/<key>, [{../persons..}])
  selectChoices: {}, // choices by component field, general for any node of the doc (eg. applicab.)
  resourcesToExpand: [],
  expandedResources: {},
  notFoundResourcesSet: new Set(),
  resourcesToGetTreeAsLeaf: [],
  resourcesWithTreeAsLeaf: {},
  isIncludedInProTheme: null,
  websitesReferenceFramesMap: {},
  referenceFrameExternalOptions: {},
  llinkidThemeReferences: {}, // eg. educationalPointers: [..themes..]
  llinkidCurriculums: [], // all llinkid curriculums needed for goal relations
  llinkidOdetCurriculum: {}, // special odet curriculum needed for llinkid goal relations
  zillOdetCurriculum: undefined, // referenced odet curriclum by root zill curriculum,
  zillCurriculums: [], // all zill curriculums needed eg. for practical example zill illustration,
  selectedZillGoals: [], // when choosing goals from zill-selector
  loadingPracticalExampleZillIllustrations: false,
  practicalExamples: [], // all practical example needed for zill illustration reference selection
  termReferences: [],
  acceptingSuggestions: [],
  proposalsToSubmit: [],
  allProposalsToSubmit: [],
  proposalsToReview: [],
  allProposalsToReview: [],
  groupedProposalsToSubmitByAuthor: [],
  viewModel: {
    loading: true,
    initialLoadOngoing: true,
    aside: {
      editDocument: {},
      loading: true,
    },
    websiteContacts: [],
    websites: [], // from /web/sites
    loadingWebsitesConfiguration: false,
    suggestions: {},
    isRefreshDisabled: true,
    allowedAbilities: [],
  },
  referenceFrame: {},
  callToActions: new Map(),
  linkedContentTypes: new Map(),
  namedSets: new Map(),
  possibleDuplicateWebConfigs: [],
  facetSourceWebConfigsMap: new Map(),
  publishModalOpen: false, // state of the publish modal
};

export const documentReducer = (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTION_TYPES.OPEN_WINDOW: {
      const url = settings.apisAndUrls.newsletterPreview.replace('{%key}', action.payload.key);

      return loop(
        state,
        Cmd.run(openWindowCmd, { args: [action.payload.window, url, 'newsletter'] })
      );
    }

    case ACTION_TYPES.SET_LINKED_CALL_TO_ACTION: {
      const label = sanitizeHTML(action.payload.label, 'clearAll');
      const pendingActions = addEditLinkReferenceNode(
        state,
        action.payload.parentKey,
        action.payload.referenceKey,
        action.payload.label,
        action.payload.referencedResourceHref
      );
      const callToActions = new Map(state.callToActions);
      callToActions.set(action.payload.parentKey, label);

      return loop(
        {
          ...state,
          pendingActions,
          callToActions,
        },
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.DOCUMENT_CLEAR_REDIRECT: {
      const newState = { ...state };

      delete newState.redirect;

      return newState;
    }

    case ACTION_TYPES.INIT_DOCUMENT: {
      return loop(
        {
          ...state,
          viewModel: {
            ...state.viewModel,
            initialLoadOngoing: true,
          },
          key: action.payload.key,
          editKey: action.payload.editKey,
          isIncludedInProTheme: null,
          mode: 'EDIT',
        },
        Cmd.list(
          [
            Cmd.list([
              Cmd.run(fetchDocumentPrivateStateCmd, {
                args: [action.payload.key, selectUser(rootState).key],
                successActionCreator: setPrivateStateAction,
              }),
              Cmd.action(initWebsiteConfigurationAction(action.payload.key)), // fetch duplicate old stuff
              Cmd.run(getWebsitesAndTemplatesCmd, {
                successActionCreator: (websites) => ({
                  type: ACTION_TYPES.SET_ALL_WEBSITES,
                  payload: websites,
                }),
              }),
            ]),
            Cmd.list(
              [
                Cmd.run(loadDocumentCmd, {
                  args: [action.payload.key],
                  successActionCreator: setDocumentNodesAction, // fetch old duplicate stuff (payload) => ({ type: 'SET_OLD_DOCUMENT_NODES', payload })
                  failActionCreator: (error) => documentLoadingFailedAction(error, state),
                }),
                Cmd.run(fetchDocumentProposalsCmd, {
                  args: [action.payload.key],
                  successActionCreator: setDocumentProposalsAction, // fetch old duplicate stuff (payload) => ({ type: 'SET_OLD_DOCUMENT_PROPOSALS', payload }),
                }),
                Cmd.action(
                  getReferenceFrameAction({
                    key: constants.dienstverleningKovKey,
                  })
                ),
              ],
              { batch: true }
            ),
          ],
          { sequence: true }
        )
      );
    }

    /* case 'SET_OLD_DOCUMENT_NODES': {
      const resources = action.payload;
      const api = {
        ...state.api,
        ...fillApiContentAndRelationsMap(resources),
      };

      const oldApiWithPendingChanges = updateApiWithPendingChanges(api, state.apiPending, state.mode);

      return {
        ...state,
        api,
        oldApiWithPendingChanges,
      };
    } */

    case ACTION_TYPES.SET_DOCUMENT_NODES: {
      const resources = action.payload;
      const oldApi = {
        ...state.oldApi,
        ...fillApiContentAndRelationsMap(resources),
      };

      const oldApiWithPendingChanges = updateApiWithPendingChanges(
        oldApi,
        state.apiPending,
        state.mode,
        state.expandedResources
      );

      // const rootNode = resources.find((x) => x.key === state.key); // ;newDocumentApi.content.get('/content/' + state.key)
      // const documentType = getOldNodeType(rootNode);
      // const preloadActions = getNodeTypeConfig(documentType).preloadActions;

      return loop(
        {
          ...state,
          oldApi,
          oldApiWithPendingChanges,
        },
        Cmd.list([
          Cmd.action(loadReferencesToDocumentAction()), // dit is waar die treeAsLeaf wordt aangeroepen. TODO: doen wij dit ook? moeten wij dat ook doen?
          Cmd.action(updateDocumentTreeAction(false, false)), // already called in setDocumentProposalsAction
          // Cmd.action(setDocumentProposalsAction()), // for refactor moved to here: since proposals are fetched by new reducer we already have them at this point
          // Cmd.action(calculateDefaultCollapsedNodesAction()), // needs the tree created in previous action // moved to the updateDocumentTreeAction
          /* Cmd.action({
            type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL
          }), */
          Cmd.action(initWholeDocumentWebsiteThemeReferenceFramesAction()),
          /* Cmd.action(({ moved to documentApiSaga onProposalsFetched
            type: ACTION_TYPES.TRIGGER_PRELOAD_ACTIONS,
            payload: { preloadActions }
          })) */
        ])
      );
    }

    case ACTION_TYPES.TRIGGER_PRELOAD_ACTIONS: {
      const { preloadActions } = action.payload;

      if (preloadActions && preloadActions.length) {
        return loop(state, Cmd.list(preloadActions.map((pla) => Cmd.action(pla))));
      }
      return state;
    }

    /* case 'SET_OLD_DOCUMENT_PROPOSALS': {
      const orderedProposals = orderBy(action.payload, ['$$meta.modified']);
      const proposals = fillApiProposalsMap(orderedProposals);

      const proposedDeleteNodes = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [nodeHref, proposal] of proposals.entries()) {
        if (getProposalType(proposal) === 'DELETE') {
          proposedDeleteNodes.push(nodeHref);
        }
      }

      const newApi = {
        ...state.api,
        proposals,
      };

      return loop({
        ...state,
        api: newApi,
      }, Cmd.list([
        Cmd.run(fetchProposalsCreatorsCmd, { // TODO can be removed
          args: [Array.from(new Set([...proposals.values()]
            .reduce((r, proposal) => [...r, ...proposal.creators], [])))],
          successActionCreator: setExpandedProposalsCreatorsAction
        }),
        Cmd.run(fetchProposalsExternalContentCmd, { // TODO can be removed
          args: [[...proposals.values()]],
          successActionCreator: setProposalsExternalContentAction
        }),
      ]));
    } */

    case ACTION_TYPES.SET_DOCUMENT_PROPOSALS: {
      const orderedProposals = orderBy(action.payload, ['$$meta.modified']);
      const proposals = fillApiProposalsMap(orderedProposals);

      const proposedDeleteNodes = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [nodeHref, proposal] of proposals.entries()) {
        if (getProposalType(proposal) === 'DELETE') {
          proposedDeleteNodes.push(nodeHref);
        }
      }

      const oldApi = {
        ...state.oldApi,
        proposals,
      };

      return loop(
        {
          ...state,
          oldApi,
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction(true)),
          Cmd.run(fetchProposalsCreatorsCmd, {
            args: [
              Array.from(
                new Set(
                  [...proposals.values()].reduce((r, proposal) => [...r, ...proposal.creators], [])
                )
              ),
            ],
            successActionCreator: setExpandedProposalsCreatorsAction,
          }),
          Cmd.run(fetchProposalsExternalContentCmd, {
            args: [[...proposals.values()]],
            successActionCreator: setProposalsExternalContentAction,
          }),
          // state.mode === 'SUGGESTING' ? Cmd.action(calculateSuggestionsToSubmitAction()) : Cmd.none, -> moved to documentApiSaga onProposalsFetched
          // state.mode === 'REVIEWING' ? Cmd.action(calculateSuggestionsToReviewAction()) : Cmd.none, -> moved to documentApiSaga onProposalsFetched
          // state.mode !== 'EDIT' ? Cmd.action(setCollapesedNodesAction(proposedDeleteNodes)) : Cmd.none -> moved to documentApiSaga onProposalsFetched
        ])
      );
    }

    // REFECTOR-TODO: can be removed if document.api is removed, we will fix this in a selector
    case ACTION_TYPES.SET_EXPANDED_PROPOSALS_CREATORS: {
      const proposals = new Map(state.oldApi.proposals);

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, proposal] of proposals) {
        proposal.expandedCreators = proposal.creators.map(
          (creatorHref) => action.payload.find((c) => c.$$meta.permalink === creatorHref) || {}
        );
        proposals.set(key, { ...proposal });
      }

      const oldApi = {
        ...state.oldApi,
        proposals,
      };

      return loop(
        {
          ...state,
          oldApi,
        },
        Cmd.action(updateDocumentTreeAction())
      );
    }

    // REFACTOR-TODO: should be removed once api is replaced
    case ACTION_TYPES.SET_PROPOSALS_EXTERNAL_CONTENT: {
      const contents = new Map(state.oldApi.content);

      // eslint-disable-next-line no-restricted-syntax
      action.payload.forEach((content) => {
        contents.set(content.$$meta.permalink, content);
      });

      const oldApi = {
        ...state.oldApi,
        content: contents,
      };

      return loop(
        {
          ...state,
          oldApi,
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.UPDATE_DOCUMENT_TREE: {
      if (!state.key) {
        return {
          ...state,
        };
      }

      // const oldTreeTimeEnd = conditionalLogTime('calculate old document tree', 250);
      // const oldTree = createDocumentTree(
      //   state.key,
      //   state.oldApiWithPendingChanges.content,
      //   state.oldApiWithPendingChanges.contentRelations.to
      // );
      // oldTreeTimeEnd();

      const newTree = selectDocumentTreeForOldSlice(rootState);

      const isTreeNotEmpty = Object.keys(newTree).length > 0;

      return loop(
        {
          ...state,
          // oldTree,
          ...{
            tree: newTree,
          },
          forceHashUpdateToAll: action.payload.forceHashUpdateToAll,
        },
        Cmd.list([
          state.mode === 'SUGGESTING' ? Cmd.action(calculateSuggestionsToSubmitAction()) : Cmd.none,
          state.mode === 'REVIEWING' ? Cmd.action(calculateSuggestionsToReviewAction()) : Cmd.none,
          action.payload.updateViewModels && isTreeNotEmpty
            ? Cmd.action({
                type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
              })
            : Cmd.none,
        ])
      );
    }

    case ACTION_TYPES.FILL_RELATIONS_WITH_EXPANDED_RESOURCES: {
      // do we still need this? a selector should do whatever this does. (Stefan) -- it does now!
      // return state;
      if (state.oldApiWithPendingChanges.relations.size === 0) {
        return state;
      }

      const relations = fillRelationsWithExpandedResources(
        state.oldApiWithPendingChanges.relations,
        state.expandedResources
      );

      return {
        ...state,
        oldApiWithPendingChanges: {
          ...state.oldApiWithPendingChanges,
          relations: new Map(relations.map((r) => [`/content/relations/${r.key}`, r])),
        },
      };
    }

    case ACTION_TYPES.UPDATE_API_PENDING_AND_WITH_CHANGES: {
      // const oldApiPending = updateApiPending(
      //   state.oldApi,
      //   state.oldApiWithPendingChanges,
      //   state.pendingActions,
      //   state.mode,
      //   state.oldTree.key,
      //   selectUser(rootState)
      // );
      // const oldApiWithPendingChanges = updateApiWithPendingChanges(
      //   state.oldApi,
      //   oldApiPending,
      //   state.mode
      // );
      // const resourcesToExpand = getResourcesToExpand(
      //   [...oldApiWithPendingChanges.content.values()],
      //   state.expandedResources,
      //   state.notFoundResourcesSet
      // );
      const newApiWithPendingChanges = selectApiWithPendingChangesOldSlice(rootState);

      return loop(
        // {
        //   ...state,
        //   oldApiPending,
        //   oldApiWithPendingChanges,
        //   resourcesToExpand,
        // },
        state,
        Cmd.run(getPossibleDuplicateWebConfigs, {
          args: [[...newApiWithPendingChanges.webpages.values()]],
          successActionCreator: (possibleDuplicateWebConfigs) => ({
            type: ACTION_TYPES.UPDATE_API_PENDING_AND_WITH_CHANGES_SUCCESS,
            payload: { possibleDuplicateWebConfigs },
          }),
        })
      );
    }

    case ACTION_TYPES.UPDATE_API_PENDING_AND_WITH_CHANGES_SUCCESS: {
      return loop(
        {
          ...state,
          possibleDuplicateWebConfigs: action.payload.possibleDuplicateWebConfigs,
        },
        Cmd.list([
          // Cmd.action({ type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL }),
          Cmd.action(expandResourcesAction()),
        ])
      );
    }

    case ACTION_TYPES.UPDATE_API_WITH_PENDING_CHANGES_AND_UPDATE_DOCUMENT_TREE: {
      return loop(
        state,
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { sequence: true, batch: true }
        )
      );
    }

    case ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL: {
      const api = selectNewDocumentApi(rootState);
      const apiPending = selectApiPendingOldSlice(rootState);
      const apiWithPendingChanges = selectApiWithPendingChangesOldSlice(rootState);

      return loop(
        { ...state, api, apiWithPendingChanges, apiPending },
        Cmd.run(updateDocumentVmDebounced, { args: [Cmd.dispatch, action.payload] })
      );
    }

    case 'UPDATE_DOCUMENT_VIEW_MODEL_DEBOUNCED': {
      const isDocmentFinishedLoading = selectAreContentAndProposalsLoaded(rootState);
      if (!state.key || !isDocmentFinishedLoading) {
        return state;
      }
      const calculateVmEnd = conditionalLogTime('Calculate VM', 250);

      // i added the forceHashUpdateToAll to the payload of the action here, so we can force the hash update from our saga, on update of tree.
      const forceHashUpdateToAll =
        action.payload?.forceHashUpdateToAll || state.forceHashUpdateToAll;

      const api = selectNewDocumentApi(rootState);
      const apiPending = selectApiPendingOldSlice(rootState);
      const apiWithPendingChanges = selectApiWithPendingChangesOldSlice(rootState);
      const tree = selectDocumentTreeForOldSlice(rootState);

      const isTreeNotEmpty = Object.keys(tree).length > 0;
      if (!isTreeNotEmpty) {
        calculateVmEnd();
        return state;
      }

      /* const collapsedNodes = rootState.documentUI.privateState
        ? Object.fromEntries([
            ...rootState.documentUI.privateState.state.collapsedNodes.map((href) => [href, true]),
            ...rootState.documentUI.uncollapsedDefaultCollapsedNodes.map((href) => [href, false]),
          ])
        : {}; */

      const areExternalResourcesLoading = selectIsExternalDataLoading(rootState);

      const lastRead = selectLastRead(rootState);

      const newViewModel = generateDocumentViewModel(
        {
          ...state,
          api,
          apiWithPendingChanges,
          apiPending,
          tree,
          lastRead,
          // markedAllRead: TODO could be just selector: are there nodes modified after lastRead we don't care if it is for now not disabled if they click again the lastRead is just updated again
          forceHashUpdateToAll,
        },
        selectAllowedAbilities(rootState),
        areExternalResourcesLoading,
        rootState
      );

      console.log('new Viewmodel', newViewModel);
      calculateVmEnd();

      const cmdList = [];

      // logic to test how long it takes for the loop to handle its cmdList
      // const timeId = uuidv4();
      // console.time(`${timeId}: UPDATE_DOCUMENT_VIEW_MODEL`);

      // cmdList.push(
      //   Cmd.run(
      //     (timeIdLoop) => {
      //       console.log('UPDATE_DOCUMENT_VIEW_MODEL loop has run');
      //       console.timeEnd(`${timeIdLoop}: UPDATE_DOCUMENT_VIEW_MODEL`);
      //     },
      //     { args: [timeId] }
      //   )
      // );

      if (state.resourcesToExpand.length > 0) {
        cmdList.push(Cmd.action(expandResourcesAction()));
      }

      if (state.editKey) {
        cmdList.push(Cmd.action(updateAsideViewModelAction(state.editKey)));
      }

      const isValid = selectIsDocumentValid(rootState);
      return loop(
        {
          ...state,
          forceHashUpdateToAll: false,
          viewModel: {
            ...newViewModel,
            isValid,
          },
          api,
          apiWithPendingChanges,
          tree,
          apiPending,
        },
        Cmd.list(cmdList)
      );
    }

    case ACTION_TYPES.LOAD_REFERENCES_TO_DOCUMENT: {
      const relationsTo = state.apiWithPendingChanges.contentRelations.to[`/content/${state.key}`];

      const expandLeaf = [];
      const done = new Set([
        ...state.resourcesToGetTreeAsLeaf.map((e) => e.href),
        ...Object.keys(state.resourcesWithTreeAsLeaf),
      ]);

      if (relationsTo) {
        relationsTo
          .filter((e) => e.relationtype === 'REFERENCES')
          .map((e) => e.from)
          .forEach((e) => {
            if (!done.has(e.href)) {
              expandLeaf.push(e);
            }
          });
      }
      return loop(
        {
          ...state,
          resourcesToGetTreeAsLeaf: [...state.resourcesToGetTreeAsLeaf, ...expandLeaf],
        },
        Cmd.list([expandLeaf.length > 0 ? Cmd.action(getTreeAsLeafForResourcesAction()) : Cmd.none])
      );
    }

    case ACTION_TYPES.UPDATE_ASIDE_VIEW_MODEL: {
      const newDocumentApi = selectNewDocumentApi(rootState);
      const { editKey } = action.payload;
      const newAside = generateAsideViewModel(
        editKey,
        state.viewModel.flat[0],
        { ...state, api: newDocumentApi },
        rootState
      );

      return loop(
        {
          ...state,
          editKey,
          viewModel: {
            ...state.viewModel,
            aside: newAside,
          },
        },
        Cmd.list([
          newAside.resourcesToExpand.size > 0
            ? Cmd.run(loadHrefsCmd, {
                args: [Array.from(newAside.resourcesToExpand)],
                successActionCreator: setExpandedAsideComponentsValuesAction,
              })
            : Cmd.none,
          state.resourcesToGetTreeAsLeaf.length > 0
            ? Cmd.action(getTreeAsLeafForResourcesAction())
            : Cmd.none,
        ])
      );
    }

    case ACTION_TYPES.SET_EXPANDED_ASIDE_COMPONENTS_VALUES: {
      // some values in the aside components should be expanded from the api node hrefs
      const newExpandedResources = action.payload.results.reduce((list, result) => {
        if (!list.map((i) => i.$$meta.permalink).includes(result.$$meta.permalink)) {
          list.push(result);
        }
        return list;
      }, state.viewModel.aside.expandedResources);

      return loop(
        {
          ...state,
          viewModel: {
            ...state.viewModel,
            aside: {
              ...state.viewModel.aside,
              expandedResources: newExpandedResources,
              resourcesToExpand: new Set(),
            },
          },
        },
        Cmd.action(updateAsideViewModelAction(state.editKey))
      );
    }

    case ACTION_TYPES.EXPAND_RESOURCES: {
      const hrefsToExpand = state.resourcesToExpand.map((r) => r.href);
      const expandResources = !state.expandingResources && hrefsToExpand.length > 0;

      if (expandResources) {
        console.log('Expand resources:', hrefsToExpand);
      }

      return loop(
        {
          ...state,
          expandingResources: expandResources ? true : state.expandingResources,
        },
        expandResources
          ? Cmd.run(loadHrefsCmd, {
              args: [Array.from(hrefsToExpand)],
              successActionCreator: setExpandedResourcesValuesAction,
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_EXPANDED_RESOURCES_VALUES: {
      const newExpandedResources = {
        ...state.expandedResources,
      };
      const notFoundHrefs = [];
      const expandAsLeaf = [];

      action.payload.hrefs.forEach((href) => {
        const result = action.payload.results.find((r) => r.$$meta.permalink === href);
        if (!result) {
          notFoundHrefs.push(href);
        } else if (!newExpandedResources[result.$$meta.permalink]) {
          newExpandedResources[result.$$meta.permalink] = result;
          if (constants.needRootResource.some((e) => e === result.type)) {
            // /for 'SECTION' and 'REFERENCES'
            expandAsLeaf.push({ href: result.$$meta.permalink });
          }
        }
      });
      return loop(
        {
          ...state,
          expandedResources: newExpandedResources,
          resourcesToExpand: [],
          expandingResources: false,
          resourcesToGetTreeAsLeaf: expandAsLeaf,
          notFoundResourcesSet: new Set([...state.notFoundResourcesSet, ...notFoundHrefs]),
        },
        Cmd.list([
          expandAsLeaf.length > 0 ? Cmd.action(getTreeAsLeafForResourcesAction()) : Cmd.none,
          Cmd.action(fillRelationsWithExpandedResourcesAction()),
          Cmd.action(updateDocumentTreeAction()),
          !state.notFoundResourcesSet.size && notFoundHrefs.length > 0
            ? Cmd.action(
                addNotificationAction({
                  type: 'WARNING',
                  message: 'warning.notFoundResources',
                  removeAfter: 0,
                })
              )
            : Cmd.none,
        ])
      );
    }

    case ACTION_TYPES.GET_TREE_AS_LEAF_FOR_RESOURCES: {
      const getTreeAsLeaf = !state.gettingTreeAsLeaf && state.resourcesToGetTreeAsLeaf.length > 0;
      return loop(
        {
          ...state,
          gettingTreeAsLeaf: getTreeAsLeaf ? true : state.gettingTreeAsLeaf,
        },
        getTreeAsLeaf
          ? Cmd.run(fetchTreeAsLeafCmd, {
              args: [
                state.resourcesToGetTreeAsLeaf.map((r) => ({ key: getKeyFromContentHref(r.href) })),
              ],
              successActionCreator: setTreeAsLeafForResourcesAction,
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_TREE_AS_LEAF_FOR_RESOURCES: {
      const newResourcesWithTreeAsLeaf = {
        ...state.resourcesWithTreeAsLeaf,
      };

      const notFoundResources = [];

      action.payload.forEach((result) => {
        if (result && !newResourcesWithTreeAsLeaf[`/content/${result.key}`]) {
          newResourcesWithTreeAsLeaf[`/content/${result.key}`] = result.$$treeAsLeaf;
        } else if (!result) {
          notFoundResources.push(result);
        }
      });

      return loop(
        {
          ...state,
          resourcesWithTreeAsLeaf: newResourcesWithTreeAsLeaf,
          resourcesToGetTreeAsLeaf: [],
          gettingTreeAsLeaf: false,
        },
        Cmd.action({ type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL })
      );
    }

    case ACTION_TYPES.SET_CURRENT_USER: {
      return {
        ...state,
        me: action.payload.user,
      };
    }

    case ACTION_TYPES.REMOVE_RELATION: {
      const relation = state.apiWithPendingChanges.relations.get(
        `/content/relations/${action.payload.key}`
      );

      const fromContent = state.apiWithPendingChanges.content.get(relation.from.href);

      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'DELETE',
        resources: [
          {
            href: `/content/relations/${action.payload.key}`,
            relatedTo: action.payload.addRelatedTo
              ? { href: fromContent ? relation.from.href : relation.to.href }
              : undefined,
          },
        ],
      });
      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            action.payload.updateTree ? Cmd.action(updateDocumentTreeAction()) : Cmd.none,
          ],
          { sequence: true, batch: true }
        )
      );
    }

    case ACTION_TYPES.PATCH_RELATION: {
      const pendingActions = [...state.pendingActions];

      const relationsAll = new Map(state.apiWithPendingChanges.relations);
      const relation = relationsAll.get(`/content/relations/${action.payload.key}`);

      if (relation) {
        const fromContent = state.apiWithPendingChanges.content.get(
          action.payload.patch.from ? action.payload.patch.from.href : relation.from.href
        );

        // Create pending action
        pendingActions.push({
          type: 'PATCH',
          resources: [
            {
              href: `/content/relations/${action.payload.key}`,
              relatedTo: { href: fromContent ? relation.from.href : relation.to.href },
              patch: Object.keys(action.payload.patch).map((field) => {
                const operation = relation && relation[field] ? 'replace' : 'add';
                return { op: operation, path: `/${field}`, value: action.payload.patch[field] };
              }),
            },
          ],
        });
      }

      const { goalRelationsParams } = action.payload;
      if (goalRelationsParams) {
        state.goalRelationsExpanded = false;
      }

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.list([
              Cmd.action(
                dirtyNodeAction(
                  getKeyFromContentHref(
                    relationsAll.get(`/content/relations/${action.payload.key}`).to.href
                  )
                ),
                false
              ),
              Cmd.action(
                dirtyNodeAction(
                  getKeyFromContentHref(
                    relationsAll.get(`/content/relations/${action.payload.key}`).from.href
                  )
                ),
                false
              ),
            ]),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
            goalRelationsParams
              ? Cmd.action(
                  expandLlinkidGoalRelations({
                    key: goalRelationsParams.key,
                    relationTypes: goalRelationsParams.relationTypes,
                    originPart: goalRelationsParams.originPart,
                  })
                )
              : Cmd.none,
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.REMOVE_NODE: {
      if (!action.payload.key) {
        return state;
      }
      const relationsToRemove = [];
      if (state.apiWithPendingChanges.contentRelations.to[`/content/${action.payload.key}`]) {
        state.apiWithPendingChanges.contentRelations.to[`/content/${action.payload.key}`].forEach(
          (relation) => {
            relationsToRemove.push(relation.key);
          }
        );
      }

      if (state.apiWithPendingChanges.contentRelations.from[`/content/${action.payload.key}`]) {
        state.apiWithPendingChanges.contentRelations.from[`/content/${action.payload.key}`].forEach(
          (relation) => {
            relationsToRemove.push(relation.key);
          }
        );
      }

      const pendingActions = [...state.pendingActions];
      const pendingAction = {
        type: 'DELETE',
        resources: relationsToRemove.map((relationKey) => ({
          href: `/content/relations/${relationKey}`,
          relatedTo: { href: `/content/${action.payload.key}` },
        })),
      };

      if (action.payload.key) {
        pendingAction.resources.push({
          href: `/content/${action.payload.key}`,
          parentHref: action.payload.parentKey ? `/content/${action.payload.parentKey}` : undefined,
        });
      }

      const attachmentsToRemove = deleteUploadsForDeletedNodes(
        [`/content/${action.payload.key}`],
        state
      );
      if (attachmentsToRemove.length) {
        pendingActions.push({
          type: 'DELETE_UPLOAD',
          resources: attachmentsToRemove,
        });
      }

      if (pendingAction.resources.length > 0) {
        pendingActions.push(pendingAction);
      }

      if (state.viewModel.document.$$type === 'PRONEWSLETTER') {
        const newsletterSetting = state.apiWithPendingChanges.newsletterSettings
          .values()
          .next().value;
        const eventPatchAction = getEventPatchAction(
          action.payload.key,
          newsletterSetting,
          state.apiWithPendingChanges.content
        );
        pendingActions.push(eventPatchAction);
      }

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(dirtyNodeAction(action.payload.key, false)),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            action.payload.updateTree ? Cmd.action(updateDocumentTreeAction()) : Cmd.none,
          ],
          { sequence: true, batch: true }
        )
      );
    }

    case ACTION_TYPES.DIRTY_NODE: {
      return loop(
        {
          ...state,
          dirtyNodes: {
            ...state.dirtyNodes,
            [action.payload.key]: new Date().toISOString(),
          },
        },
        action.payload.updateTree ? Cmd.action(updateDocumentTreeAction()) : Cmd.none
      );
    }

    case ACTION_TYPES.TOGGLE_SELECTION: {
      let selections = [...state.selections];
      if (selections.includes(action.payload.relationKey)) {
        selections.splice(selections.indexOf(action.payload.relationKey), 1);
      } else {
        const relationTree = getRelationTree(
          action.payload.relationKey,
          state.apiWithPendingChanges.content,
          state.apiWithPendingChanges.relations
        );
        selections = selections.filter((r) => !relationTree.includes(r));
        selections.push(action.payload.relationKey);
      }

      const allSelections = getNodeTree(
        selections,
        state.apiWithPendingChanges.content,
        state.apiWithPendingChanges.relations
      );
      const allSelectionsHref = allSelections.map((key) =>
        getContentPermalink(key, state.apiWithPendingChanges.content)
      );
      const selectionTypes = selections.map((key) => {
        const relation = state.apiWithPendingChanges.relations.get(`/content/relations/${key}`);
        const node = state.apiWithPendingChanges.content.get(relation.from.href);
        return node.$$type;
      });

      return loop(
        {
          ...state,
          selections,
          allSelections,
          allSelectionsHref,
          selectionTypes,
        },
        Cmd.list([
          state.mode === 'SUGGESTING' ? Cmd.action(calculateSuggestionsToSubmitAction()) : Cmd.none,
          state.mode === 'REVIEWING' ? Cmd.action(calculateSuggestionsToReviewAction()) : Cmd.none,
          action.payload.updateTree
            ? Cmd.action({
                type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
              })
            : Cmd.none,
        ])
      );
    }

    case ACTION_TYPES.SET_SELECTION: {
      const toggleSelection =
        state.selections.includes(action.payload.relationKey) !== action.payload.isSelected;

      const cmdList = [];
      if (toggleSelection) {
        cmdList.push(
          Cmd.action({
            type: ACTION_TYPES.TOGGLE_SELECTION,
            payload: { relationKey: action.payload.relationKey },
          })
        );
      }

      cmdList.push(
        Cmd.action({ type: ACTION_TYPES.UPDATE_DOCUMENT_TREE, payload: { updateViewModels: true } })
      );

      return loop(state, Cmd.list(cmdList, { batch: true }));
    }

    case ACTION_TYPES.VALIDATE_AND_REMOVE_SELECTIONS: {
      const nodesWithChildrenToRemove = [...state.allSelections];
      const validSuggestionDeleteAction = isValidSuggestionDeleteAction(
        state.mode,
        nodesWithChildrenToRemove,
        state.apiWithPendingChanges.proposals
      );

      if (!validSuggestionDeleteAction) {
        return loop({ ...state }, Cmd.action(proposedDeletionFailedAction()));
      }

      // node delete validations that are processed with a command
      const deleteValidationWithCmds = [];
      // node delete validations that can be run without a cmd run and failed
      const deleteValidationFailed = [];

      nodesWithChildrenToRemove.forEach((nodeKey) => {
        const node = findContent(nodeKey, state.apiWithPendingChanges.content);
        const nodeSelectors = node.$$typeConfig && nodeTypeSelectorsMap[node.$$type];
        if (nodeSelectors && nodeSelectors.deleteValidations) {
          nodeSelectors.deleteValidations.forEach((deleteValidation) => {
            const ranValidation = deleteValidation(node, state);
            if (ranValidation.cmd) {
              deleteValidationWithCmds.push(ranValidation);
            } else if (ranValidation !== true) {
              deleteValidationFailed.push(ranValidation);
            }
          });
        }
        if (!selectIsNodeDeletable(rootState, node.$$meta.permalink)) {
          deleteValidationFailed.push({
            message: 'Je wilt een item verwijderen dat al is gepubliceerd. Dit is niet mogelijk.',
          });
        }
      });

      if (deleteValidationFailed.length) {
        // validation actions were ran already, some failed
        return loop(
          { ...state },
          Cmd.action(
            addNotificationAction({
              type: 'ERROR',
              message: deleteValidationFailed[0].message,
              removeAfter: 15,
            })
          )
        );
      }

      // check for not applicable proposals
      state.notApplicableProposalsMap = getNotApplicableProposalsMap(
        nodesWithChildrenToRemove,
        state.apiWithPendingChanges.proposals,
        state.mode
      );
      const actionType = {
        type: state.notApplicableProposalsMap.size
          ? ACTION_TYPES.OPEN_NOT_APPLICABLE_PROPOSALS_MODAL
          : ACTION_TYPES.REMOVE_SELECTIONS,
      };

      if (!deleteValidationWithCmds.length) {
        // we don't need extra validations, proceed with the remove
        return loop({ ...state }, Cmd.action(actionType));
      }

      // add cmd run validations, api calls needed
      return loop(
        { ...state },
        Cmd.run(validateCmd, {
          args: [deleteValidationWithCmds],
          successActionCreator: () => actionType, // resultsDeleted,
          failActionCreator: showValidationErrors,
        })
      );
    }

    case ACTION_TYPES.REMOVE_SELECTIONS: {
      const nodeKeysToRemove = getNodesByRelationKeys(
        state.selections,
        state.apiWithPendingChanges.content,
        state.apiWithPendingChanges.relations
      );
      let resourcesToRemove = getResourcesToRemove(
        nodeKeysToRemove.map((n) => n.key),
        state.apiWithPendingChanges.content,
        state.apiWithPendingChanges.contentRelations,
        state
      );

      let pendingActions = [...state.pendingActions];
      if (state.viewModel.document.$$type === 'PRONEWSLETTER') {
        const nodeTreeToRemove = getNodeTree(
          state.selections,
          state.apiWithPendingChanges.content,
          state.apiWithPendingChanges.relations
        );
        const newsletterSetting = state.apiWithPendingChanges.newsletterSettings
          .values()
          .next().value;
        const teaserPatchAction = getTeaserPatchAction(
          nodeTreeToRemove,
          newsletterSetting,
          state.apiWithPendingChanges.content
        );
        const teaserDeleteAction = getTeaserDeleteAction(
          state.selections,
          state.apiWithPendingChanges.content,
          state.apiWithPendingChanges.relations
        );

        pendingActions.push(teaserPatchAction);
        resourcesToRemove = [...resourcesToRemove, ...teaserDeleteAction];
      }

      pendingActions.push({
        type: 'DELETE',
        resources: resourcesToRemove,
      });

      // Remove attachments
      const attachmentsToRemove = deleteUploadsForDeletedNodes(
        resourcesToRemove.map((resource) => resource.href),
        state
      );

      if (attachmentsToRemove.length) {
        pendingActions.push({
          type: 'DELETE_UPLOAD',
          resources: attachmentsToRemove,
        });
      }

      // update status of not applicable proposals
      if (state.notApplicableProposalsMap && state.notApplicableProposalsMap.size) {
        pendingActions = pendingActions.concat(
          getNapPendingActions(state.notApplicableProposalsMap)
        );
        state.notApplicableProposalsMap.clear();
      }

      return loop(
        {
          ...state,
          pendingActions,
        },
        // eslint-disable-next-line no-nested-ternary
        Cmd.list(
          [
            Cmd.action(clearSelectionsAction()),
            state.mode !== 'EDIT'
              ? Cmd.list(state.selections.map((key) => Cmd.action(dirtyNodeAction(key, false))))
              : Cmd.none,
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { sequence: true, batch: true }
        )
      );
    }

    case ACTION_TYPES.CLEAR_SELECTIONS: {
      return loop(
        {
          ...state,
          allSelections: [],
          allSelectionsHref: [],
          selections: [],
          selectedTypes: [],
          callToActions: new Map(),
          linkedContentTypes: new Map(),
        },
        state.selections.length > 0
          ? Cmd.action({
              type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.UNDO_ALL: {
      const modifiedNodesKeys = state.pendingActions.reduce((list, pendingAction) => {
        pendingAction.resources.forEach((resource) => {
          if (getResourceType(resource.href) === 'content') {
            list.push(getResourceKey(resource.href));
          }
          if (resource.relatedTo && getResourceType(resource.relatedTo.href) === 'content') {
            list.push(getResourceKey(resource.relatedTo.href));
          }
          if (resource.parentHref && getResourceType(resource.parentHref) === 'content') {
            list.push(getResourceKey(resource.parentHref));
          }
        });
        return list;
      }, []);

      return loop(
        {
          ...state,
          pendingActions: [],
          websitesReferenceFramesMap: {},
        },
        Cmd.list([
          Cmd.list(modifiedNodesKeys.map((key) => Cmd.action(dirtyNodeAction(key, false)))),
          Cmd.action(clearSelectionsAction()),
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.SET_LINKED_CONTENT_TYPE: {
      const linkedContentTypes = new Map(state.linkedContentTypes);
      linkedContentTypes.set(action.payload.key, action.payload.type);
      return loop(
        {
          ...state,
          linkedContentTypes,
        },
        Cmd.action(removeNodeAction(action.payload.referenceKey))
      );
    }

    case ACTION_TYPES.ADD_EDIT_LINK_REFERENCE_NODE: {
      const pendingActions = addEditLinkReferenceNode(
        state,
        action.payload.parentKey,
        action.payload.referenceKey,
        action.payload.label,
        action.payload.referencedResourceHref
      );

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.PATCH_NODE: {
      // Annotations diff
      const annotationsDiff = (node) => {
        const oldAnnotations = node.$$annotations || [];
        const newAnnotations = getAnnotations(node);
        const newTermReferences = [];
        const removedTermReferences = [];

        const relationsToRemove = oldAnnotations
          .filter(
            (oa) =>
              !newAnnotations.find((na) => na.href === oa.href && oa.field === na.field) &&
              oa.type === 'term'
          )
          .map((annotation) => {
            removedTermReferences.push(annotation.href);
            return state.apiWithPendingChanges.contentRelations.from[
              `/content/${action.payload.key}`
            ].find((rel) => rel.to.href === annotation.href);
          })
          .filter((r) => r); // remove undefined (the relation has already been deleted, eg if the term was deleted)

        const relationsToAdd = newAnnotations
          .filter(
            (na) =>
              !oldAnnotations.find((oa) => oa.href === na.href && oa.field === na.field) &&
              ['term', 'mark-explanation'].includes(na.type)
          )
          .map((annotation) => {
            if (annotation.type === 'term') {
              newTermReferences.push(annotation.href);
            }
            return {
              relationtype: 'REFERENCES',
              from: { href: `/content/${action.payload.key}` },
              to: { href: annotation.href },
            };
          });

        const nodesToRemove = oldAnnotations
          .filter(
            (oa) =>
              !newAnnotations.find((na) => na.href === oa.href && oa.field === na.field) &&
              ['demarcation', 'footnote'].includes(oa.type)
          )
          .map((annotation) => state.api.get(annotation.href));

        const demarcationsToAdd = newAnnotations
          .filter(
            (na) =>
              !oldAnnotations.find((oa) => oa.href === na.href && oa.field === na.field) &&
              na.type === 'demarcation'
          )
          .map((na) => {
            relationsToAdd.push({
              relationtype: 'IS_PART_OF',
              from: { href: na.href },
              to: { href: `/content/${action.payload.key}` },
            });
            return {
              key: na.href.split('/')[2],
              type: 'LLINKID_GOAL_DEMARCATION',
              $$new: false,
            };
          });

        const footnotesToAdd = newAnnotations
          .filter(
            (na) =>
              !oldAnnotations.find((oa) => oa.href === na.href && oa.field === na.field) &&
              na.type === 'footnote'
          )
          .map((na) => {
            relationsToAdd.push({
              relationtype: 'IS_PART_OF',
              from: { href: na.href },
              to: { href: `/content/${action.payload.key}` },
            });
            return {
              key: na.href.split('/')[2],
              title: na.$$attribs.title,
              identifiers: [na.$$attribs.identifier],
              type: 'SOURCE',
              $$new: false,
            };
          });

        return {
          relationsToRemove,
          relationsToAdd,
          nodesToRemove,
          nodesToAdd: demarcationsToAdd.concat(footnotesToAdd),
          newTermReferences,
          removedTermReferences,
        };
      };

      const node = state.apiWithPendingChanges.content.get(`/content/${action.payload.key}`);

      const $$annotations = getAnnotations(node);
      node.$$annotations = $$annotations;

      // Update content
      const patch = {};
      Object.keys(action.payload.patch).forEach((field) => {
        const transformed = transformInput(
          node,
          field,
          action.payload.patch[field],
          action.payload.sanitizeInput
        );
        patch[transformed.field] = transformed.value;
      });

      // Add to pending actions
      const pendingActions = [...state.pendingActions];
      const resources = [
        {
          href: `/content/${action.payload.key}`,
          parentHref: action.payload.parentKey ? `/content/${action.payload.parentKey}` : undefined,
          patch: Object.keys(patch).map((field) => {
            const operation = node && node[field] ? 'replace' : 'add';
            return { op: operation, path: `/${field}`, value: patch[field] };
          }),
        },
      ];
      pendingActions.push({
        type: 'PATCH',
        resources,
      });

      const payloadPatch = Object.keys(action.payload.patch).map((field) => {
        const operation = node && node[field] ? 'replace' : 'add';
        return { op: operation, path: `/${field}`, value: action.payload.patch[field] };
      });

      const annotationsDiffResult = annotationsDiff(jsonPatch.apply({ ...node }, payloadPatch));

      // in special cases we need to modify the webconfiguration of the node when a field is updated
      const webconfiguration = getWebconfigurationPatch(node, payloadPatch, state);
      if (webconfiguration) {
        resources.push({
          href: `${apiRoutes.webpages}/${webconfiguration.key}`,
          relatedTo: {
            href: `/content/${node.key}`,
          },
          patch: webconfiguration.patch,
        });
      }

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list([
          Cmd.list(
            // remove
            annotationsDiffResult.nodesToRemove.map((n) => Cmd.action(removeNodeAction(n.key)))
          ),
          Cmd.list(
            annotationsDiffResult.nodesToAdd.map((n) => Cmd.action(addNodeAction(n.type, n)))
          ),
          Cmd.list(
            annotationsDiffResult.relationsToRemove.map((rel) =>
              Cmd.action(removeRelationAction(rel.key))
            )
          ),
          Cmd.list(
            annotationsDiffResult.relationsToAdd.map((rel) => Cmd.action(addRelationAction(rel)))
          ),
          Cmd.list(
            annotationsDiffResult.removedTermReferences.map((href) =>
              Cmd.action(removeTermReferenceAction(href))
            )
          ),
          action.payload.updateTree
            ? Cmd.list([
                Cmd.action(dirtyNodeAction(action.payload.key, false)),
                Cmd.action(updateApiPendingAndWithChangesAction()),
                Cmd.action(updateDocumentTreeAction()),
              ])
            : Cmd.none,
        ])
      );
    }

    // MOVED TO DOCUMENTAPISAGAS

    // case ACTION_TYPES.SAVE_DOCUMENT: {
    //   return loop(
    //     state,
    //     Cmd.action({ type: ACTION_TYPES.LOAD_DATA_TO_SAVE_DOCUMENT, payload: { ...action.payload, webpages: [...state.apiWithPendingChanges.webpages.values()] } })
    //   );
    // }

    // case ACTION_TYPES.LOAD_DATA_TO_SAVE_DOCUMENT: {
    //   return loop(
    //     state,
    //     Cmd.run(getPossibleDuplicateWebConfigs, {
    //       args: [action.payload.webpages],
    //       successActionCreator: (possibleDuplicateWebConfigs) => ({ type: ACTION_TYPES.LOAD_DATA_TO_SAVE_DOCUMENT_SUCCESS, payload: { ...action.payload, possibleDuplicateWebConfigs } })
    //     })
    //   );
    // }

    case ACTION_TYPES.LOAD_DATA_TO_SAVE_DOCUMENT_SUCCESS: {
      const api = selectNewDocumentApi(rootState);
      const apiPending = selectApiPendingOldSlice(rootState);
      const apiWithPendingChanges = selectApiWithPendingChangesOldSlice(rootState);
      const tree = selectDocumentTreeForOldSlice(rootState);

      const newViewModel = generateDocumentViewModel(
        {
          ...state,
          api,
          apiWithPendingChanges,
          apiPending,
          tree,
          possibleDuplicateWebConfigs: action.payload.possibleDuplicateWebConfigs,
          saving: [...state.pendingActions],
        },
        selectAllowedAbilities(rootState),
        undefined,
        rootState
      );

      const isValid = selectIsDocumentValid(rootState);

      if (!isValid) {
        return loop(
          {
            ...state,
            forceHashUpdateToAll: false,
            viewModel: { ...newViewModel, isValid },
            possibleDuplicateWebConfigs: action.payload.possibleDuplicateWebConfigs,
          },
          state.resourcesToExpand.length > 0 ? Cmd.action(expandResourcesAction()) : Cmd.none
        );
      }

      const proposedFileUploads = getProposedFileUploads(state.apiPending.proposals);
      const filesToUploadCount = state.apiPending.fileUploads.length + proposedFileUploads.length;
      const cmdList = [];
      let saving = [...state.pendingActions];

      if (
        state.apiPending.content.length +
          state.apiPending.relations.length +
          state.apiPending.webpages.length +
          state.apiPending.newsletterSettings.length +
          state.apiPending.fileUploads.length >
          0 &&
        state.apiPending.proposals.length === 0
      ) {
        cmdList.push(
          Cmd.run(saveDocumentCmd, {
            args: [
              [
                ...state.apiPending.content,
                ...state.apiPending.relations,
                ...state.apiPending.fileDeletes,
              ],
              // .filter(elem => !elem.doNotSendToApi), // TODO: we don't want to send to api only when the resource modification is only an attachment upload/delete
              state.apiPending.webpages,
              state.apiPending.newsletterSettings,
              state.apiPending.fileUploads,
            ],
            successActionCreator: documentSavedAction,
            failActionCreator: (error) => documentSaveFailedAction(error, state),
          })
        );
      } else if (state.apiPending.proposals.length > 0) {
        cmdList.push(
          Cmd.run(sendProposalsBatchCmd, {
            args: [
              state.apiPending.proposals,
              [
                ...state.apiPending.content,
                ...state.apiPending.relations,
                ...state.apiPending.fileDeletes,
              ],
              // .filter(elem => !elem.doNotSendToApi), // TODO: we don't want to send to api only when the resource modification is only an attachment upload/delete
              state.apiPending.webpages,
              proposedFileUploads,
              state.apiPending.fileUploads,
            ],
            successActionCreator: documentSavedAction,
            failActionCreator: (error) => documentSaveFailedAction(error, state),
          })
        );
      } else {
        saving = [];
      }

      return loop(
        {
          ...state,
          saving,
          pendingActions: [],
          forceHashUpdateToAll: false,
          viewModel: { ...newViewModel, isValid },
          possibleDuplicateWebConfigs: action.payload.possibleDuplicateWebConfigs,
        },
        Cmd.list([
          filesToUploadCount > 0
            ? Cmd.action(
                addNotificationAction({
                  code: 'files.uploading',
                  type: 'WARNING',
                  message: 'warning.uploadingFiles',
                  params: {
                    countFiles: filesToUploadCount,
                  },
                  removeAfter: 0,
                })
              )
            : Cmd.none,
          state.resourcesToExpand.length > 0 ? Cmd.action(expandResourcesAction()) : Cmd.none,
          Cmd.list(cmdList, { sequence: true, batch: true }),
        ])
      );
    }

    case ACTION_TYPES.DOCUMENT_SAVED: {
      const newDocumentApi = selectNewDocumentApi(rootState);
      // const newApi = applyApiPending(state.oldApi, state.oldApiPending, true);
      const cmdList = [];
      if (getDateOnly(new Date(state.tree.issued)) <= getDateOnly(new Date())) {
        cmdList.push(
          Cmd.run(recacheUrlsCmd, {
            args: [newDocumentApi.webpages],
          })
        );
      }
      return loop(
        {
          ...state,
          saving: [],
          viewModel: { ...state.viewModel, isSaving: false, itemsToSave: 0 },
          // oldApi: newApi,
          pendingActions: [],
          failedSavingUploads: false,
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
          Cmd.action(addNotificationAction({ type: 'SUCCESS', message: 'saveSuccessMessage' })),
          Cmd.action(removeNotificationAction('files.uploading')),
          Cmd.list(cmdList),
        ])
      );
    }

    case ACTION_TYPES.DOCUMENT_SAVE_FAILED: {
      // revert to pending actions all that couldn't be saved
      console.error('DOCUMENT_SAVE_FAILED:', action.errors);

      const newPendingActions = [...state.pendingActions, ...state.saving];
      return loop(
        {
          ...state,
          saving: [],
          pendingActions: newPendingActions,
          contentFailed: true,
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action({
            type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
          }),
          Cmd.action(addNotificationAction({ type: 'ERROR', message: 'edit.saveErrorMessage' })),
          Cmd.action(removeNotificationAction('files.uploading')),
        ])
      );
    }

    case ACTION_TYPES.ATTACHMENT_SAVE_FAILED: {
      // revert to pending actions all that couldn't be saved
      const newPendingActions = [...state.pendingActions, ...state.saving];
      const message =
        action.payload.data && action.payload.data.errors && action.payload.data.errors.length > 0
          ? action.payload.data.errors[0].message
          : 'edit.saveAttachmentErrorMessage';

      return loop(
        {
          ...state,
          saving: [],
          pendingActions: newPendingActions,
          failedSavingUploads: true,
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(addNotificationAction({ type: 'ERROR', message })),
        ])
      );
    }

    case ACTION_TYPES.FAILED_LOADING_DOCUMENT: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          error: action.payload.status || 999,
          loading: false,
        },
      };
    }

    case ACTION_TYPES.PROPOSED_DELETION_VALIDATION_ERROR: {
      return loop(
        {
          ...state,
        },
        Cmd.list([
          Cmd.action(
            addNotificationAction({
              type: 'ERROR',
              message: 'edit.suggestions.proposedDeletionErrorMessage',
            })
          ),
        ])
      );
    }

    case setMode.type: {
      let newMode = action.payload;
      if (newMode === 'REVIEW') {
        newMode = 'REVIEWING';
      }
      if (newMode === 'SUGGEST') {
        newMode = 'SUGGESTING';
      }
      return loop(
        {
          ...state,
          mode: newMode || 'EDIT',
        },
        Cmd.list([
          Cmd.action(clearSelectionsAction()),
          Cmd.action(calculateSuggestionsToSubmitAction()),
          Cmd.action(calculateSuggestionsToReviewAction()),
          // Cmd.action(updateApiPendingAndWithChangesAction()),
          // Cmd.action(updateDocumentTreeAction())
        ])
      );
    }

    case ACTION_TYPES.SET_PRIVATE_STATE: {
      return loop(
        {
          ...state,
          ...{ privateState: action.payload },
          lastRead: action.payload.state.lastRead,
        },
        Cmd.list([Cmd.action(setCollapesedNodesAction(action.payload.state.collapsedNodes))], {
          batch: true,
        })
      );
    }

    case ACTION_TYPES.SET_COLLAPSED_NODES: {
      const newCollapsedNodes = Object.fromEntries(action.payload.map((z) => [z, true]));
      const collapsedNodes = { ...state.collapsedNodes, ...newCollapsedNodes };
      return loop(
        {
          ...state,
          ...{
            collapsedNodes,
          },
        },
        Cmd.list(
          action.payload.map((o) => Cmd.action(dirtyNodeAction(o.replace('/content/', ''), false)))
        )
      );
    }

    case ACTION_TYPES.LAST_READ_MARK_UPDATED: {
      return loop(
        {
          ...state,
          markedAllRead: true,
        },
        Cmd.none
        /* Cmd.list([
          Cmd.action(updateDocumentTreeAction()),
          state.showLastReadUpdatedNotification
            ? Cmd.action(addNotificationAction({ type: 'SUCCESS', message: 'lastRead.markUpdated' }))
            : Cmd.none
        ]) */
      );
    }

    case ACTION_TYPES.LOAD_DOCUMENT_AUTHORS: {
      return loop(
        {
          ...state,
        },
        Cmd.run(loadHrefsCmd, {
          args: [action.payload.hrefs, action.payload.key],
          successActionCreator: setDocumentAuthorsAction,
        })
      );
    }

    case ACTION_TYPES.SET_DOCUMENT_AUTHORS: {
      const newExpandedResources = {
        ...state.expandedResources,
      };

      action.payload.results.forEach((result) => {
        if (!newExpandedResources[result.$$meta.permalink]) {
          newExpandedResources[result.$$meta.permalink] = result;
        }
      });

      return loop(
        {
          ...state,
          expandedResources: newExpandedResources,
          viewModel: {
            ...state.viewModel,
            aside: {
              ...state.viewModel.aside,
              authors: action.payload.results,
            },
          },
        },
        Cmd.action(updateAsideViewModelAction(state.editKey))
      );
    }

    case ACTION_TYPES.LOAD_NAMED_SETS: {
      return loop(
        state,
        Cmd.run(loadNamedSetsCmd, {
          args: [action.payload.tag],
          successActionCreator: (results) => ({
            type: ACTION_TYPES.SET_NAMED_SETS,
            payload: { tag: action.payload.tag, namedSets: results },
          }),
        })
      );
    }

    case ACTION_TYPES.SET_NAMED_SETS: {
      const namedSets = new Map(state.namedSets);
      namedSets.set(action.payload.tag, action.payload.namedSets);

      return loop(
        {
          ...state,
          namedSets,
        },
        Cmd.action(updateAsideViewModelAction(state.editKey))
      );
    }

    case ACTION_TYPES.UPDATE_NAMED_SETS: {
      const patch = getNamedSetsPatch(action.payload.property, action.payload.namedSets);

      return loop(
        state,
        Cmd.action({
          type: ACTION_TYPES.PATCH_NODE,
          payload: {
            key: state.viewModel.aside.editDocument.key,
            patch,
            updateTree: true,
          },
        })
      );
    }

    case ACTION_TYPES.SELECT_NAMED_SETS: {
      const namedSets = state.viewModel.aside.editDocument.namedSetsOptions
        .get(action.payload.property)
        .map((c) => {
          return {
            ...c,
            selected: action.payload.selected,
          };
        });
      return loop(
        state,
        Cmd.action({
          type: ACTION_TYPES.UPDATE_NAMED_SETS,
          payload: { property: action.payload.property, namedSets },
        })
      );
    }

    case ACTION_TYPES.RESET_NAMED_SETS: {
      return loop(
        {
          ...state,
        },
        Cmd.list([
          Cmd.action(
            patchNodeAction(
              action.payload.key,
              {
                [action.payload.property]: undefined,
                mainstructures: undefined,
                outypes: undefined,
              },
              false
            )
          ),
          Cmd.action(dirtyNodeAction(action.payload.key, false)),
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction(false, false)),
          Cmd.action({
            type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
          }),
          Cmd.action(loadNamedSetsAction()),
        ])
      );
    }

    case ACTION_TYPES.REFRESH_NEWSLETTER: {
      const refFrameItemsMap = getRefFrameItemsMap(
        state.tree.newsletterType.href,
        state.viewModel.referenceFrameThemes.tree
      );
      const sections = [...state.apiWithPendingChanges.content.values()].filter(
        (c) => c.type === 'SECTION'
      );
      const newsletterSettings = state.apiWithPendingChanges.newsletterSettings
        .values()
        .next().value;
      const referencegroups = [...state.apiWithPendingChanges.content.values()].filter(
        (c) => c.type === 'REFERENCE_GROUP'
      );

      return loop(
        {
          ...state,
          isRefreshing: true,
          isLoadingEvents: true,
          isLoadingTeasers: true,
        },
        Cmd.list([
          Cmd.run(loadTeasersCmd, {
            args: [
              sections,
              refFrameItemsMap,
              newsletterSettings.dateToSend,
              state.tree.newsletterType,
              newsletterSettings.removedItems,
              rootState.documentUI.currentDocument,
            ],
            successActionCreator: (results) => ({
              type: ACTION_TYPES.LOAD_TEASERS_SUCCESS,
              payload: results,
            }),
          }),
          Cmd.run(loadEventsCmd, {
            args: [
              referencegroups,
              refFrameItemsMap,
              newsletterSettings.dateToSend,
              state.tree.newsletterType,
              newsletterSettings.removedItems,
            ],
            successActionCreator: (results) => ({
              type: ACTION_TYPES.LOAD_EVENTS_SUCCESS,
              payload: results,
            }),
          }),
        ])
      );
    }

    case ACTION_TYPES.LOAD_EVENTS_SUCCESS: {
      const resource = action.payload.referenceGroups.reduce(
        (accResource, referenceGroup) => {
          const existingEvents = referenceGroup.$$children
            .map((c) => {
              const relation = state.apiWithPendingChanges.contentRelations.from[
                `/content/${c.key}`
              ].find((r) => r.relationtype === 'REFERENCES');
              if (!relation.to.$$expanded) {
                console.warn('Event not expanded', relation.to.href);
              }
              return relation.to.$$expanded;
            })
            .filter((z) => z)
            .filter((c) => !referenceGroup.$$events.find((e) => e.key === c.key));
          const events = [...existingEvents, ...referenceGroup.$$events].sort((a, b) => {
            if (a.startDate === b.startDate) {
              return a.summary < b.summary ? -1 : 1;
            }
            return new Date(a.startDate) - new Date(b.startDate);
          });
          const groupResource = events.reduce(
            (accGroupResource, event, index) => {
              const relations =
                state.apiWithPendingChanges.contentRelations.to[event.$$meta.permalink] || [];
              const reference = referenceGroup.$$children.find((c) =>
                relations.find((r) => `/content/${c.key}` === r.from.href)
              );
              const readorder = index + 1;
              if (reference && reference.$$relation.readorder === readorder) {
                return accGroupResource;
              }

              if (reference) {
                return {
                  ...accGroupResource,
                  patches: [
                    ...accGroupResource.patches,
                    getRelationPatch(reference.$$relation, [
                      { op: 'replace', path: '/readorder', value: readorder },
                    ]),
                  ],
                };
              }

              return {
                ...accGroupResource,
                creates: [
                  ...accGroupResource.creates,
                  ...createReferenceResources(
                    referenceGroup,
                    nodeTypeDefinitions.REFERENCE.node,
                    undefined,
                    event.$$meta.permalink,
                    readorder
                  ),
                ],
              };
            },
            {
              patches: [],
              creates: [],
            }
          );

          return {
            patches: [...accResource.patches, ...groupResource.patches],
            creates: [...accResource.creates, ...groupResource.creates],
          };
        },
        {
          patches: [],
          creates: [],
        }
      );

      const pendingActions = [...state.pendingActions];
      if (resource.creates.length > 0) {
        pendingActions.push({
          type: 'CREATE',
          resources: resource.creates,
        });
      }

      if (resource.patches.length > 0) {
        pendingActions.push({
          type: 'PATCH',
          resources: resource.patches,
        });
      }

      const expandedResources = {
        ...state.expandedResources,
        ...action.payload.referenceGroups.reduce((events, g) => {
          return {
            ...events,
            ...g.$$events.reduce((accEvents, e) => {
              accEvents[e.$$meta.permalink] = e;
              return accEvents;
            }, {}),
          };
        }, {}),
      };

      return loop(
        {
          ...state,
          pendingActions,
          expandedResources,
          isRefreshing: state.isLoadingTeasers,
          isLoadingEvents: false,
        },
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction(true)),
          ],
          { batch: true }
        )
      );
    }

    // REFACTOR-TODO: build equivalent in saga's & strip down to only adding includedIn rels in pendingActions after document.api is removed
    case ACTION_TYPES.LOAD_TEASERS_SUCCESS: {
      // TODO: make this work with the new APIs
      const content = new Map(state.oldApi.content);
      // add teasers and external to content.
      action.payload.teasers.forEach((t) => {
        t.themes = t.themes || [];
        content.set(t.$$meta.permalink, t);
      });
      action.payload.references.forEach((r) => {
        content.set(r.$$meta.permalink, r);
      });

      // add relations
      const relations = new Map(state.oldApi.relations);
      action.payload.references.forEach((reference) => {
        reference.$$relationsFrom.forEach((relation) => {
          relations.set(relation.href, relation.$$expanded);
        });
      });

      const newApiWithPendingChanges = selectApiWithPendingChangesOldSlice(rootState);
      // filter out teasers and group by section so we can set a different readorder
      const sectionTeasersMap = action.payload.teasers
        .filter(
          (teaser) => !isTeaserAlreadyInSection(teaser, newApiWithPendingChanges.contentRelations)
        )
        .reduce((sectionTeasersMapAcc, filteredTeaser) => {
          const sectionTeasers = sectionTeasersMapAcc.get(filteredTeaser.$$section.key);
          if (sectionTeasers) {
            sectionTeasers.push(filteredTeaser);
          } else {
            sectionTeasersMapAcc.set(filteredTeaser.$$section.key, [filteredTeaser]);
          }
          return sectionTeasersMapAcc;
        }, new Map());

      const resources = [];
      sectionTeasersMap.forEach((sectionTeasers) => {
        const section = sectionTeasers[0].$$section;
        const readorderObject = getNewReadOrder(
          getTeaserPosition(section.$$children),
          section.$$children,
          sectionTeasers.length
        );
        sectionTeasers.forEach((teaser, index) => {
          const relationKey = uuidv4();
          resources.push({
            verb: 'PUT',
            href: `/content/relations/${relationKey}`,
            body: {
              $$meta: {
                // important for the purple line (last modified)
                permalink: `/content/relations/${relationKey}`,
                created: new Date().toISOString(),
                modified: new Date().toISOString(),
              },
              key: relationKey,
              from: { href: teaser.$$meta.permalink },
              to: { href: section.$$meta.permalink },
              relationtype: 'IS_INCLUDED_IN',
              readorder:
                readorderObject.previousReadOrder + (index + 1) * readorderObject.incrementGap,
            },
          });
        });
      });

      const pendingActions = [...state.pendingActions];
      if (resources.length > 0) {
        pendingActions.push({
          type: 'CREATE',
          resources,
        });
      }

      return loop(
        {
          ...state,
          pendingActions,
          oldApi: {
            ...state.oldApi,
            content,
            relations,
          },
          isRefreshing: state.isLoadingEvents,
          isLoadingTeasers: false,
        },
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction(true)),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.LOAD_SUBJECTS: {
      return loop(
        {
          ...state,
          viewModel: {
            ...state.viewModel,
            subjectsLoading: !state.subjects,
          },
        },
        !state.subjects
          ? Cmd.run(loadSubjectsCmd, {
              args: [],
              successActionCreator: setSubjectsAction,
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_SUBJECTS: {
      return loop(
        {
          ...state,
          subjects: action.payload,
          viewModel: {
            ...state.viewModel,
            subjectsLoading: false,
          },
        },
        state.editKey ? Cmd.action(updateAsideViewModelAction(state.editKey)) : Cmd.none
      );
    }

    case ACTION_TYPES.ADD_ATTACHMENTS: {
      let content = new Map(state.apiWithPendingChanges.content).get(
        `/content/${action.payload.documentKey}`
      );
      if (!content) {
        content = action.payload.newNode;
      }

      let newAttachmnents = [...content.attachments].map((a) => {
        // The text field is not set in certain cases (When removing / adding an image to a paragraph)
        if (a.type === 'CONTENT') {
          return {
            ...a,
            text: a.text ?? content.html,
          };
        }
        return a;
      });

      let resources = [];
      const uploadResources = action.payload.attachments.map((a) => {
        const currentAttachment = newAttachmnents.find((at) => at.key === a.key);
        if (currentAttachment) {
          // replace attachment if it already exists
          newAttachmnents = newAttachmnents.filter((at) => at.key !== a.key);
          a.key = a.newKey || a.key;
        }
        const uniqueName = getUniqueFileName(a.name, newAttachmnents);
        const attachmentToUpload = {
          ...a,
          name: uniqueName,
        };

        // for cropped images there is no file but $$base64
        if (a.file) {
          attachmentToUpload.file = new File([a.file], uniqueName, { type: a.file.type });
        }

        newAttachmnents.push(attachmentToUpload);

        return {
          type: 'UPLOAD',
          href: `/content/${action.payload.documentKey}/attachments/${attachmentToUpload.key}`,
          relatedTo: { href: `/content/${action.payload.documentKey}` },
          body: {
            ...attachmentToUpload,
            href: `/content/${action.payload.documentKey}/attachments/${attachmentToUpload.name}`,
          },
          node: content,
          parentHref: action.payload.parentKey ? `/content/${action.payload.parentKey}` : undefined,
        };
      });

      resources = [...resources, ...uploadResources];

      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        resources,
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list([
          Cmd.action(dirtyNodeAction(action.payload.documentKey, false)),
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.REMOVE_ATTACHMENTS: {
      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        resources: action.payload.attachmentKeys.map((key) => ({
          type: 'DELETE_UPLOAD',
          href: `/content/${action.payload.documentKey}/attachments/${key}`,
          relatedTo: { href: `/content/${action.payload.documentKey}` },
        })),
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list([
          Cmd.action(dirtyNodeAction(action.payload.documentKey, false)),
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.ADD_IMAGE_IN_GROUP: {
      const attachment = {
        key: action.payload.attachmentKey,
        type: 'ILLUSTRATION',
        ...action.payload.attachment,
      };

      const parent = state.apiWithPendingChanges.content.get(
        `/content/${action.payload.parentKey}`
      );
      const newImage = {
        key: action.payload.key,
        type: action.payload.type,
        attachments: [attachment],
        importance: 'MEDIUM',
        ...action.payload.initialNodeData,
        $$new: 'true', // Not sure why it needs $$new, but it doesnt matter for the inline editor.
      };

      const newRelation = {
        key: action.payload.relationKey,
        relationtype: 'IS_PART_OF',
        readorder: parent.$$children.length + 1,
        from: {
          href: `/content/${action.payload.key}`,
        },
        to: {
          href: `/content/${parent.key}`,
        },
      };

      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'CREATE',
        resources: [
          {
            href: `/content/${action.payload.key}`,
            body: newImage,
            parentHref: newRelation.to.href,
          },
          {
            href: `/content/relations/${action.payload.relationKey}`,
            relatedTo: { href: `/content/${action.payload.key}` },
            body: newRelation,
          },
          {
            type: 'UPLOAD',
            href: `/content/${action.payload.key}/attachments/${attachment.key}`,
            relatedTo: { href: `/content/${action.payload.key}` },
            body: attachment,
            node: newImage,
          },
        ],
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list([
          Cmd.action(dirtyNodeAction(parent.key, false)),
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.MOVE_SELECTIONS_TO_PARENT_NODE: {
      // Calculate new read orders
      const parent = state.apiWithPendingChanges.content.get(
        `/content/${action.payload.parentKey}`
      );
      const selections = getNodesByRelationKeys(
        action.payload.relationKeys,
        state.apiWithPendingChanges.content,
        state.apiWithPendingChanges.relations
      );

      let count = 1;

      const newReadOrderResult = getNewReadOrder(
        action.payload.position,
        parent.$$children,
        selections.length
      );

      // create pending action patching only the moved relations (use readorder with decimals)
      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'PATCH',
        resources: selections.map((selection) => {
          const relation = selection.$$relation;
          const patch = {
            readorder:
              newReadOrderResult.previousReadOrder + newReadOrderResult.incrementGap * count,
            to: {
              href: `/content/${parent.key}`,
            },
          };

          count += 1;

          return {
            href: `/content/relations/${relation.key}`,
            relatedTo: { href: relation.from.href },
            patch: Object.keys(patch).map((field) => {
              const operation = relation && relation[field] ? 'replace' : 'add';
              return { op: operation, path: `/${field}`, value: patch[field] };
            }),
          };
        }),
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.MOVE_ATTACHMENT_POSITION: {
      const relationsTo =
        state.apiWithPendingChanges.contentRelations.to[
          `/content/${action.payload.attachmentGroupKey}`
        ] || [];

      const newReadOrderResult = getNewReadOrder(
        action.payload.position,
        relationsTo
          .map((r) => ({ ...r, $$readOrder: r.readorder }))
          .sort((r1, r2) => {
            return r1.readorder - r2.readorder;
          }),
        1
      );

      const relation = state.apiWithPendingChanges.relations.get(
        `/content/relations/${action.payload.relationKey}`
      );
      const patch = {
        readorder: newReadOrderResult.previousReadOrder + newReadOrderResult.incrementGap,
      };

      // create pending action patching only the moved relations (use readorder with decimals)
      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'PATCH',
        resources: [
          {
            href: `/content/relations/${relation.key}`,
            relatedTo: action.payload.containingResourceKey
              ? { href: `/content/${action.payload.containingResourceKey}` }
              : undefined, // global document doesn't have relatedTo
            patch: Object.keys(patch).map((field) => {
              const operation = relation && relation[field] ? 'replace' : 'add';
              return { op: operation, path: `/${field}`, value: patch[field] };
            }),
          },
        ],
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list([
          Cmd.action(dirtyNodeAction(action.payload.attachmentGroupKey, false)),
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.UPDATE_WEBSITE_CONFIGURATION: {
      const newWebconfiguration = action.payload;

      // map oldUrls to OldLocations
      newWebconfiguration.oldLocations = getOldLocations(
        newWebconfiguration.oldUrlsVm,
        state.websites
      );
      // get possible webconfigurations from children that should also be updated
      const updatedChildWebconfigurations = getChildWebconfigurationsToUpdate(
        getResourceKey(newWebconfiguration.source.href),
        newWebconfiguration,
        state
      );

      const relationsToRemove = getRelationsToRemovedFacetReferenceFrame(
        newWebconfiguration,
        state
      ).map((relation) => ({
        type: 'DELETE',
        href: relation.$$meta.permalink,
      }));

      const resources = [
        {
          href: `${apiRoutes.webpages}/${action.payload.key}`,
          body: action.payload,
          relatedTo: { href: action.payload.source.href },
        },
        ...updatedChildWebconfigurations,
        ...relationsToRemove,
      ];

      const pendingAction = {
        type: 'CREATE',
        resources,
      };

      const pendingActions = [...state.pendingActions];
      pendingActions.push(pendingAction);

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(dirtyNodeAction(action.payload.source.href.split('/').pop(), true)),
        ])
      );
    }

    case ACTION_TYPES.REMOVE_WEBSITE_CONFIGURATION: {
      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'DELETE',
        resources: [
          {
            href: `${apiRoutes.webpages}/${action.payload.configurationKey}`,
            relatedTo: { href: `/content/${action.payload.documentKey}` },
          },
        ],
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(dirtyNodeAction(action.payload.documentKey, true)),
        ])
      );
    }

    case ACTION_TYPES.INIT_WEBSITE_CONFIGURATION: {
      return loop(
        {
          ...state,
          viewModel: {
            ...state.viewModel,
            loadingWebsitesConfiguration: true,
          },
        },
        Cmd.run(initDocumentWebsitesConfigurationCmd, {
          args: [action.payload.key],
          successActionCreator: setWebsiteConfigurationAction,
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        })
      );
    }

    // REFACTOR-TODO: can be removed after removing api slice
    case ACTION_TYPES.SET_WEBSITE_CONFIGURATION: {
      const webpages = new Map(state.api.webpages);

      action.payload.configurations.forEach((configuration) => {
        webpages.set(configuration.$$meta.permalink, configuration);
      });

      return {
        ...state,
        oldApi: {
          ...state.oldApi,
          webpages,
        },
        viewModel: {
          ...state.viewModel,
          loadingWebsitesConfiguration: false,
        },
      };
    }

    case ACTION_TYPES.SET_ALL_WEBSITES: {
      return {
        ...state,
        websites: action.payload.websites,
        webtemplates: action.payload.webtemplates,
      };
    }

    case ACTION_TYPES.INIT_WHOLE_DOCUMENT_WEBSITE_THEME_REFERENCE_FRAMES: {
      // load initial website themes reference frame for all nodes with REQUIRES relation as the 'to' part
      const nodesEntriesWithRequireRelations = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [href, value] of Object.entries(state.apiWithPendingChanges.contentRelations.to)) {
        if (value.some((rel) => rel.relationtype === 'REQUIRES')) {
          nodesEntriesWithRequireRelations[href] = value;
        }
      }

      let outputCmd;
      const isNodesEntriesWithRequireRelationsEmpty = isEmpty(nodesEntriesWithRequireRelations);

      if (isNodesEntriesWithRequireRelationsEmpty) {
        outputCmd = Cmd.none;
      } else {
        outputCmd = Cmd.run(fetchThemeReferenceFramesMapCmd, {
          args: [nodesEntriesWithRequireRelations],
          successActionCreator: setWebsiteThemeReferenceFramesAction,
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        });
      }

      return loop(
        {
          ...state,
        },
        outputCmd
      );
    }

    case ACTION_TYPES.INIT_WEBSITE_THEME_REFERENCE_FRAMES: {
      const needToLoadThemeReferencesMap =
        !state.websitesReferenceFramesMap[`/content/${action.payload}`];
      const relationsToNode =
        state.apiWithPendingChanges.contentRelations.to[`/content/${action.payload}`] || [];
      const requireRelationsToNode = {
        [`/content/${action.payload}`]: relationsToNode.filter(
          (rel) => rel.relationtype === 'REQUIRES'
        ),
      };

      return loop(
        {
          ...state,
        },
        needToLoadThemeReferencesMap
          ? Cmd.run(fetchThemeReferenceFramesMapCmd, {
              args: [requireRelationsToNode],
              successActionCreator: setWebsiteThemeReferenceFramesAction,
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_WEBSITE_THEME_REFERENCE_FRAMES: {
      const websitesReferenceFramesMap = {
        ...state.websitesReferenceFramesMap,
        ...action.payload,
      };

      return loop(
        {
          ...state,
          websitesReferenceFramesMap,
        },
        Cmd.action(updateDocumentTreeAction())
      );
    }

    case ACTION_TYPES.UPDATE_FACET_REFERENCE_FRAMES: {
      // detect reference frame relations to add/remove
      const newReferenceFramesMap =
        state.websitesReferenceFramesMap[`/content/${action.payload.key}`];

      const result = getReferenceFrameRelationDifferences(
        newReferenceFramesMap.get(action.payload.referenceFrameData.referenceFrameHref),
        action.payload.referenceFrameData.values
      );

      newReferenceFramesMap.set(
        action.payload.referenceFrameData.referenceFrameHref,
        action.payload.referenceFrameData.values
      );

      let relationKeysToDelete = [];
      const relationsToNode =
        state.apiWithPendingChanges.contentRelations.to[`/content/${action.payload.key}`];
      if (relationsToNode) {
        relationKeysToDelete = relationsToNode
          .filter((relation) => result.relationsToDelete.includes(relation.from.href))
          .map((relation) => relation.key);
      }

      const relationsToAdd = result.relationsToAdd.map((referenceFrameNodeHref) => ({
        to: { href: `/content/${action.payload.key}` },
        from: { href: referenceFrameNodeHref },
        relationtype: 'REQUIRES',
        strength: 'MEDIUM',
      }));

      return loop(
        {
          ...state,
          websitesReferenceFramesMap: {
            ...state.websitesReferenceFramesMap,
            [`/content/${action.payload.key}`]: newReferenceFramesMap,
          },
        },
        Cmd.list([
          Cmd.action(dirtyNodeAction(action.payload.key, false)),
          Cmd.list(relationsToAdd.map((relation) => Cmd.action(addRelationAction(relation)))),
          Cmd.list(relationKeysToDelete.map((key) => Cmd.action(removeRelationAction(key)))),
        ])
      );
    }

    case ACTION_TYPES.LOAD_REFERENCE_FRAME_EXTERNAL_OPTIONS: {
      const needToLoadOptions = !state.referenceFrameExternalOptions[action.payload.type];

      return loop(
        {
          ...state,
        },
        needToLoadOptions
          ? Cmd.run(fetchReferenceFramesExternalOptionsCmd, {
              args: [action.payload.type, action.payload.filterUrls, action.payload.label],
              successActionCreator: setReferenceFrameExternalOptionsAction,
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_REFERENCE_FRAME_EXTERNAL_OPTIONS: {
      const referenceFrameExternalOptions = {
        ...state.referenceFrameExternalOptions,
        [action.payload.type]: action.payload.options,
      };

      return {
        ...state,
        referenceFrameExternalOptions,
      };
    }

    case ACTION_TYPES.INIT_FIELD_CHOICES: {
      let command = Cmd.none;

      if (action.payload.field === 'applicability') {
        command = Cmd.run(fetchStudyProgrammesCmd, {
          args: [action.payload.field],
          successActionCreator: setFieldChoicesAction,
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        });
      }

      return loop(state, command);
    }

    case ACTION_TYPES.SET_FIELD_CHOICES: {
      const newDocumentApi = selectNewDocumentApi(rootState);
      const contents = new Map(newDocumentApi.content);
      const selectChoices = { ...state.selectChoices };

      if (action.payload.key) {
        // set field choices specific to the editing node
        const node = contents.get(`/content/${action.payload.key}`);
        node[`$$${action.payload.field}Choices`] = action.payload.results;

        contents.set(`/content/${action.payload.key}`, {
          ...node,
        });
      } else {
        // set field choices general for any node of the document
        selectChoices[action.payload.field] = action.payload.results;
      }

      return {
        ...state,
        // api: contents,
        selectChoices,
      };
    }

    case ACTION_TYPES.INIT_LLINKID_THEME_REFERENCES: {
      return loop(
        state,
        Cmd.run(fetchLlinkidThemeReferencesCmd, {
          args: [action.payload],
          successActionCreator: setLlinkidThemeReferencesAction,
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.SET_LLINKID_THEME_REFERENCES: {
      const llinkidThemeReferences = { ...state.llinkidThemeReferences };

      llinkidThemeReferences[action.payload.field] = action.payload.results;

      return {
        ...state,
        llinkidThemeReferences,
      };
    }

    // Also used for Odet goals!
    // TODO: move to a separate component
    // case ACTION_TYPES.EXPAND_LLINKID_GOAL_RELATIONS: {
    //   const originPart = action.payload.originPart ?? 'from';
    //   const endPart = originPart === 'from' ? 'to' : 'from';

    //   // expand the to part of the from relations of the given llinkid goal
    //   const apiWithPendingChanges = selectApiWithPendingChangesOldSlice(rootState);
    //   const relationsPart = apiWithPendingChanges.contentRelations[originPart];
    //   const relations = (relationsPart[`/content/${action.payload.key}`] || [])
    //     .filter(rel => {
    //       return action.payload.relationTypes.includes(rel.relationtype)
    //         && (!rel[endPart].$$expanded || !rel[endPart].$$expanded.$$treeAsLeaf);
    //     });

    //   return loop(
    //     {
    //       ...state,
    //       goalRelationsExpanded: false
    //     },
    //     Cmd.run(fetchRelationsWithExpandedPartCmd, {
    //       args: [action.payload.key, relations, endPart],
    //       successActionCreator: (results) => ({
    //         type: ACTION_TYPES.SET_EXPANDED_LLINKID_GOAL_RELATIONS,
    //         payload: {
    //           ...results,
    //           originPart,
    //           endPart,
    //           relationTypes: action.payload.relationTypes
    //         }
    //       }),
    //       failActionCreator: error => documentLoadingFailedAction(error, state)
    //     })
    //   );
    // }

    // Also used for Odet goals!
    // TODO: move to a separate component
    // case ACTION_TYPES.SET_EXPANDED_LLINKID_GOAL_RELATIONS: {
    //   const apiWithPendingChanges = selectApiWithPendingChangesOldSlice(rootState);
    //   const originPart = action.payload.originPart;
    //   const endPart = action.payload.endPart;
    //   const relationsPart = apiWithPendingChanges.contentRelations[originPart];
    //   let relations = relationsPart[`/content/${action.payload.key}`] || [];

    //   // fill $$expand of to part of the relations in result
    //   relations = relations.map((rel) => {
    //     // note: action.payload.results is a batch operation results list
    //     const expandedPart = action.payload.results.find(result => result.href === rel[endPart].href);
    //     let $$expanded = undefined;
    //     if (expandedPart) {
    //       const isOdet = expandedPart.body.type === constants.curriculumOdetDevelopmentGoalType;
    //       expandedPart.body.completeIdentifier = getGoalIdentifier({ goal: expandedPart.body, relations: expandedPart.$$treeAsLeaf, isOdet});
    //       expandedPart.body.description = clearDemarcationLinks(expandedPart.body.description);
    //       expandedPart.body.$$root = getRoot(expandedPart.$$treeAsLeaf);
    //       expandedPart.body.$$treeAsLeaf = expandedPart.$$treeAsLeaf;
    //       $$expanded = expandedPart.body;
    //     }
    //     return { ...rel, [endPart]: { ...rel[endPart], $$expanded: $$expanded || rel[endPart].$$expanded } };
    //   });

    //   // Sort goal relations of the same type by goal identifier
    //   // We are only interested in Llinkid and Odet goals
    //   relations = relations.sort((relation1, relation2) => {
    //     const node1 = relation1[endPart].$$expanded;
    //     const node2 = relation2[endPart].$$expanded;
    //     const typesToSort = [constants.llinkidGoalType, constants.curriculumOdetDevelopmentGoalType];
    //     if (node1 && node2 && node1.$$treeAsLeaf && node2.$$treeAsLeaf && typesToSort.includes(node1.type) && typesToSort.includes(node2.type)) {
    //       return sortByGoalIdentifier(node1, node2);
    //     }
    //     return 0;
    //   });

    //   relationsPart[`/content/${action.payload.key}`] = relations;

    //   return {
    //     ...state,
    //     oldApiWithPendingChanges: {
    //       ...state.oldApiWithPendingChanges,
    //       contentRelations: {
    //         ...state.oldApiWithPendingChanges.contentRelations,
    //         [originPart]: relationsPart
    //       }
    //     },
    //     goalRelationsExpanded: true
    //   };
    // }

    case ACTION_TYPES.GET_ALL_LLINKID_CURRICULUMS: {
      const params = {
        type: 'LLINKID_CURRICULUM',
        orderBy: 'title',
      };

      return loop(
        state,
        state.llinkidCurriculums.length === 0
          ? Cmd.run(fetchAllCmd, {
              args: [params],
              successActionCreator: setAllLlinkidCurriculumsAction,
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_ALL_LLINKID_CURRICULUMS: {
      const results = action.payload.map((r) => {
        r.issued = formatDate(r.issued, false);
        return r;
      });

      return {
        ...state,
        llinkidCurriculums: results,
      };
    }

    case ACTION_TYPES.GET_REFERENCE_FRAME: {
      return loop(
        state,
        !state.referenceFrame[action.payload.key] ||
          state.referenceFrame[action.payload.key].length === 0
          ? Cmd.run(loadDocumentCmd, {
              args: [action.payload.key],
              successActionCreator: (results) =>
                setReferenceFrameAction(action.payload.key, results),
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_REFERENCE_FRAME: {
      const { results } = action.payload;

      const newState = {
        ...state,
        referenceFrame: {
          ...state.referenceFrame,
          [action.payload.key]: results.map((r) => ({ ...r, display: r.title })),
        },
        viewModel: {
          ...state.viewModel,
          isRefreshDisabled: false,
        },
      };

      return loop(newState, Cmd.action(updateDocumentViewModelAction()));
    }

    case ACTION_TYPES.GET_ALL_OF_TYPE: {
      return loop(
        state,
        !state[action.payload.options.stateField] ||
          state[action.payload.options.stateField].length === 0
          ? Cmd.run(fetchAllCmd, {
              args: [action.payload.params, action.payload.options],
              successActionCreator: setAllOfTypeAction,
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_ALL_OF_TYPE: {
      let results = action.payload.map((r) => {
        if (r.issued) {
          r.issued = formatDate(r.issued, !action.payload.options.removeIssuedHour);
        }
        return r;
      });

      /* As sri4node orderBy can fail with fields containing commas, we can order clientside */
      const { sortBy } = action.payload.options;
      if (sortBy) {
        results = results.sort((a, b) => String(a[sortBy]).trim().localeCompare(b[sortBy]));
      }

      const newState = { ...state };
      newState[action.payload.options.stateField] = results;

      return loop(
        newState,
        Cmd.action({
          type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
        })
      );
    }

    case ACTION_TYPES.LOAD_LLINKID_CURRICULUM_PREVIOUS_VERSION_ITEMS: {
      const llinkidCurriculums = [...state.llinkidCurriculums];
      const curriculum = llinkidCurriculums.find((c) => c.key === action.payload.key);
      const needToLoadPreviousVersionItems = !curriculum.$$previousVersionItems?.some(
        (item) => item.type === action.payload.type
      );
      curriculum.$$loadingPreviousVersionItems = needToLoadPreviousVersionItems;

      const params = {
        root: action.payload.key,
        type: action.payload.type,
      };

      return loop(
        {
          ...state,
          llinkidCurriculums,
        },
        needToLoadPreviousVersionItems
          ? Cmd.run(fetchAllCmd, {
              args: [params, { key: action.payload.key }],
              successActionCreator: setLlinkidCurriculumPreviousVersionItemsAction,
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_LLINKID_CURRICULUM_PREVIOUS_VERSION_ITEMS: {
      const llinkidCurriculums = [...state.llinkidCurriculums];
      const curriculum = llinkidCurriculums.find((c) => c.key === action.payload.options.key);
      curriculum.$$previousVersionItems = action.payload;
      curriculum.$$loadingPreviousVersionItems = false;

      return {
        ...state,
        llinkidCurriculums,
      };
    }

    case ACTION_TYPES.LOAD_LLINKID_CURRICULUM_GOALS: {
      const llinkidCurriculums = [...state.llinkidCurriculums];
      const curriculum = llinkidCurriculums.find((c) => c.key === action.payload);
      const needToLoadGoals = !curriculum.$$goals;

      if (needToLoadGoals) {
        curriculum.$$loadingGoals = true;
      }

      const params = {
        root: action.payload,
        type: 'LLINKID_GOAL',
      };

      return loop(
        {
          ...state,
          llinkidCurriculums,
        },
        needToLoadGoals
          ? Cmd.run(fetchAllCmd, {
              args: [params, { key: action.payload }],
              successActionCreator: setLlinkidCurriculumGoalsAction,
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_LLINKID_CURRICULUM_GOALS: {
      const goals = action.payload.map((goal) => {
        goal.description = clearDemarcationLinks(goal.description);
        return goal;
      });

      const llinkidCurriculums = [...state.llinkidCurriculums];
      const curriculum = llinkidCurriculums.find((c) => c.key === action.payload.options.key);
      curriculum.$$goals = goals;
      curriculum.$$loadingGoals = false;

      return loop(
        {
          ...state,
          llinkidCurriculums,
        },
        Cmd.run(fetchTreeAsLeafCmd, {
          args: [goals, action.payload.options.key],
          successActionCreator: fillLlinkidCurriculumGoalsCompleteIdentifierAction,
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.FILL_LLINKID_CURRICULUM_GOALS_COMPLETE_IDENTIFIER: {
      const goals = action.payload.results.map((goal) => {
        goal.completeIdentifier = getGoalIdentifier({ goal, relations: goal.$$treeAsLeaf });
        goal.$$root = getRoot(goal.$$treeAsLeaf);
        return goal;
      });

      const llinkidCurriculums = [...state.llinkidCurriculums];
      const curriculum = llinkidCurriculums.find((c) => c.key === action.payload.key);
      curriculum.$$goals = sortByReadorder(goals);

      return {
        ...state,
        llinkidCurriculums,
      };
    }

    case ACTION_TYPES.LOAD_LLINKID_ODET_GOALS: {
      const llinkidOdetCurriculum = { ...state.llinkidOdetCurriculum };
      const needToLoadGoals = !llinkidOdetCurriculum.$$goals;

      if (needToLoadGoals) {
        llinkidOdetCurriculum.$$loading = true;
      }

      const params = {
        root: documentTypes.llinkidOdetCurriculumKey,
        typeIn: 'CURRICULUM_ODET_DEVELOPMENT_GOAL,CURRICULUM_ODET',
      };

      return loop(
        {
          ...state,
          llinkidOdetCurriculum,
        },
        needToLoadGoals
          ? Cmd.run(fetchAllCmd, {
              args: [params],
              successActionCreator: setLlinkidOdetGoalsAction,
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_LLINKID_ODET_GOALS: {
      const llinkidOdetCurriculum = action.payload.find((r) => r.type === 'CURRICULUM_ODET');
      const goals = action.payload.filter((r) => r.type === 'CURRICULUM_ODET_DEVELOPMENT_GOAL');

      llinkidOdetCurriculum.$$goals = goals;
      llinkidOdetCurriculum.$$loading = false;

      return loop(
        {
          ...state,
          llinkidOdetCurriculum,
        },
        Cmd.run(fetchTreeAsLeafCmd, {
          args: [goals],
          successActionCreator: fillLlinkidOdetGoalsCompleteIdentifierAction,
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.FILL_LLINKID_ODET_GOALS_COMPLETE_IDENTIFIER: {
      let goals = action.payload.map((goal) => {
        goal.completeIdentifier = getGoalIdentifier({
          goal,
          relations: goal.$$treeAsLeaf,
          isOdet: true,
        });
        return goal;
      });

      // sort by complete identifier
      goals = goals.sort((g1, g2) => {
        if (g1.completeIdentifier === g2.completeIdentifier) {
          return g1.description < g2.description;
        }
        return g1.completeIdentifier > g2.completeIdentifier ? 1 : -1;
      });

      const llinkidOdetCurriculum = { ...state.llinkidOdetCurriculum };
      llinkidOdetCurriculum.$$goals = goals;

      return {
        ...state,
        llinkidOdetCurriculum,
      };
    }

    case ACTION_TYPES.GET_EDUCATIONAL_ACTIVITY_TYPES: {
      return loop(
        state,
        !state.educationalActivityTypes
          ? Cmd.run(fetchEducationalActivityTypesCmd, {
              args: [],
              successActionCreator: setEducationalActivityTypesAction,
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    case ACTION_TYPES.SET_EDUCATIONAL_ACTIVITY_TYPES: {
      return loop(
        {
          ...state,
          educationalActivityTypes: action.payload,
        },
        Cmd.action(updateAsideViewModelAction(state.editKey))
      );
    }

    case ACTION_TYPES.EXPAND_RELATIONS: {
      let { relations } = action.payload;
      if (!relations) {
        const relationsInState = {
          ...state.apiWithPendingChanges.contentRelations[action.payload.relationsToExpand],
        };
        relations = relationsInState[`/content/${action.payload.key}`]
          ? relationsInState[`/content/${action.payload.key}`]
              .filter(
                (r) =>
                  !state.apiWithPendingChanges.content.has(r[action.payload.relationsToExpand].href)
              )
              .filter((relation) => !relation.$$new)
          : [];
      }

      const relationPartToExpand = action.payload.relationsToExpand === 'from' ? 'to' : 'from';

      return loop(
        state,
        Cmd.run(fetchRelationsWithExpandedPartCmd, {
          args: [
            action.payload.key,
            relations,
            relationPartToExpand,
            action.payload.fetchTreeAsLeaf,
          ],
          successActionCreator: (resp) => ({
            type: ACTION_TYPES.SET_EXPANDED_RELATIONS,
            payload: {
              key: resp.key,
              relationsToExpand: resp.relationsToExpand,
              relations: resp.relations,
              results: resp.results,
            },
          }),
        })
      );
    }

    case ACTION_TYPES.SET_EXPANDED_RELATIONS: {
      const { key, relationsToExpand, relations, results } = action.payload;

      const relationPartToExpand = relationsToExpand === 'from' ? 'to' : 'from';

      const relationsInState = {
        ...state.apiWithPendingChanges.contentRelations[relationsToExpand],
      };

      if (key) {
        const relationHref = `/content/${action.payload.key}`;
        const relationsInStateToExpand = relationsInState[relationHref];

        if (relationsInStateToExpand) {
          fillExpandedPartOfRelations(
            relationsInStateToExpand,
            relationPartToExpand,
            { ...state, api: state.oldApi },
            results
          );
        }
      } else {
        // different parent for each expanded relation
        relations.forEach((relation) => {
          const relationHref = relation[relationsToExpand].href;
          const relationsInStateToExpand = relationsInState[relationHref];

          if (relationsInStateToExpand) {
            fillExpandedPartOfRelations(
              relationsInStateToExpand,
              relationPartToExpand,
              { ...state, api: state.oldApi },
              results
            );
          }
        });
      }

      const newApiRelations = {
        ...state.oldApiWithPendingChanges.contentRelations,
      };

      newApiRelations[relationsToExpand] = relationsInState;

      return {
        ...state,
        oldApiWithPendingChanges: {
          ...state.oldApiWithPendingChanges,
          contentRelations: newApiRelations,
        },
      };
    }

    case ACTION_TYPES.SET_SELECTED_ZILL_CURRICULUM_AS_FRAME: {
      const { relationToCreate } = action.payload;
      const odetKey = relationToCreate.to.$$expanded.key;

      return loop(
        {
          ...state,
          zillOdetCurriculum: undefined, // cleaning previous assignations
        },
        Cmd.list(
          [
            Cmd.action(addRelationAction(relationToCreate)),
            Cmd.action({
              type: ACTION_TYPES.INIT_ZILL_ODET_CURRICULUM_DOCUMENT,
              payload: { odetKey },
            }),
            Cmd.action({
              type: ACTION_TYPES.UPDATE_ASIDE_VIEW_MODEL,
              payload: { editKey: state.editKey },
            }),
          ],
          { sequence: true }
        )
      );
    }

    case ACTION_TYPES.EDIT_ZILL_CURRICULUM_AS_FRAME: {
      const { relationToEditKey, patchToApply } = action.payload;
      const odetKey = patchToApply.to.$$expanded.key;

      return loop(
        {
          ...state,
          zillOdetCurriculum: undefined, // cleaning previous assignations
        },
        Cmd.list(
          [
            Cmd.action(patchRelationAction(relationToEditKey, patchToApply)),
            Cmd.action({
              type: ACTION_TYPES.INIT_ZILL_ODET_CURRICULUM_DOCUMENT,
              payload: { odetKey },
            }),
            Cmd.action({
              type: ACTION_TYPES.UPDATE_ASIDE_VIEW_MODEL,
              payload: { editKey: state.editKey },
            }),
          ],
          { sequence: true }
        )
      );
    }

    case ACTION_TYPES.INIT_ZILL_ODET_CURRICULUM_DOCUMENT: {
      let odetKey = action.payload ? action.payload.odetKey : undefined;

      // if the odetKey is not provided we search the odetKey in the relations of the document
      if (!odetKey) {
        const relations =
          state.apiWithPendingChanges.contentRelations.from[`/content/${state.key}`];
        const odetRelation = relations && relations.find((r) => r.relationtype === 'IS_VERSION_OF');
        if (odetRelation) {
          odetKey = commonUtils.getKeyFromPermalink(odetRelation.to.href);
        } else {
          // if we don't have a `IS_VERSION_OF` relation it means we don't need any ODET
          return {
            ...state,
            zillOdetCurriculum: null, // we need this to be null
          };
        }
      }

      // we don't load the ODET again if we have the same one already loaded
      if (state.zillOdetCurriculum && state.zillOdetCurriculum.key === odetKey) {
        return state;
      }

      return loop(
        state,
        Cmd.run(loadDocumentCmd, {
          args: [odetKey],
          successActionCreator: (documentResponse) => ({
            type: ACTION_TYPES.SET_ZILL_ODET_CURRICULUM,
            payload: { documentResponse },
          }),
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.SET_ZILL_ODET_CURRICULUM: {
      const newDocumentApi = selectNewDocumentApi(rootState);
      const { documentResponse } = action.payload;

      const root = documentResponse.find((r) => r.type === 'CURRICULUM_ODET');

      const odetNodesMap = new Map();
      const relationsTo = {};

      documentResponse.forEach((node) => {
        odetNodesMap.set(`/content/${node.key}`, node);

        node.$$relationsTo.forEach((rel) => {
          if (!relationsTo[`/content/${node.key}`]) {
            relationsTo[`/content/${node.key}`] = [];
          }
          relationsTo[`/content/${node.key}`].push(rel.$$expanded);
        });
      });

      const calculateOdetTreeEnd = conditionalLogTime('calculate Odet Tree', 250);
      const tree = createDocumentTree(root.key, odetNodesMap, relationsTo);
      const flat = treeToFlatVM(tree, { ...state, api: newDocumentApi }, true); // TODO: is this why basisleerplannen is not working????!!!
      calculateOdetTreeEnd();

      root.$$fullTree = flat.slice(1);

      return {
        ...state,
        zillOdetCurriculum: root,
      };
    }

    case ACTION_TYPES.LOAD_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS: {
      let relations = state.apiWithPendingChanges.contentRelations.to[`/content/${action.payload}`];

      // find the relations referencing the zill_illustrations
      if (relations) {
        relations = relations.filter((r) => r.relationtype === 'REFERENCES' && !r.from.$$expanded);
      }

      const needToLoadRelations = true; // relations && relations.length > 0;

      return loop(
        {
          ...state,
          loadingPracticalExampleZillIllustrations: needToLoadRelations,
        },
        needToLoadRelations
          ? Cmd.run(loadPracticalExampleZillIllustrationsCmd, {
              args: [action.payload],
              successActionCreator: setPracticalExampleZillIllustrationsAction,
              failActionCreator: (error) => documentLoadingFailedAction(error, state),
            })
          : Cmd.none
      );
    }

    // REFACTOR-TODO: update in api part can be removed after api slice is removed
    case ACTION_TYPES.SET_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS: {
      // TODO: make this work with the new APIs
      const relations = new Map(state.oldApi.relations);
      action.payload.relations.forEach((illustrationRelation) => {
        relations.set(`/content/relations/${illustrationRelation.key}`, illustrationRelation);
        illustrationRelation.from.$$expanded.$$relationsFrom.forEach((relationToGoal) => {
          relations.set(`/content/relations/${relationToGoal.key}`, relationToGoal);
        });
      });

      return loop(
        {
          ...state,
          loadingPracticalExampleZillIllustrations: false,
          oldApi: {
            ...state.oldApi,
            relations,
          },
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.ADD_ZILL_ILLUSTRATION: {
      console.log('ADD_ZILL_ILLUSTRATION action!');

      const zillIllustration = {
        ...action.payload.zillIllustration,
        ...getCreateDefaults(NodeType.ZILL_ILLUSTRATION),
        importance: 'MEDIUM',
        language: 'nl',
        attachments: [],
      };

      const zillIllustrationRelation = {
        key: action.payload.zillIllustrationRelationKey,
        relationtype: 'REFERENCES',
        strength: 'MEDIUM',
        from: {
          href: `/content/${zillIllustration.key}`,
          $$expanded: zillIllustration,
        },
        to: {
          href: `/content/${action.payload.parentKey}`,
        },
        $$new: true,
      };

      const goalRelations = action.payload.goals.map((goal) => ({
        key: goal.relationKey,
        relationtype: 'REFERENCES',
        strength: 'LOW',
        from: {
          href: `/content/${zillIllustration.key}`,
        },
        to: {
          href: goal.href,
          $$expanded: state.selectedZillGoals.find((g) => g.$$meta.permalink === goal.href),
        },
      }));

      zillIllustration.$$relationsFrom = goalRelations.map((goalRelation) => ({
        href: `/content/${goalRelation.key}`,
        $$expanded: goalRelation,
      }));

      // create pendingAction
      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'CREATE',
        resources: [
          {
            href: `/content/${action.payload.zillIllustration.key}`,
            relatedTo: { href: `/content/${action.payload.parentKey}` },
            body: zillIllustration,
          },
          {
            href: `/content/relations/${zillIllustrationRelation.key}`,
            relatedTo: { href: `/content/${zillIllustration.key}` },
            body: zillIllustrationRelation,
          },
          ...goalRelations.map((goalRelation) => ({
            href: `/content/relations/${goalRelation.key}`,
            relatedTo: { href: `/content/${zillIllustration.key}` },
            body: goalRelation,
          })),
        ],
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list([
          // Cmd.action(loadPracticalExampleZillIllustrationsAction(action.payload.parentKey))
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.REMOVE_ZILL_ILLUSTRATION_RELATION: {
      let resources = [];

      const zillIllustrationRelationsFrom =
        state.apiWithPendingChanges.contentRelations.from[`/content/${action.payload.key}`];
      if (zillIllustrationRelationsFrom.length - 1 === 1) {
        // we are removing the last REFERENCE of the zill illutration (the other one is a relation to the practical example)
        // => remove the node also
        resources = zillIllustrationRelationsFrom.map((relation) => {
          return {
            href: `/content/relations/${relation.key}`,
          };
        });
        resources.push({
          href: `/content/${action.payload.key}`,
        });
      } else {
        // remove just one of the references of the zill illustration
        resources.push({
          href: `/content/relations/${action.payload.relationKey}`,
        });
      }

      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'DELETE',
        resources,
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            action.payload.updateTree ? Cmd.action(updateDocumentTreeAction()) : Cmd.none,
          ],
          { sequence: true }
        )
      );
    }

    case ACTION_TYPES.EXPAND_ZILL_GOAL_SELECTIONS: {
      const params = {
        hrefs: action.payload,
      };

      return loop(
        state,
        Cmd.run(fetchAllCmd, {
          args: [params, { fetchTreeAsLeaf: true }],
          successActionCreator: setExpandedZillGoalSelectionsAction,
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.SET_EXPANDED_ZILL_GOAL_SELECTIONS: {
      const selections = [...state.selectedZillGoals];

      action.payload.forEach((goal) => {
        if (!selections.find((g) => g.$$meta.permalink === goal.$$meta.permalink)) {
          selections.push(goal);
        }
      });

      return {
        ...state,
        selectedZillGoals: selections,
      };
    }

    case ACTION_TYPES.LOAD_TERM_REFERENCES: {
      const params = {
        'referencedBy.root': action.payload,
        typeIn: 'TERM',
        limit: 500,
      };

      return loop(
        { ...state },
        Cmd.run(fetchAllCmd, {
          args: [params],
          successActionCreator: setTermReferencesAction,
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.SET_TERM_REFERENCES: {
      return loop(
        {
          ...state,
          termReferences: action.payload,
        },
        Cmd.action({
          type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
        })
      );
    }

    case ACTION_TYPES.ADD_TERM_REFERENCE: {
      const newTermReferences = [...state.termReferences];
      if (!newTermReferences.find((t) => t.$$meta.permalink === `/content/${action.payload.key}`)) {
        newTermReferences.push(action.payload);
      }

      return {
        ...state,
        termReferences: newTermReferences,
      };
    }

    case ACTION_TYPES.REMOVE_TERM_REFERENCE: {
      const newTermReferences = [...state.termReferences].filter(
        (t) => t.$$meta.permalink !== action.payload
      );

      return {
        ...state,
        termReferences: newTermReferences,
      };
    }

    case ACTION_TYPES.LOAD_EXTERNAL_DOCUMENT_SECTIONS: {
      const selectChoices = { ...state.selectChoices };

      selectChoices.externalDocumentSections = [];
      selectChoices.externalDocumentSectionsLoading = true;

      return loop(
        {
          ...state,
          selectChoices,
        },
        Cmd.run(fetchExternalDocumentCmd, {
          args: [action.payload],
          successActionCreator: setExternalDocumentSectionsAction,
          failActionCreator: (error) => documentLoadingFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.SET_EXTERNAL_DOCUMENT_SECTIONS: {
      const selectChoices = { ...state.selectChoices };

      const externalDocumentApi = {
        ...fillApiContentAndRelationsMap(action.payload.content),
        proposals: fillApiProposalsMap(action.payload.proposals),
      };

      const externalDocumentFlat = getExternalDocumentFlatTree(
        action.payload.documentKey,
        externalDocumentApi,
        state
      );

      // filter only sections
      selectChoices.externalDocumentSections = externalDocumentFlat
        .filter((n) => ['SECTION'].includes(n.type))
        .map((n) => {
          if (!n.$$meta) {
            n.title += ' (suggestie)';
          }
          return n;
        });
      selectChoices.externalDocumentSectionsLoading = false;

      return {
        ...state,
        selectChoices,
      };
    }

    case ACTION_TYPES.REMOVE_REFERENCE_GROUP_REFERENCES: {
      const referencesToRemove = state.apiWithPendingChanges.content
        .get(`/content/${action.payload}`)
        .$$children.filter((node) => node.type === 'REFERENCE');

      return loop(
        state,
        Cmd.list(referencesToRemove.map((r) => Cmd.action(removeNodeAction(r.key, true))))
      );
    }

    case ACTION_TYPES.PATCH_NODE_ATTACHMENT: {
      const contents = new Map(state.apiWithPendingChanges.content);
      const node = contents.get(`/content/${action.payload.key}`);
      let newAttachmnents = [...node.attachments];

      // check if we need to patch the node according to what we receive in payload
      const patchNode = hasToPatchNodeAttachments(node, action.payload);

      if (patchNode) {
        let attachment = newAttachmnents.find((a) => a.key === action.payload.attachmentKey);
        if (attachment) {
          newAttachmnents = newAttachmnents.filter((a) => a.key !== action.payload.attachmentKey);
          attachment = {
            ...attachment,
            ...action.payload.patch,
          };
          newAttachmnents.push(attachment);
        } else {
          newAttachmnents.push({
            key: action.payload.attachmentKey,
            ...action.payload.patch,
          });
        }

        const apiFileUploadPending = [...state.apiPending.fileUploads];
        const pendingUpload = apiFileUploadPending.find(
          (u) => u.documentKey === action.payload.key
        );
        if (pendingUpload) {
          let attachmentPending = pendingUpload.attachments.find(
            (o) => o.key === action.payload.attachmentKey
          );
          if (attachmentPending) {
            attachmentPending = { ...attachmentPending, ...action.payload.patch };
          }
          pendingUpload.attachments = pendingUpload.attachments.filter(
            (o) => o.key !== action.payload.attachmentKey
          );
          pendingUpload.attachments.push(attachmentPending);
        }
      }

      return loop(
        state,
        patchNode
          ? Cmd.action(patchNodeAction(action.payload.key, { attachments: newAttachmnents }))
          : Cmd.none
      );
    }

    // this should be replaced by Gunther's version
    case ACTION_TYPES.REMOVE_NODE_ATTACHMENT: {
      const contents = new Map(state.apiWithPendingChanges.content);
      const node = contents.get(`/content/${action.payload.key}`);

      const newAttachmnents = [...node.attachments].filter(
        (a) => a.key !== action.payload.attachmentKey
      );

      return loop(
        state,
        Cmd.action(patchNodeAction(action.payload.key, { attachments: newAttachmnents }, true))
      );
    }

    case ACTION_TYPES.ADD_NODE_TO_PARENT_NODE: {
      // Calculate new read orders
      const parent = state.apiWithPendingChanges.content.get(
        `/content/${action.payload.parentKey}`
      );
      const newNode = {
        key: action.payload.newKey,
        type: action.payload.type,
        ...action.payload.initialNodeData,
        $$new: 'true',
        ...cloneDeep(getCreateDefaults(action.payload.type)),
        $$meta: {
          permalink: `/content/${action.payload.newKey}`,
        },
      };
      if (!newNode.attachments) {
        newNode.attachments = [];
      } else {
        const contentAttachment = newNode.attachments.find((a) => a.type === 'CONTENT');
        if (contentAttachment && !contentAttachment.key) {
          contentAttachment.key = action.payload.newAttachmentKey;
        }
      }
      if (!newNode.importance) {
        newNode.importance = 'MEDIUM';
      }
      addNewNodeConditionalFields(
        newNode,
        nodeTypeConfigurations[action.payload.type].nodeConditional,
        parent,
        state,
        rootState
      );
      const webconfigurations = getNewNodeConditionalWebconfigurations(
        newNode,
        nodeTypeConfigurations[action.payload.type].nodeConditional,
        parent,
        state
      );
      const newReadOrderResult = getNewReadOrder(action.payload.position, parent.$$children, 1);
      const newRelation = {
        key: action.payload.newRelationKey,
        relationtype: 'IS_PART_OF',
        readorder: newReadOrderResult.previousReadOrder + newReadOrderResult.incrementGap,
        from: {
          href: `/content/${action.payload.newKey}`,
        },
        to: {
          href: `/content/${parent.key}`,
        },
        $$meta: { permalink: `/content/relations/${action.payload.newRelationKey}` },
      };

      const resources = [
        {
          href: `/content/${action.payload.newKey}`,
          body: newNode,
          parentHref: newRelation.to.href,
        },
        {
          href: `/content/relations/${action.payload.newRelationKey}`,
          relatedTo: { href: `/content/${action.payload.newKey}` },
          body: newRelation,
        },
      ];

      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'CREATE',
        resources,
      });

      webconfigurations.forEach((wc) => {
        resources.push({
          href: `${apiRoutes.webpages}/${wc.key}`,
          body: wc,
          relatedTo: { href: `/content/${action.payload.newKey}` },
        });
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(toggleCollapseAction(action.payload.parentKey, false, false)),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
            Cmd.action(newNodeDropped({ newHref: `/content/${action.payload.newKey}` })),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.ADD_NODE: {
      const newNode = {
        ...action.payload.node,
        ...JSON.parse(JSON.stringify(nodeTypeDefinitions[action.payload.$$type].node)), // deep clone!
      };
      if (!newNode.attachments) {
        newNode.attachments = [];
      } else {
        if (action.payload.node.attachments) {
          newNode.attachments = action.payload.node.attachments;
        }
        const contentAttachment = newNode.attachments.find((a) => a.type === 'CONTENT');
        if (contentAttachment && !contentAttachment.key) {
          contentAttachment.key = action.payload.node.attachmentKey;
        }
      }
      if (!newNode.importance) {
        newNode.importance = 'MEDIUM';
      }

      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'CREATE',
        resources: [{ href: `/content/${action.payload.node.key}`, body: newNode }],
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        action.payload.updateTree
          ? Cmd.list([Cmd.action(updateApiPendingAndWithChangesAction())])
          : Cmd.none
      );
    }

    case ACTION_TYPES.ADD_RELATION: {
      if (!action.payload.$$meta) {
        action.payload.$$meta = { permalink: `/content/relations/${action.payload.key}` };
      }

      const fromContent = state.apiWithPendingChanges.content.get(action.payload.from.href);

      // Create pending action
      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'CREATE',
        resources: [
          {
            href: `/content/relations/${action.payload.key}`,
            relatedTo: { href: fromContent ? action.payload.from.href : action.payload.to.href },
            body: action.payload,
          },
        ],
      });

      const { goalRelationsParams } = action.payload;
      if (goalRelationsParams) {
        state.goalRelationsExpanded = false;
      }

      return loop(
        {
          ...state,
          pendingActions,
        },
        action.payload.updateTree
          ? Cmd.list(
              [
                action.payload.markDirtyNodes
                  ? Cmd.list([
                      Cmd.action(
                        dirtyNodeAction(getKeyFromContentHref(action.payload.to.href)),
                        false
                      ),
                      Cmd.action(
                        dirtyNodeAction(getKeyFromContentHref(action.payload.from.href)),
                        false
                      ),
                    ])
                  : Cmd.none,
                Cmd.action(updateApiPendingAndWithChangesAction()),
                Cmd.action(updateDocumentTreeAction()),
                goalRelationsParams
                  ? Cmd.action(
                      expandLlinkidGoalRelations({
                        key: goalRelationsParams.key,
                        relationTypes: goalRelationsParams.relationTypes,
                        originPart: goalRelationsParams.originPart,
                      })
                    )
                  : Cmd.none,
              ],
              { sequence: true, batch: true }
            )
          : Cmd.none
      );
    }

    case ACTION_TYPES.ADD_EXTERNAL_RELATION: {
      const parent = state.apiWithPendingChanges.content.get(
        `/content/${action.payload.parentKey}`
      );
      const newReadOrderResult = getNewReadOrder(action.payload.position, parent.$$children, 1);

      const relation = {
        key: action.payload.key,
        $$meta: { permalink: `/content/relations/${action.payload.key}` },
        from: {
          href: action.payload.externalResource.$$meta.permalink,
          $$expanded: action.payload.externalResource,
        },
        to: { href: `/content/${action.payload.parentKey}` },
        relationtype: 'IS_PART_OF',
        readorder: newReadOrderResult.previousReadOrder + newReadOrderResult.incrementGap,
      };

      // Create pending action
      const pendingActions = [...state.pendingActions];

      pendingActions.push({
        type: 'CREATE',
        resources: [
          {
            href: `/content/relations/${action.payload.key}`,
            relatedTo: { href: relation.to.href },
            body: relation,
          },
          {
            // type: 'EXTERNAL_CONTENT',
            href: action.payload.externalResource.$$meta.permalink,
            body: {
              ...action.payload.externalResource,
              attachments: [],
              type: action.payload.externalResource.type
                ? action.payload.externalResource.type
                : action.payload.externalResource.$$meta.type,
            },
          },
        ],
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.list([Cmd.action(dirtyNodeAction(getKeyFromContentHref(relation.to.href)), false)]),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { sequence: true, batch: true }
        )
      );
    }

    case ACTION_TYPES.ADD_LINK_REFERENCE_NODE: {
      const pendingActions = addLinkReferenceNode(
        state,
        action.payload.parentKey,
        action.payload.label,
        action.payload.referencedResourceHref,
        action.payload.isUnderGroup
      );

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(dirtyNodeAction(action.payload.parentKey, false)),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.EDIT_LINK_REFERENCE_NODE: {
      const pendingActions = editLinkReferenceNode(
        state,
        action.payload.parentKey,
        action.payload.referenceKey,
        action.payload.label,
        action.payload.referencedResourceHref
      );

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(dirtyNodeAction(action.payload.parentKey, false)),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.ADD_REFERENCE_FROM_NODE: {
      const parent = state.apiWithPendingChanges.content.get(
        `/content/${action.payload.parentKey}`
      );
      const newReference = {
        ...action.payload.referenceNode,
        type: 'REFERENCE',
        $$new: 'true',
        attachments: [],
        importance: 'MEDIUM',
        ...nodeTypeDefinitions.REFERENCE.node,
      };

      const newRelation = {
        ...action.payload.relationToNode,
        relationtype: 'IS_PART_OF',
        readorder: parent.$$children.length + 1,
        from: {
          href: `/content/${newReference.key}`,
        },
        to: {
          href: `/content/${action.payload.parentKey}`,
        },
      };

      const newReferenceRelation = {
        ...action.payload.referenceRelation,
        relationtype: 'REFERENCES',
        strength: 'MEDIUM',
        from: {
          href: `/content/${newReference.key}`,
        },
        to: {
          href: action.payload.resourceHref,
        },
      };

      const pendingActions = [...state.pendingActions];

      const resources = [
        {
          href: `/content/${newReference.key}`,
          body: newReference,
          parentHref: newRelation.to.href,
        },
        {
          href: `/content/relations/${newRelation.key}`,
          relatedTo: { href: `/content/${newReference.key}` },
          body: newRelation,
        },
        {
          href: `/content/relations/${newReferenceRelation.key}`,
          relatedTo: { href: `/content/${newReference.key}` },
          body: newReferenceRelation,
        },
      ];

      pendingActions.push({
        type: 'CREATE',
        resources,
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.ADD_ATTACHMENT_TO_NODE: {
      let parent = state.apiWithPendingChanges.content.get(`/content/${action.payload.parentKey}`);

      let toKey = action.payload.parentKey;

      let newAttachmentsGroup;
      let newAttachmentsGroupRelation;

      if (parent.type !== 'ATTACHMENTS_GROUP' && parent.type !== 'SHARED_ATTACHMENTS_GROUP') {
        // it's a global attachments group -> try to create the group resource if doesn't exists
        parent = parent.$$children.find(
          (c) => c.$$type === 'ATTACHMENTS_GROUP' && c.tags.includes('PAGE_ATTACHMENTS_GROUP')
        );

        // if the parent (attachmentGroup) is on deleteProposal we also create a new attachmentGroup
        if (!parent || (parent && parent.deleteProposal)) {
          newAttachmentsGroup = {
            key: action.payload.newGroupKey,
            type: 'ATTACHMENTS_GROUP',
            tags: ['PAGE_ATTACHMENTS_GROUP'],
            attachments: [],
            importance: 'HIGH',
          };

          newAttachmentsGroupRelation = {
            key: action.payload.newGroupRelationKey,
            relationtype: 'IS_PART_OF',
            readorder: undefined,
            from: {
              href: `/content/${action.payload.newGroupKey}`,
            },
            to: {
              href: `/content/${action.payload.parentKey}`,
            },
          };
        }

        toKey = newAttachmentsGroup ? newAttachmentsGroup.key : parent.key;
      }

      const newReadOrder = !newAttachmentsGroup
        ? getMaxReadOrder(
            state.apiWithPendingChanges.contentRelations.to[`/content/${parent.key}`]
          ) + 1
        : 1;

      const newAttachment = {
        ...action.payload.attachmentNode,
        type: 'ATTACHMENT',
        $$new: 'true',
        attachments: [action.payload.attachment],
        importance: 'MEDIUM',
        ...nodeTypeDefinitions.ATTACHMENT.node,
      };

      const newRelation = {
        ...action.payload.relationToNode,
        relationtype: 'IS_PART_OF',
        readorder: newReadOrder,
        from: {
          href: `/content/${newAttachment.key}`,
          $$expanded: newAttachment,
        },
        to: {
          href: `/content/${toKey}`,
        },
      };

      const pendingActions = [...state.pendingActions];

      let resources = [
        {
          href: `/content/${newAttachment.key}`,
          body: newAttachment,
          parentHref: newRelation.to.href,
        },
        {
          href: `/content/relations/${newRelation.key}`,
          relatedTo: { href: `/content/${newAttachment.key}` },
          body: newRelation,
        },
        {
          type: 'UPLOAD',
          href: `/content/${newAttachment.key}/attachments/${action.payload.attachment.key}`,
          relatedTo: { href: `/content/${newAttachment.key}` },
          body: {
            ...action.payload.attachment,
            href: `/content/${newAttachment.key}/attachments/${action.payload.attachment.name}`,
          },
          node: newAttachment,
        },
      ];

      if (newAttachmentsGroup) {
        resources = [
          ...resources,
          {
            href: `/content/${newAttachmentsGroup.key}`,
            body: newAttachmentsGroup,
          },
          {
            href: `/content/relations/${newAttachmentsGroupRelation.key}`,
            relatedTo: { href: `/content/${newAttachmentsGroup.key}` },
            body: newAttachmentsGroupRelation,
          },
        ];
      }

      pendingActions.push({
        type: 'CREATE',
        resources,
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(
              dirtyNodeAction(newAttachmentsGroup ? newAttachmentsGroup.key : toKey, false)
            ),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.ADD_GLOBAL_DOCUMENT_RELATION: {
      // TODO: make this work with the new APIs
      let parent = state.apiWithPendingChanges.content.get(`/content/${action.payload.parentKey}`);

      let toKey = action.payload.parentKey;

      let newAttachmentsGroup;
      let newAttachmentsGroupRelation;

      if (action.payload.isGlobalAttachmentGroup) {
        // it's a global attachments group -> try to create the group resource if doesn't exist
        parent = parent.$$children.find(
          (c) => c.$$type === 'ATTACHMENTS_GROUP' && c.tags.includes('PAGE_ATTACHMENTS_GROUP')
        );

        // if the parent (attachmentGroup) is on deleteProposal we also create a new attachmentGroup
        if (!parent || (parent && parent.deleteProposal)) {
          newAttachmentsGroup = {
            key: action.payload.newGroupKey,
            type: 'ATTACHMENTS_GROUP',
            tags: ['PAGE_ATTACHMENTS_GROUP'],
            attachments: [],
            importance: 'HIGH',
          };

          newAttachmentsGroupRelation = {
            key: action.payload.newGroupRelationKey,
            relationtype: 'IS_PART_OF',
            readorder: 1,
            from: {
              href: `/content/${action.payload.newGroupKey}`,
            },
            to: {
              href: `/content/${action.payload.parentKey}`,
            },
          };
        }

        toKey = newAttachmentsGroup ? newAttachmentsGroup.key : parent.key;
      }

      const newReadOrder = !newAttachmentsGroup
        ? (state.apiWithPendingChanges.contentRelations.to[`/content/${parent.key}`] || []).length +
          1
        : 1;

      const newRelation = {
        ...action.payload.relationToNode,
        relationtype: 'IS_INCLUDED_IN',
        readorder: newReadOrder,
        from: {
          href: `/content/${action.payload.globalDocument.key}`,
          $$expanded: action.payload.globalDocument,
        },
        to: {
          href: `/content/${toKey}`,
        },
      };

      const pendingActions = [...state.pendingActions];

      let resources = [
        {
          href: `/content/relations/${newRelation.key}`,
          parentHref: `/content/${toKey}`,
          relatedTo: { href: `/content/${action.payload.globalDocument.key}` },
          body: newRelation,
        },
      ];

      if (newAttachmentsGroup) {
        resources = [
          ...resources,
          {
            href: `/content/${newAttachmentsGroup.key}`,
            body: newAttachmentsGroup,
          },
          {
            href: `/content/relations/${newAttachmentsGroupRelation.key}`,
            relatedTo: { href: `/content/${newAttachmentsGroup.key}` },
            body: newAttachmentsGroupRelation,
          },
        ];
      }

      pendingActions.push({
        type: 'CREATE',
        resources,
      });

      const newApiContent = new Map(state.oldApi.content);
      newApiContent.set(
        action.payload.globalDocument.$$meta.permalink,
        action.payload.globalDocument
      );

      return loop(
        {
          ...state,
          pendingActions,
          oldApi: {
            ...state.oldApi,
            content: newApiContent,
          },
          expandedResources: {
            ...state.expandedResources,
            [action.payload.globalDocument.$$meta.permalink]: action.payload.globalDocument,
          },
        },
        Cmd.list(
          [
            Cmd.action(
              dirtyNodeAction(
                newAttachmentsGroup ? newAttachmentsGroup.key : action.payload.parentKey,
                false
              )
            ),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.EDIT_GLOBAL_DOCUMENT_RELATION: {
      // TODO: make this work with the new APIs
      const relationPatch = {
        relationtype: 'IS_INCLUDED_IN',
        from: {
          href: `/content/${action.payload.globalDocument.key}`,
          $$expanded: action.payload.globalDocument,
        },
      };

      const pendingActions = [...state.pendingActions];

      const resources = [
        {
          type: 'PATCH',
          href: `/content/relations/${action.payload.previousRelation.key}`,
          // relatedTo: { href: action.payload.previousRelation.to.href },
          parentHref: action.payload.previousRelation.to.href,
          patch: Object.keys(relationPatch).map((field) => {
            const operation =
              action.payload.previousRelation && action.payload.previousRelation[field]
                ? 'replace'
                : 'add';
            return { op: operation, path: `/${field}`, value: relationPatch[field] };
          }),
        },
      ];

      if (action.payload.previousRelation.relationtype === 'IS_PART_OF') {
        resources.push({
          type: 'DELETE',
          href: action.payload.previousRelation.from.href,
          relatedTo: { href: action.payload.previousRelation.to.href },
        });
      }

      pendingActions.push({
        resources,
      });

      const newApiContent = new Map(state.oldApi.content);
      newApiContent.set(
        action.payload.globalDocument.$$meta.permalink,
        action.payload.globalDocument
      );

      return loop(
        {
          ...state,
          pendingActions,
          oldApi: {
            ...state.oldApi,
            content: newApiContent,
          },
          expandedResources: {
            ...state.expandedResources,
            [action.payload.globalDocument.$$meta.permalink]: action.payload.globalDocument,
          },
        },
        Cmd.list(
          [
            Cmd.action(dirtyNodeAction(action.payload.parentKey, false)),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.REPLACE_GLOBAL_DOCUMENT_RELATION_WITH_ATTACHMENT: {
      const relation = state.apiWithPendingChanges.relations.get(
        `/content/relations/${action.payload.previousGlobalDocumentRelationKey}`
      );

      const newAttachment = {
        ...action.payload.attachmentNode,
        type: 'ATTACHMENT',
        $$new: 'true',
        // tags: action.payload.attachmentTags,
        // description: action.payload.attachmentDescription,
        attachments: [action.payload.attachment],
        importance: 'MEDIUM',
        ...nodeTypeDefinitions.ATTACHMENT.node,
      };

      const relationPatch = {
        relationtype: 'IS_PART_OF',
        from: {
          href: `/content/${newAttachment.key}`,
          $$expanded: newAttachment,
        },
      };

      const pendingActions = [...state.pendingActions];

      const resources = [
        {
          type: 'CREATE',
          href: `/content/${newAttachment.key}`,
          body: newAttachment,
          parentHref: `/content/${action.payload.parentKey}`,
        },
        {
          type: 'PATCH',
          href: `/content/relations/${relation.key}`,
          relatedTo: { href: `/content/${newAttachment.key}` },
          patch: Object.keys(relationPatch).map((field) => {
            const operation = relation && relation[field] ? 'replace' : 'add';
            return { op: operation, path: `/${field}`, value: relationPatch[field] };
          }),
        },
        {
          type: 'UPLOAD',
          href: `/content/${newAttachment.key}/attachments/${action.payload.attachment.key}`,
          relatedTo: { href: `/content/${newAttachment.key}` },
          body: action.payload.attachment,
          node: newAttachment,
        },
      ];

      pendingActions.push({
        resources,
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list(
          [
            Cmd.action(dirtyNodeAction(action.payload.parentKey, false)),
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.OPEN_SUBMIT_SUGGESTIONS_MODAL: {
      return loop(
        {
          ...state,
          viewModel: {
            ...state.viewModel,
            submittingSuggestions: false,
            submitSuggestionsModalOpen: true,
          },
        },
        Cmd.list([
          Cmd.action(calculateSuggestionsToSubmitAction()),
          Cmd.action({
            type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
          }),
        ])
      );
    }

    case ACTION_TYPES.CLOSE_SUBMIT_SUGGESTIONS_MODAL: {
      return loop(
        {
          ...state,
          suggestionsToSubmit: [],
          viewModel: {
            ...state.viewModel,
            submittingSuggestions: false,
            submitSuggestionsModalOpen: false,
          },
        },
        Cmd.list([
          Cmd.action(calculateSuggestionsToSubmitAction()),
          Cmd.action({
            type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
          }),
        ])
      );
    }

    case ACTION_TYPES.OPEN_REVIEW_SUGGESTIONS_MODAL: {
      return loop(
        {
          ...state,
          viewModel: {
            ...state.viewModel,
            reviewingSuggestions: false,
            reviewSuggestionsModalOpen: true,
            reviewAction: action.payload.reviewAction,
          },
        },
        Cmd.action(calculateSuggestionsToReviewAction())
      );
    }

    case ACTION_TYPES.CLOSE_REVIEW_SUGGESTIONS_MODAL: {
      return {
        ...state,
        suggestionsToReview: [],
        viewModel: {
          ...state.viewModel,
          reviewingSuggestions: false,
          reviewSuggestionsModalOpen: false,
        },
      };
    }

    case ACTION_TYPES.CALCULATE_SUGGESTIONS_TO_SUBMIT: {
      if (!rootState.documentApi.isProposalsFetched) {
        return state;
      }
      const newDocumentApi = selectNewDocumentApi(rootState);
      const allProposalsToSubmit = [];

      // filter only those selected that are not submitted yet (include childs of selected node)
      let proposalsToSubmit = [...state.allSelections].reduce((toSubmit, nodeKey) => {
        let possibleProposalsToSubmit = [];

        let proposal = newDocumentApi.proposals.get(`/content/${nodeKey}`);
        if (!proposal) {
          // special case: global document attachment proposals are stored as /content/relations
          const relations = [...state.apiWithPendingChanges.relations.values()].filter(
            (rel) =>
              getResourceKey(rel.from.href) === nodeKey &&
              rel.relationtype === 'IS_INCLUDED_IN' &&
              state.allSelections.includes(getResourceKey(rel.to.href))
          );

          possibleProposalsToSubmit = relations.reduce((list, relation) => {
            proposal = state.apiWithPendingChanges.proposals.get(
              `/content/relations/${relation.key}`
            );
            if (proposal) {
              list.push(proposal);
            }
            return list;
          }, []);
        } else {
          possibleProposalsToSubmit.push(proposal);
        }

        return possibleProposalsToSubmit.reduce((list, possibleProposal) => {
          if (possibleProposal && possibleProposal.status === 'IN_PROGRESS') {
            list.push(possibleProposal);
          }
          return list;
        }, toSubmit);
      }, []);

      // fill array with all the suggestions available to submit
      // (only in api because they need to be saved first)
      // eslint-disable-next-line no-restricted-syntax
      for (const [, proposal] of newDocumentApi.proposals) {
        if (proposal && proposal.status === 'IN_PROGRESS') {
          allProposalsToSubmit.push(proposal);
        }
      }

      if (proposalsToSubmit.length === 0) {
        proposalsToSubmit = [...allProposalsToSubmit];
      }

      const groupedProposalsToSubmitByAuthor = groupProposalsToSubmitByAuthors(
        proposalsToSubmit,
        rootState
      );

      return loop(
        {
          ...state,
          proposalsToSubmit,
          allProposalsToSubmit,
          groupedProposalsToSubmitByAuthor,
          viewModel: {
            ...state.viewModel,
            suggestions: {
              ...state.viewModel.suggestions,
              isValidToSubmit: isValidProposalsSubmit(proposalsToSubmit, {
                ...state,
                api: newDocumentApi,
              }),
            },
          },
        },
        Cmd.action(updateDocumentViewModelAction())
      );
    }

    case ACTION_TYPES.CALCULATE_SUGGESTIONS_TO_REVIEW: {
      if (!rootState.documentApi.isProposalsFetched) {
        return state;
      }
      const allProposalsToReview = [];

      // filter only those selected that are already submitted (include childs of selected node)
      let proposalsToReview = [...state.allSelections].reduce((toReview, nodeKey) => {
        let possibleProposalsToReview = [];

        let proposal = state.apiWithPendingChanges.proposals.get(`/content/${nodeKey}`);
        if (!proposal) {
          // special case: global document attachment proposals are stored as /content/relations
          const relations = [...state.apiWithPendingChanges.relations.values()].filter(
            (rel) =>
              getResourceKey(rel.from.href) === nodeKey &&
              rel.relationtype === 'IS_INCLUDED_IN' &&
              state.allSelections.includes(getResourceKey(rel.to.href))
          );

          possibleProposalsToReview = relations.reduce((list, relation) => {
            proposal = state.apiWithPendingChanges.proposals.get(
              `/content/relations/${relation.key}`
            );
            if (proposal) {
              list.push(proposal);
            }
            return list;
          }, []);
        } else {
          possibleProposalsToReview.push(proposal);
        }

        return possibleProposalsToReview.reduce((list, possibleProposal) => {
          if (possibleProposal && possibleProposal.status === 'SUBMITTED_FOR_REVIEW') {
            list.push(possibleProposal);
          }
          return list;
        }, toReview);
      }, []);

      // fill array with all the suggestions available to review
      // eslint-disable-next-line no-restricted-syntax
      for (const [, proposal] of state.apiWithPendingChanges.proposals) {
        if (proposal && proposal.status === 'SUBMITTED_FOR_REVIEW') {
          allProposalsToReview.push(proposal);
        }
      }

      if (proposalsToReview.length === 0) {
        proposalsToReview = [...allProposalsToReview];
      }

      return loop(
        {
          ...state,
          proposalsToReview,
          allProposalsToReview,
        },
        Cmd.action(updateDocumentViewModelAction())
      );
    }

    case ACTION_TYPES.TOGGLE_SUBMIT_SUGGESTIONS_OF_GROUP: {
      let newProposalsToSubmit;
      const newGroups = [...state.groupedProposalsToSubmitByAuthor];
      const group = newGroups.find((g) => g.key === action.payload.groupKey);

      if (action.payload.isSelected) {
        newProposalsToSubmit = [...state.proposalsToSubmit, ...group.proposals];
      } else {
        // remove proposals to submit that are part of the unselected group
        newProposalsToSubmit = state.proposalsToSubmit.filter((proposal) => {
          return !group.proposals.some((groupProposal) => groupProposal.key === proposal.key);
        });
      }

      group.selected = action.payload.isSelected;

      return loop(
        {
          ...state,
          proposalsToSubmit: newProposalsToSubmit,
          groupedProposalsToSubmitByAuthor: newGroups,
        },
        Cmd.action({
          type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
        })
      );
    }

    case ACTION_TYPES.SUBMIT_SUGGESTIONS: {
      let batch;
      const newDocumentApi = selectNewDocumentApi(rootState);
      const isValidToSubmit = isValidProposalsSubmit(state.proposalsToSubmit, {
        ...state,
        api: newDocumentApi,
      });

      if (isValidToSubmit) {
        batch = state.proposalsToSubmit.map((proposal) => ({
          verb: 'PUT',
          href: `/proposals/${proposal.key}`,
          body: {
            ...proposal,
            status: 'SUBMITTED_FOR_REVIEW',
          },
        }));
      }

      const cmdList = [];
      if (!isValidToSubmit) {
        cmdList.push(
          Cmd.action(
            addNotificationAction({ type: 'ERROR', message: 'proposals.error.invalidToSubmit' })
          )
        );
        cmdList.push(Cmd.action(closeSubmitSuggestionsModalAction()));
      } else if (state.proposalsToSubmit.length > 0) {
        cmdList.push(
          Cmd.run(sendProposalsBatchCmd, {
            args: [batch],
            successActionCreator: sendEmailOfSubmittedSuggestionsAction,
            failActionCreator: (error) => documentSaveFailedAction(error, state),
          })
        );
      } else {
        cmdList.push(Cmd.action(closeSubmitSuggestionsModalAction()));
      }

      return loop(
        {
          ...state,
          viewModel: {
            ...state.viewModel,
            submittingSuggestions: true,
            submitSuggestionsMessage: action.payload.message,
            documentUrl: action.payload.url,
          },
        },
        Cmd.list(cmdList)
      );
    }

    // REFACTOR-TODO remove api part once api is removed
    case ACTION_TYPES.SUGGESTIONS_SUBMITTED: {
      // TODO: make this work with the new APIs
      // update the status of the proposals api cache list
      const updatedProposals = new Map(state.oldApi.proposals);
      state.proposalsToSubmit.forEach((proposal) => {
        updatedProposals.set(getRelatedContentHref(proposal), {
          ...proposal,
          status: proposal.status === 'IN_PROGRESS' ? 'SUBMITTED_FOR_REVIEW' : proposal.status,
        });
      });

      return loop(
        {
          ...state,
          suggestionsToSubmit: [],
          viewModel: {
            ...state.viewModel,
            submittingSuggestions: false,
            submitSuggestionsModalOpen: false,
          },
          oldApi: {
            ...state.oldApi,
            proposals: updatedProposals,
          },
        },
        Cmd.list([
          // Cmd.action(updateApiPendingAndWithChangesAction()),
          // Cmd.action(updateDocumentTreeAction()),
          Cmd.action(clearSelectionsAction()),
          // Cmd.list(
          //   state.proposalsToSubmit.map(proposal => Cmd.action(
          //     dirtyNodeAction(getResourceKey(getContentResourceFromRelatedHref(getRelatedContentHref(proposal), state.apiWithPendingChanges.relations)), false)
          //   ))
          // ),
          Cmd.action(calculateSuggestionsToSubmitAction()),
          Cmd.action(
            addNotificationAction({
              type: 'SUCCESS',
              message: 'edit.suggestions.suggestionsSubmittedMessage',
            })
          ),
        ])
      );
    }

    case ACTION_TYPES.REJECT_SUGGESTIONS: {
      let pendingActions = [...state.pendingActions];
      let proposalsToUpdate = [...state.proposalsToReview];

      // in case we are rolling back an issued proposal we want to ACCEPT all the proposals (including IN_PROGRESS ones)
      // only the issued patch change will be modified to be empty all the rest is accepted (#18221)
      let isRollbackIssuedDate;
      const proposalForIssuedDate = getProposalForIssuedDate(state.proposalsToReview);
      if (proposalForIssuedDate) {
        const issuedChange = proposalForIssuedDate.listOfRequestedChanges.find(
          (change) => change.patch && change.patch.some((patch) => patch.path === '/issued')
        );

        proposalForIssuedDate.listOfRequestedChanges = [
          ...proposalForIssuedDate.listOfRequestedChanges,
        ].concat({
          ...issuedChange,
          patch: [{ op: 'replace', path: '/issued', value: undefined }],
        });

        isRollbackIssuedDate = true;
        proposalsToUpdate = proposalsToUpdate
          .filter((p) => p.key !== proposalForIssuedDate.key)
          .concat(proposalForIssuedDate)
          .concat(
            [...state.apiWithPendingChanges.proposals.values()].filter(
              (proposal) => proposal.status === 'IN_PROGRESS'
            )
          );
      }

      // create the needed pending actions to update the proposals status
      proposalsToUpdate.forEach((proposal) => {
        const patch = [
          { op: 'replace', path: '/status', value: isRollbackIssuedDate ? 'ACCEPTED' : 'REJECTED' },
        ];

        if (proposalForIssuedDate && proposalForIssuedDate.key === proposal.key) {
          // replace the list of requested changes setting issued date to empty and accept the rest of the proposal
          patch.push({
            op: 'replace',
            path: '/listOfRequestedChanges',
            value: proposalForIssuedDate.listOfRequestedChanges,
          });
        }

        pendingActions = pendingActions.concat(
          createPatchProposalPendingActions(
            proposal,
            patch,
            state.apiWithPendingChanges.content,
            isRollbackIssuedDate
          )
        );
      });

      return loop(
        {
          ...state,
          pendingActions,
          viewModel: {
            ...state.viewModel,
            reviewSuggestionsModalOpen: false,
          },
        },
        Cmd.list([
          // Cmd.action(updateApiPendingAndWithChangesAction()),
          // Cmd.action(updateDocumentTreeAction()),
          Cmd.action(clearSelectionsAction()),
          // Cmd.list(
          //   state.proposalsToReview.map(proposal => Cmd.action(
          //     dirtyNodeAction(getResourceKey(getRelatedContentHref(proposal)), false)
          //   ))
          // ),
          Cmd.action(calculateSuggestionsToReviewAction()),
        ])
      );
    }

    case ACTION_TYPES.ACCEPT_SUGGESTIONS: {
      let pendingActions = [...state.pendingActions];

      state.proposalsToReview.forEach((proposal) => {
        if (proposal && proposal.status === 'SUBMITTED_FOR_REVIEW') {
          const patch = [{ op: 'replace', path: '/status', value: 'ACCEPTED' }];

          pendingActions = pendingActions.concat(
            createPatchProposalPendingActions(proposal, patch, state.api.content, true)
          );
        }
      });

      return loop(
        {
          ...state,
          pendingActions,
          viewModel: {
            ...state.viewModel,
            reviewSuggestionsModalOpen: false,
          },
        },
        Cmd.list(
          [
            // Cmd.action(updateApiPendingAndWithChangesAction()),
            // Cmd.action(updateDocumentTreeAction()),
            Cmd.action(clearSelectionsAction()),
            // Cmd.list(
            //   state.proposalsToReview.map(proposal => Cmd.action(
            //     dirtyNodeAction(getResourceKey(getRelatedContentHref(proposal)), false)
            //   ))
            // ),
            Cmd.action(calculateSuggestionsToReviewAction()),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.CANCEL_SUGGESTIONS: {
      let pendingActions = [...state.pendingActions];
      let proposalsToUpdate = [...state.proposalsToReview];

      // in case we are rolling back an issued proposal we want to ACCEPT all the proposals (including IN_PROGRESS ones)
      // only the issued patch change will be modified to be empty all the rest is accepted (#18221)
      let isRollbackIssuedDate;
      const proposalForIssuedDate = getProposalForIssuedDate(state.proposalsToReview);
      if (proposalForIssuedDate) {
        const issuedChange = proposalForIssuedDate.listOfRequestedChanges.find(
          (change) => change.patch && change.patch.some((patch) => patch.path === '/issued')
        );

        proposalForIssuedDate.listOfRequestedChanges = [
          ...proposalForIssuedDate.listOfRequestedChanges,
        ].concat({
          ...issuedChange,
          patch: [{ op: 'replace', path: '/issued', value: undefined }],
        });

        isRollbackIssuedDate = true;
        proposalsToUpdate = proposalsToUpdate
          .filter((p) => p.key !== proposalForIssuedDate.key)
          .concat(proposalForIssuedDate)
          .concat(
            [...state.apiWithPendingChanges.proposals.values()].filter(
              (proposal) => proposal.status === 'IN_PROGRESS'
            )
          );
      }

      // create the needed pending actions to update the proposals status
      proposalsToUpdate.forEach((proposal) => {
        const patch = [
          {
            op: 'replace',
            path: '/status',
            value: isRollbackIssuedDate ? 'ACCEPTED' : 'IN_PROGRESS',
          },
        ];

        if (proposalForIssuedDate && proposalForIssuedDate.key === proposal.key) {
          // replace the list of requested changes setting issued date to empty and accept the rest of the proposal
          patch.push({
            op: 'replace',
            path: '/listOfRequestedChanges',
            value: proposalForIssuedDate.listOfRequestedChanges,
          });
        }

        pendingActions = pendingActions.concat(
          createPatchProposalPendingActions(
            proposal,
            patch,
            state.apiWithPendingChanges.content,
            isRollbackIssuedDate
          )
        );
      });

      return loop(
        {
          ...state,
          pendingActions,
          viewModel: {
            ...state.viewModel,
            reviewSuggestionsModalOpen: false,
          },
        },
        Cmd.list([
          // Cmd.action(updateApiPendingAndWithChangesAction()),
          // Cmd.action(updateDocumentTreeAction()),
          Cmd.action(clearSelectionsAction()),
          // Cmd.list(
          //   state.proposalsToReview.map(proposal => Cmd.action(
          //     dirtyNodeAction(getResourceKey(getRelatedContentHref(proposal)), false)
          //   ))
          // ),
          Cmd.action(calculateSuggestionsToReviewAction()),
        ])
      );
    }

    case ACTION_TYPES.CLOSE_ASIDE: {
      return {
        ...state,
        editKey: null,
      };
    }

    case ACTION_TYPES.BACK_TO_DOCUMENTS_LIST: {
      return {
        ...state,
        key: undefined,
        api: {
          content: new Map(),
          relations: new Map(),
          proposals: new Map(),
          webpages: new Map(),
          fileUploads: new Map(),
        },
        pendingActions: [],
        collapsedNodes: {},
        dirtyNodes: [],
        selections: [],
        allSelections: [],
        allSelectionsHref: [],
        viewModel: {
          loading: true,
          flat: [],
          aside: {
            editDocument: {},
            loading: true,
          },
          terms: {
            global: [],
            local: [],
          },
          websiteContacts: [],
          websites: [], // from /web/sites
          loadingWebsitesConfiguration: false,
          suggestionsToSubmit: [],
          suggestions: {},
          referenceFrameThemes: {},
        },
        markedAllRead: false,
        callToActions: new Map(),
        linkedContentTypes: new Map(),
        referenceGroupDocument: undefined,
      };
    }

    case ACTION_TYPES.SEND_SUBMITTED_SUGGESTIONS_EMAIL: {
      const currentUser = `${selectUser(rootState).lastName} ${selectUser(rootState).firstName}`;

      const emailBody =
        `${currentUser} (<a href="mailto:${selectUser(rootState).$$email}">${
          selectUser(rootState).$$email
        }</a>)` +
        ' vraagt om een review van ' +
        `<a href="${state.viewModel.documentUrl}">(${state.tree.$$typeConfig.information.single}) ${state.tree.title}</a>` +
        `<p>Themaverantwoordelijke(n): ${state.tree.creators
          ?.map((c) => {
            const persons = selectPersons(rootState);
            const author = persons[c];
            return author ? `${author.lastName} ${author.firstName}` : c;
          })
          .join(', ')}${
          state.viewModel.submitSuggestionsMessage
            ? `<br>Extra Informatie: ${state.viewModel.submitSuggestionsMessage}`
            : ''
        }`;

      const emailjob = {
        ...settings.emails.template,
        ...settings.emails.submitSuggestions,
        recipients: [
          {
            emailAddress: settings.emails.tagAddressMap.get(state.tree.tags[0]),
          },
        ],
        key: action.payload.key,
        subject: `(${state.tree.$$typeConfig.information.single}) ${state.tree.title} : review aangevraagd door ${currentUser})`,
        replyTo: selectUser(rootState).$$email,
      };
      emailjob.recipients[0].mergeVariables = [
        {
          name: 'EMAIL',
          content: emailBody,
        },
      ];

      return loop(
        {
          ...state,
          viewModel: {
            ...state.viewModel,
            sendingEmail: true,
          },
        },
        Cmd.run(sendEmailCmd, {
          args: [[emailjob]],
          successActionCreator: () =>
            suggestionsSubmittedAction(
              state.proposalsToSubmit.map((proposal) => proposal.$$meta.permalink)
            ),
          failActionCreator: (error) => documentSaveFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.PUBLISH_DOCUMENT: {
      // TODO: make this work with the new APIs
      const batch = [];
      const { proposal } = action.payload;

      batch.push({
        verb: 'PUT',
        href: `/proposals/${proposal.key}`,
        body: proposal,
      });

      const newProposals = new Map(state.oldApi.proposals);
      newProposals.set(`/content/${state.key}`, proposal);

      return loop(
        {
          ...state,
          oldApi: {
            ...state.oldApi,
            proposals: newProposals,
          },
          publishAttempted: true,
          publishMessage: action.payload.message,
          locationUrl: action.payload.locationUrl,
        },
        Cmd.run(publishProposalCmd, {
          args: [batch],
          successActionCreator: sendDocumentPublishedMailAction,
          failActionCreator: showValidationErrors,
        })
      );
    }

    case ACTION_TYPES.SEND_PUBLISHED_EMAIL: {
      const emailBody =
        `${state.publishMessage}
          <p><a href="${state.locationUrl}">(${state.tree.$$typeConfig.information.single}) ${state.tree.title}</a>` +
        `<p>Themaverantwoordelijke: ${state.tree.creators
          ?.map((c) => {
            const persons = selectPersons(rootState);
            const author = persons[c];
            return author ? `${author.lastName} ${author.firstName}` : c;
          })
          .join(', ')}<br>Gebruiker die verzond voor publicatie: ${
          selectUser(rootState).lastName
        } ${selectUser(rootState).firstName}`;

      const emailjob = {
        ...settings.emails.template,
        ...settings.emails.publishDocument,
        recipients: [
          {
            emailAddress: settings.emails.tagAddressMap.get(state.tree.tags[0]),
          },
        ],
        key: action.payload.key,
        subject: `(${state.tree.$$typeConfig.information.single}) ${state.tree.title}`,
        replyTo: selectUser(rootState).$$email,
      };
      emailjob.recipients[0].mergeVariables = [
        {
          name: 'EMAIL',
          content: emailBody,
        },
      ];

      return loop(
        {
          ...state,
          publishAttempted: false,
          publishModalOpen: false,
        },
        Cmd.run(sendEmailCmd, {
          args: [[emailjob]],
          successActionCreator: documentPublishedAction,
          failActionCreator: showValidationErrors,
        })
      );
    }

    case ACTION_TYPES.DOCUMENT_PUBLISHED: {
      return loop(
        {
          ...state,
        },
        Cmd.list([
          Cmd.action(dirtyNodeAction(state.key, false)),
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.OPENED_PUBLISH_MODAL: {
      return { ...state, publishModalOpen: true };
    }

    case ACTION_TYPES.CLOSE_PUBLISH_MODAL: {
      return { ...state, publishModalOpen: false };
    }

    case ACTION_TYPES.IMPORT_DOCUMENT_IN_SECTION: {
      // eslint-disable-next-line no-inner-declarations
      function addNode(node, parentKey, position) {
        const newNode = {
          key: node.key,
          type: node.type,
          ...nodeTypeDefinitions[node.type].node,
          ...node,
        };
        if (!newNode.attachments) {
          newNode.attachments = [];
        } else {
          const contentAttachment = newNode.attachments.find((a) => a.type === 'CONTENT');
          if (contentAttachment && !contentAttachment.key) {
            contentAttachment.key = node.attachmentKey;
          }
        }
        if (!newNode.importance) {
          newNode.importance = 'MEDIUM';
        }

        const newRelation = action.payload.importResult.resources.relations.find(
          (relation) => node.relationKey === relation.key
        );

        const pendingActions = [...state.pendingActions];
        pendingActions.push({
          type: 'CREATE',
          resources: [
            {
              href: `/content/${node.key}`,
              body: newNode,
              parentHref: newRelation.to.href,
            },
            {
              href: `/content/relations/${node.relationKey}`,
              relatedTo: { href: `/content/${node.key}` },
              body: newRelation,
            },
          ],
        });

        return pendingActions;
      }

      let newPendingActions = [];

      let position = 1;
      action.payload.importResult.resources.content.forEach((c) => {
        newPendingActions = [...newPendingActions, ...addNode(c, c.parentKey, position)];
        position += 1;
      });

      return loop(
        {
          ...state,
          pendingActions: [...state.pendingActions, ...newPendingActions],
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.list(
            action.payload.importResult.attachmentsToUpload.map((upload) =>
              Cmd.action(
                addAttachment(
                  upload.resourceKey,
                  {
                    key: upload.key,
                    type: upload.type,
                    name: upload.file.name,
                    size: upload.file.size,
                    $$base64: upload.$$base64,
                    isNew: true,
                    created: new Date(),
                    contentType: upload.file.type,
                  },
                  upload.file
                )
              )
            )
          ),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.SET_NEWSLETTER_SETTINGS: {
      if (action.payload.newsletterSettings.length === 0) {
        return state;
      }

      const newsletterSettings = new Map();
      action.payload.newsletterSettings.forEach((n) => {
        newsletterSettings.set(n.$$meta.permalink, n);
      });

      return {
        ...state,
        lastRead: action.payload.newsletterSettings[0].approvalDate,
        oldApi: {
          ...state.oldApi,
          newsletterSettings,
        },
        oldApiWithPendingChanges: {
          ...state.oldApiWithPendingChanges,
          newsletterSettings,
        },
      };
    }

    case ACTION_TYPES.GET_NEWSLETTER_SETTINGS: {
      return loop(
        state,
        Cmd.run(fetchNewsletterSettingsCmd, {
          args: [state.key],
          successActionCreator: setNewsletterSettings,
        })
      );
    }

    case ACTION_TYPES.ADD_TEASER: {
      return loop(
        state,
        Cmd.run(loadDocumentCmd, {
          args: [action.payload.teaser.key],
          successActionCreator: (results) => ({
            type: ACTION_TYPES.ADD_TEASER_SUCCESS,
            payload: {
              sectionHref: action.payload.sectionHref,
              position: action.payload.position,
              teaser: action.payload.teaser,
              content: results,
            },
          }),
        })
      );
    }

    // REFACTOR-TODO when document api slice is removed strip down to only updating pendingActions
    case ACTION_TYPES.ADD_TEASER_SUCCESS: {
      // TODO: make this work with the new APIs
      const content = new Map(state.oldApi.content);
      action.payload.content.forEach((c) => {
        content.set(c.$$meta.permalink, c);
      });

      const relations = new Map(state.oldApi.relations);
      action.payload.content
        .filter((c) => c.type === 'REFERENCE')
        .forEach((c) => {
          c.$$relationsFrom.forEach((r) => relations.set(r.href, r.$$expanded));
        });

      const relationKey = uuidv4();
      const newApiWithPendingChanges = selectApiWithPendingChangesOldSlice(rootState);
      const parent = newApiWithPendingChanges.content.get(action.payload.sectionHref);
      const readOrder = getNewReadOrder(action.payload.position, parent.$$children, 1);
      const resources = [
        {
          verb: 'PUT',
          href: `/content/relations/${relationKey}`,
          body: {
            key: relationKey,
            from: { href: action.payload.teaser.$$meta.permalink },
            to: { href: action.payload.sectionHref },
            relationtype: 'IS_INCLUDED_IN',
            readorder: readOrder.previousReadOrder + readOrder.incrementGap,
          },
        },
      ];

      const pendingActions = [...state.pendingActions];
      pendingActions.push({
        type: 'CREATE',
        resources,
      });

      return loop(
        {
          ...state,
          pendingActions,
          oldApi: {
            ...state.oldApi,
            content,
            relations,
          },
        },
        Cmd.list(
          [
            Cmd.action(updateApiPendingAndWithChangesAction()),
            Cmd.action(updateDocumentTreeAction(true)),
          ],
          { batch: true }
        )
      );
    }

    case ACTION_TYPES.PATCH_DATE_TO_SEND: {
      const newsletterSettings = state.apiWithPendingChanges.newsletterSettings
        .values()
        .next().value;

      const pendingActions = [...state.pendingActions];
      const resources = [
        {
          href: newsletterSettings.$$meta.permalink,
          parentHref: newsletterSettings.newsletter.href,
          patch: [
            {
              op: newsletterSettings.dateToSend ? 'replace' : 'add',
              path: '/dateToSend',
              value: action.payload.dateToSend,
            },
          ],
        },
      ];
      pendingActions.push({
        type: 'PATCH',
        resources,
      });

      return loop(
        {
          ...state,
          pendingActions,
        },
        Cmd.list([
          Cmd.action(updateApiPendingAndWithChangesAction()),
          Cmd.action(updateDocumentTreeAction()),
        ])
      );
    }

    case ACTION_TYPES.PATCH_THEMES: {
      const { editDocument } = state.viewModel.aside;
      const patch = {
        themes: action.payload.themes,
      };

      if (editDocument.type === 'TEASER') {
        patch.positions =
          action.payload.themes.length === 0
            ? []
            : state.namedSets.get('doelgroepen').map((n) => n.$$meta.permalink);
      }

      const webFacetsConfig = editDocument.$$editSections.find(
        (es) => es.component === 'webFacets'
      );
      const refreshFacets =
        webFacetsConfig &&
        webFacetsConfig.options &&
        webFacetsConfig.options.source === 'themesMatches';

      return loop(
        state,
        Cmd.list(
          [
            Cmd.action({
              type: ACTION_TYPES.PATCH_NODE,
              payload: { key: action.payload.key, patch, updateTree: true },
            }),
            refreshFacets
              ? Cmd.action({
                  type: ACTION_TYPES.GET_FACET_SOURCE_WEB_CONFIGS,
                  payload: { options: webFacetsConfig.options },
                })
              : Cmd.none,
          ],
          { sequence: true }
        )
      );
    }

    default: {
      return state;
    }

    case ACTION_TYPES.VALIDATE_EXTERNAL_RELATION: {
      let success = true;
      // only for reference frame 'dienstverlening katholiek onderwijs vlaanderen'
      if (
        rootState.document.key === constants.dienstverleningKovKey &&
        action.payload.isUniqueInDienstverleningKov
      ) {
        success = isExternalRelationUniqueInDocument(
          action.payload.permalink,
          state.apiWithPendingChanges.relations
        );
      }
      return success
        ? {
            ...state,
            viewModel: {
              ...state.viewModel,
              selectExternalRelationModalOpen: false,
            },
          }
        : loop(
            {
              ...state,
            },
            Cmd.action(
              addNotificationAction({
                type: 'ERROR',
                message: 'selectExternalRelationModal.error.externalRelationNotUnique',
                params: {
                  type: action.payload.type,
                  title: action.payload.title,
                },
                removeAfter: 5,
              })
            )
          );
    }

    case ACTION_TYPES.OPEN_SELECT_EXTERNAL_RELATION_MODAL: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          selectExternalRelationModalOpen: true,
        },
      };
    }

    case ACTION_TYPES.OPEN_NOT_APPLICABLE_PROPOSALS_MODAL: {
      // add nodes with not applicable proposals to view model
      const nodes = [...state.notApplicableProposalsMap.keys()].map((key) => {
        const node = state.viewModel.flatWithHiddens.find((n) => n.key === key);
        return {
          title: node.title || node.description,
          typeName: node.$$typeConfig.information.single,
          path: getPath(node.key, state.viewModel.flatWithHiddens),
        };
      });

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          napModal: {
            nodes,
            open: true,
          },
        },
      };
    }

    case ACTION_TYPES.CLOSE_NOT_APPLICABLE_PROPOSALS_MODAL: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          napModal: {
            open: false,
          },
        },
      };
    }

    case ACTION_TYPES.GET_IS_INCLUDED_IN_PRO_THEME: {
      return loop(
        state,
        Cmd.run(fetchisIncludedInProThemeCmd, {
          args: [state.key],
          successActionCreator: (isIncludedInProTheme) => ({
            type: ACTION_TYPES.SET_IS_INCLUDED_IN_PRO_THEME,
            payload: { isIncludedInProTheme },
          }),
        })
      );
    }

    case ACTION_TYPES.SET_IS_INCLUDED_IN_PRO_THEME: {
      const { isIncludedInProTheme } = action.payload;

      return {
        ...state,
        isIncludedInProTheme,
      };
    }

    case ACTION_TYPES.LOAD_PREVIOUS_VERSION: {
      return loop(
        {
          ...state,
        },
        Cmd.run(loadHrefsCmd, {
          args: [action.payload.hrefs, action.payload.key],
          successActionCreator: ({ results }) => ({
            type: ACTION_TYPES.SET_PREVIOUS_VERSION,
            payload: { results },
          }),
        })
      );
    }

    case ACTION_TYPES.SET_PREVIOUS_VERSION: {
      const { results } = action.payload;
      const [firstPreviousVersion] = results;
      const newExpandedResources = {
        ...state.expandedResources,
      };

      results.forEach((result) => {
        if (!newExpandedResources[result.$$meta.permalink]) {
          newExpandedResources[result.$$meta.permalink] = result;
        }
      });

      return loop(
        {
          ...state,
          expandedResources: newExpandedResources,
          viewModel: {
            ...state.viewModel,
            aside: {
              ...state.viewModel.aside,
              previousVersion: firstPreviousVersion,
            },
          },
        },
        Cmd.action(updateAsideViewModelAction(state.editKey))
      );
    }

    case ACTION_TYPES.INIT_WEB_FACETS: {
      const key = state.editKey;

      // load reference frames for themes matching to get web facets
      const { matchingParams } = action.payload.config.options;
      const referenceFrameActions = matchingParams
        ? Object.values(matchingParams)
            .filter((p) => p.referenceFrameKey)
            .map((p) => Cmd.action(getReferenceFrameAction({ key: p.referenceFrameKey })))
        : [];

      return loop(
        state,
        Cmd.list(
          [
            Cmd.list([Cmd.action(initWebsiteConfigurationAction(key)), ...referenceFrameActions]),
            Cmd.action({
              type: ACTION_TYPES.GET_FACET_SOURCE_WEB_CONFIGS,
              payload: { options: action.payload.config.options },
            }),
            Cmd.action(initWebsiteThemeReferenceFramesAction(key)),
          ],
          { sequence: true }
        )
      );
    }

    case ACTION_TYPES.GET_FACET_SOURCE_WEB_CONFIGS: {
      return loop(
        state,
        Cmd.run(getFacetSourceWebConfigsCmd, {
          args: [state.editKey, action.payload.options, state],
          successActionCreator: (facetSourceWebConfigs) => ({
            type: ACTION_TYPES.SET_FACET_SOURCE_WEB_CONFIGS,
            payload: { facetSourceWebConfigs },
          }),
          failActionCreator: (error) => documentLoadingFailedAction(error),
        })
      );
    }

    case ACTION_TYPES.SET_FACET_SOURCE_WEB_CONFIGS: {
      const key = state.editKey;
      state.facetSourceWebConfigsMap.set(key, action.payload.facetSourceWebConfigs);

      return loop(state, Cmd.action(updateAsideViewModelAction(key)));
    }

    case ACTION_TYPES.PATCH_EXPIRY_DATE: {
      const patch = {
        expiryDate: action.payload.expiryDate,
      };

      return loop(
        state,
        Cmd.action({
          type: ACTION_TYPES.PATCH_NODE,
          payload: {
            key: action.payload.key,
            patch,
            updateTree: true,
          },
        })
      );
    }
  }
};
