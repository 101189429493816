import { PersonHref } from './apiTypes';
import { IApiResouce } from './sriTypes';

export const isPersonHref = (href: string): href is PersonHref =>
  !!href.match(/^\/persons\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);

export type Person = {
  $$meta: {
    permalink: PersonHref;
    created: string;
    modified: string;
    type: 'PERSON';
    managedExternalBy: string;
  };
  key: string;
  sex: string;
  title: string;
  firstName: string;
  lastName: string;
  username: string;
  $$email: string;
  attachments: Array<{
    href: string;
    $$expanded: {
      key: string;
      href: string;
      name: string;
      type: string;
      width: number;
      $$meta: {
        created: string;
        modified: string;
        permalink: string;
      };
      height: number;
      contentType: string;
      description: any;
    };
  }>;
  mergedPersons: Array<string>;
};

export type PrivateState = {
  $$meta: {
    permalink: string;
    created?: string;
    modified?: string;
  };
  key: string;
  owner: { href: string };
  context: string;
  state: {
    root: string;
    version: number;
    lastRead: string;
    collapsedNodes: string[];
  };
};
export const isPerson = (r: IApiResouce | undefined): r is Person =>
  !!r && r.$$meta.type === 'PERSON';
