import { useSelector } from 'react-redux';
import { LegalNode } from '@newStore/documentUI/documentUITypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import InlineEditor from '@UI/editor/InlineEditor';
import { getPlaceHolderLabel } from '../../contentRowHelpers';
import './legalRow.scss';

const LegalRow: React.FC<{ contentNode: LegalNode }> = ({ contentNode }) => {
  const nodeTypeConfig = useSelector((state: RootState) =>
    selectAppliedNodeConfig(state, contentNode.href)
  );

  return (
    <div>
      <div className="side-image">
        <img src="/images/LEGAL.svg" draggable="false" />
      </div>
      <div>
        <div className="legal-content">
          <div>
            <InlineEditor
              initial={contentNode.title}
              field="title"
              placeholder={getPlaceHolderLabel(nodeTypeConfig, 'title')}
              skey={contentNode.key}
              href={contentNode.href}
            ></InlineEditor>
          </div>
        </div>
        <div className="legal-content">
          <div className="legal-description">
            <InlineEditor
              initial={contentNode.text}
              field="html"
              placeholder={getPlaceHolderLabel(nodeTypeConfig, 'html')}
              skey={contentNode.key}
              href={contentNode.href}
            ></InlineEditor>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalRow;
