// this is a "dumb" component

class FacetShow {
  $onInit() {}
}

module.exports = {
  template: require('./facetShow.html'),
  controllerAs: 'ctrl',
  bindings: {
    sFacet: '<',
  },
  controller: FacetShow,
};
