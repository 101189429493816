import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectIsNodeProposedToDeleted,
  selectTableOfContentChildren,
  selectTableOfContentNode,
} from '@newStore/documentUI/documentUISelectors';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types/rootStateTypes';
import ContentChangedIndicator from '../changeIndicators/ContentChangedIndicator';
import ProposalIndicatorForToc from '../changeIndicators/ProposalIndicatorForToc';
import { useScrollToNode } from '../scrollToNode/useScrollToNode';
import ValidationIndicatorForToc from '../validationIndicator/ValidationIndicatorForToc';
import './TableOfContentRow.scss';

interface TableOfContentRowProps {
  href: ContentHref;
  parentHref?: ContentHref;
  level?: number;
}

const TableOfContentRow: React.FC<TableOfContentRowProps> = ({ href, level = 0, parentHref }) => {
  const scrollToItem = useScrollToNode();
  const tocNode = useSelector((state: RootState) => selectTableOfContentNode(state, href));
  const isProposedToDelete = useSelector((state: RootState) =>
    selectIsNodeProposedToDeleted(state, href)
  );
  const childHrefs = useSelector((state: RootState) => selectTableOfContentChildren(state, href));

  if (!tocNode) {
    return null;
  }

  return (
    <section className="toc-section">
      <ProposalIndicatorForToc href={href} />
      <ContentChangedIndicator href={href} />

      {/* <div class="red-line-container"
             ng-if=":validations:ctrl.sItem.$$validations.length > 0"
             uib-popover-html=":change:ctrl.validationPopoverMessage()" popover-trigger="'mouseenter'" popover-placement="left" popover-append-to-body="true">
            <div class="line" ng-class=":validations:ctrl.validationColorOfRow()"></div>
        </div>

        <div class="delete-proposal-line" ng-class="::ctrl.proposalLineStyle()" scroll-to="{{::ctrl.sItem.key}}"
            ng-if=":change:ctrl.hasDeleteProposal()">
        </div> */}

      {/* <div>
            <div ng-show=":hover:ctrl.sItem.$$hoverOnCollapse.parent" class="dashed-line" ng-class=":change:'level-'+ctrl.sItem.$$level"></div>
            <div ng-show=":hover:ctrl.sItem.$$hoverOnCollapse.self" class="dashed-line head-dashed-line" ng-class=":change:'level-'+ctrl.sItem.$$level"></div>
            <div ng-if=":collapse:ctrl.isCollapseble() && !ctrl.hasDeleteProposal() && !ctrl.isTogglingCollapse"
                 ng-class=":collapse:('collapse-icon level-' + ctrl.sItem.$$level)"
                 ng-mouseenter="::ctrl.hoverOnCollapse()"
                 ng-mouseleave="::ctrl.stopHoverOnCollapse()">
                <div class="expand-collapsed-icon-container">
                    <img src="../../../img/icons/collapse-grey-light.svg" ng-click="::ctrl.toggleCollapse()" class="img-bottom"/>
                    <img src="../../../img/icons/collapse-grey.svg" ng-click="::ctrl.toggleCollapse()" class="img-top"/>
                </div>
            </div>
            <div ng-if=":collapse:ctrl.isExpandable() && !ctrl.hasDeleteProposal() && !ctrl.isTogglingCollapse"
                 ng-class=":collapse:('expand-icon level-' + ctrl.sItem.$$level)">
                <div class="expand-collapsed-icon-container">
                    <img src="../../../img/icons/expand-grey.svg" ng-click="::ctrl.toggleCollapse()" class="img-bottom"/>
                    <img src="../../../img/icons/expand-grey-dark.svg" ng-click="::ctrl.toggleCollapse()" class="img-top"/>
                </div>
            </div>
            <div ng-if=":collapse:ctrl.isTogglingCollapse" ng-class=":collapse:('collapse-icon level-' + ctrl.sItem.$$level)">
                <div class="spinner-icon center"></div>
            </div>
        </div> */}

      <div
        className={`toc-item toc-item-level-${level} ${isProposedToDelete ? 'deleted' : ''}`}
        onClick={() => scrollToItem(href, parentHref)}
      >
        {!isProposedToDelete && <ValidationIndicatorForToc href={href} parentHref={parentHref} />}
        {/* ng-if="::!ctrl.hasDeleteProposal()" */}
        {tocNode.identifier && (
          <span style={{ color: tocNode.color }} className="toc-identifier">
            {tocNode.identifier}
          </span>
        )}
        {tocNode.title && (
          <span
            className="toc-title"
            title={tocNode.title}
            style={{ color: tocNode.placeholder ? '#a0a0a0' : 'inherit' }}
          >
            {tocNode.title}
          </span>
        )}
      </div>

      {childHrefs && childHrefs.length > 0 && (
        <section className="toc-row-children">
          {childHrefs.map((childHref) => (
            <TableOfContentRow
              key={childHref}
              href={childHref}
              parentHref={href}
              level={level + 1}
            />
          ))}
        </section>
      )}
    </section>
  );
};

export default TableOfContentRow;
