import {
  AttachmentMetaInfo,
  ContentRelation,
  ExternalUrl,
  InternalReferenceHref,
  ContentHref,
  ResourceType,
} from '@generalTypes/apiTypes';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@generalTypes/rootStateTypes';
import { conditionalLogTime } from '@store/helpers/generalUtils';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import { IApiResouce } from '@generalTypes/sriTypes';
import { isVosOrganisation, isOrganisationalUnit } from '@generalTypes/samTypes';
import { isPerson } from '@generalTypes/personApiTypes';
import { FilterableEditComponent, FilteredEditComponent } from '@nodeTypeConfig/configTypes';
import { ContentRelationsMap } from '../types/generalTypes';
import { settings } from '../config/settings';
import { AllExternalData } from './externalData/externalDataTypes';

export const add$$metaAndKey = (path) => {
  const key = uuidv4();
  return {
    $$meta: {
      permalink: `${path}/${key}`,
      modified: new Date().toISOString(),
      created: new Date().toISOString(),
    },
    key,
  };
};

export const getResourcePathFromHref = (href: string): string | null => {
  const regex = /(\/.+)(?=\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/;
  const match = href.match(regex);
  return match ? match[1] : null;
};

export const arrayToObjectMap = <T extends IApiResouce>(array: T[]): Record<string, T> => {
  const endLog = conditionalLogTime(`[arrayToObjectMap] ${array.length} items`, 5);
  const objMap = Object.fromEntries(array.map((elem) => [elem.$$meta.permalink, elem]));
  endLog();
  return objMap;
};

export const isHref = (href: string): boolean => {
  return Boolean(
    href && href.match(/^\/[\w/]+\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g)
  );
};

export const getAttachmentUrl = (attachment: AttachmentMetaInfo): string => {
  if ('$$base64' in attachment) {
    return attachment.$$base64;
  }

  const { href } = attachment;

  if (!href) {
    return '/images/IMAGE.svg';
  }

  if (href.startsWith('/content')) {
    return settings.apisAndUrls.contentApi + href;
  }

  if (href.startsWith('/proposals')) {
    return settings.apisAndUrls.proposalApi + href;
  }

  throw new Error(`${href} is not an attachment on Content or Proposals Api!`);
};

export function deepFreeze(object) {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self

  propNames.forEach((name) => {
    const value = object[name];
    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  });

  return Object.freeze(object);
}

export const getResourceTypeFromHref = (href: string): ResourceType => {
  if (href.startsWith('/content/relations')) {
    return 'RELATION';
  }
  if (href.match(/^\/content\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g)) {
    return 'CONTENT';
  }
  if (href.startsWith('/web/pages')) {
    return 'WEBPAGE';
  }
  if (href.startsWith('/proposals')) {
    return 'CONTENT_PROPOSAL';
  }
  if (href.startsWith('/newsletter/settings')) {
    return 'NEWSLETTER_SETTINGS';
  }
  if (
    href.match(
      /\/sam\/commons\/subjects\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g
    )
  ) {
    return 'COMMONS_SUBJECT';
  }
  if (
    href.match(
      /\/sam\/commons\/studyprogrammes\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g
    )
  ) {
    return 'COMMONS_STUDY_PROGRAMME';
  }
  if (
    href.match(
      /\/sam\/commons\/studyprogrammegroups\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g
    )
  ) {
    return 'COMMONS_STUDY_PROGRAMME_GROUP';
  }
  if (
    href.match(
      /\/sam\/commons\/studiegebieden\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g
    )
  ) {
    return 'COMMONS_STUDIEGEBIED';
  }
  if (href.match(/\/vakken\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g)) {
    return 'VAK';
  }
  throw new Error(
    `[getResourceTypeFromHref] we did not anticipate that a proposal or a relation can apply to ${href}`
  );
};

export const mapRelationsToRelationsToAndFrom = (
  relations: Record<string, ContentRelation>
): ContentRelationsMap => {
  const relationsTo: Record<string, ContentRelation[]> = {};
  const relationsFrom: Record<string, ContentRelation[]> = {};

  Object.values(relations).forEach((relation) => {
    if (!relationsTo[relation.to.href]) {
      relationsTo[relation.to.href] = [];
    }
    relationsTo[relation.to.href].push(relation);

    if (!relationsFrom[relation.from.href]) {
      relationsFrom[relation.from.href] = [];
    }
    relationsFrom[relation.from.href].push(relation);
  });

  return { to: relationsTo, from: relationsFrom };
};

export const getGenericErrorMessage = (error) => {
  if (error.status === 403) {
    return { code: 'genericError.403' };
  }

  return { code: 'An unexpected error occured' };
};

export const stripHtml = (html: string | undefined) => {
  if (!html?.length) {
    return '';
  }
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const formatDate = (isoDate: string | undefined, includeHour = true) => {
  if (!isoDate) {
    return '';
  }
  return moment(isoDate).format(`DD/MM/YYYY${includeHour ? ' HH:mm' : ''}`);
};

export const parentChildRelationFilter = (relation: ContentRelation) =>
  relation.relationtype === 'IS_INCLUDED_IN' || relation.relationtype === 'IS_PART_OF';

export const getSortedChildHrefsFromRelations = (
  relations: ContentRelation[] | undefined
): ContentHref[] => {
  return (
    relations
      ?.filter(parentChildRelationFilter)
      .sort((a, b) => (a.readorder || 0) - (b.readorder || 0))
      .map((rel) => rel.from.href) || []
  );
};

export const isNotNull = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
};

export const getChildRelationsOfRelation = (
  relationsMap: ContentRelationsMap,
  relation: ContentRelation
) => {
  const allChildrenRelations: ContentRelation[] = [];
  const childRelations = relationsMap.to[relation.from.href].filter(parentChildRelationFilter);

  childRelations.forEach((childRelation) => {
    allChildrenRelations.push(childRelation);
    const chilChilddRelations = getChildRelationsOfRelation(relationsMap, childRelation);
    allChildrenRelations.push(...chilChilddRelations);
  });

  return allChildrenRelations;
};

export const createTypedSelector = createSelector.withTypes<RootState>();

const hasValidProtocol = (url: URL) => url.protocol === 'http:' || url.protocol === 'https:';
const hasValidHostname = (url: URL) => url.hostname.includes('.');

export const isValidExternalUrl = (url: string): url is ExternalUrl => {
  try {
    const parsedUrl = new URL(url);
    return hasValidProtocol(parsedUrl) && hasValidHostname(parsedUrl);
  } catch (_) {
    return false;
  }
};

const allowedInternalPaths = [
  '/content/',
  '/training/modules/',
  '/events/',
  '/sam/commons/educationalactivitytypes/',
] as const;

type AllowedInternalPath = (typeof allowedInternalPaths)[number];
export type AllowedInternalPaths = Array<AllowedInternalPath>;

/**
 * A lot of nodes can point to other content (webPage, webPageSubsection, job offer, etc.)
 * LLINKID_GOALs can point to educational activities
 * Teasers can point to a training (but it is not the teaser itself it is the reference child that references something)
 * in newsletters REFERENCES in a REFERENCE_GROUP can point to events
 */
export const isValidInternalReference = (href: string): href is InternalReferenceHref =>
  allowedInternalPaths.some((path) => href.startsWith(path));

export const entityHrefToString = (href: string, a: AllExternalData) => {
  const r = a[href];
  if (!r) {
    return null;
  }
  if (isPerson(r)) {
    return `${r.firstName} ${r.lastName}`;
  }
  if (isVosOrganisation(r)) {
    return r.$$name;
  }
  if (isOrganisationalUnit(r)) {
    return r.$$displayName;
  }
  console.error('href is not refering to an entity (person or OU)', r);
  return null;
};

export const getEditComponent = <T extends FilterableEditComponent | FilteredEditComponent>(
  property: string,
  edit: Array<T>
): T | undefined => {
  const component = edit.find((e) => e.property === property);
  if (component) {
    return component;
  }
  return edit.find((e) => e.component === property);
};
