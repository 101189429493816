import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChangesWithoutDeletes,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { RequiredType } from '@nodeTypeConfig/configTypes';
import { createError } from '../validationHelpers';

/**
 * This method returns the date when the curriculum become being valid
 * @param issuedDateString
 * @returns
 */
const getValidityStartDate = (issuedDateString: string) => {
  const issuedDate = new Date(issuedDateString);
  const schoolYearStartDate = new Date(issuedDate.getFullYear(), 8); // first september 20xx

  if (issuedDate > schoolYearStartDate) {
    // if it was issued after 1st september we set up the next year as the validity start (next school year)
    issuedDate.setFullYear(issuedDate.getFullYear() + 1);
  }
  return issuedDate;
};

/**
 * The expiryDate needs to be always the 31/08 of a year
 * @param expiryDate
 * @returns
 */
const isExpiryDateCorrect = (expiryDate: Date): boolean => {
  return expiryDate.getMonth() === 7 && expiryDate.getDate() === 31;
};

const doesValidityPeriodMatchesWithExpiryDate = (
  issuedDate: string,
  realisationPeriodInMonths: 12 | 24,
  expiryDate: Date
) => {
  const validityStartDate = getValidityStartDate(issuedDate);

  const validityEndDate = new Date(
    validityStartDate.getFullYear(),
    validityStartDate.getMonth() + realisationPeriodInMonths,
    validityStartDate.getDate() - 1
  );

  return validityEndDate < expiryDate;
};

const selectExpiryDateValidRule = createTypedSelector(
  [(state, href) => selectContentItem(state, href)?.expiryDate],
  (expiryDateString) => {
    if (expiryDateString) {
      const expiryDate = new Date(expiryDateString);

      if (!isExpiryDateCorrect(expiryDate)) {
        return createError(
          'expiryDateOnlyAtEndOfSchoolYear',
          'selectExpiryDateValidRule',
          'De vervaldatum moet steeds op 31 augustus vallen.',
          'expiryDate',
          RequiredType.ERROR
        );
      }
    }
    return true;
  }
);

const selectExpiryDateValidityPeriodRule = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (nodeItem) => {
    if (nodeItem.expiryDate) {
      const { issued, realisationPeriod, expiryDate: expiryDateString } = nodeItem;

      const expiryDate = new Date(expiryDateString);
      if (
        issued &&
        realisationPeriod &&
        !doesValidityPeriodMatchesWithExpiryDate(issued, realisationPeriod, expiryDate)
      ) {
        return createError(
          'expiryDateNotBeforeRealisationPeriod',
          'selectExpiryDateValidityPeriodRule',
          'De vervaldatum mag niet voor de realisatieperiode vallen.',
          'expiryDate',
          RequiredType.ERROR
        );
      }
    }
    return true;
  }
);

const selectExpiryDateWasReplacedRule = createTypedSelector(
  [
    (state, href) => Boolean(selectContentItem(state, href)?.expiryDate),
    (state, href: ContentHref) =>
      selectApiWithPendingChangesWithoutDeletes(state).relationsToAndFromMap.to[href],
  ],
  (hasExpiryDate, nodeToRelations) => {
    if (!hasExpiryDate) {
      return true;
    }

    const hasBeenReplaced = nodeToRelations.some((relTo) => relTo.relationtype === 'REPLACES');

    if (hasBeenReplaced) {
      return createError(
        'noExpiryDateIfNewVersionExists',
        'selectExpiryDateWasReplacedRule',
        'Een leerplan mag geen vervaldatum hebben als er een nieuwe versie van bestaat.',
        'expiryDate',
        RequiredType.ERROR
      );
    }

    return true;
  }
);

export const selectExpiryDateValidation = createTypedSelector(
  [selectExpiryDateValidRule, selectExpiryDateValidityPeriodRule, selectExpiryDateWasReplacedRule],
  (expiryDateValidRule, expiryDateValidatyPeriodRule, expiryDateWasReplacedRule) => [
    expiryDateValidRule,
    expiryDateValidatyPeriodRule,
    expiryDateWasReplacedRule,
  ]
);

export const expiryDateValidationRules = [
  selectExpiryDateValidRule,
  selectExpiryDateValidityPeriodRule,
  selectExpiryDateWasReplacedRule,
].map((validationSelector) => ({ rule: validationSelector }));
