import { Content, ContentRelation } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRootItem,
} from '@newStore/documentApi/documentApiSelectors';
import { selectExternalContent } from '@newStore/externalData/externalDataSelectors';
import { expandLlinkidGoalRelations } from '@newStore/llinkid/llinkidActions';
import { EditAsideGoalRelations } from '@nodeTypeConfig/configTypes';
import { openGoalRelationsModal } from '@store/helpers/documentAsideHelpers';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideLlinkidGoal from '../asideLlinkidGoal/asideLlinkidGoal';
import AsideRelationsPanel from '../asideRelationsPanel/AsideRelationsPanel';
import { useDeleteRelationHook } from '../asideRelationsPanel/useDeleteRelationHook';

const AsideGoalRelations: React.FC<{ config: EditAsideGoalRelations }> = ({ config }) => {
  const dispatch = useDispatch();
  const currentEditingHref = useEditHref();

  const rootItem = useSelector(selectRootItem) as Content;

  const readOnly = useReadOnly(config.options.readOnly);
  const relationOrigin = !config.options.revertedRelationDirection ? 'from' : 'to';
  const relationEnd = !config.options.revertedRelationDirection ? 'to' : 'from';
  const isRelationTypeRelationIncluded = config.options.relationTypes.includes('RELATION');

  const allRelations = useSelector(
    (state: RootState) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state)[relationOrigin][currentEditingHref]
  );

  const endRelations = useSelector(
    (state: RootState) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state)[relationEnd][currentEditingHref]
  );

  const externalContent = useSelector(selectExternalContent);

  const multipleRelationTypes = config.options.relationTypes.length > 1;

  const filterFn = (relation: ContentRelation) => {
    return (
      config.options.relationTypes.includes(relation.relationtype) &&
      externalContent[relation[relationEnd].href] &&
      (!config.options.toType ||
        config.options.toType === externalContent[relation[relationEnd].href].type)
    );
  };

  let relations = allRelations?.filter(filterFn);

  // special case of RELATION relations: get to relations and reverse
  if (isRelationTypeRelationIncluded) {
    const toRelations = endRelations?.filter(
      (r) => r.relationtype === 'RELATION' && externalContent[r[relationOrigin].href]
    );

    if (toRelations) {
      const reversedToRelations = toRelations.map((r) => ({
        ...r,
        from: r.to,
        to: r.from,
        $$reversed: true,
      })) as ContentRelation[];

      relations = relations.concat(reversedToRelations.filter(filterFn));
    }
  }

  const goalRelationsParams = {
    key: getResourceKey(currentEditingHref),
    relationTypes: config.options.relationTypes,
    originPart: relationOrigin,
  };

  const onDelete = useDeleteRelationHook();

  const onCreate = async () => {
    // @ts-expect-error item is not mandatory
    const response = await openGoalRelationsModal({
      asideOptions: config.options,
      rootKey: rootItem.key,
      relations,
    });

    if (response) {
      const newRelation = {
        relationtype: multipleRelationTypes
          ? response.realisation.value
          : config.options.relationTypes[0],
        strength: 'MEDIUM',
        [relationOrigin]: {
          href: currentEditingHref,
        },
        [relationEnd]: {
          href: response.goal.$$meta.permalink,
        },
      };

      // @ts-expect-error addRelationAction is not typed
      dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation, true, true, goalRelationsParams));
    }
  };

  const onEdit = async (relation) => {
    if (readOnly) {
      return;
    }
    // @ts-expect-error defaultCurriculumKey is not mandatory
    const response = await openGoalRelationsModal({
      asideOptions: config.options,
      rootKey: rootItem.key,
      relations,
      item: relation,
    });

    dispatch(
      DOCUMENT_ACTIONS.patchRelationAction(
        relation.key,
        {
          [relation.$$reversed ? relationOrigin : relationEnd]: {
            href: response.goal.$$meta.permalink,
            $$expanded: response.goal,
          },
          relationtype: multipleRelationTypes
            ? response.realisation.value
            : config.options.relationTypes[0],
        },
        {
          ...goalRelationsParams,
          ...(relation.$$reversed && { originPart: relationEnd }),
        }
      )
    );
  };

  useEffect(() => {
    dispatch(
      expandLlinkidGoalRelations({
        key: getResourceKey(currentEditingHref),
        relationTypes: config.options.relationTypes,
        originPart: relationOrigin,
      })
    );

    // special case of RELATION relations where we also need to fetch the from in the to relations
    if (isRelationTypeRelationIncluded) {
      dispatch(
        expandLlinkidGoalRelations({
          key: getResourceKey(currentEditingHref),
          relationTypes: ['RELATION'],
          originPart: relationEnd,
        })
      );
    }
  }, []);

  return (
    <AsideRelationsPanel
      title={config.label}
      items={relations}
      readOnly={readOnly}
      onCreate={onCreate}
      onDelete={onDelete}
      onEdit={multipleRelationTypes ? onEdit : null} // only allow editing if there is something to edit, like the type of the relation.
    >
      {(item) => (
        <AsideLlinkidGoal item={item} options={config.options} href={item[relationEnd].href} />
      )}
    </AsideRelationsPanel>
  );
};

AsideGoalRelations.propTypes = {
  config: any,
};

export default AsideGoalRelations;
