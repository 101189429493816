import { put, select, take } from 'redux-saga/effects';

export function* waitFor(selector) {
  if (yield select(selector)) {
    return;
  }

  while (true) {
    yield take('*');
    if (yield select(selector)) {
      return;
    }
  }
}

export function* runCallbackWhenSelectorChanges(selector, callback) {
  let previousValue = yield select(selector);
  while (true) {
    yield take('*');
    const currentValue = yield select(selector);
    if (previousValue !== currentValue) {
      yield callback(currentValue);
      previousValue = currentValue;
    }
  }
}

export function* dispatchWhenSelectorChanges(selector, action) {
  let previousValue = yield select(selector);
  while (true) {
    yield take('*');
    const currentValue = yield select(selector);
    if (previousValue !== currentValue) {
      yield put(action);
      previousValue = currentValue;
    }
  }
}
