import {
  selectTitleNode,
  selectTitleWithDescriptionNode,
} from '@newStore/documentUI/transformContent/genericSelectors';
import { selectProMenuLevel2Node } from '@newStore/documentUI/transformContent/proMenuSelectors';
import {
  validateWebTemplateConfig,
  validateWebConfigurationRequired,
} from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { referencedInProHomeCannotUnpublish } from '@newStore/validation/validationRules/referencedInProHomeCannotUnpublish';
import { ValidationRule, AsyncValidationRule } from '@newStore/validation/validationTypes';
import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import { validateDownloadListAndFaqPageShouldNotHaveDescription } from '@newStore/validation/validationRules/downloadPageShouldNotHaveDescription';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { selectValidateAttachmentAudienceParentIntersectionNotEmpty } from '@newStore/validation/validationRules/audienceIntersectionRules';

const proThemeDefaultValidationRules: Array<{ rule: ValidationRule | AsyncValidationRule }> = [
  validateWebTemplateConfig,
  { rule: referencedInProHomeCannotUnpublish },
  { rule: selectCurriculumThemesValidation },
];
const proTileDefaultValidionRules = [validateWebTemplateConfig];

export const proSelectors = {
  [NodeType.WEBPAGE2]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: [
      validateWebConfigurationRequired,
      validateWebTemplateConfig,
      { rule: referencedInProHomeCannotUnpublish },
    ],
  },
  [NodeType.PRO_THEME_HOME_FULL]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proThemeDefaultValidationRules,
  },
  [NodeType.PRO_THEME_HOME_TEXT]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proThemeDefaultValidationRules,
  },
  [NodeType.PRO_THEME_HOME_PICTURE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proThemeDefaultValidationRules,
  },
  [NodeType.PRO_TEXT_PAGE_TILE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: [
      ...proTileDefaultValidionRules,
      { rule: selectValidateAttachmentAudienceParentIntersectionNotEmpty },
    ],
  },
  [NodeType.PRO_TEXT_PAGE_STANDALONE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: [
      ...proThemeDefaultValidationRules,
      { rule: selectValidateAttachmentAudienceParentIntersectionNotEmpty },
    ],
  },
  [NodeType.PRO_DATABASE_TILE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proTileDefaultValidionRules,
  },
  [NodeType.PRO_DATABASE_STANDALONE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proThemeDefaultValidationRules,
  },
  [NodeType.PRO_DATABASE_ITEM]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proTileDefaultValidionRules,
  },
  [NodeType.PRO_BLOG_TILE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proTileDefaultValidionRules,
  },
  [NodeType.PRO_BLOG_STANDALONE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proThemeDefaultValidationRules,
  },
  [NodeType.PRO_BLOG_ITEM]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proTileDefaultValidionRules,
  },
  [NodeType.PRO_DOWNLOAD_PAGE_TILE]: {
    nodeSelector: selectTitleNode,
    validationRules: [
      ...proTileDefaultValidionRules,
      validateDownloadListAndFaqPageShouldNotHaveDescription,
    ],
  },
  [NodeType.PRO_DOWNLOAD_PAGE_STANDALONE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proThemeDefaultValidationRules,
  },
  [NodeType.PRO_FAQ_PAGE_TILE]: {
    nodeSelector: selectTitleNode,
    validationRules: [
      ...proTileDefaultValidionRules,
      validateDownloadListAndFaqPageShouldNotHaveDescription,
    ],
  },
  [NodeType.PRO_CURRICULUM_PAGE_TILE]: {
    nodeSelector: selectTitleWithDescriptionNode,

    validationRules: [
      ...proTileDefaultValidionRules,
      { rule: selectValidateAttachmentAudienceParentIntersectionNotEmpty },
    ],
  },
  [NodeType.PRO_TEMPORARY_PAGE_TILE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proTileDefaultValidionRules,
  },
  [NodeType.PRO_TEMPORARY_PAGE_STANDALONE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: proThemeDefaultValidationRules,
  },
  [NodeType.PRO_STATIC_PAGE]: {
    nodeSelector: selectTitleNode,
    validationRules: proThemeDefaultValidationRules,
  },
  [NodeType.PRO_GLOBAL_DATABASE]: {
    nodeSelector: selectTitleNode,
    validationRules: [],
  },
  [NodeType.PRO_GLOBAL_DATABASE_SECTION]: { nodeSelector: selectTitleNode },
  [NodeType.PRO_THEME_SECTION]: {
    nodeSelector: selectTitleNode,
    validationRules: [validateWebTemplateConfig],
  },
  // Pro Menu
  [NodeType.PRO_HOME_PAGE]: {
    nodeSelector: selectTitleNode,
    validationRules: [],
  },
  [NodeType.PRO_MENU_LEVEL_2]: {
    nodeSelector: selectProMenuLevel2Node,
    validationRules: [],
  },
  [NodeType.PRO_MENU_LEVEL_3]: {
    nodeSelector: selectTitleNode,
    validationRules: [],
  },
  [NodeType.PRO_MENU_SECTION]: {
    nodeSelector: selectTitleNode,
  },
};
