import { ContentHref } from '@generalTypes/apiTypes';
import { NodeType } from '@nodeTypeConfig/configTypes';

export type ZillCurriculumMini = {
  href: ContentHref;
  title: string | undefined;
  version: string | undefined;
};

export type ZillGoal = {
  href: ContentHref;
  code: string;
  color: string | undefined;
  title: string;
  type: NodeType.CURRICULUM_ZILL_GENERIC_GOAL;
  icon: string | null;
  relationFromIllustrationHref?: string | undefined;
  zillCurriculum: ZillCurriculumMini;
};

export type ZillGoalSubDetail = {
  href: ContentHref;
  type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT | NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER;
  description: string;
  shortDescription: string;
  relationFromIllustrationHref?: string | undefined;
  zillGoal: ZillGoal;
};

export type ZillIllustration = {
  href: ContentHref;
  relationToPracticalExampleHref: string;
  title: string;
};

/**
 * A selected Zill Goal is a goal that is selected for a Practical Example / Illustration (in the data always directly linked to the illustration)
 * If an illustration links to multiple develoment content of the same goals it seen as: it is llinked to the goal with a subselection of specific develoment content.
 */
export type SelectedZillGoal = ZillGoal & {
  subDetails: {
    href: ContentHref;
    type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT | NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER;
    description: string;
    shortDescription: string;
    relationFromIllustrationHref?: string | undefined;
  }[];
  illustration: ZillIllustration;
};

export type ZillGoalIsLoading = {
  href: ContentHref;
  relationFromIllustrationHref?: string;
  type: NodeType.ZILL_ILLUSTRATION | 'ZILL_GOAL_OR_DEVELOPMENTCONTENT';
  isLoading: true;
};

export type ZillGoalHasFailedToLoad = {
  href: ContentHref;
  relationFromIllustrationHref?: string;
  hasFailedToLoad: true;
};

export const isLoadedZillGoal = (
  elem: ZillGoalSubDetail | ZillGoal | ZillGoalIsLoading | ZillGoalHasFailedToLoad
): elem is ZillGoal => {
  return 'type' in elem && elem.type === NodeType.CURRICULUM_ZILL_GENERIC_GOAL;
};

export const isSelectedZillGoal = (
  elem:
    | SelectedZillGoal
    | ZillGoalSubDetail
    | ZillGoal
    | ZillGoalIsLoading
    | ZillGoalHasFailedToLoad
): elem is SelectedZillGoal => {
  return 'illustration' in elem;
};

export const isZillGoalSubDetail = (
  elem: ZillGoalSubDetail | ZillGoal | ZillGoalIsLoading | ZillGoalHasFailedToLoad
): elem is ZillGoalSubDetail => {
  return (
    'type' in elem &&
    (elem.type === NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT ||
      elem.type === NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER)
  );
};

export type ZillCurriculumSelection = {
  href: ContentHref;
  type: 'ZILL_GOAL' | 'ZILL_GOAL_SUB_DETAIL';
  relationFromIllustrationHref: string | undefined;
  zillGoal: SelectedZillGoal;
};
