import { MusicNode } from '@newStore/documentUI/documentUITypes';

const MusicRow: React.FC<{ contentNode: MusicNode }> = ({ contentNode }) => {
  return (
    contentNode.mp3src && (
      <div>
        <div className="title" style={{ paddingLeft: '10px' }}>
          <span className="glyphicon glyphicon-music"></span>
          <span dangerouslySetInnerHTML={{ __html: contentNode.title }}></span>
        </div>
        <audio controls controlsList="nodownload">
          <source src={contentNode.mp3src} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    )
  );
};

export default MusicRow;
