import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

require('./notApplicableProposals.scss');

class notApplicableProposalsController {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.document.viewModel.napModal.open) {
        this.close();
      }
      return {
        nodes: state.document.viewModel.napModal.nodes,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  cancel() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.closeNotApplicableProposalsModal());
  }

  confirm() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.closeNotApplicableProposalsModal());
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeSelections());
  }
}

export const notApplicableProposalsComponent = {
  template: require('./notApplicableProposals.html'),
  controllerAs: 'ctrl',
  controller: notApplicableProposalsController,
  bindings: {
    close: '&',
  },
};
