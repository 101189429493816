import {
  selectAllNodeHrefs,
  selectApiWithPendingChanges,
} from '@newStore/documentApi/documentApiSelectors';
import { getVisibleTOCChildren } from '@newStore/documentUI/documentUIHelpers';
import {
  selectContentTermNodes,
  selectTableOfContentChildren,
} from '@newStore/documentUI/documentUISelectors';
import {
  selectAllNodeTypesMap,
  selectAppliedDocumentRootConfig,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types/rootStateTypes';
import TableOfContentRow from '../TableOfContentRow/TableOfContentRow';
import './TableOfContent.scss';

const TableOfContent: React.FC = () => {
  const href = useSelector((state: RootState) => state.documentUI.currentDocument);

  const hasToc = useSelector((state: RootState) => {
    // we get all children of the main document but only take into consideration for the count the elements of those that are shown on the TOC.
    // This differs from the selector in the documentUiSelector in the sense that this one is done over all children of the main document (selectAllNodeHrefs) and not only the children directly connected to the root document
    const rootTocAllowedTypes = selectAppliedDocumentRootConfig(state)?.tocTypes || [];

    const contentChildrenCount = getVisibleTOCChildren(
      selectAllNodeHrefs(state),
      selectApiWithPendingChanges(state).content,
      selectAllNodeTypesMap(state),
      rootTocAllowedTypes
    );

    return (
      state.documentUI.currentDocument &&
      Boolean(selectTableOfContentChildren(state, state.documentUI.currentDocument)?.length) &&
      contentChildrenCount.length >= 2 // we only show the TOC if we have 3 or more elements (root + 2) (root is not part of the contentChildrenCount)
    );
  });

  const termsList = useSelector(selectContentTermNodes);

  const scrollToTerms = () => {
    setTimeout(() => {
      const element = document.getElementById('termsList');
      if (element) {
        element.scrollIntoView({ behavior: 'instant', block: 'start' });
      }
    });
  };

  if (!hasToc || !href) {
    return null;
  }

  return (
    <div className="toc-container" data-testid="table-of-content">
      <h5>Inhoudsopgave</h5>
      <TableOfContentRow href={href}></TableOfContentRow>

      {termsList.length > 0 && (
        <section className="toc-section" onClick={scrollToTerms}>
          <div className="toc-item toc-item-level-0">
            <span>Begrippen</span>
          </div>
        </section>
      )}
    </div>
  );
};

export default TableOfContent;
