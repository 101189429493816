// eslint-disable-next-line import/no-webpack-loader-syntax
import MarkIcon from '!!raw-loader!../../../../img/icons/mark.svg';
import { Editor } from 'tinymce/tinymce';
import { openModal, registerButton } from './editorHelpers';

const modalOptions = {
  component: 'selectMarkExplanationModal',
};

const renderFunction = (modalResult, selectedText) =>
  `<span data-href="/content/${modalResult.key}" data-rel="mark-explanation">${selectedText}</span>`;

const onAction = async (editor: Editor) => {
  openModal(editor, modalOptions, renderFunction);
};

const isEnabledButton = (editor: Editor) =>
  editor.selection.getNode().tagName !== 'A' &&
  (!editor.selection.isCollapsed() || !!editor.selection.getNode().getAttribute('data-href'));

registerButton('mark', 'Markeerverklaring', MarkIcon as string, onAction, isEnabledButton);
