import { addNotificationAction } from '@store/actions/notificationActions';
import { BACK_TO_DOCUMENTS_LIST } from '@store/constants/actionTypes';
import { eventChannel } from 'redux-saga';
import { call, fork, put, race, select, take } from 'redux-saga/effects';
import { settings } from '../../config/settings';
import { openPreview, setSelectedDocument } from '../documentUI/documentUIState';
import { selectPostmessageData } from './previewSelectors';

function* sendPostMessage(originWindow, data) {
  try {
    const trustedOrigins = settings.trustedOrigins.join(',');
    console.log('[PostMessage] sending data', data);
    originWindow?.postMessage(data, trustedOrigins);
  } catch (error) {
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message: 'Er is een onverwachte fout opgetreden bij het versturen van de preview data.',
      })
    );
  }
}

function* sendAndMonitorPreviewData(originWindow: Window) {
  let previousValue = yield select(selectPostmessageData);
  if (previousValue) {
    yield sendPostMessage(originWindow, previousValue);
  }
  while (true) {
    yield take('*');
    if (!originWindow || originWindow.closed) {
      break;
    }
    const currentValue = yield select(selectPostmessageData);
    if (previousValue !== currentValue) {
      if (currentValue) {
        yield sendPostMessage(originWindow, currentValue);
      }
      previousValue = currentValue;
    }
  }
}

function createPostMessageChannel(window) {
  return eventChannel((emit) => {
    const listener = (event: MessageEvent) => {
      console.log('[PostMessage] listener message received', event.data);
      if (event.data.event === 'LOADED') {
        emit(event.data);
      }
    };

    window.addEventListener('message', listener);
    console.log('[PostMessage] listener added');
    return () => {
      console.log('[PostMessage] listener removed');
      window.removeEventListener('message', listener);
    };
  });
}

function* startPreviewListenerSaga({ payload }) {
  let postmessageChannel;
  try {
    const url = payload.previewLocation.location.replace('{%key}', payload.key);
    const originWindow = window.open(url, 'pro');
    if (!originWindow) {
      console.error('[PostMessage] could not open preview window');
      return;
    }

    postmessageChannel = yield call(createPostMessageChannel, window);
    const result = yield take(postmessageChannel);
    console.log('[PostMessage] result', result);

    yield sendAndMonitorPreviewData(originWindow);
  } finally {
    // when the saga is finished or cancelled, we need to close the channel
    console.log('[PostMessage] startPreviewListenerSaga ended');
    postmessageChannel.close();
  }
}

/**
 * This saga listens for the openPreview action, opens the window, and starts a saga that listens for postmessage events.
 * When the user selects a different document or goes back to the documents list, the saga and therefor the postmessage listener is cancelled.
 * When the user opens a new preview, the saga is cancelled and a new one is started.
 */
export function* postMessageListenerSaga() {
  while (true) {
    const openPreviewAction = yield take(openPreview);
    // we use a fork since it is non-blocking. this means when we dispatch openPreview again, the current running race will cancel, and a new one will start.
    yield fork(function* () {
      // we need to cancel the postmessage saga when the user selects a different document or goes back to the documents list.
      yield race({
        task: call(startPreviewListenerSaga, openPreviewAction),
        cancel: take([setSelectedDocument, BACK_TO_DOCUMENTS_LIST, openPreview]),
      });
    });
  }
}
