import { LlinkidCurriculumNode } from '@newStore/documentUI/documentUITypes';
import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import InlineEditor from '@UI/editor/InlineEditor';
import { getPlaceHolderLabel } from '../../contentRowHelpers';

const LlinkidCurriculumRow: React.FC<{ contentNode: LlinkidCurriculumNode }> = ({
  contentNode,
}) => {
  const nodeTypeConfig = useSelector((state: RootState) =>
    selectAppliedNodeConfig(state, contentNode.href)
  );

  return (
    <>
      <div className="node-title" style={{ display: 'flex' }}>
        {contentNode.identifier && (
          <div style={{ paddingRight: '10px' }}>{contentNode.identifier}</div>
        )}
        <div>
          <InlineEditor
            initial={contentNode.title}
            field="title"
            placeholder={getPlaceHolderLabel(nodeTypeConfig, 'title')}
            skey={contentNode.key}
            href={contentNode.href}
          ></InlineEditor>
        </div>
        <div style={{ paddingLeft: '10px' }}>({contentNode.version})</div>
      </div>
      {contentNode.educationalGroups.map(
        (educationalGroup) =>
          educationalGroup.themes.length > 0 && (
            <div className="curriculumReference llinkidReference" key={educationalGroup.key}>
              <div className="nopadding referenced-group-title">{educationalGroup.label}</div>
              <div className="row" style={{ marginLeft: 0 }}>
                {educationalGroup.themes.map((theme) => (
                  <div key={theme.key} className="nopadding referenced-item row">
                    <span className="col-xs-1">
                      {theme.imgSrc && (
                        <img src={theme.imgSrc} height={theme.imgSize} width={theme.imgSize} />
                      )}
                    </span>
                    <span
                      dangerouslySetInnerHTML={{ __html: theme.title }}
                      className="col-xs-10"
                    ></span>
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </>
  );
};

export default LlinkidCurriculumRow;
