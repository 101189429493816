import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectApiWithPendingChangesOldSlice,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';

export const selectCurrentEditingNode = (state: RootState) =>
  selectContentItem(state, state.documentUI.currentEditingNode as ContentHref);

export const GoalRelationsSelector = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesOldSlice(state),
    (state) => state.document.viewModel?.aside?.editDocument?.key,
  ],
  (api, documentKey) => {
    const relations = api?.contentRelations?.from[`/content/${documentKey}`] ?? [];
    return relations;
  }
);
