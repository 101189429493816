import {
  selectAreAllResultsSelected,
  selectAreCloneableItemsSelected,
  selectIsPublishDisabled,
  selectIsDeleteDisabled,
  selectAreVersionableItemsSelected,
  selectColumnsToShow,
  selectSearchResults,
  selectSelectedResults,
  selectIsPublishHidden,
  selectUserVmForDocumentList,
} from '@newStore/documentList/newDocumentListSelectors';
import { diffString } from 'json-diff';
import {
  loadMore,
  sortByColumn,
  toggleSelectAll,
  toggleSelected,
  search,
  openPublishModal,
  openCreateContentModal,
  createCopy,
  createNewVersion,
  validateAndDeleteSelected,
  updateNews,
} from '@newStore/documentList/newDocumentListState';
import { selectHasUserUpdateNewsAbility } from '@newStore/user/userSelectors';
import { attachmentDocumentTypes } from '@newStore/documentUI/transformContent/attachmentHelpers';

const kovsentry = require('../../app/kovSentry');
require('./listScreen.scss');

class listScreen {
  constructor($scope, $ngRedux, $state, ConfirmationService, ModalWindowService) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.confirmationService = ConfirmationService;

    this.$state = $state;
    this.modalWindowService = ModalWindowService;
  }

  async $onInit() {
    const { $state } = this;
    const { $ngRedux } = this;
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.newDocumentList.loading) {
        kovsentry.endLog('Load Redactie');
        kovsentry.endLog('Search Document');
        kovsentry.endLog('Delete Document');
      }

      const vmresults = selectSearchResults(state);
      const columnsToShow = selectColumnsToShow(state);

      const allSelected = selectAreAllResultsSelected(state);
      const security = selectUserVmForDocumentList(state);
      // console.log(diffString(vmresults, state.documentList.results));
      // if (vmresults.length) debugger;
      return {
        loading: state.newDocumentList.loading,
        loadingMore: state.newDocumentList.loadingMore,
        results: vmresults,
        nextLink: state.newDocumentList.nextLink,
        selected: selectSelectedResults(state),
        deleteDisabled: selectIsDeleteDisabled(state),
        deleting: state.newDocumentList.deleting,
        creatingNewVersion: state.newDocumentList.creatingNewVersion,
        creatingCopy: state.newDocumentList.creatingCopy,
        searchParams: state.newDocumentList.searchParams,
        creatables: security.creatables,
        searchables: security.searchables,
        hasSomeProThemaResult: columnsToShow.hasSomeProThemaResult,
        souldShowProposalsLabel: columnsToShow.souldShowProposalsLabel,
        hasAllGlobalDocumentResults: columnsToShow.hasAllGlobalDocumentResults,
        updateNewsDisabled: !selectHasUserUpdateNewsAbility(state),
        cloneableItemSelected: selectAreCloneableItemsSelected(state),
        allowVersionItemSelected: selectAreVersionableItemsSelected(state),
        publishDisabled: selectIsPublishDisabled(state),
        publishHidden: selectIsPublishHidden(state),
        allSelected,
      };
    })(this);

    this.doSearch(this.$scope, null, this.searchParams); // do the search on init
  }

  $onDestroy() {
    this.unsubscribe();
  }

  doSearch(scope, event, params) {
    const eventLog = scope.ctrl.getEventLog(params);
    kovsentry.startLog('Search Document', eventLog);

    this.$ngRedux.dispatch(search(params));
  }

  getEventLog(params) {
    const { searchables, ...eventLog } = params;

    return {
      ...eventLog,
      theme: params.theme ? params.theme.title : null,
      author: params.author ? params.author.fullName : null,
    };
  }

  loadMore() {
    this.$ngRedux.dispatch(loadMore({ href: this.nextLink }));
  }

  toggleSelectAll() {
    this.$ngRedux.dispatch(toggleSelectAll());
  }

  toggleSelected(result) {
    this.$ngRedux.dispatch(toggleSelected({ href: result.$$meta.permalink }));
  }

  async publish() {
    const opts = {
      template: require('../modals/publishDate/publishDate.html'),
      controller: 'publishDateModal as ctrl',
    };

    this.$ngRedux.dispatch(openPublishModal());

    await this.modalWindowService.open(opts);
  }

  async delete() {
    const confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      kovsentry.startLog('Delete Document', {
        Documents: this.selected.forEach((doc) => doc.title),
      });
      this.deleting = true;
      this.$ngRedux.dispatch(validateAndDeleteSelected());
    }
  }

  async duplicate() {
    const confirmed = await this.confirmationService.confirmVersion();
    if (confirmed) {
      this.$ngRedux.dispatch(createNewVersion(this.selected));
    }
  }

  async copy() {
    const confirmed = await this.confirmationService.confirmCopy();
    if (confirmed) {
      this.creatingCopy = true;
      this.$ngRedux.dispatch(createCopy(this.selected));
    }
  }

  async create(typeConfig) {
    this.$ngRedux.dispatch(openCreateContentModal({ type: typeConfig.type }));

    await this.modalWindowService.open({ component: 'createContentModal' });
  }

  sortByColumn(column) {
    this.$ngRedux.dispatch(sortByColumn({ column }));
  }

  getContentType(result) {
    if (result && result.attachments) {
      const attachment = result.attachments.find((a) => a.type === 'CONTENT');
      return attachment ? attachment.contentType : '';
    }
    return '';
  }

  getDocumentType(result) {
    if (result && result.tags) {
      return result.tags
        .filter((t) => attachmentDocumentTypes.some((type) => type.value === t))
        .map((t) => {
          return attachmentDocumentTypes.find((type) => type.value === t).display;
        })
        .join(',');
    }
    return '';
  }

  async updateNews() {
    const confirmed = await this.confirmationService.confirm({
      confirmationMessage: 'edit.updateNews.confirmation',
    });
    if (confirmed) {
      this.$ngRedux.dispatch(updateNews());
    }
  }
}

export default {
  template: require('./listScreen.html'),
  controllerAs: 'ctrl',
  bindings: {},
  controller: listScreen,
};
