import { Content } from '@generalTypes/apiTypes';
import { formatDate } from '@newStore/genericHelpers';
import {
  EditAsideIdentifier,
  RootEditComponent,
  RequiredType,
  EditAsideTitle,
  EditAsideSimpleDropDown,
  EditAsideContactsOrCreators,
} from '@nodeTypeConfig/configTypes';
import { defaultAuthorsParameters } from '@store/constants/constants';
import { entityHrefsArrayToString } from './editComponentHelpers';

export const identifierComponent: EditAsideIdentifier = {
  component: 'identifier',
  property: 'identifiers',
  label: 'Prefix',
  reactComponent: 'AsideIdentifier',
};

export const titleComponent: EditAsideTitle = {
  component: 'title',
  reactComponent: 'AsideTitle',
  property: 'title',
  label: 'Titel',
  required: RequiredType.ERROR,
};

export const authorsComponent: EditAsideContactsOrCreators = {
  component: 'authors',
  property: 'creators',
  reactComponent: 'AsidePersonPicker',
  label: 'Auteurs',
  valueToString: (c, a) => entityHrefsArrayToString(c.creators, a),
  options: {
    queryParams: defaultAuthorsParameters,
  },
};

export const publishComponent: RootEditComponent = {
  component: 'issued',
  property: 'issued',
  label: 'Publicatiedatum',
  reactComponent: 'AsideIssuedDatePicker',
  options: {
    openTo: 'day',
  },
  valueToString: (c: Content) => formatDate(c.issued, false),
};

const importance = {
  VERY_LOW: 'heel laag',
  LOW: 'laag',
  MEDIUM: 'normaal',
  HIGH: 'hoog',
  VERY_HIGH: 'heel hoog',
};

export const importanceComponent: EditAsideSimpleDropDown = {
  required: RequiredType.ERROR,
  component: 'importance',
  property: 'importance',
  label: 'Belangrijkheid',
  reactComponent: 'AsideSimpleDropDown',
  valueToString: (c: Content) => importance[c.importance] || '',
  options: {
    clearable: false,
    options: Object.entries(importance)
      .reverse()
      .map(([key, label]) => ({
        key,
        label,
      })),
  },
} as const;
