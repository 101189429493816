import { BuildingBlockNodeConfig, NodeType, RequiredType } from '@nodeTypeConfig/configTypes';

const CURRICULUM_CLUSTER: BuildingBlockNodeConfig = {
  information: {
    single: 'Leerplancluster',
    plural: 'Leerplanclusters',
  },

  buildingBlocks: [{ type: NodeType.CURRICULUM_THEME }],
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      label: 'Titel',
      required: RequiredType.ERROR,
    },
  ],
  onNewNodeDropped: {
    focusOnField: 'title',
  },
  confirmDelete: true,
  themesDisplaySuffix: '(cluster)',
  isCollapsible: false,
};

export default CURRICULUM_CLUSTER;
