import {
  AttachmentMetaInfo,
  isIllustrationAttachmentMetaInfo,
  isThumbnailAttachmentMetaInfo,
} from '@generalTypes/apiTypes';

export const getIllustration = (attachments: AttachmentMetaInfo[], width?: number) => {
  if (!attachments?.length) {
    return undefined;
  }

  const illustrations = attachments.filter(isIllustrationAttachmentMetaInfo);
  if (width) {
    const resizedIllustrations = illustrations.filter((i) => i.resized);
    return (
      resizedIllustrations.find((r) => r.width === width) ||
      resizedIllustrations[0] ||
      illustrations[0]
    );
  }
  return illustrations[0];
};

export const getThumbnail = (attachments: AttachmentMetaInfo[]) => {
  if (!attachments?.length) {
    return undefined;
  }

  const thumbnails = attachments.filter(isThumbnailAttachmentMetaInfo);
  return thumbnails[0];
};
