import { ContentType } from '@generalTypes/apiTypes';
import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';

const VIDEO_GROUP: BuildingBlockNodeConfig = {
  information: {
    single: 'Videogroep',
    plural: 'Videogroepen',
    ribonIcon: require('../../../../img/buildingBlocks/VIDEO_GROUP.svg'),
  },

  buildingBlocks: [],
  edit: [
    {
      component: 'videoGroup',
      label: "Video's",
      hiddenChildren: {
        containerType: ContentType.VIDEO_GROUP,
        items: [
          {
            type: ContentType.VIDEO,
            label: 'video',
          },
        ],
      },
    },
  ],
  isCollapsible: false,
  hideChildrenInDocument: true,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default VIDEO_GROUP;
