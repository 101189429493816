import { Cmd, loop } from 'redux-loop';
import { removeNotificationAction } from '../actions/notificationActions';
import { waitForTimeoutNotificationCmd } from '../commands/notificationCommands';
import * as ACTION_TYPES from '../constants/actionTypes';

/**
 * This is the initial state of this reducer.
 */
const initialState = [];

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ADD_NOTIFICATION: {
      return loop(
        [...state, action.payload],
        Cmd.list([
          Cmd.run(waitForTimeoutNotificationCmd, {
            args: [action.payload],
            successActionCreator: removeNotificationAction,
          }),
        ])
      );
    }

    case ACTION_TYPES.REMOVE_NOTIFICATION: {
      return [
        ...state.filter((o) => o.key !== action.payload && (!o.code || o.code !== action.payload)),
      ];
    }

    default: {
      return state;
    }
  }
};
