import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import './proposalIndicator.scss';

class proposalIndicator {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {}

  $onChanges() {}

  proposalLineStyle() {
    if (!this.sProposal && this.sItem) {
      this.sProposal = this.sItem.proposal;
    }

    if (this.sProposal) {
      const line = this.sProposal.isSubmitted ? 'submitted' : 'not-submitted';
      let color = this.sProposal.isSameUser ? 'same-user' : 'different-user';
      if (this.sProposal.isReviewingMode) {
        color = 'reviewing';
      }
      const isDeleted = this.sProposal.type === 'DELETE' ? ' is-deleted' : '';
      const isNew = this.sProposal.isNew && this.sProposal.isReviewingMode ? ' is-new' : '';
      const manyCreators = this.sProposal.hasManyCreators ? ' many-creators' : '';
      return `${line} ${color}${isDeleted}${manyCreators}${isNew}`;
    }
    return '';
  }

  hoverProposalLine() {
    this.$scope.$emit('hover_proposal');

    if (this.sProposal) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.hoverProposalLineAction(this.sProposal.key, this.sScreen)
      );
    }
  }

  stopHoverProposalLine() {
    this.$scope.$emit('stop_hover_proposal');

    if (this.sProposal) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.clearHoverProposalLineAction(this.sProposal.key, this.sScreen)
      );
    }
  }
}

export const proposalIndicatorComponent = {
  transclude: true,
  template: require('./proposalIndicator.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<?',
    sProposal: '<?',
    sScreen: '@?',
  },
  controller: proposalIndicator,
};
