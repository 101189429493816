class ReferenceModal {
  constructor($notification, $translate, types, contentApi) {
    'ngInject';

    this.notificationService = $notification;
    this.translationService = $translate;
    this.types = types;

    this.contentApi = contentApi;
    this.strengths = ['LOW', 'MEDIUM', 'HIGH'];
    this.strength = 'MEDIUM'; // default
    this.resourcePickerTemplate = require('../../../../screen/optionTemplates/themeOption.html');
  }

  async submit() {
    if (this.selectedReference) {
      this.modalInstance.close({ ...this.selectedReference, strength: this.strength });
    } else {
      this.notificationService.error(
        this.translationService.instant('components.llinkid.referencesModal.error.invalidReference')
      );
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  display(d) {
    return d ? d.title : '<NONE>';
  }

  filterAlreadySelectedReferences(references) {
    const alreadySelectedReferencesKeys = this.$parent.ctrl.alreadySelectedReferences;

    return references.filter((reference) => {
      return !alreadySelectedReferencesKeys.includes(reference.$$meta.permalink);
    });
  }

  $onInit() {
    this.parameters = this.resolve.modalData.parameters;
    this.typeTranslation = this.resolve.modalData.typeTranslation;
    this.strength = this.resolve.modalData.edit.relation?.strength || this.strength;
    this.selectedReference = this.resolve.modalData.edit.item
      ? this.resolve.modalData.edit.item
      : this.selectedReference;
    this.alreadySelectedReferences = this.resolve.modalData.alreadySelectedReferences
      ? this.resolve.modalData.alreadySelectedReferences
      : [];
    this.showStrength = this.resolve.modalData.showStrength;
  }
}

export default {
  template: require('./referenceModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: ReferenceModal,
};
