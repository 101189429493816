import { RootState } from '@generalTypes/rootStateTypes';
import { BatchItem } from '@generalTypes/sriTypes';
import {
  selectApiWithPendingChangesWithoutDeletes,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import {
  AsyncValidationRule,
  ValidationError,
  ValidationRuleName,
} from '@newStore/validation/validationTypes';
import { createTypedSelector } from '@newStore/genericHelpers';
import { proHomeHref } from '@store/constants/constants';
import { isEqual } from 'lodash';
import { ContentHref } from '@generalTypes/apiTypes';
import { VALIDATION_PENDING } from '../validationHelpers';

const selectValidationData = (state: RootState, href: string) =>
  state.validationData[href]?.referencedInProHomeCannotUnpublish?.data;

const selectNeedsData = createTypedSelector(
  [
    (state, href) =>
      selectApiWithPendingChangesWithoutDeletes(state).relationsToAndFromMap.to[href],
    selectValidationData,
  ],
  (relationsMapToHref, loadedData) => {
    return (
      loadedData === undefined && relationsMapToHref?.some((z) => z.relationtype === 'REFERENCES')
    );
  }
);

const selectValidation = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectValidationData(state, href),
    (state, href: ContentHref) => selectNeedsData(state, href),
  ],
  (content, validationData, needsData) => {
    if (!content) {
      return VALIDATION_PENDING;
    }
    const now = new Date();
    const issuedDate = content.issued ? new Date(content.issued) : null;
    if (issuedDate && issuedDate <= now) {
      return true;
    }

    if (!validationData) {
      if (!needsData) {
        return true;
      }
      return VALIDATION_PENDING;
    }

    if ((!issuedDate || issuedDate > now) && validationData.length > 0) {
      // we are not published, and we are referenced in pro home
      const error: ValidationError = {
        code: 'referencedInProHomeCannotUnpublish',
        rule: 'referencedInProHomeCannotUnpublish',
        type: 'ERROR',
        message: `Je mag dit PRO.-thema <strong>niet depubliceren</strong>. Het is gelinkt aan de homepagina. <br /> ${validationData
          .map((z) => [...z.$$pathToRoot].reverse().slice(1).join(' > '))
          .join('<br />')}`,
        property: 'issued',
      };
      return error;
    }

    return true;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

/**
 * It is not allowed to unpublish a Pro Theme if it is referenced in the Pro Menu (Pro Home Page)
 */
export const referencedInProHomeCannotUnpublish: AsyncValidationRule = {
  getName: (): ValidationRuleName => 'referencedInProHomeCannotUnpublish',
  isAsync: true,
  selectNeedsData,
  selectDataBatch: createTypedSelector(
    [
      (state, href) =>
        selectApiWithPendingChangesWithoutDeletes(state).relationsToAndFromMap.to[href],
    ],
    (relationsMapToHref) => {
      const hrefs = relationsMapToHref
        ?.filter((z) => z.relationtype === 'REFERENCES')
        .map((z) => z.from.href)
        .join(',');

      // with selectNeedsData, this should not ever be an empty list.
      if (!hrefs) {
        return null;
      }

      const batchItem: BatchItem<'GET'> = {
        verb: 'GET',
        href: `/content?root=${proHomeHref}&hrefs=${hrefs}&$$expandPathToRoot=true&limit=500`,
      };
      return batchItem;
    },
    {
      memoizeOptions: {
        resultEqualityCheck: isEqual,
      },
    }
  ),
  selectValidation,
};
