import he from 'he';
import { Content } from '@generalTypes/apiTypes';
import constants from '../reduxLoop/constants/constants';

export const getTitle = (node: Content, { addIdentifier } = { addIdentifier: false }) => {
  const title = node.title || '';
  if (node.type !== constants.llinkidCurriculum && node.type !== constants.curriculumZill) {
    return title;
  }

  const version = node.$$version ? `(v${node.$$version.replace('.0.0', '')})` : '';
  const identifier = (node.identifiers && node.identifiers[0]) || '';

  const completeTitle = addIdentifier ? `${title} ${identifier} ${version}` : `${title} ${version}`;

  return he.decode(completeTitle);
};
