import { any } from 'prop-types';
import React from 'react';
import { getResourceKey } from '@store/helpers/documentHelpers';
import AsideEditor from '@UI/editor/AsideEditor';
import { EditAsideStaticHtml } from '@nodeTypeConfig/configTypes';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';

const AsideStaticHtml: React.FC<{
  config: EditAsideStaticHtml;
}> = ({ config }) => {
  const editHref = useEditHref();

  const initialValue = useInitialValue(config.property);

  const readOnly = useReadOnly(false);

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        {config.label && (
          <label className="control-label control-label-inline">{config.label}</label>
        )}
        <AsideEditor
          placeholder={''}
          skey={getResourceKey(editHref)}
          initial={initialValue}
          field="html"
          readOnly={Boolean(readOnly)}
          plainText={true}
        ></AsideEditor>
      </AsideValidation>
    </div>
  );
};

AsideStaticHtml.displayName = 'AsideStaticHtml';

export default AsideStaticHtml;

AsideStaticHtml.propTypes = {
  config: any,
};
