import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LOAD_DATA_TO_SAVE_DOCUMENT_SUCCESS,
  SET_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS,
  SUGGESTIONS_SUBMITTED,
  BACK_TO_DOCUMENTS_LIST,
} from '@store/constants/actionTypes';
import { Content, ContentHref, ContentRelation, Proposal, WebPage } from '@generalTypes/apiTypes';
import { NewsletterSettings } from '@generalTypes/newsletterTypes';
import { setSelectedDocument } from '../documentUI/documentUIState';
import { arrayToObjectMap } from '../genericHelpers';
import { DocumentApiState, LoadIncludedWebPagesAction } from './documentApiTypes';
import { settings } from '../../config/settings';

const getInitialState = (): DocumentApiState => ({
  content: {},
  relations: {},
  proposals: {},
  webPages: {},
  newsletterSettings: null,
  isContentAndRelationsFetched: false,
  isProposalsFetched: false,
  isWebPagesFetched: false,
  includedWebPagesFetched: {},
  resourcesToLoad: [],
  failedResources: [],
});

const documentApiSlice = createSlice({
  name: 'documentApi',
  initialState: getInitialState,
  reducers: {
    refetchDocument(state) {
      state.isContentAndRelationsFetched = false;
      state.isProposalsFetched = false;
      state.isWebPagesFetched = false;
      state.includedWebPagesFetched = {};
      state.resourcesToLoad = [];
    },
    contentAndRelationsFetched(
      state,
      action: PayloadAction<{ content: Content[]; relations: ContentRelation[] }>
    ) {
      state.content = arrayToObjectMap(action.payload.content);
      state.relations = arrayToObjectMap(action.payload.relations);
      state.isContentAndRelationsFetched = true;
    },
    proposalsFetched(state, action: PayloadAction<Proposal[]>) {
      state.proposals = arrayToObjectMap(action.payload);
      state.isProposalsFetched = true;
    },
    loadNewsletterSettings() {},
    newsletterSettingsFetched(
      state,
      action: PayloadAction<{ newsletterSettings: NewsletterSettings }>
    ) {
      const { newsletterSettings } = action.payload;
      state.newsletterSettings = newsletterSettings;
    },
    loadWebConfigurations() {},
    webConfigurationsFetched(state, action: PayloadAction<WebPage[]>) {
      state.webPages = arrayToObjectMap(action.payload);
      state.isWebPagesFetched = true;
    },
    setProposal(state, action: PayloadAction<{ proposal: Proposal }>) {
      const { proposal } = action.payload;
      state.proposals[proposal.$$meta.permalink] = proposal;
    },
    addInternalResourcesToLoad: (state, action: PayloadAction<{ resources: string[] }>) => {
      const { resources } = action.payload;
      state.resourcesToLoad = [...state.resourcesToLoad, ...resources];
    },
    addInternalNotFoundHrefs: (state, action: PayloadAction<{ hrefs: string[] }>) => {
      const { hrefs } = action.payload;
      state.failedResources = [...state.failedResources, ...hrefs];
      state.resourcesToLoad = state.resourcesToLoad.filter((href) => !hrefs.includes(href));
    },
    loadIncludedWebPages: (state, action: LoadIncludedWebPagesAction) => {
      const { hrefs } = action.payload;
      hrefs.forEach((href) => {
        state.includedWebPagesFetched[href] = false;
      });
    },
    includedWebPagesFetched: (
      state,
      action: PayloadAction<{ hrefs: ContentHref[]; results: WebPage[] }>
    ) => {
      const { hrefs, results } = action.payload;
      hrefs.forEach((href) => {
        state.includedWebPagesFetched[href] = true;
      });
      state.webPages = { ...state.webPages, ...arrayToObjectMap(results) };
    },
    addExtraInternalData: (
      state,
      action: PayloadAction<{ content: Content[]; relations: ContentRelation[] }>
    ) => {
      const { content, relations } = action.payload;
      content.forEach((item) => {
        state.content[item.$$meta.permalink] = item;
        state.resourcesToLoad = state.resourcesToLoad.filter(
          (href) => href !== item.$$meta.permalink
        );
      });
      relations.forEach((item) => {
        state.relations[item.$$meta.permalink] = item;
      });
    },
    updateLastReadNewsletter(state) {
      if (state.newsletterSettings) {
        state.newsletterSettings.approvalDate = new Date(
          new Date().getTime() + settings.CLOCK_OUT_OF_SYNC_SAFETY_DELAY
        ).toISOString();
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createTeaserFromNewsitem(state, action: PayloadAction<{ newsItemHref: string }>) {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createNewsitemFromTeaser(state, action: PayloadAction<{ teaserHref: string }>) {},
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedDocument, () => {
      const initialState = getInitialState();
      return initialState;
    });

    builder.addCase(BACK_TO_DOCUMENTS_LIST, () => {
      const initialState = getInitialState();
      return initialState;
    });

    // @ts-expect-error old actions aren't liked by TS
    builder.addCase(SET_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS, (state, { payload }) => {
      payload.relations.forEach((illustrationRelation) => {
        state.relations[`/content/relations/${illustrationRelation.key}`] = illustrationRelation;
        illustrationRelation.from.$$expanded.$$relationsFrom.forEach((relationToGoal) => {
          state.relations[`/content/relations/${relationToGoal.key}`] = relationToGoal;
        });
      });
    });
    // @ts-expect-error old actions aren't liked by TS
    builder.addCase(SUGGESTIONS_SUBMITTED, (state, { payload }) => {
      Object.values(state.proposals)
        .filter(
          (proposal) =>
            proposal.status === 'IN_PROGRESS' &&
            payload.correspondingProposals.includes(proposal.$$meta.permalink)
        )
        .forEach((proposal) => {
          proposal.status = 'SUBMITTED_FOR_REVIEW';
        });
    });
    builder.addCase(LOAD_DATA_TO_SAVE_DOCUMENT_SUCCESS, (state) => {
      state.isContentAndRelationsFetched = false;
      state.isProposalsFetched = false;
    });
  },
});

export const {
  refetchDocument,
  contentAndRelationsFetched,
  proposalsFetched,
  loadWebConfigurations,
  loadNewsletterSettings,
  newsletterSettingsFetched,
  webConfigurationsFetched,
  setProposal,
  addInternalResourcesToLoad,
  addInternalNotFoundHrefs,
  addExtraInternalData,
  updateLastReadNewsletter,
  createTeaserFromNewsitem,
  createNewsitemFromTeaser,
  includedWebPagesFetched,
  loadIncludedWebPages,
} = documentApiSlice.actions;
export default documentApiSlice.reducer;
