import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';

const CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE: BuildingBlockNodeConfig = {
  information: {
    single: 'Verwijs Stap (voorgaand)',
    plural: 'Verwijs Stapen (voorgaand)',
  },
  edit: [
    {
      component: 'developmentPhase',
      label: 'Ontwikkelingsfase',
      showInAncestorTypes: [NodeType.CURRICULUM_ZILL_LEERLIJN_AN],
    },
    { component: 'leerlijnReference', label: 'Omschrijving' },
  ],
  // rowClass: 'goal-timeline-row',
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE;
