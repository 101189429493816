import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { EditAsideSources } from '@nodeTypeConfig/configTypes';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideRelationsPanel from '../asideRelationsPanel/AsideRelationsPanel';
import { AsideSourceItem } from './AsideSourceItem';
import { selectRelationsToSources } from './selectRelationsToSources';

const AsideSources: React.FC<{
  config: EditAsideSources;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const currentEditingHref = useEditHref();
  const readOnly = useReadOnly();

  const relations = useSelector(selectRelationsToSources);

  const [confirmationService, modalWindowService] = getAngularService(
    'ConfirmationService',
    'ModalWindowService'
  );

  const onDelete = useCallback(
    async (item) => {
      const confirmed = await confirmationService.confirmDeletion();
      if (confirmed) {
        dispatch(DOCUMENT_ACTIONS.removeNodeAction(getResourceKey(item.from.href)));
      }
    },
    [confirmationService, dispatch]
  );

  const onCreate = async () => {
    const response = await modalWindowService.open({ component: 'sourceModal' });
    if (response) {
      dispatch(
        DOCUMENT_ACTIONS.addNodeToParentNodeAction(
          getResourceKey(currentEditingHref),
          1,
          ContentType.SOURCE,
          {
            title: response.title,
          }
        )
      );
    }
  };

  return (
    <AsideRelationsPanel
      title={config.label}
      items={relations}
      readOnly={readOnly}
      onCreate={onCreate}
      onDelete={onDelete}
    >
      {(item) => <AsideSourceItem href={item.from.href as ContentHref} />}
    </AsideRelationsPanel>
  );
};

AsideSources.displayName = 'AsideSources';

AsideSources.propTypes = {
  config: any,
};

export default AsideSources;
