import { debounce } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useRef } from 'react';

interface AsideSimpleInputProps {
  label: string;
  defaultValue: string;
  onChange: (value: string) => void;
  readOnly: boolean;
  // component: string;
}

const SimpleInput: React.FC<AsideSimpleInputProps> = ({
  label,
  defaultValue,
  onChange,
  readOnly,
  // component,
}) => {
  const change = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, 1000);

  const inputIdRef = useRef(`simple-input-${nanoid()}`);
  const inputId = inputIdRef.current;

  return (
    <div className={`${readOnly ? 'aside-component-disabled' : ''}`}>
      <label className="control-label" htmlFor={inputId}>
        {label}
      </label>
      <input
        type="text"
        className="form-control"
        defaultValue={defaultValue}
        // value={version}
        disabled={readOnly}
        id={inputId}
        onChange={change}
        aria-autocomplete="none"
        autoComplete="off"
      />
    </div>
  );
};

export default SimpleInput;
