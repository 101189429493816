module.exports = [
  '$q',
  '$uibModal',
  ($q, $uibModal) => {
    const service = {};

    /**
   *
   * @param {object} modalOptions
   * Possible attributes we are using now to send to the modal
   * component, dataForModal, size, windowClass, resolve,
      template, controller, backdrop, scope
   * Modal docs => https://angular-ui.github.io/bootstrap/#!modal
   * @returns
   */
    service.open = (modalOptions) => {
      const defer = $q.defer();

      // some defaults
      if (!modalOptions.resolve) {
        modalOptions.resolve = {
          modalData: () => {
            return modalOptions.dataForModal || {};
          },
        };
      }

      modalOptions.size = modalOptions.size || 'dialog';

      $uibModal.open(modalOptions).result.then(
        (result) => {
          defer.resolve(result);
        },
        (reason) => {
          console.info(`Modal dismissed at: ${new Date()} - Reason: ${reason}`);
        }
      );

      return defer.promise;
    };

    return service;
  },
];
