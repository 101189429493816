import { setPersons } from '@newStore/externalData/externalDataState';
import { PayloadAction } from '@reduxjs/toolkit';
import { addNotificationAction } from '@store/actions/notificationActions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PersonHref } from '@generalTypes/apiTypes';
import { Person } from '@generalTypes/personApiTypes';
import { getMe, getPerson, getSecurityData, getUserRoles } from './userDataAccess';
import {
  initUser,
  initUserFailed,
  securityDataFetched,
  userFetched,
  userRolesFetched,
} from './userState';

function* getSecurityDataSaga(payload: PayloadAction<{ uuid: string; username: string }>) {
  try {
    const { uuid } = payload.payload;
    const ret = yield call(getSecurityData, uuid);
    yield put(securityDataFetched({ securityData: ret }));
    const userRoles = yield call(getUserRoles, uuid);
    yield put(userRolesFetched({ userRoles }));
  } catch (error) {
    console.error(error);
    yield put(initUserFailed(error));
  }
}

function* getPersonSaga(payload: PayloadAction<{ uuid: string; username: string }>) {
  try {
    const { uuid } = payload.payload;
    const person: Person = yield getPerson(`/persons/${uuid}` as PersonHref);
    yield put(setPersons({ persons: [person] }));
    /// temporarily still put the user in the documentReducer as well.
    // TODO: remove this when the documentReducer is removed
    yield put({
      type: 'SET_CURRENT_USER',
      payload: {
        user: person,
      },
    });
  } catch (personError) {
    // we don't care
    console.error('An error occured fetching the personal data of the user', personError);
  }
}

function* initUserData() {
  try {
    const me: {
      uuid: string;
      username: string;
    } = yield call(getMe);
    yield put(userFetched(me));
  } catch (error) {
    console.error(error);
    yield put(initUserFailed(error));
  }
}

function* initUserFailedSaga() {
  yield put(
    addNotificationAction({
      type: 'ERROR',
      message:
        'Er is een onverwachte fout opgetreden bij het ophalen van de gebruiker en zijn security.',
    })
  );
}

export function* userSaga() {
  yield takeEvery(initUser.match, initUserData);
  yield takeEvery(initUserFailed.match, initUserFailedSaga);
  yield takeEvery(userFetched.match, getPersonSaga);
  yield takeEvery(userFetched.match, getSecurityDataSaga);
}
