import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { selectRelationToParent } from '@newStore/documentApi/documentApiSelectors';
import {
  selectContentNode,
  selectContentNodeChildren,
  selectIsNodeCollapsible,
  selectIsNodeProposedToDeleted,
  selectIsNodeSelected,
} from '@newStore/documentUI/documentUISelectors';
import { useDispatch, useSelector } from 'react-redux';

import InlineEditor from '@UI/editor/InlineEditor';
import { isLlinkidGoalSubItemNode, isFallbackNode } from '@newStore/documentUI/documentUITypes';
import { MutableRefObject, useRef } from 'react';

import { ContentHref } from '@generalTypes/apiTypes';
import Collapse from '@mui/material/Collapse';
import {
  selectAreSomeNodesCollapsed,
  toggleAllCollapsed,
  toggleCollapseNode,
} from '@newStore/documentUI/documentUIState';
import { selectValidationInfoForNode } from '@newStore/validation/validationSelectors';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { toggleSelectionAction, updateAsideViewModelAction } from '@store/actions/documentActions';
import { getContentNodeId } from '@newStore/documentApi/documentApiHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import {
  selectIsNodeDraggable,
  selectShouldDocumentShowCollapseAll,
  selectShowSelectBox,
} from './contentRowSelectors';
import { RootState } from '../../../../types/rootStateTypes';
import DragAndDropRow from '../DragnDrop/DragAndDrop';
import AttachmentsGroupRow from './customContentRows/attachmentsGroupRow/AttachmentsGroupRow';
import ContentChangedIndicator from '../changeIndicators/ContentChangedIndicator';
import ProposalIndicatorForDoc from '../changeIndicators/ProposalIndicatorForDoc';
import GlobalDocumentRow from './customContentRows/globalDocumentRow/globalDocumentRow';
import ImageGroupRow from './customContentRows/imageGroupRow/ImageGroupRow';
import ImageRow from './customContentRows/imageRow/ImageRow';
import LegalRow from './customContentRows/legalRow/legalRow';
import LlinkidCurriculumRow from './customContentRows/llinkidCurriculumRow/LlikindCurriculumRow';
import LlinkidGoalRow from './customContentRows/llinkidGoalRow/llinkidGoalRow';
import LlinkidGoalSubItemRow from './customContentRows/llinkidGoalSubItemRow/LlinkidGoalSubItemRow';
import MusicRow from './customContentRows/musicRow/musicRow';
import ProNewsItemRow from './customContentRows/proNewsItemRow/ProNewsItemRow';
import ReferenceGroupRow from './customContentRows/referenceGroupRow/ReferenceGroupRow';
import TeaserRow from './customContentRows/teaserRow/TeaserRow';
import ValidationErrors from '../validationIndicator/ValidationErrors';
import ValidationIndicatorForDoc from '../validationIndicator/ValidationIndicatorForDoc';
import VideoGroupRow from './customContentRows/videoGroupRow/VideoGroupRow';
import VideoRow from './customContentRows/videoRow/VideoRow';
import ZillLeerlijnReferenceStepRow from './customContentRows/zillLeerlijnRows/ZillLeerlijnReferenceStepRow';
import ZillLeerlijnStepRow from './customContentRows/zillLeerlijnRows/ZillLeerlijnStepRow';
import ContentReferencesLinks from './ContentReferencesLinks';
import QuoteRow from './customContentRows/quoteRow/QuoteRow';
import { getPlaceHolderLabel } from './contentRowHelpers';
import './ContentRow.scss';

const enableDragAndDrop = true;

const ContentRow: React.FC<{
  href: ContentHref;
  isRoot?: boolean;
  parentHref?: ContentHref;
}> = ({ href, isRoot, parentHref }) => {
  const dispatch = useDispatch();
  const $state = getAngularService('$state');
  const contentNode = useSelector((state: RootState) => selectContentNode(state, href));
  const areSomeNodesCollapsed = useSelector(selectAreSomeNodesCollapsed);
  const collapsible = useSelector((state: RootState) => selectIsNodeCollapsible(state, href));
  const childHrefs = useSelector((state: RootState) => selectContentNodeChildren(state, href));
  const isCollapsed = useSelector((state: RootState) => state.documentUI.collapsedNodes[href]);
  const showCollapseAll = useSelector(selectShouldDocumentShowCollapseAll);

  const isDragging = useSelector((state: RootState) =>
    state.documentUI.draggingNodes.some(
      (node) => node.node.href === href && node.relation.to.href === parentHref
    )
  );

  const isProposedToDelete = useSelector((state: RootState) =>
    selectIsNodeProposedToDeleted(state, href)
  );

  const relationToParent = useSelector(
    (state: RootState) => parentHref && selectRelationToParent(state, href, parentHref)
  );
  const isSelected = useSelector(
    (state: RootState) => parentHref && selectIsNodeSelected(state, href, parentHref)
  );
  const showSelectionCheckbox = useSelector(
    (state: RootState) => parentHref && selectShowSelectBox(state, href, parentHref)
  );
  const isDraggable = useSelector(
    (state: RootState) => parentHref && selectIsNodeDraggable(state, href, parentHref)
  );

  const validation = useSelector((state: RootState) =>
    selectValidationInfoForNode(state, href, parentHref)
  );

  const nodeTypeConfig = useSelector((state: RootState) => selectAppliedNodeConfig(state, href));

  const rowRef = useRef() as MutableRefObject<HTMLDivElement>;
  const dragRef = useRef() as MutableRefObject<HTMLDivElement>;
  const dropChildZone = useRef() as MutableRefObject<HTMLDivElement>;

  if (!contentNode) {
    return null;
  }

  const openAside = () => {
    const { key } = contentNode;
    $state.go('edit.aside', { editKey: key });
    dispatch(updateAsideViewModelAction(key));
  };

  const toggleSelectedRow = () => {
    if (relationToParent) {
      dispatch(toggleSelectionAction(relationToParent.key, true, true));
    } else {
      console.error('Relation not found');
    }
  };

  return (
    <DragAndDropRow
      contentNode={contentNode}
      parentHref={parentHref}
      rowRef={rowRef}
      dragRef={dragRef}
      dropChildZoneRef={dropChildZone}
      onUnCollapse={() => {
        if (isCollapsed) {
          dispatch(toggleCollapseNode({ nodeHref: href }));
        }
      }}
      disableDrag={!isDraggable}
      disableDrop={!enableDragAndDrop}
    >
      <div className="content-row-container">
        <div className="side-block">
          <div className="error-block">
            <ValidationErrors
              href={href}
              validationErrors={validation?.validationErrors}
              defaultMinimized={true}
            />
          </div>
        </div>
        <section
          ref={rowRef}
          className={`content-section${isDragging ? ' dragging' : ''} ${
            isSelected ? 'selected' : ''
          } ${isProposedToDelete ? 'deleted' : ''}
          `}
        >
          <ProposalIndicatorForDoc href={href} />

          <ContentChangedIndicator href={href} height90={true}></ContentChangedIndicator>

          <div className="row-container">
            <div ref={dragRef} className={`drag-handle ${!isDraggable && 'disabled'}`}>
              <img src="images/drag-handle.svg" draggable="false" />
            </div>

            {!isProposedToDelete && (
              <ValidationIndicatorForDoc href={href} parentHref={parentHref} />
            )}

            {isRoot && !!childHrefs?.length && showCollapseAll && (
              <div
                className="expand-collapse-icon all"
                onClick={() => dispatch(toggleAllCollapsed())}
              >
                {areSomeNodesCollapsed ? (
                  <img
                    src="images/expand-all-arrow-icon.svg"
                    draggable="false"
                    title="Klap alles open"
                  />
                ) : (
                  <img
                    src="images/collapse-all-arrow-icon.svg"
                    draggable="false"
                    title="Klap alles dicht"
                  />
                )}
              </div>
            )}
            {collapsible && !!childHrefs?.length && (
              <div
                className={`expand-collapse-icon ${isCollapsed ? 'collapsed' : 'expanded'}`}
                onClick={() => dispatch(toggleCollapseNode({ nodeHref: href }))}
              >
                {isCollapsed && <img src="images/expand-arrow-icon.svg" draggable="false" />}
                {!isCollapsed && <img src="images/collapse-arrow-icon.svg" draggable="false" />}
              </div>
            )}

            <div
              className={`inner-content-row type-${contentNode.type.toLowerCase()}`}
              id={getContentNodeId({ childHref: href, parentHref })}
            >
              <ContentReferencesLinks contentNodeHref={contentNode.href} />

              {'icon' in contentNode && contentNode.icon && (
                <span
                  className="structure-icon-wrapper"
                  style={{ backgroundColor: contentNode.color }}
                >
                  <img
                    src={contentNode.icon.url}
                    style={{ height: '25px', width: '25px' }}
                    draggable="false"
                  />
                </span>
              )}
              {(!('customRender' in contentNode) || !contentNode.customRender) && (
                <div>
                  <div className="node-title">
                    {'identifier' in contentNode && (
                      <div
                        style={{
                          verticalAlign: 'top',
                          whiteSpace: 'nowrap',
                          color:
                            'color' in contentNode && contentNode.color
                              ? contentNode.color
                              : 'black',
                        }}
                        className="identifier"
                      >
                        <div id={href} contentEditable={false}>
                          {contentNode.identifier}
                        </div>
                      </div>
                    )}
                    {'title' in contentNode && (
                      <InlineEditor
                        initial={contentNode.title}
                        field="title"
                        placeholder={getPlaceHolderLabel(nodeTypeConfig, 'title')}
                        skey={contentNode.key}
                        href={contentNode.href}
                      ></InlineEditor>
                    )}
                  </div>

                  {('text' in contentNode || 'description' in contentNode) && (
                    <div className="node-text">
                      {'image' in contentNode && contentNode.image && (
                        <img
                          className="node-illustration"
                          src={contentNode.image.url}
                          style={{ width: '150px' }}
                          draggable="false"
                        />
                      )}
                      <div>
                        {'description' in contentNode && (
                          <InlineEditor
                            initial={contentNode.description}
                            field="description"
                            placeholder={getPlaceHolderLabel(nodeTypeConfig, 'description')}
                            skey={contentNode.key}
                            href={contentNode.href}
                          ></InlineEditor>
                        )}
                        {'text' in contentNode && 'description' in contentNode && <br />}
                        {'text' in contentNode && (
                          <div>
                            {'isStaticHtml' in contentNode && contentNode.isStaticHtml ? (
                              <pre>
                                <code style={{ display: 'block' }}>{contentNode.text}</code>
                              </pre>
                            ) : (
                              <InlineEditor
                                initial={contentNode.text}
                                field="html"
                                placeholder={getPlaceHolderLabel(nodeTypeConfig, 'html')}
                                skey={contentNode.key}
                                href={contentNode.href}
                              ></InlineEditor>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {isFallbackNode(contentNode) && (
                    <div className="warning-label">
                      {contentNode.type} has no viewmodel selector!
                    </div>
                  )}

                  {'placeholder' in contentNode && (
                    <div className="node-title" style={{ color: '#a0a0a0', cursor: 'default' }}>
                      [{contentNode.placeholder}]
                    </div>
                  )}
                </div>
              )}

              {'customRender' in contentNode && contentNode.customRender && (
                <>
                  {contentNode.type === NodeType.TEASER && <TeaserRow contentNode={contentNode} />}
                  {contentNode.type === NodeType.VIDEO && <VideoRow contentNode={contentNode} />}
                  {contentNode.type === NodeType.VIDEO_GROUP && (
                    <VideoGroupRow contentNode={contentNode} />
                  )}
                  {contentNode.type === NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER && (
                    <ZillLeerlijnStepRow contentNode={contentNode} />
                  )}
                  {(contentNode.type === NodeType.CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE ||
                    contentNode.type === NodeType.CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE) && (
                    <ZillLeerlijnReferenceStepRow contentNode={contentNode} />
                  )}
                  {contentNode.type === NodeType.QUOTE && <QuoteRow contentNode={contentNode} />}
                  {isLlinkidGoalSubItemNode(contentNode) && (
                    <LlinkidGoalSubItemRow contentNode={contentNode} />
                  )}
                  {(contentNode.type === NodeType.ATTACHMENTS_GROUP ||
                    contentNode.type === NodeType.SHARED_ATTACHMENTS_GROUP) && (
                    <AttachmentsGroupRow contentNode={contentNode} />
                  )}
                  {contentNode.type === NodeType.IMAGE && <ImageRow contentNode={contentNode} />}
                  {contentNode.type === NodeType.IMAGE_GROUP && (
                    <ImageGroupRow contentNode={contentNode} />
                  )}
                  {contentNode.type === NodeType.GLOBAL_DOCUMENT && (
                    <GlobalDocumentRow contentNode={contentNode} />
                  )}
                  {contentNode.type === NodeType.LEGAL && <LegalRow contentNode={contentNode} />}
                  {contentNode.type === NodeType.REFERENCE_GROUP && (
                    <ReferenceGroupRow contentNode={contentNode} />
                  )}
                  {contentNode.type === NodeType.MUSIC && <MusicRow contentNode={contentNode} />}
                  {contentNode.type === NodeType.LLINKID_GOAL && (
                    <LlinkidGoalRow contentNode={contentNode} />
                  )}
                  {contentNode.type === NodeType.PRONEWSITEM && (
                    <ProNewsItemRow contentNode={contentNode} />
                  )}
                  {contentNode.type === NodeType.LLINKID_CURRICULUM && (
                    <LlinkidCurriculumRow contentNode={contentNode} />
                  )}
                </>
              )}
            </div>

            <div className="type-label">{nodeTypeConfig.information.single}</div>

            {(!('readOnlyNode' in nodeTypeConfig) || !nodeTypeConfig.readOnlyNode) && (
              <div className="edit-button">
                <span className="glyphicon glyphicon-edit" onClick={openAside}></span>
              </div>
            )}
            {showSelectionCheckbox && (
              <div className="select-action">
                <input type="checkbox" onChange={toggleSelectedRow} checked={isSelected} />
                <span className="customCheckmark" onClick={toggleSelectedRow}></span>
              </div>
            )}
          </div>
        </section>
        <div className="content-row-children">
          <div className="dropChild" ref={dropChildZone}></div>
          {!isDragging && childHrefs && childHrefs.length > 0 && (
            <Collapse in={!isCollapsed} timeout={{ appear: 0, enter: 0, exit: 400 }} unmountOnExit>
              {childHrefs?.map((childHref) => (
                <ContentRow key={childHref} href={childHref} parentHref={contentNode.href} />
              ))}
            </Collapse>
          )}
        </div>
      </div>
    </DragAndDropRow>
  );
};

export default ContentRow;
