import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { EditAsideSecondaryEducationType } from '@nodeTypeConfig/configTypes';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React from 'react';

import { RootState } from '@generalTypes/rootStateTypes';
import { pathMap } from '@newStore/externalData/externalDataTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { IApiResouce } from '@generalTypes/sriTypes';

import AsideValidation from '../asideValidationErrors/AsideValidation';

import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useEditHref } from '../../../hooks/UseEditHref';

const AsideSecondaryEducationType: React.FC<{ config: EditAsideSecondaryEducationType }> = ({
  config,
}) => {
  /**
   * very similar component to AsideSimpleDropDown.tsx
   */
  const dispatch = useDispatch();

  const readOnly = useReadOnly();

  const editHref = useEditHref();

  const secundaryEducationTypes = useSelector(
    (state: RootState) => state.externalData.data[pathMap.secondaryEducationTypes].items
  );

  const options = sortBy(Object.values(secundaryEducationTypes), ['name']);

  const currentValue = useInitialValue(config.property)?.[0];

  const defaultValue = secundaryEducationTypes[currentValue];

  const onChange = (selection: IApiResouce) => {
    dispatch(
      patchNodeAction(getResourceKey(editHref), {
        [config.property]: [selection.$$meta.permalink],
      })
    );
  };

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        <label className="control-label">{config.label}</label>
        {!Boolean(options.length) ? (
          <div className="form-group shimmer narrowLine"></div>
        ) : (
          <KovResourcePicker
            localData={options}
            display="name"
            defaultValue={defaultValue}
            clearable={config.options.clearable}
            disabled={readOnly}
            disableSearch={true}
            style={{ option: (provided) => ({ ...provided, cursor: 'pointer' }) }}
            onChange={onChange}
          />
        )}
      </AsideValidation>
    </div>
  );
};

AsideSecondaryEducationType.displayName = 'AsideSecondaryEducationType';

AsideSecondaryEducationType.propTypes = {
  config: any,
};

export default AsideSecondaryEducationType;
