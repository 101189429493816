import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';

class localTermRow {
  constructor($scope, $state, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    if (this.sItem.$$new) {
      this.openEdit();
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.sItem.key, { $$new: false }));
    }
  }

  $onDestroy() {}

  openEdit() {
    this.$state.go('edit.aside', { editKey: this.sItem.key });
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.updateAsideViewModelAction(this.sItem.key));
  }

  getDescription() {
    return this.sItem.description
      ? this.sItem.description.split('<table>').join('<table class="table table-bordered">')
      : '';
  }

  deleteTerm() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeNodeAction(this.sItem.key));
  }

  // getIllustration() {
  //   return this.sItem.$$attachments.has('ILLUSTRATION') ?
  // this.sItem.$$attachments.get('ILLUSTRATION').original : null;
  // }
}

export default {
  template: require('./localTermRow.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<',
    sHash: '<',
  },
  controller: localTermRow,
};
