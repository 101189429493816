import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideCurrent {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        current: {
          ...state.document.viewModel.aside.editDocument.current,
          key: 'current',
        },
        start: state.document.viewModel.aside.editDocument.current
          ? state.document.viewModel.aside.editDocument.current.value.startDate
          : null,
        end: state.document.viewModel.aside.editDocument.current
          ? state.document.viewModel.aside.editDocument.current.value.endDate
          : null,
        editKey: state.document.viewModel.aside.editDocument.key,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  updateCurrentField(scope, event, newDate) {
    if (newDate) {
      newDate = new Date(newDate).toISOString();
    }
    const newCurrent = {
      ...scope.ctrl.current.value,
      [scope.ctrl.sConfig.options.field]: newDate,
    };
    if (!newDate) {
      delete newCurrent[scope.ctrl.sConfig.options.field];
    }

    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(scope.ctrl.editKey, {
        current: newCurrent,
      })
    );
  }
}

export default {
  template: require('./asideCurrent.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
  },
  controller: asideCurrent,
};
