import { isInformatieVoorBestuurdersAccessRights } from '../reduxLoop/helpers/accessRights';

const getAccessRightsToApply = (documentSubState, nodeKey) => {
  const flatWithHiddens = documentSubState.viewModel && documentSubState.viewModel.flatWithHiddens;
  if (!flatWithHiddens) {
    return [];
  }
  const { getSelectedAccessRights } = require('../reduxLoop/viewmodels/viewModelHelpers');
  const mainDocumentAccessRights = getSelectedAccessRights(
    flatWithHiddens.find((e) => e.key === documentSubState.key)
  );
  const nodeAccessRights = getSelectedAccessRights(flatWithHiddens.find((e) => e.key === nodeKey));

  return [mainDocumentAccessRights, nodeAccessRights]
    .filter((x) => x)
    .map((x) => x.namedsets)
    .flat();
};

const getNodeKey = (documentSubState, node = undefined) => {
  if (node) {
    return node.key;
  }

  const asideElement =
    documentSubState.viewModel &&
    documentSubState.viewModel.aside &&
    documentSubState.viewModel.aside.editDocument;

  return asideElement && asideElement.key;
};

const isForbidden = (documentSubState, node = undefined) => {
  const errorOutput = {
    type: 'ERROR',
    class: '',
    message: 'labels.attachmentsGroup.globalDocumentNotAllowed',
  };

  const nodeKey = getNodeKey(documentSubState, node);
  const accessRightsToApply = getAccessRightsToApply(documentSubState, nodeKey);
  const isInformatieVoorBestuurders = isInformatieVoorBestuurdersAccessRights(accessRightsToApply);

  if (isInformatieVoorBestuurders) {
    return errorOutput;
  }

  return false;
};

export const isGlobalDocumentAllowed = (node, documentSubState) => {
  const opts = {
    node,
    state: documentSubState,
    fromAside: false,
  };

  const {
    getAttachmentResources,
    isGlobalDocument,
  } = require('../reduxLoop/viewmodels/viewModelHelpers');

  const attachments = getAttachmentResources(opts);
  const hasAnyGlobalAttachment =
    attachments && attachments.find((attachment) => isGlobalDocument(attachment));
  if (hasAnyGlobalAttachment) {
    const errorOutput = isForbidden(documentSubState, node);
    if (errorOutput) {
      return { ...errorOutput, class: 'error-label' };
    }
  }
  return true;
};

export const isGlobalDocumentAllowedFromAside = (documentSubState, attachments) => {
  const { isGlobalDocument } = require('../reduxLoop/viewmodels/viewModelHelpers');
  const errorOutput = isForbidden(documentSubState);
  if (errorOutput) {
    const hasAnyGlobalAttachment =
      attachments && attachments.find((node) => isGlobalDocument(node));
    return hasAnyGlobalAttachment ? { ...errorOutput, class: 'error-label' } : errorOutput;
  }
  return true;
};
