import { Content, isAttachmentAttachmentMetaInfo } from '@generalTypes/apiTypes';
import { compactTitle } from '@store/helpers/documentHelpers';
import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { MusicNode } from '../documentUITypes';
import { settings } from '../../../config/settings';

export const selectMusicNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): MusicNode => {
    const attachment = content.attachments.find(isAttachmentAttachmentMetaInfo);
    return {
      key: content.key,
      title: compactTitle(attachment?.name),
      href: content.$$meta.permalink,
      mp3src: attachment?.href && settings.apisAndUrls.contentApi + attachment.href,
      type: NodeType.MUSIC,
      customRender: true,
    };
  }
);
