class Facets {
  constructor(ProWebsiteConfigurationsService, ConfirmationService, ModalWindowService) {
    'ngInject';

    this.configurationsService = ProWebsiteConfigurationsService;
    this.confirmationService = ConfirmationService;
    this.modalWindowService = ModalWindowService;
  }

  async getFacetData(facet) {
    let copiedFacet = {};

    if (facet) {
      copiedFacet = angular.copy(facet);
    }

    return {
      componentTypes: this.configurationsService.getFacetComponentTypes(this.sType),
      facet: copiedFacet,
    };
  }

  getConfirmMessage(facet) {
    if (facet.component === 'SELECT_FROM_REFERENCE_FRAME') {
      return 'components.proWebsite.facetModalForm.warning.deleteReferenceFrame';
    }
    return 'delete.warningMessage';
  }

  async deleteFacet(facet) {
    const confirmed = await this.confirmationService.confirmDeletion(this.getConfirmMessage(facet));
    if (confirmed) {
      this.sFacets = this.sFacets.filter((facetInList) => {
        return facetInList.label !== facet.label;
      });
      this.onFacetsChange({ $event: { facets: this.sFacets } });
    }
  }

  async editFacet(facet) {
    const facetData = await this.getFacetData(facet);

    const opts = {
      component: 'proWebsiteFacetModalForm',
      dataForModal: facetData,
    };

    const modifiedFacet = await this.modalWindowService.open(opts);

    let index = 0;
    while (index < this.sFacets.length && this.sFacets[index].label !== facet.label) {
      index += 1;
    }

    this.sFacets.splice(index, 1, modifiedFacet);
    this.onFacetsChange({ $event: { facets: this.sFacets } });
  }

  async addFacet() {
    const facetData = await this.getFacetData();

    const opts = {
      component: 'proWebsiteFacetModalForm',
      dataForModal: facetData,
    };

    const newFacet = await this.modalWindowService.open(opts);

    this.sFacets.push(newFacet);
    this.onFacetsChange({ $event: { facets: this.sFacets } });
  }

  async $onInit() {
    // AGAIN it is important to make a copy in order to keep the component "parent independent"
    // objects even passing it with "<" are still being passed by "reference"
    // https://toddmotto.com/one-way-data-binding-in-angular-1-5/
    this.sFacets = angular.copy(this.sFacets);
  }
}

module.exports = {
  template: require('./facets.html'),
  controllerAs: 'ctrl',
  bindings: {
    sFacets: '<',
    sType: '<',
    onFacetsChange: '&',
  },
  controller: Facets,
};
