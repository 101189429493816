import { loadOdetReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { publishComponent, titleComponent } from '@nodeTypeConfig/helpers/genericComponents';
import * as ACTION_TYPES from '@store/constants/actionTypes';
import { settings } from '../../settings';

const CURRICULUM_ZILL: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Leerplan basis',
    plural: 'Leerplannen basis',
    icon: require('../../../../img/icons/zill.svg'),
    category: 'LEERPLAN',
  },
  preloadActions: [
    {
      type: ACTION_TYPES.INIT_ZILL_ODET_CURRICULUM_DOCUMENT,
    },
  ],
  onLoadActions: [loadOdetReferenceFrame()],
  relationsToIgnore: ['IS_VERSION_OF', 'IS_INCLUDED_IN', 'REPLACES', 'REFERENCES'],
  tocTypes: [
    NodeType.CURRICULUM_ZILL_CLUSTER,
    NodeType.CURRICULUM_ZILL_DEVELOPMENT_FIELD,
    NodeType.CURRICULUM_ZILL_DEVELOPMENT_THEME,
  ],
  buildingBlocks: [{ type: NodeType.CURRICULUM_ZILL_CLUSTER }],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
    {
      component: 'concordanties',
      label: 'Concordanties',
      options: {
        isRoot: true,
      },
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        fields: [],
      },
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Miniatuur',
      options: {
        type: 'THUMBNAIL',
        fields: ['description', 'rightsHolder', 'alt'],
      },
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Achtergrond',
      options: {
        type: 'BACKGROUND_IMAGE',
        fields: [],
      },
    },
  ],
  disableDeleteWhenIssued: true,
  allowSuggestions: false,
  isCreatable: true,
  isSearchable: true,
  previewModes: [
    {
      type: 'URL',
      name: 'Zill Leerplansite',
      location: `${settings.apisAndUrls.zill}/#!/leerinhoud?preview&version=/content/{%key}`,
    },
    {
      type: 'URL',
      name: 'Zill Selectietool',
      location: `${settings.apisAndUrls.zillSelector}/#/?preview&version=/content/{%key}`,
    },
  ],
};

export default CURRICULUM_ZILL;
