module.exports = [
  '$q',
  '$translate',
  'ModalWindowService',
  ($q, $translate, ModalWindowService) => {
    const service = {};

    this.modalWindowService = ModalWindowService;

    service.confirm = ({
      confirmationMessage,
      confirmText = 'confirmation.accept',
      cancelText = 'confirmation.cancel',
    }) => {
      const confirmDefer = $q.defer();

      const opts = {
        template: require('../screen/dialogs/confirm.html'),
        controller: require('../screen/dialogs/confirmController'),
        resolve: {
          data: {
            title: $translate.instant(confirmationMessage),
            confirmText: $translate.instant(confirmText),
            cancelText: $translate.instant(cancelText),
            onConfirm: (defer) => {
              defer.resolve();
              confirmDefer.resolve(true);
            },
            onCancel: () => {
              confirmDefer.resolve(false);
            },
          },
        },
      };

      this.modalWindowService.open(opts);

      return confirmDefer.promise;
    };

    service.confirmDeletion = (message) => {
      return service.confirm({
        confirmationMessage: message || 'delete.warningMessage',
      });
    };

    service.confirmVersion = (message) => {
      return service.confirm({
        confirmationMessage: message || 'versioning.confirmationMessage',
        confirmText: 'versioning.confirmationButton',
      });
    };

    service.confirmCopy = (message) => {
      return service.confirm({
        confirmationMessage: message || 'versioning.copyConfirmationMessage',
        confirmText: 'edit.copy',
      });
    };

    return service;
  },
];
