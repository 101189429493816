import { Content, ContentHref } from '@generalTypes/apiTypes';
import { createTypedSelector, getAttachmentUrl } from '@newStore/genericHelpers';

import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { dienstverleningKovKey } from '@store/constants/constants';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { ProMenuLevel2Node } from '../documentUITypes';

export const selectProMenuLevel2Node = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state) => state.referenceFrames[`/content/${dienstverleningKovKey}`]?.content,
  ],
  (content: Content, referenceFramContent): ProMenuLevel2Node => {
    const referenceFrameItem = content.themes && referenceFramContent[content.themes[0]];
    const icon =
      referenceFrameItem && referenceFrameItem.attachments?.find((att) => att.type === 'ICON');
    return {
      href: content.$$meta.permalink,
      key: content.key,
      color: content.color || referenceFrameItem?.color || undefined,
      title: content.title || '',
      type: NodeType.PRO_MENU_LEVEL_2,
      icon: icon ? { url: getAttachmentUrl(icon) } : null,
    };
  }
);
