import { createTypedSelector, parentChildRelationFilter } from '@newStore/genericHelpers';
import { selectProReferenceFrame } from '@newStore/referenceFrames/referenceFramesSelectors';

export const selectAsidePrimaryReferenceFrameOptions = createTypedSelector(
  [selectProReferenceFrame],
  (proReferenceFrame) => {
    if (!proReferenceFrame) {
      return null;
    }
    return Object.values(proReferenceFrame.relations).flatMap((relation) => {
      if (
        proReferenceFrame.content[relation.from.href].type === 'CURRICULUM_THEME' &&
        proReferenceFrame.content[relation.to.href].type !== 'CURRICULUM_THEME' &&
        parentChildRelationFilter(relation)
      ) {
        return proReferenceFrame.content[relation.from.href];
      }
      return [];
    });
  }
);
