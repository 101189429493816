import { getEditComponent } from '@newStore/genericHelpers';
import { InlineField, NodeTypeConfigApplied } from '@nodeTypeConfig/configTypes';

export const getPlaceHolderLabel = (nodeTypeConfig: NodeTypeConfigApplied, field: InlineField) => {
  const property = field === 'html' ? '$$html' : field;
  const component = getEditComponent(property, nodeTypeConfig.edit);
  if (component) {
    return `Vul een ${component.label.toLowerCase()} in...`;
  }
  console.warn(
    `make sure property is added to NodeTypeConfig edit of ${nodeTypeConfig.information.single} for ${field}`
  );
  // only for description I expect the property in config not to be defined (title uses generic titleComponent and html does not occur that often)
  return `Vul een omschrijving in...`;
};
