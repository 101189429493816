import { ContentType } from '@generalTypes/apiTypes';
import { getAllOfResource } from '@newStore/externalData/externalDataState';
import { pathMap } from '@newStore/externalData/externalDataTypes';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { NodeType, RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { settings } from '../../settings';
import {
  identifierComponent,
  publishComponent,
  titleComponent,
} from '../helpers/genericComponents';

const llinkidCurriculum: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Leerplan secundair',
    plural: 'Leerplannen secundair',
    icon: require('../../../../img/icons/llinkid.svg'),
    category: 'LEERPLAN',
  },
  relationsToIgnore: ['REPLACES'], // i dont know if this will break anything. the components who work with REPLACES relations do fetch their own data.
  preloadActions: [],
  onLoadActions: [
    loadReferenceFrame({ referenceFrame: referenceFrames.educationalPointers }),
    loadReferenceFrame({ referenceFrame: referenceFrames.educationalComponents }),
    getAllOfResource({ resource: pathMap.studyProgrammes, refresh: false }),
    getAllOfResource({ resource: pathMap.studyProgrammeGroups, refresh: false }),
    getAllOfResource({ resource: pathMap.secondaryEducationTypes, refresh: false }),
  ],
  createDefaults: {
    realisationPeriod: 24,
    version: {
      major: 1,
      minor: 0,
      patch: 0,
    },
  },
  createChildDefaults: [
    {
      type: ContentType.LLINKID_GUIDING_PRINCIPLE_GROUP,
      readorder: 1,
    },
    {
      type: ContentType.LLINKID_CONTEXT,
      readorder: 2,
    },
    {
      type: ContentType.LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT,
      readorder: 3,
    },
    {
      type: ContentType.LLINKID_GOAL_LIST,
      readorder: 4,
    },
    {
      type: ContentType.LLINKID_BASIC_MATERIAL_REQUIREMENTS,
      readorder: 5,
    },
    {
      type: ContentType.LLINKID_CORRELATION_ENDTERMS,
      readorder: 6,
    },
  ],
  edit: [
    {
      component: 'foundational',
      property: 'foundational',
      label: 'Gemeenschappelijk',
      reactComponent: 'AsideCheckbox',
    },
    {
      ...identifierComponent,
      required: RequiredType.ERROR,
      width: 2,
    },
    {
      ...titleComponent,
      width: 8,
    },
    {
      // TODO: practically, we have a default set of edit properties like component, label, property
      // but actually, each of the components should have its own definition of what needs to be defined, since the component will consume this config.
      component: 'version',
      reactComponent: 'AsideVersion',
      label: 'Versie',
      property: '$$version',
      width: 2,
      readonly: true,
    },
    {
      component: 'previousVersion',
      reactComponent: 'AsidePreviousVersion',
      label: 'Vervangt (vorige versie)',
      readonly: true,
    },
    {
      ...publishComponent,
      width: 4,
    },
    {
      component: 'realisationPeriod',
      property: 'realisationPeriod',
      label: 'Realisatieperiode',
      reactComponent: 'AsideSimpleDropDown',
      width: 4,
      options: {
        clearable: false,
        options: [
          {
            key: 12,
            label: '1 schooljaar',
          },
          {
            key: 24,
            label: '2 schooljaren',
          },
        ],
      },
      required: RequiredType.ERROR,
    },
    {
      component: 'expiryDate',
      property: 'expiryDate',
      reactComponent: 'AsideDatePicker',
      options: {
        openTo: 'year',
        shouldDisableDate: (day: Date) => {
          const is31stAugust = day.getDate() === 31 && day.getMonth() === 7; // August is month 7 (0-indexed)
          if (is31stAugust) {
            return false;
          }
          return true;
        },
      },
      label: 'Vervaldatum',
      width: 4,
    },
    {
      component: 'validityPeriod',
      label: 'Van toepassing',
      reactComponent: 'AsideValidityPeriod',
      width: 12,
      readonly: true,
    },
    {
      component: 'applicability',
      property: 'applicability.studyProgrammes',
      required: RequiredType.ERROR,
      customRequiredMessage: 'Je moet minimum 1 <strong>studierichting</strong> kiezen',
      type: 'select',
      label: 'In welke studierichtingen is het leerplan van toepassing?',
      options: {
        clearAllAllowed: true,
        selectAllAllowed: false,
        placeholder: 'Study programmes...',
        subfield: 'studyProgrammes',
      },
    },
    {
      component: 'curriculumTheme',
      reactComponent: 'AsideCurriculumTheme',
      property: 'themes',
      definiteArticle: true,
      label: 'Leerplanthema',
      subLabel:
        "Koppel deze leerplanversie aan het overkoepelende leerplanthema uit ordeningskader 'Dienstverlening Katholiek Onderwijs Vlaanderen'.",
      required: RequiredType.ERROR,
    },
    {
      component: 'secondaryEducationTypes',
      property: 'secondaryEducationTypes',
      reactComponent: 'AsideSecondaryEducationType',
      definiteArticle: true,
      label: 'Soort vorming',
      required: RequiredType.ERROR,
      options: {
        clearable: false,
      },
    },
    {
      component: 'educationalPointers',
      type: 'llinkidReferences',
      label: 'Wegwijzers',
      reactComponent: 'AsideReferenceFrameReferences',
      options: {
        revertedRelationDirection: true,
        referenceFrame: referenceFrames.educationalPointers,
        relationTypes: ['REQUIRES'],
        modal: 'llinkidReferenceModal',
        listColumn: 'title',
      },
    },
    {
      component: 'educationalComponents',
      type: 'llinkidReferences',
      label: 'Vormingscomponenten',
      reactComponent: 'AsideReferenceFrameReferences',
      options: {
        revertedRelationDirection: true,
        referenceFrame: referenceFrames.educationalComponents,
        relationTypes: ['REQUIRES'],
        modal: 'llinkidReferenceModal',
        listColumn: 'title',
      },
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        fields: [],
      },
    },
  ],
  tocTypes: [
    NodeType.LLINKID_GUIDING_PRINCIPLE_GROUP,
    NodeType.LLINKID_GOAL_SECTION,
    NodeType.LLINKID_GOAL_LIST,
    NodeType.LLINKID_CORRELATION_ENDTERMS,
    NodeType.LLINKID_CONTEXT,
    NodeType.LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT,
    NodeType.LLINKID_BASIC_MATERIAL_REQUIREMENTS,
    NodeType.SECTION,
  ],
  customEditorOptions: {
    showMarkerButton: true,
    hideTermButton: false,
  },
  buildingBlocks: [],
  isCreatable: true,
  isSearchable: true,
  isCloneable: true,
  previewModes: [
    {
      type: 'URL',
      name: 'LLinkID',
      location: `${settings.apisAndUrls.llinkid}/#!/leerplan/{%key}/doelenlijst?preview=true`,
    },
  ],
  disableDeleteWhenIssued: true,
  allowSuggestions: false,
};

export const LLINKID_CURRICULUM: RootNodeConfig = {
  ...llinkidCurriculum,
};

export const LLINKID_FOUNDATIONAL_CURRICULUM: RootNodeConfig = {
  ...llinkidCurriculum,
  isSearchable: false,
  isCreatable: false,
};
