import { Content } from '@generalTypes/apiTypes';
import { nodeTypeDefinitions } from './nodeTypeConfig';
import { nodeTypeConfigurations } from '.';
import { NodeType } from './configTypes';

export const getCreateDefaults = (nodeType: NodeType): Partial<Content> => {
  const { node } = nodeTypeDefinitions[nodeType];

  const config = nodeTypeConfigurations[nodeType];

  if ('createDefaults' in config) {
    return { ...node, ...config.createDefaults };
  }
  return node;
};
