/* eslint-disable func-names */
import { selectZillGoalsForZillIllustration } from '@newStore/zill/zillSelectors';
import { getNow } from '@kathondvla/sri-client/date-utils';
import { addResourceAncestorsToLoad } from '@newStore/externalData/externalDataState';
import {
  buildCurrentSelectionForZillSelector,
  groupGoalsByZillCurriculum,
} from '@newStore/zill/zillHelpers';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class asideGoalsByZillIllustrations {
  constructor(ZillSelectorService, ModalWindowService, ConfirmationService, $scope, $ngRedux) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
    this.zillSelectorService = ZillSelectorService;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const { illustrationsAreLoaded, genericGoals, loadingZillGoals } =
        selectZillGoalsForZillIllustration(state);
      const goalsGroupedByZillCurriculum = groupGoalsByZillCurriculum(genericGoals);

      return {
        zillCurriculumGroups: Object.values(goalsGroupedByZillCurriculum),
        zillCurriculumGroupMap: goalsGroupedByZillCurriculum,
        loading: !illustrationsAreLoaded || loadingZillGoals.length,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  async openSelector() {
    const zillCurriculum = await this.modalWindowService.open({
      component: 'documentSelectorModal',
      dataForModal: {
        type: 'zillCurriculum',
        stateField: 'zillCurriculums',
        params: {
          type: 'CURRICULUM_ZILL',
          issuedBefore: getNow(),
          orderBy: 'issued',
          descending: true,
        },
      },
    });

    if (zillCurriculum) {
      try {
        // after selecting a zill version, we will open the zill selector where you can adapt the
        // selected goals of that version. We do not touch the goals of different zill versions.
        const groupForSelectedVersion =
          this.zillCurriculumGroupMap[zillCurriculum.$$meta.permalink];
        const currentSelections = groupForSelectedVersion
          ? buildCurrentSelectionForZillSelector(groupForSelectedVersion.goals)
          : [];

        const selectedGoals = await this.zillSelectorService.openZillSelector(
          zillCurriculum.$$meta.permalink,
          currentSelections.map((sel) => sel.href),
          'illustratie'
        );

        const deletedSelections = currentSelections.filter(
          (cs) => !selectedGoals.includes(cs.href)
        );

        deletedSelections.forEach((selection) => {
          this.$ngRedux.dispatch(
            DOCUMENT_ACTIONS.removeRelationAction(
              selection.relationFromIllustrationHref.split('/').pop()
            )
          );
        });

        const newSelections = selectedGoals.filter(
          (href) => !currentSelections.some((sel) => sel.href === href)
        );
        if (newSelections) {
          this.$ngRedux.dispatch(addResourceAncestorsToLoad({ resources: newSelections }));
          newSelections.forEach(async (selection) => {
            this.$ngRedux.dispatch(
              DOCUMENT_ACTIONS.addRelationAction({
                relationtype: 'REFERENCES',
                from: {
                  href: `/content/${this.sDocument.key}`,
                },
                to: {
                  href: selection,
                },
                strength: 'LOW',
              })
            );
          });
        }
      } catch (error) {
        if (error === 'ZILL_SELECTOR_ABORTED') {
          console.log('The user closed the zill selector manually.');
        } else {
          console.error(
            'An unexpected error occured when communicating with the Zill selector',
            error
          );
        }
      }
    }
  }

  async deleteIllustration(goal) {
    // delete illustration and relation to document
    const confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      if (goal.subDetails.length) {
        goal.subDetails.forEach((subDetail) => {
          this.$ngRedux.dispatch(
            DOCUMENT_ACTIONS.removeRelationAction(
              subDetail.relationFromIllustrationHref.split('/').pop()
            )
          );
        });
      } else {
        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.removeRelationAction(goal.relationFromIllustrationHref.split('/').pop())
        );
      }
    }
  }
}

export default {
  template: require('./asideGoalsByZillIllustrations.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideGoalsByZillIllustrations,
};
