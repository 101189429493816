/* eslint-disable max-len */

const containerType = new Map();
containerType.set('MINI_DATABASE', ['SECTION', 'SHARED_MINI_DATABASE_ITEM']);
containerType.set('DOWNLOAD_PAGE', ['ATTACHMENTS_GROUP', 'SHARED_ATTACHMENTS_GROUP']);
containerType.set('BLOG', ['SECTION']);
containerType.set('HOME_PAGE', ['SECTION']);
containerType.set('FALLBACK_MENU_LEVEL_2', ['SECTION']);
containerType.set('FALLBACK_MENU_LEVEL_3', ['SECTION']);

function convertMapToArrayOfJsonObject(map, websitesReferenceFramesMap) {
  const response = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [href, title] of map) {
    const item = {
      href,
      title,
      choices: [], // TODO where we get this choices?
      themeGroup: {
        selected: [],
      },
    };

    if (websitesReferenceFramesMap && websitesReferenceFramesMap.has(href)) {
      item.themeGroup.selected = websitesReferenceFramesMap
        .get(href)
        .filter((value) => {
          const deleteProposal = !(value.$$expanded || value).deleteProposal;
          if (deleteProposal) {
            item.themeGroup.hasProposal = true;
          }
          return deleteProposal;
        })
        .map((value) => {
          return value.$$expanded || value;
        });
    }

    response.push(item);
  }

  return response;
}

function typeHasFacetsForContainer(template, container) {
  if (template && containerType.has(template.code)) {
    const allowedTypes = containerType.get(template.code);
    return allowedTypes.includes(container);
  }
  return false;
}

function typeHasFacets(template) {
  return template && template.options && template.options.length > 0;
}

export const getReferenceFramesFacet = (configurations, type, websitesReferenceFramesMap) => {
  const referenceFrames = new Map();
  const configurationsWithFacets = configurations.filter((configuration) => {
    return typeHasFacetsForContainer(configuration.template, type);
  });

  configurationsWithFacets.forEach((config) => {
    const referenceFrameFacets =
      config.options && config.options.facets
        ? config.options.facets.filter((facet) => {
            return facet.component === 'SELECT_FROM_REFERENCE_FRAME';
          })
        : [];

    referenceFrameFacets.forEach((referenceFrameFacet) => {
      const href = referenceFrameFacet.source.$$meta
        ? referenceFrameFacet.source.$$meta.permalink
        : referenceFrameFacet.source.href || `/content/${referenceFrameFacet.source.key}`;
      referenceFrames.set(href, referenceFrameFacet.source.title);
    });
  });

  return convertMapToArrayOfJsonObject(referenceFrames, websitesReferenceFramesMap);
};

export const isAgeRangeFacetRequired = (configurations) => {
  const configurationsWithFacets = configurations.filter((configuration) => {
    return typeHasFacets(configuration.template);
  });

  const configurationsWithAgeRangeSliders = configurationsWithFacets.filter((config) => {
    const ageRangeSliders = config.options.facets.filter((facet) => {
      return facet.component === 'AGE_RANGE_SLIDER';
    });

    return ageRangeSliders.length > 0;
  });

  return configurationsWithAgeRangeSliders.length > 0;
};

export const hasGoalSelectorFacet = (configurations) => {
  const configurationsWithFacets = configurations.filter((configuration) => {
    return typeHasFacets(configuration.template);
  });

  const configurationsWithAGoalSelector = configurationsWithFacets.filter((config) => {
    const zillGoals = config.options.facets.filter((facet) => {
      return facet.component === 'ZILL_GOAL_SELECTOR';
    });

    return zillGoals.length > 0;
  });

  return configurationsWithAGoalSelector.length > 0;
};
