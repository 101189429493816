import { ContentHref } from '@generalTypes/apiTypes';

export const referenceFrames = {
  pro: '/content/dfa63c53-a63c-4f61-90bd-6ab13644c538' as ContentHref, // = dienstverling KathOndVla
  educationalPointers: '/content/8f39910a-5db7-4306-9443-74a318aba0da' as ContentHref, // llinkid
  educationalComponents: '/content/8669afe3-aa83-437b-b624-742e93e8de5a' as ContentHref, // llinkid
} as const;

export const referenceFrameLabels = {
  [referenceFrames.educationalPointers]: 'Wegwijzers',
  [referenceFrames.educationalComponents]: 'Vormingscomponenten',
} as const;

function swapKeysAndValues(obj: Record<string, string>): Record<string, string> {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));
}

export const referenceFramesByHref = swapKeysAndValues(referenceFrames);
