import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';

const PARAGRAPH: BuildingBlockNodeConfig = {
  information: {
    single: 'Alinea',
    plural: "Alinea's",
    ribonIcon: require('../../../../img/buildingBlocks/PARAGRAPH.svg'),
  },

  createDefaults: {
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html',
      },
    ],
  },
  buildingBlocks: [],
  edit: [
    {
      component: 'attachedContent',
      label: 'Inhoud',
      property: '$$html',
      hideInAncestorTypes: [
        NodeType.PRONEWSLETTER,
        NodeType.LLINKID_CURRICULUM,
        NodeType.LLINKID_FOUNDATIONAL_CURRICULUM,
        NodeType.PRO_STATIC_PAGE,
        NodeType.WWW_STATIC_PAGE,
      ],
    },
    {
      component: 'attachedContent',
      label: 'Inhoud',
      property: '$$html',
      showInAncestorTypes: [NodeType.PRONEWSLETTER],
      options: {
        hideTermButton: true,
      },
    },
    {
      component: 'attachedContent',
      label: 'Inhoud',
      property: '$$html',
      showInAncestorTypes: [NodeType.LLINKID_CURRICULUM, NodeType.LLINKID_FOUNDATIONAL_CURRICULUM],
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'staticHtml',
      reactComponent: 'AsideStaticHtml',
      label: 'Inhoud',
      property: '$$html',
      showInAncestorTypes: [NodeType.PRO_STATIC_PAGE, NodeType.WWW_STATIC_PAGE],
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Afbeelding',
      options: {
        type: 'ILLUSTRATION',
        fields: ['alt'],
      },
      hideInAncestorTypes: [
        NodeType.LLINKID_STATIC_PART,
        NodeType.TERM,
        NodeType.PRONEWSLETTER,
        NodeType.PRONEWSLETTERTEMPLATE,
      ],
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'html',
  },
};

export default PARAGRAPH;
