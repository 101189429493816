import { EditAsideIssuedDatePicker } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import 'moment/locale/nl-be';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getResourceKey } from '@store/helpers/documentHelpers';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectIsPublishedEditable } from '@newStore/documentUI/documentUISelectors';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import DatePickerElement from './DatePicker';
import { convertDateToUTCISOString } from './dateHelper';

const AsideIssuedDatePicker: React.FC<{
  config: EditAsideIssuedDatePicker;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const editHref = useEditHref();

  const isUserAllowedToEditIssuedDate = useSelector(
    (state: RootState) => state.documentUI.mode === 'SUGGEST' || selectIsPublishedEditable(state)
  );

  const currentValue = useInitialValue(config.property);

  const currentDate = currentValue ? new Date(currentValue) : null;

  const change = (value: Date | null) => {
    dispatch(
      patchNodeAction(
        getResourceKey(editHref),
        { [config.property]: value ? convertDateToUTCISOString(value) : undefined },
        true,
        null,
        false
      )
    );
  };
  return (
    <DatePickerElement
      config={config}
      value={currentDate}
      onChange={change}
      readOnly={!isUserAllowedToEditIssuedDate}
    />
  );
};

AsideIssuedDatePicker.displayName = 'AsideIssuedDatePicker';

export default AsideIssuedDatePicker;

AsideIssuedDatePicker.propTypes = {
  config: any,
};
