import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectLoadedContentHrefsSet,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import {
  selectIsNodeReadOnly,
  selectIsSomeParentSelected,
  selectIsNodeDeletable,
  selectIsRoot,
} from '@newStore/documentUI/documentUISelectors';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';

export const selectShowSelectBox = (
  state: RootState,
  href: ContentHref,
  parentHref: ContentHref
) => {
  return (
    (!selectIsNodeReadOnly(state, href) || !selectRawContentItem(state, href)) &&
    !selectIsSomeParentSelected(state, href, parentHref) &&
    selectIsNodeDeletable(state, href)
  );
};

export const selectShouldDocumentShowCollapseAll = createTypedSelector(
  [(state) => selectLoadedContentHrefsSet(state)],
  (loadedContent) => loadedContent.size < 1000
);

export const selectIsNodeDraggable = createTypedSelector(
  [
    (state, href: ContentHref, _parentHref: ContentHref) => selectIsNodeReadOnly(state, href),
    (state, href: ContentHref, _parentHref: ContentHref) => selectAppliedNodeConfig(state, href),
    (state, href: ContentHref, _parentHref: ContentHref) => selectIsRoot(state, href),
    (state, href: ContentHref, parentHref: ContentHref) =>
      selectIsSomeParentSelected(state, href, parentHref),
  ],
  (isNodeReadOnly, nodeTypeConfig, isRoot, isSomeParentSelected) => {
    if (isNodeReadOnly || isRoot || isSomeParentSelected) {
      return false;
    }

    if ('isNotDraggable' in nodeTypeConfig && nodeTypeConfig.isNotDraggable === true) {
      return false;
    }

    return true;
  }
);
