/* eslint-disable func-names */
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class asidePracticalExampleReferences {
  constructor(ModalWindowService, ConfirmationService, $scope, $ngRedux) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const relations = (
        state.document.apiWithPendingChanges.contentRelations.from[
          `/content/${this.sDocument.key}`
        ] || []
      ).filter(
        (relation) =>
          relation.relationtype === 'REFERENCES' &&
          relation.to.$$expanded &&
          relation.to.$$expanded.tags &&
          relation.to.$$expanded.tags.includes('PRACTICAL_EXAMPLE')
      );

      return {
        references: relations,
        loading: false,
      };
    })(this);

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.expandRelationsFromAction(this.sDocument.key, null, true)
    );
  }

  $onDestroy() {
    this.unsubscribe();
  }

  async addPracticalExample() {
    const opts = {
      component: 'documentSelectorModal',
      dataForModal: {
        type: 'practicalExample',
        stateField: 'practicalExamples',
        params: {
          tagsIn: 'PRACTICAL_EXAMPLE',
          orderBy: 'issued',
          descending: true,
        },
      },
    };

    const practicalExample = await this.modalWindowService.open(opts);

    if (practicalExample) {
      const newRelation = {
        relationtype: 'REFERENCES',
        strength: 'MEDIUM',
        from: {
          href: `/content/${this.sDocument.key}`,
        },
        to: {
          href: practicalExample.$$meta.permalink,
          $$expanded: practicalExample,
        },
      };

      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation));
    }
  }

  async deleteRelation(relation) {
    // delete relation to practical example
    const confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(relation.key));
    }
  }
}

export default {
  template: require('./asidePracticalExampleReferences.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asidePracticalExampleReferences,
};
