import { ExtendedRootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import {
  publishComponent,
  titleComponent,
  authorsComponent,
} from '@nodeTypeConfig/helpers/genericComponents';

import { proThemeAudienceTab } from '../proGenericConfigProps';
// Extends WEBPAGE2 => When adding HOME_PAGE WebConfig to a WEBPAGE2 it becomes "the" (there is only one) PRO_HOME_PAGE
// technically also a Pro Theme because it is tag WEBPAGE2, but actually the Pro Menu which is not a Pro Theme Page and something totally different
export const PRO_HOME_PAGE: ExtendedRootNodeConfig = {
  information: {
    description:
      'The Pro Home page: just one page which is the actual navigation/menu structure for PRO',
    single: 'Pro Home Pagina',
    plural: 'Pro Home Pagina',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: [NodeType.PRO_MENU_LEVEL_2, NodeType.PRO_MENU_LEVEL_3, NodeType.PRO_MENU_SECTION],
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    titleComponent,
    {
      component: 'image',
      property: 'attachments',
      width: '6',
      label: 'Afbeelding voor Social media',
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: ['alt'],
      },
    },
    {
      ...authorsComponent,
      label: 'Themaverantwoordelijke',
    },
    publishComponent,
  ],
  audienceTab: proThemeAudienceTab,
};
