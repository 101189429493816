import { ExtendedBuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import {
  authorsComponent,
  identifierComponent,
  importanceComponent,
  titleComponent,
} from '../../helpers/genericComponents';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import {
  proContactsComponent,
  proDefaultBuildingBlocks,
  proLinksAndMenuReferences,
  proThumbnailComponent,
  proWebFacetComponent,
} from '../proGenericConfigProps';
// Extends SECTION => A SECTION inside a Pro Theme and webConfig MINI_DATABASE_ITEM becomes a PRO_DATABASE_ITEM
const PRO_BLOG_ITEM: ExtendedBuildingBlockNodeConfig = {
  information: {
    definiteArticle: true,
    description: 'A Blog Item, can only appear as child of a blog',
    single: 'Blog item',
    plural: 'Blog Items',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: true,
  buildingBlocks: proDefaultBuildingBlocks,
  edit: [
    {
      ...identifierComponent, // can go if we work on Aside (confirmed by Isabelle)
      label: 'Prefix',
      width: '2',
    },
    {
      ...titleComponent,
      width: '10',
      label: 'Titel',
    },
    {
      ...importanceComponent,
      width: '6',
    },
    {
      ...authorsComponent,
      width: '6',
    },
    {
      ...proContactsComponent,
      width: '12',
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: true },
    },
    {
      ...proThumbnailComponent,
      width: '6',
    },
    ...proLinksAndMenuReferences,
    proWebFacetComponent,
  ],
  confirmDelete: true,
};

export default PRO_BLOG_ITEM;
