import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

import './rowLlinkidExtra.scss';

class rowLlinkidExtra {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.icon = require('../../../../img/icon_extra.svg');
  }

  saveInline(scope, event, editorData) {
    const patch = {
      [editorData.field]: editorData.value,
    };
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(editorData.key, patch));
  }
}

export const rowLlinkidExtraComponent = {
  template: require('./rowLlinkidExtra.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
  },
  controller: rowLlinkidExtra,
};
