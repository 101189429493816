import { RootState } from '@generalTypes/rootStateTypes';
import { EditAsideDateToSendDatePicker } from '@nodeTypeConfig/configTypes';
import { patchDateToSendAction } from '@store/actions/documentActions';
import 'moment/locale/nl-be';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createTypedSelector } from '@newStore/genericHelpers';
import { selectApiWithPendingChanges } from '@newStore/documentApi/documentApiSelectors';
import DatePickerElement from './DatePicker';
import { convertDateToUTCISOString } from './dateHelper';

const selectDateToSendValue = createTypedSelector(
  [(state: RootState) => selectApiWithPendingChanges(state).newsletterSettings?.dateToSend],
  (dateToSend) => {
    return dateToSend ? new Date(dateToSend) : null;
  }
);

const AsideDateToSendPicker: React.FC<{
  config: EditAsideDateToSendDatePicker;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const initialValue = useSelector(selectDateToSendValue);

  const change = (value: Date | null) => {
    dispatch(patchDateToSendAction(value ? convertDateToUTCISOString(value) : undefined));
  };

  return <DatePickerElement config={config} value={initialValue} onChange={change} />;
};

AsideDateToSendPicker.displayName = 'AsideDateToSendPicker';

export default AsideDateToSendPicker;

AsideDateToSendPicker.propTypes = {
  config: any,
};
