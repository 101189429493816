exports.__esModule = true;

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

exports.batchedSubscribe = batchedSubscribe;

let subscribeImmediate;

function batchedSubscribe(batch) {
  if (typeof batch !== 'function') {
    throw new Error('Expected batch to be a function.');
  }

  let currentListeners = [];
  let nextListeners = currentListeners;

  function ensureCanMutateNextListeners() {
    if (nextListeners === currentListeners) {
      nextListeners = currentListeners.slice();
    }
  }

  function subscribe(listener) {
    if (typeof listener !== 'function') {
      throw new Error('Expected listener to be a function.');
    }

    /**
     * This is the only change from the original code.
     * since the debouncing of the notify breaks some logic in the angularjs components of redactie,
     * we decided to exclude it from the debounce functionality and still get the benefits for react.
     */
    if (listener.toString().includes('@@NGREDUX')) {
      return subscribeImmediate(listener);
    }

    let isSubscribed = true;

    ensureCanMutateNextListeners();
    nextListeners.push(listener);

    return function unsubscribe() {
      if (!isSubscribed) {
        return;
      }

      isSubscribed = false;

      ensureCanMutateNextListeners();
      const index = nextListeners.indexOf(listener);
      nextListeners.splice(index, 1);
    };
  }

  function notifyListeners() {
    const listeners = (currentListeners = nextListeners);
    for (let i = 0; i < listeners.length; i++) {
      listeners[i]();
    }
  }

  function notifyListenersBatched() {
    batch(notifyListeners);
  }

  return function (next) {
    return function () {
      const store = next.apply(undefined, arguments);
      subscribeImmediate = store.subscribe;

      function dispatch() {
        const res = store.dispatch.apply(store, arguments);
        notifyListenersBatched();
        return res;
      }

      return {
        ...store,
        dispatch,
        subscribe,
        subscribeImmediate,
      };
    };
  };
}
