import { RootState } from '@generalTypes/rootStateTypes';
import { selectValidationInfoForNodeAside } from '@newStore/validation/validationSelectors';
import { EditComponentProperty } from '@nodeTypeConfig/configTypes';
import React from 'react';
import { useSelector } from 'react-redux';
import './AsideValidation.scss';

export type AsideValidationProps = {
  property: EditComponentProperty | undefined;
  component: string;
  children: React.ReactNode;
};

const AsideValidation: React.FC<AsideValidationProps> = ({ property, component, children }) => {
  const errors = useSelector((state: RootState) =>
    selectValidationInfoForNodeAside(state, component, property)
  );

  return (
    <div className="form-group">
      {children}
      {/* // below is copied from ValidationErrors.tsx */}
      {errors.validationErrors.map((error) => (
        <div key={error.code} className={`error-message type-${error.type.toLowerCase()}`}>
          <div className="error-message-content">
            <span dangerouslySetInnerHTML={{ __html: error.message }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AsideValidation;
