import { sriMeta } from './sriTypes';

export type TrainingModuleHref = `/training/modules/${string}`;

export type TrainingModule = {
  $$meta: sriMeta & { permalink: TrainingModuleHref; type: 'MODULE' };
  /**
   * unique key of this event (guid)
   */
  key: string;
  /**
   * A human readable code for this module.
   */
  identifier: string;
  /**
   * The title of this module.
   */
  title: string;
  /**
   * list of types
   */
  types?: (
    | 'INDIVIDUGERICHT'
    | 'TEAMGERICHT'
    | 'CONGRES'
    | 'DAGENVAN'
    | 'LERENDNETWERK'
    | 'LEERPAD'
    | 'MEERDAAGSE'
    | 'NETWERKDAG'
  )[];
  /**
   * The motivation for this module.
   */
  motivation?: string;
  /**
   * Textual description of the prerequisites for taking this training.
   */
  qualifications?: string;
  /**
   * a textual description of the goals to be reached.
   */
  targets?: string;
  /**
   * Textual description of the training. A textual description of what this course will teach to its participants.
   */
  content: string;
  /**
   * A textual description of the strategies and methods used in this module.
   */
  strategies?: string;
  /**
   * Extra information concerning the module.
   */
  extraInfo?: string;
  /**
   * textual description of extra terms expected of the participant/team.
   */
  terms?: string;
  /**
   * A textual, more precise description of the target audience for this module.
   */
  audiencesDescription?: string;
  /**
   * list of audiences. It defines the (primary) target audiences for whom this module is intended.
   */
  audiences?: {
    /**
     * permalink of an audience
     */
    href: string;
  }[];
  /**
   * List of organiser hrefs (persons/OUs/...) who organise the module
   */
  organisers?: {
    href: string;
  }[];
  /**
   * list of themes from Content API
   */
  legacyThemes?: {
    /**
     * permalink of a theme
     */
    href: string;
  }[];
  /**
   * Organisational unit types (such as SCHOOL, GOVERNING_INSTITUTION) for whom this training is intended.
   */
  outypes?: unknown[];
  /**
   * Education levels of the training.
   */
  mainstructures?: unknown[];
  /**
   * Mainstructures outype combination of the training.
   */
  mainstructuresOuTypeCombinations?: unknown[];
  /**
   * Tells us for which regions this training is relevant.
   */
  coverage?: unknown[];
  /**
   * Tells us for themes in the reference frame this training is relevant.
   */
  themes?: unknown[];
  /**
   * References to /positions or /namedsets to indicate for which functions/roles this training is intended.
   */
  positions?: unknown[];
  /**
   * boolean showing if the module is private.
   */
  private?: boolean;
  /**
   * List of organisational units for whom the module is customized/created.
   */
  customers?: {
    href: string;
  }[];
  sourceModule?: {
    /**
     * permalink to the module on which this module is based.
     */
    href: string;
  };
  conditions: {
    /**
     * how many days before start you can subscribe to the course
     */
    subscribePerdiodInDays: number;
    /**
     * how many days before start you can unsubscribe to the course
     */
    unsubscribePeriodInDays: number;
    /**
     * the minimum amount of persons per OU that have to subscribe for this course
     */
    minimumPersonsPerOU: number;
    /**
     * the maximum amount of persons per OU that can subscribe for this course
     */
    maximumPersonsPerOU: number;
  };
  /**
   * How many sessions (/events) the training takes to complete.
   */
  sessionCount?: number;
  /**
   * published and unpublished date.
   */
  issued?: {
    /**
     * Date on which this item must be published.
     */
    startDate?: string;
    /**
     * Date on which this item must be unpublished.
     */
    endDate?: string;
  };
  /**
   * a date when enrollment can start (and end).
   */
  enrollmentPeriod?: {
    /**
     * Date on which this item must be published.
     */
    startDate?: string;
    /**
     * Date on which this item must be unpublished.
     */
    endDate?: string;
  };
};

export const isTrainingModule = (obj: any): obj is TrainingModule => {
  return obj?.$$meta?.permalink.startsWith('/training/modules/');
};
