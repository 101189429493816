import { ContentType } from '@generalTypes/apiTypes';
import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';

const LLINID_INITIAL_SITUATION: BuildingBlockNodeConfig = {
  information: {
    single: 'Beginsituatie',
    plural: 'Beginsituaties',
  },

  edit: [
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
      required: RequiredType.WARNING,
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'goalRelations',
      reactComponent: 'AsideGoalRelations',
      label: 'Beginsituatie',
      options: {
        relationTypes: ['REFERENCES'],
        modal: 'llinkidGoalRelationsModal',
        listColumn: 'description',
        modalTitle: 'Specifieke leerplandoelen beginsituatie',
        toType: ContentType.LLINKID_GOAL,
      },
    },
    {
      component: 'previousVersionItems',
      reactComponent: 'AsidePreviousVersionItems',
      label: 'Vervangt (uit vorige versie)',
      options: {
        relationTypes: ['REPLACES'],
        modal: 'llinkidPreviousVersionModal',
        modalTitle: 'Vervangt (uit vorige versie)',
        listColumn: 'description',
        placeholder: 'Kies een te vervangen beginsituatie',
        relation: 'Beginsituatie',
        errorMessage: 'Je moet een geldige beginsituatie selecteren',
        type: ContentType.LLINKID_INITIAL_SITUATION,
      },
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'description',
  },
};

export default LLINID_INITIAL_SITUATION;
