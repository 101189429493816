import { ImageGroupNode } from '@newStore/documentUI/documentUITypes';

const ImageGroupRow: React.FC<{ contentNode: ImageGroupNode }> = ({ contentNode }) => {
  return (
    <div className="images-group">
      {contentNode.images.map((img) => (
        <div
          className={`image ${img.isDeletedByProposal ? 'deleted-hidden-child' : ''}`}
          key={img.key}
        >
          <img
            src={img.src}
            style={{ marginBottom: '10px', maxWidth: '100%', maxHeight: '400px' }}
          />
        </div>
      ))}
    </div>
  );
};

export default ImageGroupRow;
