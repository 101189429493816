import './hasChangesIndicator.scss';

class hasChangesIndicator {
  constructor($scope, $translate, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$translate = $translate;
  }

  $onInit() {}

  $onChanges() {}

  lastModificationText() {
    return `${this.$translate.instant('lastRead.lastModification')} ${
      this.sModification.lastModification
    }`;
  }

  hasChangesLineStyle() {
    if (!this.sProposal && this.sItem) {
      this.sProposal = this.sItem.proposal;
    }

    if (this.sProposal) {
      const line = this.sProposal.isSubmitted ? 'submitted' : 'not-submitted';
      let color = this.sProposal.isSameUser ? 'same-user' : 'different-user';
      if (this.sProposal.isReviewingMode) {
        color = 'reviewing';
      }
      const isDeleted = this.sProposal.type === 'DELETE' ? ' is-deleted' : '';
      const isNew = this.sProposal.isNew && this.sProposal.isReviewingMode ? ' is-new' : '';
      const manyCreators = this.sProposal.hasManyCreators ? ' many-creators' : '';
      return `${line} ${color}${isDeleted}${manyCreators}${isNew}`;
    }
    return '';
  }
}

export const hasChangesIndicatorComponent = {
  transclude: true,
  template: require('./hasChangesIndicator.html'),
  controllerAs: 'ctrl',
  bindings: {
    sModification: '<?',
    sScreen: '@?',
  },
  controller: hasChangesIndicator,
};
