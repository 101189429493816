import { RootState } from '@generalTypes/rootStateTypes';
import { selectEditConfigForNode } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectChildren,
} from '@newStore/documentApi/documentApiSelectors';
import { selectDeletedProposalFlags } from '@newStore/documentUI/transformProposal/proposalSelectors';
import { ContentHref, ContentType, isReferencesRelation } from '@generalTypes/apiTypes';
import { EditComponentProperty } from '../../config/nodeTypeConfigurations/configTypes';

export const selectAsideConfigForProperty = (
  state: RootState,
  component: string,
  property: EditComponentProperty
) => {
  if (!state.documentUI.currentEditingNode) {
    throw new Error('No currentEditingNode');
  }

  const editConfig = selectEditConfigForNode(state, state.documentUI.currentEditingNode);

  const configsForComponent = editConfig.filter((z) => z.component === component);

  //   if (component === 'validityPeriod') {
  //     debugger;
  //   }

  if (configsForComponent.length === 0) {
    // console.warn(`No config found for component ${component}`);
    throw new Error(`No config found for component ${component}`);
  }

  const [configForProperty, secondConfig] = configsForComponent.filter(
    (z) => z.property === property
  );

  if (secondConfig) {
    console.warn(`Multiple configs found for component ${component} and property ${property}`);
  }

  if (!configForProperty) {
    // console.warn(`No config found for component ${component} and property ${property}`);
    throw new Error(`No config found for component ${component} and property ${property}`);
  }

  return configForProperty;
};

export const selectAsideLinks = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectApiWithPendingChanges(state).content,
    (state, href: ContentHref) => selectChildren(state, href),
    (state) => selectDeletedProposalFlags(state),
    (state, href: ContentHref) => href,
  ],
  (relationsMap, content, children, deletedProposalFlags, href) => {
    const linkGroupHref = children.find((c) => c.type === 'LINK_GROUP')?.$$meta.permalink || href;

    return relationsMap.to[linkGroupHref].flatMap((relation) => {
      const contentLink = content[relation.from.href];
      if (relation.relationtype === 'IS_PART_OF' && contentLink.type === ContentType.REFERENCE) {
        const referencesRelation = relationsMap.from[contentLink.$$meta.permalink].find((rel) =>
          isReferencesRelation(rel)
        );
        return {
          key: contentLink.key,
          title: contentLink.title,
          href: referencesRelation?.to.href,
          deletedProposalFlags: deletedProposalFlags[contentLink.key],
        };
      }
      return [];
    });
  }
);
