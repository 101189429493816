import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectValidationInfoForNode } from '@newStore/validation/validationSelectors';
import { useSelector } from 'react-redux';
import ValidationIndicator from './ValidationIndicator';

const ValidationIndicatorForDoc: React.FC<{
  href: ContentHref;
  parentHref: ContentHref | undefined;
}> = ({ href, parentHref }) => {
  const validation = useSelector((state: RootState) =>
    selectValidationInfoForNode(state, href, parentHref)
  );

  // const dispatch = useDispatch();

  // const $state = getAngularService('$state');

  // const scrollToAndOpenAside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  //   if (validation.validationErrors[0].node.href === href) {
  //     const key = href.split('/').pop();
  //     $state.go('edit.aside', { editKey: key });
  //     dispatch(updateAsideViewModelAction(key));
  //   }
  // };

  if (!validation?.validationErrors?.length) {
    return null;
  }

  return (
    <ValidationIndicator
      href={href}
      status={validation.status}
      validationErrors={validation.validationErrors}
      // onIconClick={scrollToAndOpenAside}
    ></ValidationIndicator>
  );
};

export default ValidationIndicatorForDoc;
