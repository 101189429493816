import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import React from 'react';
import { useSelector } from 'react-redux';

export const AsideSourceItem: React.FC<{ href: ContentHref }> = ({ href }) => {
  const item = useSelector((state: RootState) => selectContentItem(state, href));

  return (
    <div className="col-md-12 indented">
      <span dangerouslySetInnerHTML={{ __html: item?.title as string }}></span>
    </div>
  );
};
