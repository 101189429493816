import React from 'react';

import './dropZone.scss';

const DropZone = ({ visible = false, onHover = false, children }) => {
  if (!visible) {
    return null;
  }
  return <div className={`drop-zone ${onHover && 'on-hover'}`}>{children}</div>;
};

export default DropZone;
