module.exports = [
  '$q',
  'BackendManager',
  'settings',
  'Utils',
  function ($q, BackendManager, settings, Utils) {
    //eslint-disable-line
    const that = {};

    that.insertRelationsInBatchTo = function (relations, relationType, document, batch) {
      if (document.isNew && !document.$$relationsTo) {
        document.$$relationsTo = [];
      }

      const newRelations = relations.map((relationHref) => {
        const key = relationHref.split('/').slice(-1)[0];
        return that.createRelation(key, document.key, relationType);
      });

      newRelations.forEach((relation) => {
        batch.put(relation.$$meta.permalink, relation);
        document.$$relationsTo.push({
          href: relation.$$meta.permalink,
          $$expanded: {
            to: {
              href: relation.to.href,
            },
            from: {
              href: relation.from.href,
            },
            relationtype: relation.relationtype,
            key: relation.key,
          },
        });
      });
    };

    /**
     * @deprecated in a near future, in all redactie we should use insertRelationsInBatchTo
     */
    that.insertRelationsTo = async function (relations, relationType, document) {
      const promises = [];

      relations.forEach((href) => {
        const key = href.split('/').slice(-1)[0];
        promises.push(that.putRelation(key, document.key, relationType));
      });

      const values = await Promise.all(promises);

      if (document.isNew && !document.$$relationsTo) {
        document.$$relationsTo = [];
      }

      values.forEach((relResult) => {
        const resource = relResult.resource ? relResult.resource : relResult;
        document.$$relationsTo.push({
          href: `${settings.resourcesNames.relations}/${resource.key}`,
          $$expanded: {
            to: {
              href: resource.to.href,
            },
            from: {
              href: resource.from.href,
            },
            relationtype: relationType,
            key: resource.key,
          },
        });
      });
    };

    that.insertRelationsInBatchFrom = function (relations, relationType, document, batch) {
      if (document.isNew && !document.$$relationsFrom) {
        document.$$relationsFrom = [];
      }

      const newRelations = relations.map((relationHref) => {
        const key = relationHref.split('/').slice(-1)[0];
        return that.createRelation(document.key, key, relationType);
      });

      newRelations.forEach((relation) => {
        batch.put(relation.$$meta.permalink, relation);
        document.$$relationsFrom.push({
          href: relation.$$meta.permalink,
          $$expanded: {
            to: {
              href: relation.to.href,
            },
            relationtype: relation.relationtype,
            key: relation.key,
          },
        });
      });
    };

    /**
     * @deprecated in a near future, in all redactie we should use insertRelationsInBatchFrom
     */
    that.insertRelationsFrom = async function (relations, relationType, document) {
      const promises = [];

      relations.forEach((href) => {
        const key = href.split('/').slice(-1)[0];
        promises.push(that.putRelation(document.key, key, relationType));
      });

      const values = await Promise.all(promises);

      if (document.isNew && !document.$$relationsFrom) {
        document.$$relationsFrom = [];
      }

      values.forEach((relResult) => {
        const resource = relResult.resource ? relResult.resource : relResult;
        document.$$relationsFrom.push({
          href: `${settings.resourcesNames.relations}/${resource.key}`,
          $$expanded: {
            to: {
              href: resource.to.href,
            },
            relationtype: relationType,
            key: resource.key,
          },
        });
      });
    };

    // NOTE: relationsHref is actually an array of href of content resources
    that.removeRelationsInBatchTo = function (relationsHref, relations, batch) {
      relationsHref.forEach((href) => {
        const relationToBeRemoved = relations.find((relation) => {
          return relation.$$expanded.to.href === href;
        });

        if (relationToBeRemoved) {
          batch.delete(relationToBeRemoved.href);
        }
      });
    };

    /**
     * @deprecated in a near future, in all redactie we should use removeRelationsInBatchTo
     */
    that.removeRelationsTo = async function (relationsHref, relations) {
      const promises = [];

      relationsHref.forEach((href) => {
        const relationToBeRemoved = relations.find((relation) => {
          return relation.$$expanded.to.href === href;
        });

        if (relationToBeRemoved) {
          promises.push(that.deleteRelation(relationToBeRemoved.$$expanded.key));
        }
      });

      await Promise.all(promises);
    };

    that.removeRelationsInBatchFrom = function (relationsHref, relations, batch) {
      relationsHref.forEach((href) => {
        const relationToBeRemoved = relations.find((relation) => {
          return relation.$$expanded.from.href === href;
        });

        if (relationToBeRemoved) {
          batch.delete(relationToBeRemoved.href);
        }
      });
    };

    /**
     * @deprecated in a near future, in all redactie we should use removeRelationsInBatchFrom
     */
    that.removeRelationsFrom = async function (relationsHref, relations) {
      const promises = [];

      relationsHref.forEach((href) => {
        const relationToBeRemoved = relations.find((relation) => {
          return relation.$$expanded.from.href === href;
        });

        if (relationToBeRemoved) {
          promises.push(that.deleteRelation(relationToBeRemoved.$$expanded.key));
        }
      });

      await Promise.all(promises);
    };

    that.createRelation = function (fromKey, toKey, type, readorder, strength, key) {
      key = key || Utils.getUUID();

      const relation = {
        $$meta: {
          permalink: `${settings.resourcesNames.relations}/${key}`,
        },
        key,
        from: {
          href: `${settings.resourcesNames.content}/${fromKey}`,
        },
        to: {
          href: `${settings.resourcesNames.content}/${toKey}`,
        },
        relationtype: type || 'IS_PART_OF',
        strength: strength || 'MEDIUM',
      };

      if (readorder) {
        relation.readorder = readorder;
      }

      return relation;
    };

    that.createRelationToExternalSource = function (fromKey, externalSourceUrl, type) {
      const relation = that.createRelation(fromKey, fromKey, type);
      relation.toExternal = {
        href: externalSourceUrl,
      };

      return relation;
    };

    that.putRelation = function (fromKey, toKey, type, readorder, key, strength) {
      const d = $q.defer();
      const relation = that.createRelation(fromKey, toKey, type, readorder, strength, key);

      BackendManager.putResource(relation).then(function (response) {
        if (response.status < 400) {
          d.resolve(relation);
        } else {
          d.reject(response);
        }
      });

      return d.promise;
    };

    that.deleteRelation = function (key) {
      return BackendManager.deleteResource(`${settings.resourcesNames.relations}/${key}`);
    };

    return that;
  },
];
