import { useCallback } from 'react';
import { Popover, Overlay } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectContentTermNodes } from '@newStore/documentUI/documentUISelectors';
import useEditorPopOver from './useEditorPopOver';

const TermPopover = ({ editor }) => {
  const terms = useSelector(selectContentTermNodes);
  const findItem = useCallback(
    (target: HTMLElement) => terms.find((term) => term.href === target.getAttribute('href')),
    [terms]
  );
  const popOverData = useEditorPopOver(findItem, 'a[rel="term"]', editor);

  return (
    popOverData.target && (
      <Overlay show={true} target={popOverData.target} placement="top" containerPadding={20}>
        <Popover id={editor?.id} title="Term">
          <div dangerouslySetInnerHTML={{ __html: popOverData.content }} />
        </Popover>
      </Overlay>
    )
  );
};

export default TermPopover;
