import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { identifierComponent, titleComponent } from '@nodeTypeConfig/helpers/genericComponents';

const CURRICULUM_ZILL_DEVELOPMENT_FIELD: BuildingBlockNodeConfig = {
  information: {
    single: 'Veld',
    plural: 'Velden',
  },
  createDefaults: {
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html',
      },
    ],
  },
  buildingBlocks: [{ type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_THEME }],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Omschrijving',
    },
    {
      component: 'attachedContent',
      property: '$$html',
      label: 'Lees meer',
      view: {
        hideIfEmpty: true,
      },
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Ikoon',
      options: {
        type: 'ICON',
        fields: [],
      },
    },
    {
      component: 'color',
      property: 'color',
      reactComponent: 'AsideColor',
      label: 'Kleur',
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        fields: [],
      },
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Miniatuur',
      options: {
        type: 'THUMBNAIL',
      },
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Achtergrond',
      options: {
        type: 'BACKGROUND_IMAGE',
        fields: [],
      },
    },
    {
      component: 'video',
      property: 'attachments',
      label: 'Video',
      options: {
        type: 'VIDEO',
      },
    },
  ],
  documentViewIdentifier: 'i',
  isCollapsible: true,
  isCollapsedByDefault: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ZILL_DEVELOPMENT_FIELD;
