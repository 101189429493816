import { selectContentTermNodes } from '@newStore/documentUI/documentUISelectors';
import { useSelector } from 'react-redux';

import './termsList.scss';

const TermsList: React.FC = () => {
  const terms = useSelector(selectContentTermNodes);
  return (
    terms.length > 0 && (
      <div id="termsList">
        <div className="title">Begrippen</div>
        <div className="content-row no-border">
          {terms.map((term) => (
            <div key={term.href} id={term.href}>
              <a
                target="_blank"
                href={term.redactieLink}
                className="content-row-title"
                rel="noreferrer"
              >
                {term.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default TermsList;
