import { AttachmentsGroupNode } from '@newStore/documentUI/documentUITypes';

const AttachmentsGroupRow: React.FC<{ contentNode: AttachmentsGroupNode }> = ({ contentNode }) => (
  <div className="curriculumReference">
    {contentNode.attachments.map((attachment, index) => (
      <div key={attachment.attachmentKey}>
        <div
          className={`nopaddings row ${
            attachment.isDeletedByProposal ? 'deleted-hidden-child' : ''
          }`}
          style={{ margin: '5px 5px', padding: 0 }}
        >
          <div className="col-sm-5">
            <div className="text-ellipsis">
              <img src={attachment.fileTypeIconSrc} width="20" />
              <span>{attachment.title}</span>
            </div>
            {index === 0 && attachment.description && (
              <span
                style={{ fontSize: '10px', marginLeft: '25px' }}
                dangerouslySetInnerHTML={{ __html: attachment.description }}
              />
            )}
          </div>

          <div className="col-sm-2 text-ellipsis">
            <span>{attachment.createDate}</span>
          </div>

          <div className="col-sm-2 text-ellipsis">
            <span>{attachment.fileType}</span>
          </div>

          <div className="col-sm-1 text-ellipsis">
            <span>{attachment.fileSize}</span>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default AttachmentsGroupRow;
