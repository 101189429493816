import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { llinkidDefaultBuildingBlocks } from './llinkidGenericConfigProps';

const LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT: BuildingBlockNodeConfig = {
  information: {
    single: 'Pedagogisch didactische duiding',
    plural: 'Pedagogisch didactische duiding',
  },

  buildingBlocks: llinkidDefaultBuildingBlocks,
  edit: [],
  showPlaceholder: true,
  readOnlyNode: true,
  createInModal: false,
  isNotDraggable: true,
  isNotDeletable: true,
  isCollapsible: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT;
