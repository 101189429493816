import { ZillLeerlijnReferenceStepNode } from '@newStore/documentUI/documentUITypes';
import './ZillLeerlijn.scss';

const ZillLeerlijnReferenceStepRow: React.FC<{ contentNode: ZillLeerlijnReferenceStepNode }> = ({
  contentNode,
}) => {
  return (
    <div className="zill-row-container">
      <div>
        {contentNode.type === 'CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE' && (
          <span
            className="glyphicon glyphicon-flip glyphicon-share-alt"
            style={{ marginLeft: '15px' }}
          ></span>
        )}
        {contentNode.type === 'CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE' && (
          <span className="glyphicon glyphicon-share-alt" style={{ marginLeft: '15px' }}></span>
        )}
      </div>
      {contentNode.genericGoal && (
        <div>
          <span
            style={{
              margin: '1rem',
              color: contentNode.genericGoal.color,
            }}
          >
            {contentNode.genericGoal.identifier}
          </span>
          <span
            dangerouslySetInnerHTML={{
              __html: contentNode.genericGoal.title,
            }}
          ></span>
        </div>
      )}
    </div>
  );
};

export default ZillLeerlijnReferenceStepRow;
