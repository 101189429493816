import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '../helpers/genericComponents';
import { menuItemWithReference } from '../../../deleteConfirmations/menuItemWithReference';
import * as apiRoutes from '../../../reduxLoop/api/apiRoutes';
import { settings } from '../../settings';
import { currentUserAsCreators } from '../../../services/currentUserAsCreators';

const SECTION: BuildingBlockNodeConfig = {
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../img/buildingBlocks/SECTION.svg'),
  },
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.ATTACHMENTS_GROUP },
    { type: NodeType.IMAGE_GROUP },
    {
      type: NodeType.MUSIC,
      hideInAncestorTypes: [
        NodeType.JOB_OFFER,
        NodeType.LLINKID_CURRICULUM,
        NodeType.LLINKID_FOUNDATIONAL_CURRICULUM,
      ],
    },
    { type: NodeType.VIDEO },
    {
      type: NodeType.VIDEO_GROUP,
      hideInAncestorTypes: [
        NodeType.WWW,
        NodeType.WWW_STATIC_PAGE,
        NodeType.PRESS,
        NodeType.JOB_OFFER,
        NodeType.WWWNEWSITEM,
        NodeType.LLINKID_CURRICULUM,
        NodeType.LLINKID_FOUNDATIONAL_CURRICULUM,
      ],
    },
    {
      type: NodeType.QUOTE,
      hideInAncestorTypes: [NodeType.LLINKID_CURRICULUM, NodeType.LLINKID_FOUNDATIONAL_CURRICULUM],
    },
    {
      type: NodeType.WORD_IMPORT,
      options: {
        openImportModal: true,
      },
    },
  ],
  edit: [
    {
      ...titleComponent,
      width: '10',
    },
  ],
  nodeConditional: [
    // not a very good name, this should be refactored to onCreateActions with actions that should be thrown on create of this building block
    {
      field: 'creators',
      value: currentUserAsCreators,
      condition: {
        parent: ['BLOG'],
      },
    },
    {
      field: 'webconfiguration',
      value: {
        type: 'BLOG_ITEM',
        website: {
          href: `${apiRoutes.websites}/${settings.websites.pro}`,
        },
        template: {
          href: '/web/templates/35213fa8-b26b-7ace-92ad-180e5cd0a37c',
        },
      },
      condition: {
        parent: ['BLOG'],
      },
    },
    {
      field: 'webconfiguration',
      value: {
        type: 'MINI_DATABASE_ITEM',
        website: {
          href: `${apiRoutes.websites}/${settings.websites.pro}`,
        },
        template: {
          href: '/web/templates/21363fa8-c06b-4ace-92ad-180e5cd0c41a',
        },
      },
      condition: {
        parent: ['MINI_DATABASE'],
      },
    },
    {
      field: 'webconfiguration',
      value: {
        type: 'THEME_PAGE',
        website: {
          href: `${apiRoutes.websites}/${settings.websites.pro}`,
        },
        template: {
          href: '/web/templates/3e09d09a-11b6-4bfe-a20d-62223633b09b',
        },
      },
      condition: {
        parent: ['THEME_HOME_FULL', 'THEME_HOME_PICTURE', 'THEME_HOME_TEXT'],
      },
    },
  ],
  documentViewIdentifier: 'p.i',
  isCollapsible: true,
  isCollapsedByDefault: false,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
  globalAttachmentsGroupContainer: true,
  customConfirmDeleteMessage: menuItemWithReference,
};
export default SECTION;
