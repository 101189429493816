import { ContentType } from '@generalTypes/apiTypes';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { proWebFacetAncestorTypes } from '@nodeTypeConfig/PRO/proGenericConfigProps';

const ATTACHMENTS_GROUP: BuildingBlockNodeConfig = {
  information: {
    single: 'Bijlagegroep',
    plural: 'Bijlagegroep',
    description:
      'Download or bijlagegroep that is a building block (as opposed to PAGE_ATTACHMENTS_GROUP) and appears inline in the document',
    conditionalTitle: (node) => (node.isDownloadAttachmentsGroup ? 'Download' : 'Bijlagegroep'),
    ribbonTitle: 'Bijlagegroep/Download',
    ribonIcon: require('../../../../img/buildingBlocks/ATTACHMENT.svg'),
  },
  edit: [
    // components downloadDocument and attachmentsGroup render MULTIPLE components themselves so configuration regarding diffText generation and error messages is not working properly for this component
    {
      component: 'downloadDocument',
      label: 'Bijlagen',
      showInAncestorTypes: [
        NodeType.PRO_HOME_PAGE,
        NodeType.PRO_THEME_HOME_FULL,
        NodeType.PRO_THEME_HOME_TEXT,
        NodeType.PRO_THEME_HOME_PICTURE,
        NodeType.PRO_TEXT_PAGE_STANDALONE,
        NodeType.PRO_DATABASE_STANDALONE,
        NodeType.PRO_DOWNLOAD_PAGE_STANDALONE,
        NodeType.PRO_BLOG_STANDALONE,
        NodeType.PRO_GLOBAL_DATABASE,
        NodeType.PRO_TEMPORARY_PAGE_STANDALONE,
        NodeType.PRO_STATIC_PAGE,
      ],
      avoidDispatchDownloadTag: true,
      hiddenChildren: {
        containerType: ContentType.ATTACHMENTS_GROUP,
        items: [
          {
            type: ContentType.ATTACHMENT,
            label: 'bijlage',
          },
          {
            type: ContentType.UNSTRUCTURED_DOCUMENT,
            label: 'gedeeld document',
          },
        ],
      },
      options: {
        validExtensions:
          '.pdf, .jpg, .jpeg, .png, .mp3, .mp4, .doc, .docx, .dotx, .xls, .xlsx, .xltx, .ppt, .pptx, .ppsx, .potx, .tiff',
      },
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      hiddenChildren: {
        containerType: ContentType.ATTACHMENTS_GROUP,
        items: [
          {
            type: ContentType.ATTACHMENT,
            label: 'bijlage',
          },
          {
            type: ContentType.UNSTRUCTURED_DOCUMENT,
            label: 'gedeeld document',
          },
        ],
      },
    },
    {
      component: 'webFacets',
      label: 'Facetten',
      width: '12',
      options: { source: 'parent' },
      showInAncestorTypes: proWebFacetAncestorTypes,
    },
  ],
  addAccessRightsToAudienceTab: true,
  createInModal: true,
  isCollapsible: false,
  hideChildrenInDocument: true,
  confirmDelete: true,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default ATTACHMENTS_GROUP;
