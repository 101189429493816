// import { selectUser } from '@newStore/user/userSelectors';
import { createTypedSelector, parentChildRelationFilter } from '@newStore/genericHelpers';
// import { selectNewDocumentApi } from './documentApiSelectors';
// import { updateApiPending } from '@store/helpers/documentStateHelpers';
import { pathMap } from '@newStore/externalData/externalDataTypes';
import {
  ContentHref,
  Proposal,
  isContentHref,
  isCreateChange,
  isContentRelationHref,
  isPatchChange,
} from '@generalTypes/apiTypes';
import { shallowEqual } from 'react-redux';
import { makeProposalMap } from './documentApiHelpers';

export const selectApiProposalsMap = createTypedSelector(
  [
    (state) => state.documentApi.proposals,
    (state) => state.externalData.data[pathMap.persons].items,
  ],
  (proposals, personsMap): Record<ContentHref, Proposal> => {
    const proposalsMap = makeProposalMap(proposals, personsMap);
    console.log('proposalsMap', proposalsMap);
    return proposalsMap;
  }
);

const getAllDescendantsNodeHrefsCreatedInPendingActions = (
  nodeHrefs: Array<ContentHref>,
  pendingActions
) => {
  const childNodeHrefs: Array<ContentHref> = [];
  pendingActions.forEach((pendingAction) => {
    if (pendingAction.type === 'CREATE') {
      pendingAction.resources.forEach((resource) => {
        if (
          isContentRelationHref(resource.href) &&
          nodeHrefs.includes(resource.body.to.href) &&
          parentChildRelationFilter(resource.body)
        ) {
          childNodeHrefs.push(resource.body.from.href);
        }
      });
    }
  });
  if (childNodeHrefs.length) {
    return [
      ...nodeHrefs,
      ...getAllDescendantsNodeHrefsCreatedInPendingActions(childNodeHrefs, pendingActions),
    ];
  }
  return nodeHrefs;
};

const emptyArray = [];
/**
 * selects all nodes that are part of a submitted proposal
 * this means:
 * - all nodes updated by a submitted proposal
 * - all nodes created by a submitted proposal
 * - all created pending nodes that are a descendant of a created submitted proposal (because they depend on being created as a part of the "newly created root node of the proposal")
 *
 * We need to know this for when an editor is in review mode and he makes changes to a node or adds new nodes,
 * he can directly edit them so they are imediately created/updated in Content Api and not made as a proposal.
 *
 * BUT when he edits newly created nodes by submitted proposals or adds new nodes under newly created nodes by submitted propsoals
 * it is not possible to update the content directly (therefore changes were not persisted, the code tried to update stuff as content but the patch was not applyable on content)
 *
 * also when a node is just edited by a submitted proposal the editor also wants to continue working on that proposal instead of creating content
 * because content would be overwritten again by the proposal.
 * (if it is just an update, creating child nodes can be just content, no need to make these child nodes proposals [old redactie did also make those nodes a proposal however])
 */
export const selectNodeHrefsThatArePartOfSubmittedProposal = createTypedSelector(
  [
    (state) => state.documentApi.proposals,
    (state) => state.document.pendingActions,
    (state) => state.documentUI.mode,
  ],
  (proposals, pendingActions, mode) => {
    if (mode !== 'REVIEW') {
      return emptyArray;
    }

    const submittedProposals = Object.values(proposals).filter(
      (p) => p.status === 'SUBMITTED_FOR_REVIEW'
    );

    const nodeHrefsCreatedInSubmittedProposal = submittedProposals.flatMap((p) => {
      const nodeHrefs: Array<ContentHref> = [];
      p.listOfRequestedChanges.forEach((c) => {
        if (isCreateChange(c) && isContentHref(c.appliesTo.href)) {
          nodeHrefs.push(c.appliesTo.href);
        }
      });
      return nodeHrefs;
    });

    const nodeHrefsUpdatedInSubmittedProposal = submittedProposals.flatMap((p) => {
      const nodeHrefs: Array<ContentHref> = [];
      p.listOfRequestedChanges.forEach((c) => {
        if (isPatchChange(c)) {
          if (isContentHref(c.appliesTo.href)) {
            nodeHrefs.push(c.appliesTo.href);
          } else if (c.relatedTo && isContentHref(c.relatedTo.href)) {
            nodeHrefs.push(c.relatedTo.href);
          }
        }
      });
      return nodeHrefs;
    });
    return [
      ...nodeHrefsUpdatedInSubmittedProposal,
      ...getAllDescendantsNodeHrefsCreatedInPendingActions(
        nodeHrefsCreatedInSubmittedProposal,
        pendingActions
      ),
    ];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual, // compares the reference of each item in the array
    },
  }
);
