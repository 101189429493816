import { selectStudyProgrammeGroups } from '@newStore/externalData/externalDataSelectors';
import { selectContentItemStudyProgrammes } from '@newStore/llinkid/llinkidSelectors';
import { grade } from '@store/constants/studyProgrammeGroupTypes';
import { ContentHref } from '@generalTypes/apiTypes';
import { createTypedSelector } from '../../genericHelpers';
import { createError } from '../validationHelpers';
import { ValidationError } from '../validationTypes';

const validationError = createError(
  'studyProgrammesWithDifferentGradesValidation',
  'selectLlinkidCurriculumApplicabilityRule',
  'Alle studierichtingen moeten tot dezelfde graad behoren.',
  'applicability'
);

export const selectLlinkidCurriculumApplicabilityRule = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItemStudyProgrammes(state, href),
    (state) => selectStudyProgrammeGroups(state),
  ],
  (studyProgrammes, studyProgrammeGroups): true | ValidationError => {
    // we iterate all studyProgrammes extracting the grade studyProgrammesGroups from them. If, after making them unique, we have length greater than one it means we have studyProgrammes with different grades there, and that is not allowed

    const gradeHrefs = Object.values(studyProgrammeGroups)
      .filter((stProgGroup) => stProgGroup.type.href === grade.href)
      .map((stProgGroup) => stProgGroup.$$meta.permalink);

    const hasMoreThanOneGrade =
      [
        ...new Set(
          studyProgrammes.map((studyProgramme) => {
            const studyProgrammeGradeGroup = studyProgramme?.studyProgrammeGroups?.find(
              (stProgrGroupRel) => gradeHrefs.includes(stProgrGroupRel.studyProgrammeGroup?.href)
            );

            return studyProgrammeGradeGroup?.studyProgrammeGroup.href;
          })
        ),
      ].length > 1;

    if (hasMoreThanOneGrade) {
      return validationError;
    }

    return true;
  }
);
