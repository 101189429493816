/* eslint-disable max-len */
import uuidv4 from 'uuid/v4';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { getBase64, getResourceKey } from '../../../reduxLoop/helpers/documentHelpers';
import { documentTags } from '../../../reduxLoop/constants/documentTags';
import { isGlobalDocumentAllowedFromAside } from '../../../validations/isGlobalDocumentAllowed';
import { isGlobalDocument } from '../../../reduxLoop/viewmodels/viewModelHelpers';
import { allAuthorsParameters } from '../../../reduxLoop/constants/constants';

require('./asideDownloadDocument.scss');

class asideDownloadDocument {
  constructor(ConfirmationService, $scope, $ngRedux, settings, Utils, constants, searchApi) {
    'ngInject';

    this.confirmationService = ConfirmationService;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.utils = Utils;
    this.constants = constants;
    this.searchApi = searchApi;
    this.searchAuthorsParameters = allAuthorsParameters;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      // main attachment is always the first one
      const mainAttachment =
        state.document.viewModel.aside.editDocument.attachmentResources.length > 0
          ? state.document.viewModel.aside.editDocument.attachmentResources[0]
          : undefined;
      const validatorAttachments = mainAttachment ? [mainAttachment] : [];

      const isMainDocumentGlobalDocument = mainAttachment && isGlobalDocument(mainAttachment);

      const connectedScope = {
        globalDocumentAllowed: isGlobalDocumentAllowedFromAside(
          state.document,
          validatorAttachments
        ),
        uploadedDocument: !isMainDocumentGlobalDocument ? mainAttachment : {},
        attachmentFile:
          !isMainDocumentGlobalDocument && mainAttachment
            ? mainAttachment.attachments.find((a) => a.type === 'ATTACHMENT')
            : undefined,
        selectedGlobalDocument: isMainDocumentGlobalDocument ? mainAttachment : undefined,
        isDownloadAttachmentsGroup:
          state.document.viewModel.aside.editDocument.isDownloadAttachmentsGroup,
        loading: false,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
        type: isMainDocumentGlobalDocument ? 'global' : 'upload',
      };
      return connectedScope;
    })(this);

    if (this.uploadedDocument && this.uploadedDocument.creators) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.loadDocumentAuthorsAction(
          this.uploadedDocument.key,
          this.uploadedDocument.creators
        )
      );
    }
  }

  $onDestroy() {
    this.unsubscribe();
  }

  globalDocumentSelected(e, model) {
    // Remove uploadedDocument if doesn't have a relation property
    // to avoid a undefined property typeError.
    if (this.uploadedDocument && this.uploadedDocument.$$relation === undefined) {
      this.uploadedDocument = undefined;
    }

    if (!this.selectedGlobalDocument && !this.uploadedDocument) {
      // add global document relation add main attachment
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.addGlobalDocumentRelationAction(
          this.sDocument.key,
          model,
          this.sConfig.options.global
        )
      );
    } else {
      // edit existent global document relation
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.editGlobalDocumentRelationAction(
          this.sDocument.key,
          model,
          this.selectedGlobalDocument
            ? this.selectedGlobalDocument.$$relation
            : this.uploadedDocument.$$relation,
          this.sConfig.options.global
        )
      );
    }

    if (this.sConfig.options.copyTitle) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.patchNodeAction(this.sDocument.key, {
          title: model.title,
        })
      );
    }
    this.selectedGlobalDocument = model;
    this.uploadedDocument = {};
  }

  async attachmentChanged(e, model) {
    console.log('In aside download document normal changed:', model);

    const base64 = await getBase64(model.file);
    const newAttachment = {
      key: uuidv4(),
      type: model.type,
      name: model.file.name,
      size: model.file.size,
      $$base64: base64,
      isNew: true,
      created: new Date(),
      contentType: model.file.type,
    };

    // check when replace old global document relation with attachment

    if (this.selectedGlobalDocument) {
      // previously had a global document selected we need to replace it
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.replaceGlobalDocumentRelationWithAttachmentAction(
          getResourceKey(this.selectedGlobalDocument.$$relation.to.href),
          this.selectedGlobalDocument.$$relation.key,
          {
            tags: this.uploadedDocument.tags ? this.uploadedDocument.tags.map((t) => t.value) : [],
            description: this.uploadedDocument.description,
            title: this.uploadedDocument.title,
            creators: this.uploadedDocument.authors
              ? this.uploadedDocument.authors.map((s) => s.$$meta.permalink)
              : undefined,
          },
          newAttachment,
          model.file
        )
      );
    } else if (this.uploadedDocument && this.uploadedDocument.key) {
      // replacing a normal attachment upload
      if (this.attachmentFile) {
        if (
          this.attachmentFile.name !== model.file.name &&
          this.attachmentFile.originalName !== model.file.name
        ) {
          this.changedFileName = true;
        }

        this.$ngRedux.dispatch(
          DOCUMENT_ACTIONS.removeAttachment(this.uploadedDocument.key, this.attachmentFile.key)
        );
      }
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.addAttachment(
          this.uploadedDocument.key,
          newAttachment,
          model.file,
          this.sDocument.key
        )
      );
    } else {
      // add new normal attachment as main document
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.addAttachmentToNodeAction(
          this.sDocument.key,
          {
            tags:
              this.uploadedDocument && this.uploadedDocument.tags
                ? this.uploadedDocument.tags.map((t) => t.value)
                : [],
            description: this.uploadedDocument ? this.uploadedDocument.description : undefined,
            title: this.uploadedDocument ? this.uploadedDocument.title : undefined,
            creators:
              this.uploadedDocument && this.uploadedDocument.authors
                ? this.uploadedDocument.authors.map((a) => a.$$meta.permalink)
                : undefined,
          },
          newAttachment,
          model.file
        )
      );
    }

    this.selectedGlobalDocument = undefined;
    this.type = 'upload';

    this.$scope.$apply();
  }

  attachmentRemoved() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.removeNodeAction(this.uploadedDocument.key, true, this.sDocument.key)
    );
    this.uploadedDocument = {};
  }

  attachmentFieldChanged(field, value) {
    if (this.uploadedDocument && this.uploadedDocument.key) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.patchNodeAction(
          this.uploadedDocument.key,
          {
            [field]: value || this.uploadedDocument[field],
          },
          true,
          this.sDocument.key
        )
      );
    }
    if (field === 'title' && this.uploadedDocument?.title && this.sConfig.options.copyTitle) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.patchNodeAction(this.sDocument.key, {
          title: this.uploadedDocument.title,
        })
      );
    }
  }

  changeAuthorsKOV(e, selection, list) {
    console.log(list);

    if (this.uploadedDocument && this.uploadedDocument.key) {
      const authors = list.map((s) => s.$$meta.permalink);
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.patchNodeAction(this.uploadedDocument.key, { creators: authors })
      );
    } else {
      this.uploadedDocument.authors = list;
    }
  }

  getStableLink() {
    if (!this.uploadedDocument || !this.uploadedDocument.file) {
      return '';
    }
    const href = this.uploadedDocument.proposal
      ? `/content/${this.uploadedDocument.key}/attachments/${this.uploadedDocument.file.name}`
      : this.uploadedDocument.file.href;

    return this.sDocument.$$root.tags.includes(documentTags.webPage2) ||
      this.sDocument.$$root.tags.includes(documentTags.proNewsItem)
      ? `${this.settings.apisAndUrls.proWebsite}/download${href}`
      : this.settings.apisAndUrls.contentApi + href;
  }

  copyStableLink() {
    const copyText = document.getElementById('copy-link');
    copyText.select();
    document.execCommand('copy');
  }

  author2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return `${resource.firstName} ${resource.lastName}`;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }
    }
    return '<NONE>';
  }

  proposalLineStyle(row) {
    if (row.proposal) {
      const line = row.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      const color = row.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = row.proposal.isDeleted ? ' is-deleted' : '';
      const isReviewing = row.proposal.isReviewingMode ? ' is-reviewing' : '';
      return `${line} ${color}${isDeleted}${isReviewing}`;
    }
    return '';
  }

  hasDeleteProposal(row) {
    return row.proposal && row.proposal.type === 'DELETE';
  }
}

export default {
  template: require('./asideDownloadDocument.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<',
  },
  controller: asideDownloadDocument,
};
