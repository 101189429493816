/* eslint-disable max-len */
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class LlinkidPreviousVersionModal {
  constructor($notification, $translate, $ngRedux, $scope, Utils) {
    'ngInject';

    this.notificationService = $notification;
    this.translationService = $translate;

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.utils = Utils;
  }

  $onInit() {
    this.placeholder = this.resolve.modalData.placeholder;
    this.relationName = this.resolve.modalData.relation;
    this.errorMessage = this.resolve.modalData.errorMessage;
    this.nodeType = this.resolve.modalData.type;
    this.defaultCurriculumKey = this.resolve.modalData.defaultCurriculumKey;
    this.selectedValues = this.resolve.modalData.selectedValues;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const curriculums = state.document.llinkidCurriculums.filter(
        (c) => c.key !== this.resolve.modalData.editDocumentRootKey
      );

      curriculums.forEach((c) => {
        c.displayTitle = this.utils.formatCurrDisplayTitle(c);
      });

      let goals = [];
      let previousVersionItems = [];
      if (this.selectedCurriculum) {
        const curr = curriculums.find((c) => c.key === this.selectedCurriculum.key);
        goals = curr.$$goals;
        previousVersionItems = curr.$$previousVersionItems;

        if (this.selectedValues && previousVersionItems) {
          previousVersionItems = previousVersionItems.filter(
            (pt) => !this.selectedValues.includes(pt.key)
          );
        }
      }

      if (curriculums.length && this.defaultCurriculumKey && !this.selectedCurriculum) {
        this.selectedCurriculum = curriculums.find((c) => c.key === this.defaultCurriculumKey);
        this.changedCurriculum();
      }

      return {
        curriculums,
        goals: goals || [],
        previousVersionItems: previousVersionItems || [],
        allPreviousVersionItems: previousVersionItems || [],
        loadingGoals: this.selectedCurriculum && this.selectedCurriculum.$$loadingGoals,
        loadingCurr: this.defaultCurriculumKey && curriculums.length === 0,
        loadingPreviousVersionItems:
          this.selectedCurriculum && this.selectedCurriculum.$$loadingPreviousVersionItems,
      };
    })(this);

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.getAllOfTypeAction(
        {
          type: 'LLINKID_CURRICULUM',
          orderBy: 'title',
          limit: 6000,
        },
        {
          stateField: 'llinkidCurriculums',
          removeIssuedHour: true,
        }
      )
    );
  }

  $onDestroy() {
    this.unsubscribe();
  }

  hasError() {
    if (!this.selectedCurriculum) {
      this.notificationService.error(
        this.translationService.instant('components.llinkid.goalImplements.error.invalidCurriculum')
      );
      return true;
    }

    if (!this.selectedPreviousVersionItems) {
      this.notificationService.error(this.translationService.instant(this.errorMessage));
      return true;
    }
    return false;
  }

  async submit() {
    if (!this.hasError()) {
      this.modalInstance.close({
        goal: this.selectedGoal,
        previousVersion: this.selectedPreviousVersionItems,
      });
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  changedCurriculum() {
    this.selectedGoal = null;
    this.selectedPreviousVersionItems = null;
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.loadLlinkidCurriculumGoalsAction(this.selectedCurriculum?.key)
    );

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.loadLlinkidCurriculumPreviousVersionItemsAction(
        this.selectedCurriculum?.key,
        this.nodeType
      )
    );
  }

  changedGoal() {
    this.selectedPreviousVersionItems = null;

    if (!this.selectedGoal) {
      this.previousVersionItems = this.allPreviousVersionItems;
      return;
    }

    const goalHref = this.selectedGoal.$$meta.permalink;
    this.previousVersionItems = this.allPreviousVersionItems.filter((pt) => {
      const isPartOfGoal = pt.$$relationsFrom.some((r) => r.$$expanded.to.href === goalHref);
      return isPartOfGoal;
    });
  }
}

export default {
  template: require('./llinkidPreviousVersionModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: LlinkidPreviousVersionModal,
};
