import { ContentRelation } from '@generalTypes/apiTypes';
import Button from '@UI/button/Button';
import React from 'react';

const AsideRelationsPanel: React.FC<{
  title: string;
  items: ContentRelation[];
  readOnly: boolean;
  loading?: boolean;
  onCreate: () => void;
  onEdit?: ((item: ContentRelation) => void) | null;
  onDelete?: (item: ContentRelation) => void;
  children: (item: ContentRelation) => React.ReactNode;
}> = ({ title, children, items, readOnly, loading = false, onCreate, onEdit, onDelete }) => {
  return (
    <article className="panel panel-default">
      <header className="panel-heading">
        <div className="panel-controls panel-controls-left">
          <h3 className="panel-title">{title}</h3>
        </div>
        <div className="panel-controls panel-controls-right">
          {!readOnly && (
            <Button icon="glyphicon-add" onClick={onCreate}>
              Voeg toe
            </Button>
          )}
        </div>
      </header>

      {loading && <div className="shimmer narrowLine"></div>}
      {!loading && (
        <div className="structure">
          {items?.map((item) => (
            <div className="row table-row" key={item.key}>
              <div onClick={() => onEdit?.(item)}>{children(item)}</div>

              {!readOnly && (
                <div className="btn-group btn-group-xs row-actions" role="group" aria-label="...">
                  {onEdit && <Button icon="glyphicon-edit" onClick={() => onEdit?.(item)} />}
                  {onDelete && <Button icon="glyphicon-delete" onClick={() => onDelete?.(item)} />}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </article>
  );
};

export default AsideRelationsPanel;
