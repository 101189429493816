import { VideoNode } from '@newStore/documentUI/documentUITypes';

const VideoRow: React.FC<{ contentNode: VideoNode }> = ({ contentNode }) => {
  return (
    <div className="center">
      <iframe
        width="415"
        height="250"
        frameBorder="0"
        allowFullScreen
        src={contentNode.videoSrc}
      ></iframe>
    </div>
  );
};

export default VideoRow;
