module.exports = [
  function () {
    //eslint-disable-line
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const onChangeHandler = scope.$eval(attrs.fileToUploadChange);

        element.on('change', (...args) => onChangeHandler(scope, ...args));

        element.on('$destroy', function () {
          element.off();
        });
      },
    };
  },
];
