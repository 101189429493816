import { selectApiWithPendingChangesOldSlice } from '@newStore/documentApi/documentApiSelectors';

import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectExternalContent } from '@newStore/externalData/externalDataSelectors';
import {
  addResourceAncestorsToLoad,
  addResourcesToLoad,
} from '@newStore/externalData/externalDataState';
import { expandLlinkidGoalRelations, loadPreviousVersion } from '@newStore/llinkid/llinkidActions';
import { put, select, takeEvery } from 'redux-saga/effects';
import { selectPreviousVersionHrefs } from './llinkidSelectors';

function* expandLlinkidGoalRelationsSaga({
  payload,
}: ReturnType<typeof expandLlinkidGoalRelations>) {
  const { key, relationTypes } = payload;

  const originPart = payload.originPart ?? 'from';
  const endPart = originPart === 'from' ? 'to' : 'from';

  // expand the to part of the from relations of the given llinkid goal
  const apiWithPendingChanges = yield select(selectApiWithPendingChangesOldSlice);
  const relationsPart = apiWithPendingChanges.contentRelations[originPart];
  const relations = (relationsPart[`/content/${key}`] || []).filter((rel) => {
    return relationTypes.includes(rel.relationtype);
    // && (!rel[endPart].$$expanded || !rel[endPart].$$expanded.$$treeAsLeaf)
  });

  const loadedAncestors: Set<string> = new Set(
    yield select((state: RootState) => state.externalData.loadedAncestors)
  );

  const resourceAncestorsToLoadSet: Set<string> = new Set(
    yield select((state: RootState) => state.externalData.resourceAncestorsToLoad)
  );
  const hrefs = relations
    .map((rel) => rel[endPart].href)
    .filter((z) => !resourceAncestorsToLoadSet.has(z) && !loadedAncestors.has(z));

  if (hrefs.length > 0) {
    yield put(addResourceAncestorsToLoad({ resources: hrefs }));
  }
}

function* loadPreviousVersionSaga({ payload }: ReturnType<typeof loadPreviousVersion>) {
  const { href } = payload;

  const previousCurrHrefs = yield select((state: RootState) =>
    selectPreviousVersionHrefs(state, href)
  );

  const resourcesToLoadSet: Set<ContentHref> = new Set(
    yield select((state: RootState) => {
      const contentMap = selectExternalContent(state);
      const { resourcesToLoad } = state.externalData;
      return previousCurrHrefs.filter((z) => !contentMap[z] && !resourcesToLoad.includes(z));
    })
  );

  if (resourcesToLoadSet.size > 0) {
    yield put(addResourcesToLoad({ resources: Array.from(resourcesToLoadSet) }));
  }
}

export function* watchExternalDataSaga() {
  yield takeEvery(expandLlinkidGoalRelations.match, expandLlinkidGoalRelationsSaga);
  yield takeEvery(loadPreviousVersion.match, loadPreviousVersionSaga);
}
