import {
  proAccessRightsComponent,
  proThemeAudienceTab,
} from '@nodeTypeConfig/PRO/proGenericConfigProps';
import { RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import * as ACTION_TYPES from '@store/constants/actionTypes';
// import { globalDocumentDeleteValidationCmd } from '../../reduxLoop/commands/documentListCommands';
import { getAccessRightsToStringFunction } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { accessRights } from '../../../reduxLoop/helpers/accessRights';
import { authorsComponent, identifierComponent } from '../helpers/genericComponents';

const GLOBAL_DOCUMENT: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Gedeeld document',
    plural: 'Gedeelde documenten',
  },
  preloadActions: [
    {
      type: ACTION_TYPES.GET_IS_INCLUDED_IN_PRO_THEME,
    },
  ],

  createDefaults: { accessRights: accessRights.publiek.namedsets },
  buildingBlocks: [],
  tocTypes: [],
  createModal: {
    identifier: {},
    attachmentDocumentType: { required: true },
    authors: { addUser: true },
    uploadAttachment: { required: true },
  },
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      label: 'Titel',
      required: RequiredType.ERROR,
      width: '10',
    },
    {
      ...identifierComponent,
      width: '2',
      label: 'Referentienummer',
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Inleiding / korte inhoud',
      required: RequiredType.WARNING,
    },
    authorsComponent,
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: {
    ...proAccessRightsComponent,
    property: 'accessRights',
    valueToString: getAccessRightsToStringFunction('accessRights'),
  },
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  allowSuggestions: false,
};

export default GLOBAL_DOCUMENT;
