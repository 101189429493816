import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/genericComponents';

const FAQ: BuildingBlockNodeConfig = {
  information: {
    single: 'Faq',
    plural: 'Faqs',
    ribonIcon: require('../../../../img/buildingBlocks/FAQ.svg'),
  },

  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.IMAGE },
    { type: NodeType.VIDEO },
    { type: NodeType.ATTACHMENTS_GROUP },
    { type: NodeType.QUOTE },
  ],
  edit: [
    {
      ...titleComponent,
      label: 'Vraag',
    },
  ],
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default FAQ;
