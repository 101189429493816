import { RequestedChange } from '@generalTypes/apiTypes';
import { NodeTypeConfigApplied } from '@nodeTypeConfig/configTypes';
import { wrapPropertyLabel } from './proposalHelpers';

const translateOperation = (change: RequestedChange) => {
  if (change.type === 'CREATE') {
    return 'toegevoegd';
  }
  if (change.type === 'DELETE') {
    return 'verwijderd';
  }
  return 'aangepast';
};

export const getMessageForReferenceRelation = (
  change: RequestedChange,
  nodeTypeConfig: NodeTypeConfigApplied
) => {
  const component = nodeTypeConfig.edit.find((c) => c.property === 'referenceRelation.to');
  return {
    message: `${
      component ? wrapPropertyLabel`${component.label}: ` : ''
    } referentie ${translateOperation(change)}`,
    property: 'referenceRelation.to',
  } as const;
};
